/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import ErrorService from 'core/error.service';

// data
import LaravelAPI from '@data/api/laravel.api';
import { Meta, PaginateResult } from '@data/models/api.interface';
import {
  StoredSearch,
  StoredSearchHttp,
  StoredSearchWizard,
} from '@data/models/stored-search.interface';
import { toStoredSearch } from '@data/dto/stored-search.dto';
import { FilterParams } from '@data/models/filter.interface';

@injectable()
export default class StoredSearchService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private errorService = container.get<ErrorService>(ErrorService);
  private readonly storedSearchesEndpoint = '/stored-searches';

  public async listStoredSearches(
    filters: FilterParams,
  ): Promise<PaginateResult<StoredSearch[]>> {
    const { data: response, error } = await this.API?.get<{
      data: StoredSearchHttp[];
      meta: Meta;
    }>(this.storedSearchesEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map((search) => toStoredSearch(search)) || [],
      totalSize: response?.data?.length || 0,
    };
  }

  public async upsertStoredSearch({
    id,
    ...storedSearchWizard
  }: StoredSearchWizard): Promise<StoredSearch | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<StoredSearchWizard, 'id'> },
        { data: StoredSearchHttp }
      >(this.storedSearchesEndpoint, { data: storedSearchWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toStoredSearch(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: StoredSearchWizard },
      { data: StoredSearchHttp }
    >(`${this.storedSearchesEndpoint}/${id}`, {
      data: { ...storedSearchWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    return toStoredSearch(data?.data || {});
  }

  public async deleteStoredSearch({
    id,
  }: StoredSearch): Promise<StoredSearch | void> {
    if (!id) {
      throw new Error('Id is missing');
    }

    const { data, error } = await this.API?.delete<{
      data: StoredSearchHttp;
    }>(`${this.storedSearchesEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toStoredSearch(data?.data || {});
  }
}
