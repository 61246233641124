import { lazy } from 'react';

import { NavRoute } from '@data/models/navigation.interface';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';

const ProjectOriginList = lazy(() => import('@pages/ProjectOrigin/List'));
const RegionList = lazy(() => import('@pages/Region/List'));
const ActivityFieldList = lazy(() => import('@pages/ActivityField/List'));
const DeviceTypeList = lazy(() => import('@pages/DeviceType/List'));

const MinWageList = lazy(() => import('@pages/MinWage/List'));

const PecRateList = lazy(() => import('@pages/PecRate/List'));

const OpcoChargeList = lazy(() => import('@pages/OpcoCharge/List'));

const StrategicAxisList = lazy(() => import('@pages/StrategicAxis/List'));

const CoFunderList = lazy(() => import('@pages/CoFunder/List'));

const GroupMigrationList = lazy(() => import('@pages/GroupMigration/List'));

const CustomerType = lazy(() => import('@pages/CustomerType/List'));

const CourseTypeList = lazy(() => import('@pages/CourseType/List'));

const ValidationTypeList = lazy(() => import('@pages/ValidationType/List'));

const ControlOrganizationList = lazy(
  () => import('@pages/ControlOrganization/List'),
);

const Blocklist = lazy(() => import('@pages/Blocklist/List'));

const UserList = lazy(() => import('@pages/Manager/List'));

const CandidateTypeList = lazy(() => import('@pages/CandidateType/List'));

const FinancingTypeList = lazy(() => import('@pages/FinancingType/List'));

export const repositoriesRoutes: NavRoute[] = [
  {
    name: 'navigation.links.strategic_axis',
    path: '/strategic-axis',
    component: <StrategicAxisList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.blocklist',
    path: '/blocklists',
    component: <Blocklist />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.opco_charge',
    path: '/opco-charges',
    component: <OpcoChargeList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.co_funders',
    path: '/co-funders',
    component: <CoFunderList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.group_migrations',
    path: '/group-migration',
    component: <GroupMigrationList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.activity_field',
    path: '/activity-fields',
    component: <ActivityFieldList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.project_origins',
    path: '/project-origins',
    component: <ProjectOriginList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.control_organization',
    path: '/control-organizations',
    component: <ControlOrganizationList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.regions',
    path: '/regions',
    component: <RegionList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.min_wage',
    path: '/min-wages',
    component: <MinWageList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.pec_rate',
    path: '/pec-rates',
    component: <PecRateList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.customer_type',
    path: '/customer-types',
    component: <CustomerType />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.device_type',
    path: '/device-types',
    component: <DeviceTypeList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.course_type',
    path: '/course-types',
    component: <CourseTypeList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.validation_type',
    path: '/validation-types',
    component: <ValidationTypeList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.managers',
    path: '/managers',
    component: <UserList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.candidate_type',
    path: '/candidate-types',
    component: <CandidateTypeList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
  {
    name: 'navigation.links.financing_type',
    path: '/financing-types',
    component: <FinancingTypeList />,
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faBookmark} />,
  },
];
