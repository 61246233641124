import { Api } from 'constants/api';
import { System } from 'constants/system';
import { Delays } from 'constants/delays';
import { Routes } from 'constants/routes';

export default {
  Api: Api,
  System: System,
  Delays: Delays,
  Routes: Routes,
};
