import day from '@_plugins/dayjs';
import dayjs from '@_plugins/dayjs';
import { FilterParams } from '@data/models/filter.interface';
import { ManagerHttp } from '@data/models/manager.interface';
import {
  Region,
  RegionHttp,
  RegionWizard,
} from '@data/models/region.interface';

import { toManager } from './manager.dto';

export const toRegion = (
  region: Partial<RegionHttp>,
  managers: ManagerHttp[] = [],
): Region => {
  const managerId = region.relationships?.manager.data?.id;

  const manager =
    (managerId && managers.find(({ id }) => id === managerId)) || null;

  return {
    id: region.id || '',
    name: region.attributes?.name || '',
    createdAt: day(region.attributes?.createdAt),
    initials: region.attributes?.initials || '',
    director: region.attributes?.regional_director || '',
    updatedAt: day(region.attributes?.updatedAt),
    manager: (manager && toManager(manager)) || null,
    directionCode: region.attributes?.direction_code || '',
  };
};

export const toRegionWizard = (region: Partial<Region>): RegionWizard => {
  const managerId = region.manager?.id;
  return {
    type: 'region_repositories',
    id: region.id || '',
    relationships: {
      manager: {
        data:
          (managerId && {
            id: managerId,
            type: 'manager_repositories',
          }) ||
          null,
      },
    },
    attributes: {
      name: region.name || '',
      initials: region.initials || '',
      regional_director: region.director || '',
      direction_code: region.directionCode || '',
    },
  };
};

export const toRegionFilterParams = ({
  orderBy,
  filter,
  ...filterParams
}: FilterParams): FilterParams => {
  const mapPropsToHttp = (orderBy?: string): string | undefined => {
    switch (orderBy) {
      case 'director':
        return 'regional_director';
      case 'name':
        return 'name';
      default:
        return orderBy;
    }
  };

  const renameFilter = Object.keys(filter || {}).reduce(
    (acc, cur) => {
      const propName = mapPropsToHttp(cur);
      if (!propName) {
        return acc;
      }
      acc[propName] = (filter || {})[cur] || '';
      return acc;
    },
    {} as {
      [key: string]: string | number | string[] | undefined;
    },
  );

  return {
    ...filterParams,
    orderBy: mapPropsToHttp(orderBy),
    filter: renameFilter,
  };
};

export const fromStoredSearchRegion = ({
  id,
  name,
}: Pick<Region, 'id' | 'name'>): Region => {
  return {
    createdAt: dayjs(),
    directionCode: '',
    director: '',
    id: id,
    initials: '',
    manager: toManager({}),
    name: name,
    updatedAt: dayjs(),
  };
};
