import day from '@_plugins/dayjs';
import { toApiDate } from '@data/dto/api.dto';
import {
  PERIOD_TYPE,
  TrainingActionCandidatePeriod,
  TrainingActionCandidatePeriodHttp,
  TrainingActionCandidatePeriodWizard,
} from '@data/models/training-action-candidate-period.interface';

import { toApiFloat } from './api.dto';

export const toTrainingActionCandidatePeriod = ({
  id,
  attributes,
  relationships,
}: Partial<TrainingActionCandidatePeriodHttp>): TrainingActionCandidatePeriod => {
  return {
    id: id || '',
    anaelCost: attributes?.anael_cost || '',
    createdAt: day(attributes?.createdAt || undefined),
    updatedAt: day(attributes?.updatedAt || undefined),
    expectedCost: attributes?.expected_cost || '',
    expectedDuration: attributes?.expected_duration || '',
    hourCost: attributes?.hour_cost || '',
    label: attributes?.label || 'Période',
    realCost: attributes?.real_cost || '',
    realDuration: attributes?.real_duration || '',
    fromDate: day(attributes?.from_date || undefined),
    toDate: day(attributes?.to_date || undefined),
    periodType: attributes?.period_type || PERIOD_TYPE.EXTERNAL,
    trainingCourseRate: attributes?.training_course_rate || '',
    trainingCourseTrainingId:
      relationships?.training_course_training?.data?.id || '',
    trainingActionCandidateId:
      relationships?.training_action_candidate?.data?.id || '',
    absenceReason: attributes?.absence_reason || '',
  };
};

export const fromTrainingActionPeriod = (): TrainingActionCandidatePeriod => {
  return {
    id: '',
    anaelCost: '',
    createdAt: day(''),
    updatedAt: day(''),
    expectedCost: '',
    expectedDuration: '',
    realDuration: '',
    periodType: PERIOD_TYPE.EXTERNAL,
    hourCost: '',
    label: 'Période',
    realCost: '',
    fromDate: day(''),
    toDate: day(''),
    trainingCourseRate: '0',
    absenceReason: '',
    trainingCourseTrainingId: 'xxxxx-xxxxx-xxxxx-xxxxx',
    trainingActionCandidateId: 'xxxxx-xxxxx-xxxxx-xxxxx',
  };
};

export const toTrainingActionCandidatePeriodWizard = (
  period: Partial<TrainingActionCandidatePeriod>,
): TrainingActionCandidatePeriodWizard => {
  return {
    type: 'training_action_candidate_periods',
    id: period.id || '',
    attributes: {
      anael_cost: toApiFloat(period.anaelCost),
      expected_cost: toApiFloat(period.expectedCost),
      expected_duration: toApiFloat(period.expectedDuration),
      period_type: period.periodType || PERIOD_TYPE.EXTERNAL,
      hour_cost: toApiFloat(period.hourCost),
      label: period.label || 'Période',
      real_cost: toApiFloat(period.realCost),
      real_duration: toApiFloat(period.realDuration),
      from_date: toApiDate(period.fromDate),
      to_date: toApiDate(period.toDate),
      absence_reason: period.absenceReason || '',
      training_course_rate: toApiFloat(period.trainingCourseRate || '0'),
    },
    relationships: {
      training_course_training: {
        data: {
          id: period?.trainingCourseTrainingId || '',
          type: 'training_course_trainings',
        },
      },
      training_action_candidate: {
        data: {
          id: period?.trainingActionCandidateId || '',
          type: 'training_action_candidates',
        },
      },
    },
  };
};
