import { lazy } from 'react';

import i18n from '@_plugins/i18n';
import EditSkeleton from '@components/Skeleton/EditSkeleton';
import ListSkeleton from '@components/TrainingCourse/Skeleton/ListSkeleton';
import { presets } from '@components/Transition/presets';
import { NavRoute } from '@data/models/navigation.interface';
import { User } from '@data/models/user.interface';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import {
  faCalculator,
  faChartPie,
  faCog,
  faFolder,
  faHomeLg,
  faHouse,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';
import ClearStorage from '@pages/ClearStorage/ClearStorage';
import DashboardSkeleton from '@pages/Dashboard/components/DashboardSkeleton';
import DelegationForm from '@pages/Delegation/DelegationForm';
import DelegationList from '@pages/Delegation/DelegationList';
import DelegationWizard from '@pages/Delegation/DelegationWizard';
import UserNotificationListSkeleton from '@pages/UserNotification/components/ListSkeleton';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';

import { repositoriesRoutes } from './repositories';
import { trainingCourseActionRoutes } from './training-action';
import { trainingCourseRoutes } from './training-courses';

const Dashboard = lazy(() => import('@pages/Dashboard/Main'));

const UserNotificationList = lazy(() => import('@pages/UserNotification/List'));

const EditContext = lazy(() => import('@pages/TrainingCourseContext/Context'));

const EditProgram = lazy(() => import('@pages/TrainingCourseProgram/Program'));

const EditCandidate = lazy(
  () => import('@pages/TrainingCourseCandidate/Candidate'),
);

const EditEstimator = lazy(
  () => import('@pages/TrainingCourseEstimator/Estimator'),
);

const EditValidation = lazy(
  () => import('@pages/TrainingCourseValidation/Validation'),
);
const TrainingCourseList = lazy(() => import('@pages/TrainingCourseList/List'));

const TrainingCatalog = lazy(() => import('@pages/TrainingCatalog/Index'));

const Statistic = lazy(() => import('@pages/Statistic/List'));

const EstimatorConfiguration = lazy(
  () => import('@pages/EstimatorConfiguration/Main'),
);

const TrainingOutsideCatalog = lazy(
  () => import('@pages/TrainingOutsideCatalog/List'),
);

const NotionPage = lazy(() => import('@pages/NotionPage/Index'));

export const routes: NavRoute[] = [
  {
    name: 'navigation.links.dashboard',
    path: '/dashboard',
    component: <Dashboard />,
    skeleton: <DashboardSkeleton />,
    permissions: ['erpf.training-course.list'],
    icon: <IconProvider icon={faHouse} />,
  },
  {
    name: 'user_notification.list.all', // todo : change name
    path: '/manager/user-notifications',
    component: <UserNotificationList />,
    skeleton: <UserNotificationListSkeleton />,
    permissions: ['erpf.training-course.list'],
    hidden: true,
    icon: <IconProvider icon={faUser} />,
  },
  {
    name: 'navigation.links.notion', // todo : change name
    path: '/manager/notion/:id/:childId?',
    component: <NotionPage />,
    skeleton: <UserNotificationListSkeleton />,
    permissions: ['erpf.training-course.list'],
    hidden: true,
    icon: <IconProvider icon={faUser} />,
  },
  {
    name: 'navigation.links.training-course-estimator',
    path: '/manager/training-course/:ref/validation/:action?',
    component: <EditValidation />,
    skeleton: <EditSkeleton />,
    permissions: ['erpf.training-course.list'],
    hidden: true,
    icon: <IconProvider icon={faCalculator} />,
  },
  {
    name: 'navigation.links.training-actions',
    path: '/manager/training-actions/:ref',
    permissions: ['erpf.training-course.list'],
    children: trainingCourseActionRoutes,
    hidden: true,
    icon: <IconProvider icon={faHomeLg} />,
  },
  {
    name: 'navigation.links.training-course-estimator',
    path: '/manager/training-course/:ref/estimator/:action?/:estimatorID?',
    component: <EditEstimator />,
    skeleton: <EditSkeleton />,
    permissions: ['erpf.training-course.list'],
    hidden: true,
    icon: <IconProvider icon={faHomeLg} />,
  },
  {
    name: 'navigation.links.training-course-candidates',
    path: '/manager/training-course/:ref/candidates/:action?',
    component: <EditCandidate />,
    skeleton: <EditSkeleton />,
    permissions: ['erpf.training-course.list'],
    hidden: true,
    icon: <IconProvider icon={faHomeLg} />,
  },
  {
    name: 'navigation.links.training-course-program',
    path: '/manager/training-course/:ref/program/:action?/:educationalPathID?',
    component: <EditProgram />,
    skeleton: <EditSkeleton />,
    permissions: ['erpf.training-course.list'],
    hidden: true,
    icon: <IconProvider icon={faHomeLg} />,
  },
  {
    name: 'navigation.links.training-course-context',
    path: '/manager/training-course/:ref/:action?',
    component: <EditContext />,
    skeleton: <EditSkeleton />,
    permissions: ['erpf.training-course.list'],
    hidden: true,
    icon: <IconProvider icon={faHomeLg} />,
  },
  {
    name: 'navigation.links.training-course-list',
    path: '/manager/training-courses',
    component: <TrainingCourseList />,
    skeleton: <ListSkeleton />,
    permissions: ['erpf.training-course.list'],
    icon: <IconProvider icon={faFolder} />,
  },
  {
    name: 'navigation.links.training-catalog',
    path: '/manager/training-catalog',
    component: <TrainingCatalog />,
    skeleton: <ListSkeleton />,
    permissions: ['erpf.training-course.list'],
    icon: <IconProvider icon={faFolder} />,
  },
  {
    name: 'navigation.links.statistics',
    path: '/manager/statistics',
    component: <Statistic />,
    skeleton: <ListSkeleton />,
    permissions: ['erpf.training-course.list'],
    icon: <IconProvider icon={faChartPie} />,
  },
  {
    name: 'navigation.links.configurations',
    path: '/configuration',
    permissions: ['erpf.repositories.list'],
    icon: <IconProvider icon={faCog} />,
    children: [
      {
        name: 'navigation.links.repositories',
        path: '/repositories',
        icon: <IconProvider icon={faBookmark} />,
        children: repositoriesRoutes.sort(({ name }, { name: secondName }) => {
          const sanitizeName = i18n.t(name).toLowerCase().replace(' ', '');
          const sanitizeSecondName = i18n
            .t(secondName)
            .toLowerCase()
            .replace(' ', '');
          return (sanitizeSecondName > sanitizeName && -1) || 1;
        }),
      },
      {
        name: 'navigation.links.estimator-configurations',
        path: '/estimator-configurations',
        component: <EstimatorConfiguration />,
        permissions: ['erpf.repositories.list'],
        icon: <IconProvider icon={faBookmark} />,
      },
      {
        name: 'navigation.links.training-outside-catalog',
        path: '/trainings-outside-catalog',
        component: <TrainingOutsideCatalog />,
        permissions: ['erpf.repositories.list'],
        icon: <IconProvider icon={faBookmark} />,
      },
    ],
  },
  {
    name: 'navigation.links.training',
    path: '/training-courses',
    children: trainingCourseRoutes,
    hidden: true,
    icon: <IconProvider icon={faHomeLg} />,
  },
  {
    name: 'navigation.links.clear-storage',
    path: '/clear',
    hidden: true,
    component: <ClearStorage />,
    permissions: ['erpf.training-course.list'],
  },
  {
    name: 'Delegation',
    path: '/delegation?',
    hidden: true,
    component: <DelegationList />,
    permissions: ['erpf.training-course.list'],
  },
  {
    name: 'Delegation',
    path: '/delegation/add',
    hidden: true,
    component: <DelegationForm />,
    permissions: ['erpf.training-course.list'],
  },
  {
    name: 'Delegation',
    path: '/delegation/:ref',
    hidden: true,
    component: <DelegationWizard />,
    permissions: ['erpf.training-course.list'],
  },
];

const flattenRoutes = (
  routes: Array<NavRoute & { ancestor?: NavRoute[] }>,
  path = '',
  ancestor?: NavRoute[],
): Array<NavRoute & { ancestors: NavRoute[] }> => {
  return flatMap(
    routes.map((route) => {
      const paths =
        route.path.split('/').reduce(
          (acc, cur) => {
            if (!cur) return acc;

            const latest = acc.pop() || path;

            if (!cur.includes('?')) {
              return [...acc, `${latest}/${cur}`];
            }

            acc.push(latest);
            return [...acc, `${latest}/${cur.replace('?', '')}`];
          },
          [path],
        ) || [];

      const ancestors = compact([
        ...(ancestor || []),
        ...paths.map((p) => ({
          ...route,
          path: p,
        })),
      ]);

      const fullRoute = paths.map((p) => ({
        ...route,
        path: p,
        ancestors,
      }));

      if (isEmpty(route?.children)) {
        return fullRoute;
      }

      return compact([
        ...flattenRoutes(route.children || [], fullRoute?.[0].path, ancestors),
        ...((route.component && fullRoute) || []),
      ]);
    }),
  );
};

export const flatRoutes = flattenRoutes(routes);

export const getUserRoutes = (
  user: User,
): Array<NavRoute & { ancestors: NavRoute[] }> =>
  flatRoutes.filter((route) =>
    (route.permissions || ['*']).some(
      (permission) =>
        permission === '*' || user.permissions.includes(permission),
    ),
  );
export const getRouteByPathname = (
  pathname: string,
): (NavRoute & { ancestors: NavRoute[] }) | undefined => {
  return flatRoutes.find(({ path }) => path === pathname);
};

export const getRouteTransitionByPathname = (
  pathname: string,
  previousPathName?: string,
): keyof typeof presets => {
  const { transitions } =
    flatRoutes.find(({ path }) => path === pathname) || {};

  if (!transitions) {
    return 'scaleDownScaleUp';
  }

  const index = flatRoutes.findIndex(({ path }) => path === pathname);
  const previousIndex = flatRoutes.findIndex(
    ({ path }) => path === previousPathName,
  );

  return (index >= previousIndex && transitions.next) || transitions.prev;
};
