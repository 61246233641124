import dayjs from '@_plugins/dayjs';
import { Dayjs } from 'dayjs';
import gPhoneNumber from 'google-libphonenumber';
import * as Yup from 'yup';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

declare module 'yup' {
  export interface StringSchema {
    /**
     * Check for phone number validity.
     *
     * @param {String} [countryCode=IN] The country code to check against.
     * @param {Boolean} [strict=false] How strictly should it check.
     * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
     */
    phone(
      countryCode?: string,
      strict?: boolean,
      errorMessage?: string,
    ): StringSchema;

    dayjs(date: Dayjs): StringSchema;

    asFloat(errorMessage?: string): StringSchema;
    asFloatMin(minValue: number, errorMessage?: string): StringSchema;
  }
}

const YUP_PHONE_METHOD = 'phone';
const YUP_AS_FLOAT_METHOD = 'asFloat';
const YUP_AS_FLOAT_MIN_METHOD = 'asFloatMin';
const YUP_DAY_JS_METHOD = 'dayjs';
const CLDR_REGION_CODE_SIZE = 2;

const isValidCountryCode = (countryCode: unknown): boolean =>
  typeof countryCode === 'string' &&
  countryCode.length === CLDR_REGION_CODE_SIZE;

Yup.addMethod(
  Yup.string,
  YUP_DAY_JS_METHOD,
  function isValidDayJsDate(errorMessage = '') {
    return this.test(YUP_DAY_JS_METHOD, errorMessage, (value?: string) => {
      return dayjs(value).isValid();
    });
  },
);

Yup.addMethod(
  Yup.string,
  YUP_AS_FLOAT_METHOD,
  function yupAsFloat(errorMessage = '') {
    return this.test(YUP_AS_FLOAT_METHOD, errorMessage, (value?: string) => {
      if (!value) {
        return true;
      }

      try {
        const hourlyRate = parseFloat(value);

        return /^\d+(\.\d+)?$/.test(value) && !isNaN(hourlyRate);
      } catch {
        return false;
      }
    });
  },
);

Yup.addMethod(
  Yup.string,
  YUP_AS_FLOAT_MIN_METHOD,
  function yupAsFloatMin(minValue: number, errorMessage = '') {
    return this.test(
      YUP_AS_FLOAT_MIN_METHOD,
      errorMessage,
      (value?: string) => {
        if (!value) {
          return true;
        }

        const number = parseFloat(value || '');
        if (isNaN(number)) {
          return true;
        }

        try {
          return number >= minValue;
        } catch {
          return false;
        }
      },
    );
  },
);

Yup.addMethod(
  Yup.string,
  YUP_PHONE_METHOD,
  function yupPhone(countryCode?: string, strict = false, errorMessage = '') {
    const errMsg =
      /* eslint-disable no-nested-ternary */
      typeof errorMessage === 'string' && errorMessage
        ? errorMessage
        : isValidCountryCode(countryCode)
          ? `\${path} must be a valid phone number for region ${String(
              countryCode,
            )}`
          : '${path} must be a valid phone number.';

    return this.test(YUP_PHONE_METHOD, errMsg, (value?: string) => {
      if (!value) {
        return true;
      }

      try {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(value);

        if (!phoneUtil.isPossibleNumber(phoneNumber)) {
          return false;
        }

        const regionCodeFromPhoneNumber =
          phoneUtil.getRegionCodeForNumber(phoneNumber);

        /* check if the countryCode provided should be used as
        default country code or strictly followed
     */
        return strict
          ? phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)
          : phoneUtil.isValidNumberForRegion(
              phoneNumber,
              regionCodeFromPhoneNumber,
            );
      } catch {
        return false;
      }
    });
  },
);
