import { Chat } from '@data/models/chat.interface';
import { StateCreator } from 'zustand';

export interface ChatState {
  chats: Chat[];
  setChat: (chats: Chat[]) => void;
  addMessage: (message: Chat) => void;
}

export const createChatSlice: StateCreator<
  ChatState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  ChatState
> = (set) => ({
  chats: [],
  setChat: (chats: Chat[]) => set(() => ({ chats })),
  addMessage: (message: Chat) =>
    set((state) => ({ chats: [...state.chats, message] })),
});
