export const getPriorityColor = (priority: string | undefined) => {
  switch (priority) {
    case 'highest':
      return {
        color: 'redActual',
        backgroundColor: 'rgba(228, 5, 33, 0.06)',
      };
    case 'high':
      return {
        color: '#E86826',
        backgroundColor: 'rgba(232, 103, 38, 0.06)',
      };
    case 'medium':
      return {
        color: '#1D91C3',
        backgroundColor: 'rgba(29, 145, 195, 0.06)',
      };
    case 'low':
      return {
        color: '#27A579',
        backgroundColor: 'rgba(39, 165, 121, 0.06)',
      };
    default:
      return {};
  }
};
