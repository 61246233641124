import { ManagerHttp } from '@data/models/manager.interface';
import {
  TrainingCourseComment,
  TrainingCourseCommentHttp,
  TrainingCourseCommentIncluded,
  TrainingCourseCommentRawIncluded,
  TrainingCourseCommentWizard,
} from '@data/models/training-course-comment.interface';
import { TrainingCourse } from '@data/models/training-course.interface';
import { EditStep } from '@data/models/training-course.interface';
import dayjs from 'dayjs';
import { toManager } from './manager.dto';

export const toTrainingCourseCommentIncluded = (
  included: TrainingCourseCommentRawIncluded,
): TrainingCourseCommentIncluded => ({
  comments: included.filter(
    ({ type }) => type === 'training_course_comments',
  ) as TrainingCourseCommentHttp[],
  managers: included.filter(
    ({ type }) => type === 'manager_repositories',
  ) as ManagerHttp[],
});

export const toTrainingCourseComment = (
  { attributes, id, relationships }: Partial<TrainingCourseCommentHttp>,
  included: Partial<TrainingCourseCommentIncluded>,
): TrainingCourseComment => {
  const managerHttp =
    included.managers?.find(
      ({ id }) => id === relationships?.manager_repository?.data?.id,
    ) || {};

  const childrenId = relationships?.children?.data?.map(({ id }) => id) || [];

  const childrenHttp =
    included.comments?.filter(({ id }) => childrenId.includes(id)) || [];
  return {
    id: id || '',
    content: attributes?.comment || '',
    manager: toManager(managerHttp),
    children: childrenHttp
      .map((c) => toTrainingCourseComment(c, { ...included, comments: [] }))
      .sort(({ createdAt: a }, { createdAt: b }) => a.unix() - b.unix()),
    state: attributes?.state || EditStep.CONTEXT,
    createdAt: dayjs(attributes?.createdAt || ''),
    updatedAt: dayjs(attributes?.updatedAt || ''),
  };
};

export const toTrainingCourseCommentWizard = (
  comment: Partial<TrainingCourseComment>,
  { id: trainingCourseId }: Pick<TrainingCourse, 'id'>,
  parent?: TrainingCourseComment,
): TrainingCourseCommentWizard => {
  const wizard: TrainingCourseCommentWizard = {
    type: 'training_course_comments',
    id: comment.id || '',
    attributes: {
      comment: comment.content || '',
      state: comment.state || EditStep.CONTEXT,
    },
    relationships: {
      training_course: {
        data: {
          type: 'training_courses',
          id: trainingCourseId || '',
        },
      },
      manager_repository: {
        data: {
          type: 'manager_repositories',
          id: comment.manager?.id || '',
        },
      },
    },
  };

  if (parent) {
    wizard.relationships.parent = {
      data: {
        id: parent.id,
        type: 'training_course_comments',
      },
    };
  }

  return wizard;
};
