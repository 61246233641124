import { DatatableParams } from '@data/models/datatable.interface';
import { FilterParams } from '@data/models/filter.interface';
import {
  TypeSingleSortInfo,
  TypeSortInfo,
} from '@inovua/reactdatagrid-enterprise/types';

const isTypeSingleSortInfo = (
  sortInfo: TypeSortInfo,
): sortInfo is TypeSingleSortInfo => {
  return !Array.isArray(sortInfo);
};

export const fromSortInfo = (sortInfo: TypeSortInfo): FilterParams => {
  if (!sortInfo) {
    return {};
  }

  const sort: TypeSingleSortInfo =
    (isTypeSingleSortInfo(sortInfo) && sortInfo) || sortInfo[0];
  return {
    orderBy: sort.id,
    orderDirection: (sort.dir === -1 && 'desc') || 'asc',
  };
};

export const toFilterParams = ({
  skip,
  limit,
  sortInfo,
  filterValue,
}: DatatableParams): FilterParams => {
  return {
    itemsPerPage: limit,
    page: skip === 0 ? 1 : skip / limit + 1,
    ...fromSortInfo(sortInfo),
    filter: (filterValue || []).reduce(
      (acc, cur) => {
        if (!cur) {
          return acc;
        }
        acc[cur.name] = cur?.value;
        return acc;
      },
      {} as {
        [key: string]: string | number | string[] | undefined;
      },
    ),
  };
};
