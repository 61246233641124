import { Text } from '@groupeactual/ui-kit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SelectDateDelegation from './components/SelectDateDelegation';
import RegionSelectMultiple from '@components/Form/Widgets/Selects/RegionSelectMultiple';
import TextField from '@mui/material/TextField';
import { Form, Formik } from 'formik';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import useStore from '@stores/store';
import { useInjection } from 'context/Ioc.context';
import ManagerService from '@data/services/manager.service';
import { Manager } from '@data/models/manager.interface';
import DelegationFormSchema from './schema/delegation-form.schema';
import FormHelperText from '@mui/material/FormHelperText';
import { Region } from '@data/models/region.interface';
import { Delegation } from '@data/models/delegation.interface';
import { toDelegation, toDelegationWizard } from '@data/dto/delegation.dto';
import DelegationService from '@data/services/delegation.service';
import RegionService from '@data/services/region.service';

const DelegationForm = ({ t: trans }: WithTranslation): JSX.Element => {
  const [managers, setManagers] = useState<Manager[]>();
  const [regions, setRegions] = useState<Region[]>();
  const { addToast, user } = useStore();
  const regionsRef = useRef<HTMLInputElement>();
  const managerService = useInjection<ManagerService>(ManagerService);
  const delegService = useInjection<DelegationService>(DelegationService);
  const regionService = useInjection<RegionService>(RegionService);
  const navigate = useNavigate();

  const initialValues: Delegation = toDelegation({}, {});

  const handleSubmit = (values: Delegation) => {
    delegService
      .upsertDelegation(toDelegationWizard(values))
      .then((updatedDelegation) => {
        if (!updatedDelegation) {
          return;
        }
        addToast({
          message: trans('delegation.action_created'),
          level: 'success',
        });
        navigate('/delegation');
      });
  };

  useEffect(() => {
    managerService.listManagers({ include: ['Formation'] }).then((res) => {
      setManagers(res.items.filter((m) => m.id !== user.id));
    });
    regionService.listRegions({}).then((res) => setRegions(res.items));
  }, []);

  return (
    <div>
      <Box mt={4} display="flex" alignItems="center">
        <Box flexGrow={1} whiteSpace="nowrap">
          <Text component="h1" variant="header4">
            {trans('delegation.create_delegation')}
          </Text>
        </Box>
      </Box>

      <Box>
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '13px' }}>
          <Link
            color="inherit"
            to="/"
            component={RouterLink}
            sx={{ color: '#136cac' }}
          >
            {trans('navigation.links.dashboard')}
          </Link>
          <Link
            sx={{ color: '#136cac' }}
            color="inherit"
            to="/delegation"
            component={RouterLink}
          >
            {trans('delegation.delegation_management')}
          </Link>
          <span>{trans('delegation.create_delegation')}</span>
        </Breadcrumbs>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={DelegationFormSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, setFieldValue, errors }) => {
          useEffect(() => {
            if (values.manager === '') {
              const meAsManager = managers?.filter(
                (m) => m.id === user.managerId,
              );
              if (meAsManager?.length) {
                setFieldValue('manager', meAsManager[0].id);
              }
            }
            setFieldValue('regions', []);
          }, [managers, user]);

          return (
            <Form>
              <Card
                sx={{
                  marginTop: '14px',
                  border: '1px solid lightgrey',
                }}
              >
                <Grid
                  container
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Grid item xs={4}>
                    <Box
                      p={2}
                      sx={{
                        height: '100%',
                        backgroundColor: '#F0F0F0',
                        borderRight: '1px solid lightgrey',
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel error={!!errors.manager}>
                          {trans('delegation.delegate')}
                        </InputLabel>
                        <Select
                          style={{
                            backgroundColor: '#ffff',
                          }}
                          label={trans('delegation.delegate')}
                          name={'manager'}
                          value={values.manager}
                          fullWidth
                          error={!!errors.manager}
                          onChange={handleChange}
                        >
                          {managers &&
                            managers.map((user: Manager, i: number) => {
                              return (
                                <MenuItem key={i} value={user.id}>
                                  {user.fullName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        {errors.manager && (
                          <FormHelperText
                            className="Mui-error"
                            sx={{ marginLeft: '0' }}
                          >
                            {errors.manager}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        fullWidth
                        sx={{
                          marginTop: '24px',
                        }}
                      >
                        <InputLabel error={!!errors.delegationManager}>
                          {trans('delegation.representative')}
                        </InputLabel>
                        <Select
                          style={{
                            backgroundColor: '#ffff',
                          }}
                          label={trans('delegation.representative')}
                          name={'delegationManager'}
                          value={values.delegationManager}
                          fullWidth
                          error={!!errors.delegationManager}
                          onChange={handleChange}
                        >
                          {managers &&
                            managers.map((user: Manager, i: number) => {
                              return (
                                <MenuItem key={i} value={user.id}>
                                  {user.fullName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        {errors.delegationManager && (
                          <FormHelperText
                            className="Mui-error"
                            sx={{ marginLeft: '0' }}
                          >
                            {errors.delegationManager}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      p={2}
                      sx={{
                        height: '100%',
                      }}
                    >
                      <SelectDateDelegation
                        label="dates"
                        error={{
                          fromDate: (errors.fromDate as string) || '',
                          toDate: (errors.toDate as string) || '',
                        }}
                      />
                      <Box mt={3}></Box>
                      <RegionSelectMultiple
                        label={trans('delegation.regions')}
                        ref={regionsRef}
                        value={values.regions}
                        onChange={(regions: Region[]) => {
                          setFieldValue('regions', regions);
                        }}
                        error={errors.regions as string[]}
                        defaultOptions={regions}
                        fromDelegation
                      />
                      <TextField
                        onChange={handleChange}
                        sx={{ marginTop: '20px' }}
                        label="Motif"
                        name="reason"
                        placeholder="Indiquez un motif"
                        multiline
                        rows={5}
                        variant="outlined"
                        fullWidth
                        error={!!errors.reason}
                      />
                      {errors.reason && (
                        <FormHelperText className="Mui-error">
                          {errors.reason}
                        </FormHelperText>
                      )}
                      <Grid container item justifyContent="flex-end" mt={4}>
                        <Box display="flex" alignContent="center">
                          <Button
                            variant="outlined"
                            color="primary"
                            disableElevation
                            type="button"
                            onClick={() => navigate('/delegation')}
                          >
                            {trans('action.cancel.label')}
                          </Button>
                          <Button
                            sx={{
                              marginLeft: '8px',
                            }}
                            variant="contained"
                            color="primary"
                            disableElevation
                            type="submit"
                          >
                            {trans('action.confirm.label')}
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default withTranslation()(DelegationForm);
