import {
  Habilitation,
  HabilitationHttp,
} from '@data/models/habilitation.interface';

export const toHabilitation = (
  habilitationHttp: Partial<HabilitationHttp>,
): Habilitation => {
  return {
    id: habilitationHttp.id || '',
    code: habilitationHttp.attributes?.fields.code || '',
    label: habilitationHttp.attributes?.fields.label || '',
    category: habilitationHttp.attributes?.fields.category || '',
    lucieId: habilitationHttp.attributes?.fields['lucie-id'] || '',
  };
};
