import Toast from './components/Toast';
import { SnackbarProvider } from 'notistack';
import './style.scss';
import useStore from '@stores/store';

const ToastList = (): JSX.Element => {
  const { toast } = useStore();

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        variantSuccess: 'toast success',
        variantError: 'toast error',
        variantWarning: 'toast warning',
        variantInfo: 'toast info',
      }}
    >
      {toast.map((toast) => (
        <Toast toast={toast} key={toast.id} />
      ))}
    </SnackbarProvider>
  );
};

export default ToastList;
