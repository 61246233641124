import day from '@_plugins/dayjs';
import { toApiDate } from '@data/dto/api.dto';
import {
  MinWage,
  MinWageHttp,
  MinWageWizard,
} from '@data/models/min-wage.interface';

export const toMinWage = (minWage: Partial<MinWageHttp>): MinWage => {
  return {
    id: minWage.id || '',
    label: minWage.attributes?.label || '',
    fromDate: day(minWage.attributes?.from_date),
    rate: minWage.attributes?.rate || '',
    createdAt: day(minWage.attributes?.createdAt),
    updatedAt: day(minWage.attributes?.updatedAt),
  };
};

export const toMinWageWizard = (minWage: Partial<MinWage>): MinWageWizard => {
  return {
    type: 'min_wage_repositories',
    id: minWage.id || '',
    attributes: {
      label: minWage.label || '',
      from_date: toApiDate(minWage.fromDate),
      rate: minWage.rate || '',
    },
  };
};
