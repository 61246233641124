/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import ErrorService from 'core/error.service';
import {
  Manager,
  ManagerHttp,
  ManagerWizard,
} from '@data/models/manager.interface';
import LaravelAPI from '@data/api/laravel.api';
import { toManager, toManagerFilterParams } from '@data/dto/manager.dto';
import { FilterParams } from '@data/models/filter.interface';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import { toSortParams } from '@data/dto/api.dto';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import i18n from '@_plugins/i18n';

@injectable()
export default class ManagerService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private errorService = container.get<ErrorService>(ErrorService);
  private managerEndpoint = '/manager-repositories';

  public async listManagers(
    filters: FilterParams,
  ): Promise<PaginateResult<Manager[]>> {
    const { data: response, error } = await this.API?.get<{
      data: ManagerHttp[];
      included?: ManagerHttp[];
      meta: Meta;
    }>(
      this.managerEndpoint,
      toManagerFilterParams({
        ...filters,
        include: ['supervisor'],
      }),
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items:
        response?.data?.map((manager) =>
          toManager(manager, [...response.data, ...(response.included || [])]),
        ) || [],
      totalSize: response?.meta?.page?.total || 0,
    };
  }

  public async upsertManager({
    id,
    ...managerWizard
  }: ManagerWizard): Promise<Manager | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<ManagerWizard, 'id'> },
        { data: ManagerHttp }
      >(this.managerEndpoint, { data: managerWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toManager(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: ManagerWizard },
      { data: ManagerHttp }
    >(`${this.managerEndpoint}/${id}`, {
      data: { ...managerWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toManager(data?.data || {});
  }

  public async deleteManager(id: string): Promise<Manager | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: ManagerHttp;
    }>(`${this.managerEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toManager(data?.data || {});
  }

  public async sortManager(
    sortParams: DatatableReorderParams<Manager>,
  ): Promise<Manager | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.managerEndpoint}/${id}/sort`,
      {
        data: toSortParams('manager_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}
