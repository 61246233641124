import { TrainingCourseComment } from '@data/models/training-course-comment.interface';
import { StateCreator } from 'zustand';

export interface CommentState {
  comments: TrainingCourseComment[];
  setComments: (comments: TrainingCourseComment[]) => void;
  addComment: (comment: TrainingCourseComment) => void;
  updateComment: (comment: TrainingCourseComment) => void;
}

export const createCommentSlice: StateCreator<
  CommentState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  CommentState
> = (set) => ({
  comments: [],
  setComments: (comments: TrainingCourseComment[]) => set(() => ({ comments })),
  updateComment: (comment: TrainingCourseComment) =>
    set((state) => ({
      comments: state.comments.map((c) => (comment.id === c.id ? comment : c)),
    })),
  addComment: (comment: TrainingCourseComment) =>
    set((state) => ({ comments: [...state.comments, comment] })),
});
