/* eslint-disable no-case-declarations */
import day from '@_plugins/dayjs';
import { toCandidateType } from '@data/dto/candidate-type.dto';
import { Candidate } from '@data/models/candidate.interface';
import { FINANCING_TYPE_TYPE } from '@data/models/financing-type.interface';
import { RefundRequest } from '@data/models/refund-request.interface';
import {
  TrainingActionCandidate,
  TrainingActionCandidateHttp,
  TrainingActionCandidateWizard,
} from '@data/models/training-action-candidate.interface';
import { FinancingBlock } from '@data/models/training-action-candidate.interface';
import { TrainingActionIncluded } from '@data/models/training-action.interface';
import { TrainingCourseEducationalPath } from '@data/models/training-course-educational-path.interface';
import { generateAvatarFromFullName } from '@helpers/avatar.helper';
import compact from 'lodash/compact';

import { toApiFloat, toBoolean } from './api.dto';
import { toAge } from './candidate.dto';
import { toProfession } from './profession.dto';

const DEFAULT_AGE = 18;

// eslint-disable-next-line complexity
export const toTrainingActionCandidate = (
  { attributes, id, relationships }: Partial<TrainingActionCandidateHttp>,
  included: Partial<TrainingActionIncluded>,
): TrainingActionCandidate => {
  const professionHttp = included.professions?.find(
    ({ id }) => id === relationships?.profession?.data?.id,
  );

  const candidateTypesHttp = included.candidateTypes?.find(
    ({ id }) => id === relationships?.candidate_type?.data?.id,
  );

  return {
    id: id || '',
    position: attributes?.position || 0,
    lucieId: attributes?.lucie_id?.toString() || null,
    lucieRef: attributes?.lucie_reference || '',
    age: attributes?.age || DEFAULT_AGE,
    birthdayDate: day(attributes?.birthday_date || ''),
    firstName: attributes?.first_name || '',
    lastName: attributes?.last_name || '',
    fullName: attributes?.full_name || '',
    currentProfession: attributes?.current_profession || '',
    address: attributes?.address || '',
    city: attributes?.city || '',
    postalCode: attributes?.postal_code || '',
    country: attributes?.country || '',
    trainingCourseRate: attributes?.training_course_rate || '',
    missionRate: attributes?.mission_rate || '',
    followedByPoleEmploi: toBoolean(attributes?.followed_by_pole_emploi),
    followedByMissionLocale: toBoolean(attributes?.followed_by_mission_locale),
    cpfAmount: attributes?.cpf_amount || '',
    hasInterimExperience: toBoolean(attributes?.has_interim_experience),
    hoursInterimExperience:
      parseInt(attributes?.interim_experience_hours || '0') || 0,
    isA2i: toBoolean(attributes?.is_a2i),
    isCdii: toBoolean(attributes?.is_cdii),
    isRqth: toBoolean(attributes?.is_rqth),
    needOpenCPFAccount: toBoolean(attributes?.open_cpf_account),
    hasDiagCompetences: toBoolean(attributes?.has_competences_diagnostic),
    isEPSPApproach: toBoolean(attributes?.is_epsp_approach),
    emails: attributes?.emails || [],
    phones: attributes?.phones || [],
    professionExpected: toProfession(professionHttp || {}),
    avatar: generateAvatarFromFullName(attributes?.full_name || ''),
    accommodationFees: attributes?.accommodation_fees || '',
    feesDescription: attributes?.fees_description || '',
    mealFees: attributes?.meal_fees || '',
    totalFees: attributes?.total_fees || '',
    pecRate: attributes?.pec_rate || '',
    transportFees: attributes?.transport_fees || '',
    internType: compact([
      attributes?.is_cdii && 'CDI I',
      attributes?.is_epsp_approach && 'EPSP',
      attributes?.is_a2i && 'A2i',
      attributes?.age && attributes?.age > 45 && '+ 45 ans',
    ]),
    publicType: compact([
      attributes?.followed_by_pole_emploi && 'Suivi par Pôle Emploi',
      attributes?.followed_by_mission_locale && 'Suivi par mission locale',
      attributes?.is_rqth && 'Travailleur handicapé',
    ]),
    validateCandidateType: toBoolean(attributes?.validate_candidate_type),
    candidateType: toCandidateType(candidateTypesHttp || {}),
    cofiForecastAmount: attributes?.cofi_forecast_amount || '0',
    cofiReceivedAmount: attributes?.cofi_received_amount || '0',
    cofiRequestedAmount: attributes?.cofi_requested_amount || '0',
    miscellaneousServicesForecastAmount:
      attributes?.miscellaneous_services_forecast_amount || '0',
    miscellaneousServicesReceivedAmount:
      attributes?.miscellaneous_services_received_amount || '0',
    miscellaneousServicesRequestedAmount:
      attributes?.miscellaneous_services_received_amount || '0',
    opcoForecastAmount: attributes?.cofi_forecast_amount || '0',
    opcoReceivedAmount: attributes?.opco_received_amount || '0',
    opcoRequestedAmount: attributes?.opco_requested_amount || '0',
    remainingForecastAmount: attributes?.remaining_forecast_amount || '0',
    remainingReceivedAmount: attributes?.remaining_received_amount || '0',
    remainingRequestedAmount: attributes?.remaining_requested_amount || '0',
    variousAidsForecastAmount: attributes?.various_aids_forecast_amount || '0',
    variousAidsReceivedAmount: attributes?.various_aids_received_amount || '0',
    variousAidsRequestedAmount:
      attributes?.various_aids_requested_amount || '0',
    totalTrainingExpenses: attributes?.total_training_expenses || '0',
    drivingLicenses: attributes?.driving_licenses || [],
    meansOfTransport: attributes?.means_of_transport || [],
  };
};

// eslint-disable-next-line complexity
export const toTrainingActionCandidateWizard = (
  candidate: Partial<TrainingActionCandidate>,
  trainingActionId: string | null,
): TrainingActionCandidateWizard => {
  const candidateHttp: TrainingActionCandidateWizard = {
    type: 'training_action_candidates',
    id: candidate.id || '',
    attributes: {
      position: candidate.position || 0,
      lucie_id: parseInt(candidate.lucieId || '0'),
      age: candidate.age || DEFAULT_AGE,
      birthday_date:
        (candidate.birthdayDate?.isValid() &&
          candidate.birthdayDate?.toISOString()) ||
        day().toISOString(),
      lucie_reference: candidate.lucieRef || '',
      first_name: candidate.firstName || '',
      last_name: candidate.lastName || '',
      full_name: candidate.fullName || '',
      current_profession: candidate.currentProfession || '',
      address: candidate.address || '',
      postal_code: candidate.postalCode || '',
      city: candidate.city || '',
      country: candidate.country || '',
      followed_by_pole_emploi: candidate.followedByPoleEmploi || false,
      followed_by_mission_locale: candidate.followedByMissionLocale || false,
      has_interim_experience: candidate.hasInterimExperience || false,
      is_a2i: candidate.isA2i || false,
      is_cdii: candidate.isCdii || false,
      is_rqth: candidate.isRqth || false,
      open_cpf_account: candidate.needOpenCPFAccount || false,
      has_competences_diagnostic: candidate.hasDiagCompetences || false,
      is_epsp_approach: candidate.isEPSPApproach || false,
      cpf_amount: toApiFloat(candidate.cpfAmount),
      interim_experience_hours: `${candidate.hoursInterimExperience || '0'}.00`,
      emails: candidate.emails || [],
      phones: candidate.phones || [],
      accommodation_fees: toApiFloat(candidate.accommodationFees),
      fees_description: candidate.feesDescription || '',
      meal_fees: toApiFloat(candidate.mealFees),
      total_fees: toApiFloat(candidate.totalFees),
      pec_rate: toApiFloat(candidate.pecRate),
      transport_fees: toApiFloat(candidate.transportFees),
      opco_forecast_amount: toApiFloat(candidate?.opcoForecastAmount),
      opco_requested_amount: toApiFloat(candidate?.opcoRequestedAmount),
      opco_received_amount: toApiFloat(candidate?.opcoReceivedAmount),
      miscellaneous_services_forecast_amount: toApiFloat(
        candidate?.miscellaneousServicesForecastAmount,
      ),
      miscellaneous_services_requested_amount: toApiFloat(
        candidate?.miscellaneousServicesRequestedAmount,
      ),
      miscellaneous_services_received_amount: toApiFloat(
        candidate?.miscellaneousServicesReceivedAmount,
      ),
      cofi_forecast_amount: toApiFloat(candidate?.cofiForecastAmount),
      cofi_requested_amount: toApiFloat(candidate?.cofiRequestedAmount),
      cofi_received_amount: toApiFloat(candidate?.cofiReceivedAmount),
      various_aids_forecast_amount: toApiFloat(
        candidate?.variousAidsForecastAmount,
      ),
      various_aids_requested_amount: toApiFloat(
        candidate?.variousAidsRequestedAmount,
      ),
      various_aids_received_amount: toApiFloat(
        candidate?.variousAidsReceivedAmount,
      ),
      remaining_forecast_amount: toApiFloat(candidate?.remainingForecastAmount),
      remaining_requested_amount: toApiFloat(
        candidate?.remainingRequestedAmount,
      ),
      remaining_received_amount: toApiFloat(candidate?.remainingReceivedAmount),
      total_training_expenses: toApiFloat(candidate?.totalTrainingExpenses),
    },
    relationships: {
      training_action: {
        data: {
          id: trainingActionId || '',
          type: 'training_actions',
        },
      },
      profession: {
        data:
          (candidate.professionExpected?.id && {
            id: candidate.professionExpected?.id || '',
            type: 'profession_repositories',
          }) ||
          null,
      },
      candidate_type: {
        data:
          (candidate.candidateType?.id && {
            id: candidate.candidateType?.id || '',
            type: 'candidate_type_repositories',
          }) ||
          null,
      },
    },
  };

  if (candidate.missionRate) {
    candidateHttp.attributes.mission_rate = toApiFloat(candidate.missionRate);
  }

  if (candidate.trainingCourseRate) {
    candidateHttp.attributes.training_course_rate = toApiFloat(
      candidate.trainingCourseRate,
    );
  }
  return candidateHttp;
};

export const fromCandidate = (
  candidate: Partial<Candidate>,
  position = 0,
): TrainingActionCandidate => {
  return {
    id: '',
    position,
    lucieId: candidate.id || '',
    lucieRef: candidate.fullRegistrationNumber || '',
    age: toAge(candidate.birthdayDate),
    birthdayDate: day(candidate.birthdayDate),
    firstName: candidate.firstName || '',
    lastName: candidate.lastName || '',
    fullName: candidate.fullName || '',
    currentProfession: candidate.current_profession || '',
    professionExpected: toProfession({}),
    address: candidate.address || '',
    city: candidate.city || '',
    postalCode: candidate.postalCode || '',
    country: candidate.country || '',
    trainingCourseRate: '',
    missionRate: '',
    followedByPoleEmploi: candidate.followedByPoleEmploi || false,
    followedByMissionLocale: candidate.followedByMissionLocale || false,
    cpfAmount: '',
    hasInterimExperience: false,
    hoursInterimExperience: 0,
    isA2i: candidate.isA2i || false,
    isCdii: candidate.isCdii || false,
    isRqth: candidate.isRqth || false,
    needOpenCPFAccount: false,
    hasDiagCompetences: false,
    isEPSPApproach: false,
    emails: candidate.emails || [],
    phones: candidate.phones || [],
    avatar: candidate.avatar || '',
    accommodationFees: '',
    feesDescription: '',
    mealFees: '',
    totalFees: '',
    transportFees: '',
    candidateType: toCandidateType({}),
    internType: [],
    publicType: [],
    validateCandidateType: false,
    pecRate: '',
    opcoForecastAmount: '',
    opcoRequestedAmount: '',
    opcoReceivedAmount: '',
    miscellaneousServicesForecastAmount: '',
    miscellaneousServicesRequestedAmount: '',
    miscellaneousServicesReceivedAmount: '',
    cofiForecastAmount: '',
    cofiRequestedAmount: '',
    cofiReceivedAmount: '',
    variousAidsForecastAmount: '',
    variousAidsRequestedAmount: '',
    variousAidsReceivedAmount: '',
    remainingForecastAmount: '',
    remainingRequestedAmount: '',
    remainingReceivedAmount: '',
    totalTrainingExpenses: '',
    drivingLicenses: candidate.drivingLicenses || [],
    meansOfTransport: candidate.meansOfTransport || [],
  };
};

export const toFinancingBlock = (
  blockTitle: string,
  {
    pecRate,
    miscellaneousServicesForecastAmount,
    cofiForecastAmount,
    variousAidsForecastAmount,
  }: TrainingActionCandidate,
  {
    trainingCourseTrainings,
    practicalTrainingDuration,
    positioningReviewDuration,
  }: TrainingCourseEducationalPath,
  refundRequests: RefundRequest[],
  realisedHours: number,
): FinancingBlock | undefined => {
  const opcoRefundRequest = refundRequests.filter(
    ({ type }) => type.financingType === FINANCING_TYPE_TYPE.OPCO,
  );

  const miscellaneousServiceRefundRequest = refundRequests.filter(
    ({ type }) =>
      type.financingType === FINANCING_TYPE_TYPE.MISCELLANEOUS_SERVICES,
  );

  const cofiRefundRequest = refundRequests.filter(
    ({ type }) => type.financingType === FINANCING_TYPE_TYPE.COFI,
  );

  const variousAidsRefundRequest = refundRequests.filter(
    ({ type }) => type.financingType === FINANCING_TYPE_TYPE.VARIOUS_AIDS,
  );

  switch (blockTitle) {
    case 'opco':
      const opcoRequested = opcoRefundRequest.reduce(
        (acc: number, { requestAmount }) => {
          return acc + parseFloat(requestAmount);
        },
        0,
      );
      return {
        title: blockTitle,
        forecastTitle: 'forecast',
        forecastValue:
          parseFloat(pecRate) *
            (trainingCourseTrainings.reduce((acc: number, training) => {
              return acc + parseFloat(training.duration);
            }, 0) +
              parseFloat(practicalTrainingDuration || '0') +
              parseFloat(positioningReviewDuration || '0')) || 0,
        financableTitle: 'financable',
        financableValue:
          parseFloat(pecRate) * realisedHours - opcoRequested || 0,
        requestedTitle: 'requested',
        requestedValue: opcoRequested,
        receivedTitle: 'received',
        receivedValue:
          opcoRefundRequest.reduce((acc: number, { preTaxRefundAmount }) => {
            return acc + parseFloat(preTaxRefundAmount);
          }, 0) || 0,
      };
    case 'miscellaneous_service':
      const miscellaneousServiceRequested =
        miscellaneousServiceRefundRequest.reduce(
          (acc: number, { requestAmount }) => {
            return acc + parseFloat(requestAmount);
          },
          0,
        ) || 0;
      return {
        title: blockTitle,
        forecastTitle: 'forecast',
        forecastValue: parseFloat(miscellaneousServicesForecastAmount),
        financableTitle: 'financable',
        financableValue:
          parseFloat(miscellaneousServicesForecastAmount) -
            miscellaneousServiceRequested || 0,
        requestedTitle: 'requested',
        requestedValue: miscellaneousServiceRequested,
        receivedTitle: 'received',
        receivedValue:
          miscellaneousServiceRefundRequest.reduce(
            (acc: number, { preTaxRefundAmount }) => {
              return acc + parseFloat(preTaxRefundAmount);
            },
            0,
          ) || 0,
      };
    case 'cofi':
      const cofiServiceRequested =
        cofiRefundRequest.reduce((acc: number, { requestAmount }) => {
          return acc + parseFloat(requestAmount);
        }, 0) || 0;
      return {
        title: blockTitle,
        forecastTitle: 'forecast',
        forecastValue: parseFloat(cofiForecastAmount) || 0,
        financableTitle: 'financable',
        financableValue:
          parseFloat(cofiForecastAmount) - cofiServiceRequested || 0,
        requestedTitle: 'requested',
        requestedValue: cofiServiceRequested,
        receivedTitle: 'received',
        receivedValue:
          cofiRefundRequest.reduce((acc: number, { preTaxRefundAmount }) => {
            return acc + parseFloat(preTaxRefundAmount);
          }, 0) || 0,
      };
    case 'various_aids':
      const variousAidsServiceRequested =
        variousAidsRefundRequest.reduce((acc: number, { requestAmount }) => {
          return acc + parseFloat(requestAmount);
        }, 0) || 0;
      return {
        title: blockTitle,
        forecastTitle: 'forecast',
        forecastValue: parseFloat(variousAidsForecastAmount) || 0,
        financableTitle: 'financable',
        financableValue:
          parseFloat(variousAidsForecastAmount) - variousAidsServiceRequested ||
          0,
        requestedTitle: 'requested',
        requestedValue: variousAidsServiceRequested,
        receivedTitle: 'received',
        receivedValue:
          variousAidsRefundRequest.reduce(
            (acc: number, { preTaxRefundAmount }) => {
              return acc + parseFloat(preTaxRefundAmount);
            },
            0,
          ) || 0,
      };
  }
};

export const toFinancingRemainBlock = (
  candidate: TrainingActionCandidate,
  financingBlocks: FinancingBlock[],
): FinancingBlock => {
  return {
    title: 'remains_dependent',
    forecastTitle: 'forecast',
    forecastValue:
      parseFloat(candidate.totalTrainingExpenses) -
        parseFloat(candidate.opcoForecastAmount) -
        parseFloat(candidate.miscellaneousServicesForecastAmount) -
        parseFloat(candidate.variousAidsForecastAmount) -
        parseFloat(candidate.cofiForecastAmount) || 0,
    financableTitle: 'waiting',
    financableValue:
      financingBlocks.reduce((acc: number, { financableValue }) => {
        return acc + (financableValue || 0);
      }, 0) || 0,
    requestedTitle: 'remaining_requested',
    requestedValue:
      financingBlocks.reduce((acc: number, { requestedValue }) => {
        return acc - (requestedValue || 0);
      }, parseFloat(candidate.totalTrainingExpenses)) || 0,
    receivedTitle: 'additional_cost',
    receivedValue:
      financingBlocks.reduce((acc: number, { receivedValue }) => {
        return acc - (receivedValue || 0);
      }, parseFloat(candidate.totalTrainingExpenses)) || 0,
  };
};
