import day from '@_plugins/dayjs';
import {
  StrategicAxis,
  StrategicAxisHttp,
  StrategicAxisWizard,
} from '@data/models/strategic-axis.interface';

export const toStrategicAxis = (
  strategicAxis: Partial<StrategicAxisHttp>,
): StrategicAxis => {
  return {
    id: strategicAxis.id || '',
    label: strategicAxis.attributes?.label || '',
    createdAt: day(strategicAxis.attributes?.createdAt),
    updatedAt: day(strategicAxis.attributes?.updatedAt),
  };
};

export const toStrategicAxisWizard = (
  strategicAxis: Partial<StrategicAxis>,
): StrategicAxisWizard => {
  return {
    type: 'strategic_axis_repositories',
    id: strategicAxis.id || '',
    attributes: {
      label: strategicAxis.label || '',
    },
  };
};
