import day from '@_plugins/dayjs';
import { TrainingActionDeviceMap } from '@data/models/training-action-device-map.interface';
import { StateCreator } from 'zustand';

const isTrainingActionDeviceMap = (
  deviceMap: TrainingActionDeviceMap | TrainingActionDeviceMap[] | undefined,
): deviceMap is TrainingActionDeviceMap => {
  return !Array.isArray(deviceMap);
};

export interface TrainingActionDeviceMapState {
  trainingActionDeviceMaps: TrainingActionDeviceMap[];
  setTrainingActionDeviceMaps: (deviceMaps: TrainingActionDeviceMap[]) => void;
  setTrainingActionDeviceMap: (
    deviceMapToUpdated: TrainingActionDeviceMap,
  ) => void;
  toggleTrainingActionDeviceMapIsChecked: (
    deviceMap: TrainingActionDeviceMap,
  ) => void;
}

export const createTrainingActionDeviceMapSlice: StateCreator<
  TrainingActionDeviceMapState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  TrainingActionDeviceMapState
> = (set) => ({
  trainingActionDeviceMaps: [],
  setTrainingActionDeviceMaps: (deviceMaps: TrainingActionDeviceMap[]) => {
    if (isTrainingActionDeviceMap(deviceMaps)) {
      return;
    }
    set(() => ({ trainingActionDeviceMaps: deviceMaps }));
  },
  setTrainingActionDeviceMap: (deviceMapToUpdate: TrainingActionDeviceMap) => {
    function updateDeviceMap(
      deviceMaps: TrainingActionDeviceMap[],
      deviceMapToUpdate: TrainingActionDeviceMap,
    ): TrainingActionDeviceMap[] {
      return deviceMaps.map((deviceMap) => {
        if (deviceMap.children && deviceMap.children.length > 0) {
          // On vérifie si l'objet est un enfant à mettre à jour
          const updatedChildren = deviceMap.children.map((child) =>
            child.id === deviceMapToUpdate.id ? deviceMapToUpdate : child,
          );
          // Si l'enfant à mettre à jour est trouvé, on renvoie l'objet mis à jour avec ses enfants
          if (updatedChildren !== deviceMap.children) {
            return {
              ...deviceMap,
              children: updatedChildren,
            };
          }
          // Sinon, on continue à chercher dans les enfants
          return {
            ...deviceMap,
            children: updateDeviceMap(deviceMap.children, deviceMapToUpdate),
          };
        }
        // Si l'objet n'a pas d'enfants, on le renvoie tel quel
        return deviceMap;
      });
    }
    if (isTrainingActionDeviceMap(deviceMapToUpdate)) {
      set((state) => {
        return {
          trainingActionDeviceMaps: updateDeviceMap(
            state.trainingActionDeviceMaps,
            deviceMapToUpdate,
          ),
        };
      });
    }
  },
  toggleTrainingActionDeviceMapIsChecked: (
    deviceMap: TrainingActionDeviceMap,
  ) => {
    if (!isTrainingActionDeviceMap(deviceMap)) {
      return;
    }
    set((state) => ({
      trainingActionDeviceMaps: state.trainingActionDeviceMaps.reduce(
        (acc, map) => {
          const toggleChildren = (
            maps: TrainingActionDeviceMap[],
          ): TrainingActionDeviceMap[] => {
            return maps.map((m) => ({
              ...m,
              isChecked:
                m.id === deviceMap.id ? !deviceMap.isChecked : m.isChecked,
              children: toggleChildren(m.children),
              updatedAt: m.id === deviceMap.id ? day(new Date()) : m.updatedAt,
            }));
          };
          return [
            ...acc,
            {
              ...map,
              children: toggleChildren(map.children),
            },
          ];
        },
        [] as TrainingActionDeviceMap[],
      ),
    }));
  },
});
