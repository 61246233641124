import {
  City,
  CityGeo,
  CityHttp,
  CityWizard,
} from '@data/models/city.interface';

export const fromGeoApi = (city: Partial<CityGeo>): City => {
  return {
    id: '',
    code: city.code || '',
    name: city.nom || '',
    postCode: city.codesPostaux?.[0] || '',
    county: city.departement?.nom || '',
    countyCode: city.departement?.code || '',
  };
};

export const toCity = (city: Partial<CityHttp>): City => {
  return {
    id: city.id || '',
    code: city.attributes?.code || '',
    name: city.attributes?.name || '',
    postCode: city.attributes?.post_code || '',
    county: city.attributes?.county || '',
    countyCode: city.attributes?.county_code || '',
  };
};

export const toCityWizard = (city: Partial<City>): CityWizard => {
  return {
    id: city.id || '',
    type: 'cities',
    attributes: {
      code: city.code || '',
      county: city.county || '',
      county_code: city.countyCode || '',
      name: city.name || '',
      post_code: city.postCode || '',
    },
  };
};
