import { createContext, useContext } from 'react';
import { Container, interfaces } from 'inversify';

export const InversifyContext = createContext<{
  container: Container | null;
}>({ container: null });

type Props = {
  container: Container;
  children: JSX.Element;
};

export const Provider = ({ container, children }: Props): JSX.Element => {
  return (
    <InversifyContext.Provider value={{ container: container }}>
      {children}
    </InversifyContext.Provider>
  );
};

export function useInjection<T>(
  identifier: interfaces.ServiceIdentifier<T>,
): T {
  const { container } = useContext(InversifyContext);
  if (!container) {
    throw new Error();
  }
  return container.get<T>(identifier);
}
