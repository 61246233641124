import { Dayjs } from 'dayjs';

export enum TrainingCourseSessionType {
  CUSTOMER = 'customer',
  TRAINING = 'training',
  UNPAID = 'unpaid',
  PRACTICAL_TRAINING = 'practical_training',
}

export enum TrainingCourseSessionPeriod {
  ALL_DAY_PERIOD = 'ALL_DAY_PERIOD',
  MORNING_PERIOD = 'MORNING_PERIOD',
  AFTERNOON_PERIOD = 'AFTERNOON_PERIOD',
}

export interface TrainingCourseSessionHttp {
  type: 'training_course_sessions';
  id: string;
  attributes: {
    from_date: string;
    to_date: string;
    session_type: TrainingCourseSessionType;
    period: TrainingCourseSessionPeriod;
    session_duration: string;
  };
  relationships: {
    training_course: {
      data: {
        type: 'training_courses';
        id: string;
      };
    };
    training_course_training?: {
      data: {
        type: 'training_course_trainings';
        id: string;
      };
    };
  };
}

export interface TrainingCourseSession {
  id: string;
  fromDate: Dayjs;
  toDate: Dayjs;
  trainingCourseTrainingId: string;
  type: TrainingCourseSessionType;
  period: TrainingCourseSessionPeriod;
  duration: string;
}

export interface TrainingCourseSessionForm
  extends Omit<TrainingCourseSession, 'fromDate' | 'toDate' | 'duration'> {
  fromDate: Dayjs | null;
  toDate: Dayjs | null;
  duration: string | number | null;
}
