import day from '@_plugins/dayjs';
import {
  ActivityField,
  ActivityFieldHttp,
  ActivityFieldWizard,
} from '@data/models/activity-field.interface';

export const toActivityField = (
  activityField: Partial<ActivityFieldHttp>,
): ActivityField => {
  return {
    id: activityField.id || '',
    label: activityField.attributes?.label || '',
    createdAt: day(activityField.attributes?.createdAt),
    updatedAt: day(activityField.attributes?.updatedAt),
  };
};

export const toActivityFieldWizard = (
  activityField: Partial<ActivityField>,
): ActivityFieldWizard => {
  return {
    type: 'activity_field_repositories',
    id: activityField.id || '',
    attributes: {
      label: activityField.label || '',
    },
  };
};
