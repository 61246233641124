import day from '@_plugins/dayjs';
import {
  CoFunder,
  CoFunderHttp,
  CoFunderWizard,
} from '@data/models/co-funder.interface';

export const toCoFunder = (coFunder: Partial<CoFunderHttp>): CoFunder => {
  return {
    id: coFunder.id || '',
    label: coFunder.attributes?.label || '',
    createdAt: day(coFunder.attributes?.createdAt),
    updatedAt: day(coFunder.attributes?.updatedAt),
  };
};

export const toCoFounderWizard = (
  coFunder: Partial<CoFunder>,
): CoFunderWizard => {
  return {
    type: 'co_funder_repositories',
    id: coFunder.id || '',
    attributes: {
      label: coFunder.label || '',
    },
  };
};
