/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  ControlOrganization,
  ControlOrganizationHttp,
  ControlOrganizationWizard,
} from '@data/models/control-organization.interface';
import { toControlOrganization } from '@data/dto/control-organization.dto';
import { FilterParams } from '@data/models/filter.interface';
import { Meta, PaginateResult, SortParams } from '@data/models/api.interface';
import { toSortParams } from '@data/dto/api.dto';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import ErrorService from 'core/error.service';
import i18n from '@_plugins/i18n';

@injectable()
export default class ControlOrganizationService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly controlOrganizationEndpoint =
    '/control-organization-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listControlOrganizations(
    filters: FilterParams,
  ): Promise<PaginateResult<ControlOrganization[]>> {
    const { data: response, error } = await this.API?.get<{
      data: ControlOrganizationHttp[];
      meta: Meta;
    }>(this.controlOrganizationEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toControlOrganization) || [],
      totalSize: response?.meta.page.total || 0,
    };
  }

  public async upsertControlOrganization({
    id,
    ...controlOrganizationWizard
  }: ControlOrganizationWizard): Promise<ControlOrganization | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<ControlOrganizationWizard, 'id'> },
        { data: ControlOrganizationHttp }
      >(this.controlOrganizationEndpoint, {
        data: controlOrganizationWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toControlOrganization(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: ControlOrganizationWizard },
      { data: ControlOrganizationHttp }
    >(`${this.controlOrganizationEndpoint}/${id}`, {
      data: { ...controlOrganizationWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toControlOrganization(data?.data || {});
  }

  public async deleteControlOrganization(
    id: string,
  ): Promise<ControlOrganization | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: ControlOrganizationHttp;
    }>(`${this.controlOrganizationEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toControlOrganization(data?.data || {});
  }

  public async sortControlOrganization(
    sortParams: DatatableReorderParams<ControlOrganization>,
  ): Promise<ControlOrganization | void> {
    const {
      current: { id },
    } = sortParams;

    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.sorting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { error } = await this.API?.post<{ data: SortParams }, {}>(
      `${this.controlOrganizationEndpoint}/${id}/sort`,
      {
        data: toSortParams('control_organization_repositories', sortParams),
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return sortParams.current;
  }
}
