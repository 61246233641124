import { StateCreator } from 'zustand';
import { MinWage } from '@data/models/min-wage.interface';
import { toMinWage } from '@data/dto/min-wage.dto';

export interface MinWageState {
  minWage: MinWage;
  setMinWage: (minWage: MinWage) => void;
}

export const createMinWageSlice: StateCreator<
  MinWageState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  MinWageState
> = (set) => ({
  minWage: toMinWage({}),
  setMinWage: (minWage: MinWage) => set(() => ({ minWage })),
});
