/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import ErrorService from 'core/error.service';
import { NotionPageHttp } from '@data/models/notion-page.interface';

@injectable()
export default class NotionPageService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly notionPageEndpoint = '/notion-pages';
  private errorService = container.get<ErrorService>(ErrorService);

  public async findNotionPage(
    id?: string,
  ): Promise<NotionPageHttp | undefined> {
    if (!id) {
      return;
    }
    const { data: response, error } = await this.API?.get<{
      data: NotionPageHttp;
    }>(`${this.notionPageEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return response?.data;
  }
}
