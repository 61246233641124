/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import ErrorService from 'core/error.service';
import { Meta } from '@data/models/api.interface';
import {
  EstimatorConfiguration,
  EstimatorConfigurationHttp,
  EstimatorConfigurationWizard,
} from '@data/models/estimator-configuration.interface';
import {
  toEstimatorConfiguration,
  toEstimatorConfigurationWizard,
} from '@data/dto/estimator-configuration.dto';
import LaravelAPI from '@data/api/laravel.api';
import omit from 'lodash/omit';

@injectable()
export default class EstimatorConfigurationService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private errorService = container.get<ErrorService>(ErrorService);
  private estimatorConfigEndpoint = '/estimator-configurations';

  public async findOrCreate(): Promise<EstimatorConfiguration | null> {
    const { data: response, error } = await this.API?.get<{
      data: EstimatorConfigurationHttp[];
      meta: Meta;
    }>(this.estimatorConfigEndpoint, {
      orderBy: 'createdAt',
      orderDirection: 'asc',
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }
    const estimatorConfigHttp = response?.data?.[0];

    if (estimatorConfigHttp) {
      return toEstimatorConfiguration(estimatorConfigHttp);
    }

    const { data, error: createError } = await this.API?.post<
      { data: Omit<EstimatorConfigurationWizard, 'id'> },
      { data: EstimatorConfigurationHttp }
    >(this.estimatorConfigEndpoint, {
      data: omit(toEstimatorConfigurationWizard({}), 'id'),
    });

    if (createError) {
      this.errorService.handleHttpError(createError);
      return null;
    }

    return toEstimatorConfiguration(data?.data || {});
  }

  public async updateEstimatorConfiguration({
    id,
    ...estimatorConfig
  }: EstimatorConfigurationWizard): Promise<EstimatorConfiguration | null> {
    const { data, error } = await this.API?.patch<
      { data: EstimatorConfigurationWizard },
      { data: EstimatorConfigurationHttp }
    >(`${this.estimatorConfigEndpoint}/${id}`, {
      data: { ...estimatorConfig, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }
    return toEstimatorConfiguration(data?.data || {});
  }
}
