const system = {
  'system.title': 'My Actual space',
  'system.actions.submit': 'Envoyer',
  'system.actions.save': 'Enregistrer',
  'system.actions.validate': 'Valider',
  'system.actions.delete': 'Supprimer',
  'system.actions.cancel': 'Annuler',
  'system.actions.update': 'Mettre à jour',
  'system.select.autocomplete.information':
    'Veuillez saisir au moins 2 caractères',
  'system.select.autocomplete.searching': 'Recherche en cours',
  'system.select.autocomplete.noOptionsMessage': 'Aucune donnée trouvée',
};

const navigation = {
  'navigation.links.dashboard': 'Dashboard',
  'navigation.links.my_profile': 'My profile',
  'navigation.links.my_account': 'My Account',
  'navigation.links.my_ads': 'Mes ads',
  'navigation.links.my_applications': 'My Applications',
  'navigation.links.my_missions': 'My Missions',
  'navigation.links.my_documents': 'My Documents',
  'navigation.links.my_advantages': 'My Advantages',
  'navigation.links.settings': 'My Settings',
  'navigation.links.faq': 'Faq',
};

const layout = {
  'layout.footer.faq.help_text': 'Need help about your personal space ?',
  'layout.footer.faq.help_link_text': 'Check our FAQ',
};

const pages = {
  'pages.dashboard.welcome': 'Welcome',
  'pages.dashboard.dashboard': 'Dashboard',
  'pages.dashboard.my_suggested_ads': 'My suggested ads',
  'pages.dashboard.my_active_applications': 'My active applications',
  'pages.my_account.title': 'My account',
  'pages.ads.card.publication_date': 'Published {{date}} ago',
  'layout.widgets.disponibilite.available.text':
    'Vous nous avez indiqué être indisponible le {{date}}',
  'layout.widgets.disponibilite.available.cta': 'Je suis toujours disponible',
  'layout.widgets.disponibilite.available_from.text':
    'Vous nous avez indiqué être disponible à partir du {{date}}',
  'layout.widgets.disponibilite.available_from.cta':
    'Je suis toujours disponible à cette date',
  'layout.widgets.disponibilite.not_available.text':
    'Vous nous avez indiqué ne pas être disponible le {{date}}',
  'layout.widgets.disponibilite.not_available.cta': 'Je suis disponible',
  'layout.widgets.disponibilite.update_link': 'Modifier ma disponibilite',
};

const forms = {
  'forms.api.error': 'Error api',
  'forms.profile.availability.label': 'Availability',
  'forms.profile.availability.text': "I'm available",
  'forms.profile.availability_start_date.label': 'Begin',
  'forms.profile.availability_end_date.label': 'End',
  'forms.profile.extended_research.label': 'Extended research',
  'forms.profile.extended_research.cities.label': 'Cities',
  'forms.profile.extended_research.departments.label': 'Districts',
  'forms.profile.extended_research.regions.label': 'Counties',
  'forms.profile.jobs_wanted.label': 'Wanted jobs',
  'forms.profile.jobs_wanted.placeholder': '',
  'forms.profile.search_radius.label': 'Search radius',
  'forms.profile.search_radius.label_suffix': 'around {{city}}',
  'forms.profile.search_all_over_france.label': 'Search all over France',
  'forms.profile.types_of_contract.label': 'Type of contract sought',
  'forms.profile.types_of_contract.placeholder': '',
  'forms.profile.full_time_job.label': 'Full-time',
  'forms.profile.part_time_job.label': 'Part-time',
  'forms.profile.annual_remuneration.label': 'Annual remuneration',
  'forms.profile.working_environments.label': 'Working environments',
  'forms.profile.working_environments.placeholder':
    'Night work, carrying heavy loads ...',
  'forms.profile.skills.label': 'Skills',
  'forms.profile.skills.placeholder':
    'Mechanical assembly, creation of quotes ...',
  'forms.profile.authorizations.label': 'Authorizations management',
  'forms.profile.authorizations.placeholder': 'CACES ...',
  'forms.profile.driving_licenses.label': 'Driving licenses',
  'forms.profile.driving_licenses.placeholder': '',
  'forms.profile.transportations.label': 'Conveyance',
  'forms.profile.transportations.placeholder': '',
  'forms.profile.languages.title': 'Languages',
  'forms.profile.languages.lang.label': 'Language',
  'forms.profile.languages.lang_level.label': 'Level',
  'forms.profile.soft_skills.label': 'Know how',
  'forms.profile.soft_skills.placeholder': 'Perseverance, discretion ...',
  'forms.profile.experiences.title': 'Experiences',
  'forms.profile.experience.role.label': 'Role',
  'forms.profile.experience.role.placeholder': '',
  'forms.profile.experience.date_begin.label': 'Begin',
  'forms.profile.experience.date_end.label': 'End',
  'forms.profile.experience.is_actual.label': 'This is my current position',
  'forms.profile.experience.company.label': 'Company',
  'forms.profile.experience.company.placeholder': '',
  'forms.profile.experience.contract_type.label': 'Contract type',
  'forms.profile.experience.contract_type.placeholder': '',
  'forms.profile.experience.summary.label': 'Summary of the experience',
  'forms.profile.experience.summary.placeholder': '',
  'forms.profile.formations.title': 'Formations',
  'forms.profile.formation.name.label': 'Name of diploma or training',
  'forms.profile.formation.date_begin.label': 'Begin',
  'forms.profile.formation.date_end.label': 'End',
  'forms.profile.formation.graduation.label': 'Graduation',
  'forms.profile.formation.graduation.1': 'Yes',
  'forms.profile.formation.graduation.0': 'No',
  'forms.profile.formation.graduation.2': 'In progress',
  'forms.profile.formation.type.label': 'Formation type',
  'forms.profile.formation.type.1': 'Initial',
  'forms.profile.formation.type.2': 'Continue',
  'forms.profile.formation.location.label':
    'School, institute or training organization',
  'forms.profile.formation.location.placeholder': '',
  'forms.profile.formation.study_level.label': 'Study level',
  'forms.profile.formation.study_level.placeholder': '',
  'forms.profile.formation.activity_category.label': 'Activity category',
  'forms.profile.formation.activity_category.placeholder': 'Activity category',
  'forms.profile.extended_radius.label': 'Extended search',
  'forms.account.civility.label': 'Civilité',
  'forms.account.civility.option.2': 'Madame',
  'forms.account.civility.option.1': 'Monsieur',
  'forms.account.first_name.label': 'Prénom',
  'forms.account.first_name.placeholder': '',
  'forms.account.last_name.label': 'Nom',
  'forms.account.last_name.placeholder': '',
  'forms.account.phone_number.label': 'Numéro de téléphone',
  'forms.account.phone_number.placeholder': '',
  'forms.account.birth_date.label': 'Date de naissance',
  'forms.account.birth_country.label': 'Pays de naissance',
  'forms.account.birth_country.placeholder': '',
  'forms.account.nationality.label': 'Nationalité',
  'forms.account.nationality.placeholder': '',
  'forms.account.nss.label': 'Numéro de sécurité sociale',
  'forms.account.nss.placeholder': '1-22-33-44-555-666',
  'forms.account.student.label': 'Je suis étudiant',
  'forms.account.disabled.label': 'Je suis en situation de handicap',
  'forms.account.localisation.title': 'Ma localisation',
  'forms.account.country.label': 'Pays',
  'forms.account.country.placeholder': '',
  'forms.account.google.label': 'Adresse',
  'forms.account.google.placeholder': '',
  'forms.account.email.label': 'Adresse email',
  'forms.account.communication.title': 'Mes préférences de communication',
  'forms.account.candidate_data_updated.label': 'Mise à jour des données',
  'forms.account.candidate_data_updated.description':
    'Ces emails vous permettent d’enrichir votre profil (disponibilité, informations de votre fiche …).',
  'forms.account.push_ads.label': "Proposition d'annonces pertinentes",
  'forms.account.push_ads.description':
    'Ces emails vous permettent de recevoir des offres d’emploi correspondant à votre profil de recherche.',
  'forms.account.civility.option.daily': 'Quotidien',
  'forms.account.civility.option.weekly': 'Hebdomadaire',
  'forms.account.civility.option.monthly': 'Mensuel',
  'forms.account.civility.option.push_adds': 'Publication annonce',
  'forms.account.notifications.title': 'Notifications',
  'forms.account.network.title': 'Mes réseaux sociaux',
  'forms.account.stop_sms.text': 'Stop sms',
  'form.account.stop_sms.description':
    'Si vous ne souhaitez plus recevoir de SMS de notre part.',
  'forms.account.stop_email.text': 'Stop email',
  'form.account.stop_email.description':
    "Si vous ne souhaitez plus recevoir d'email de notre part.",
  'forms.account.linkedin.label': 'LinkedIn',
  'forms.account.viadeo.label': 'Viadeo',
  'forms.account.linkedin.placeholder': '',
  'forms.account.viadeo.placeholder': '',
  'forms.account.informations.phone.label': 'Numéro de téléphone',
  'forms.account.informations.phone.placeholder': '00 00 00 00 00',
  'forms.account.google.incomplete_link': 'Adresse incomplète ?',
  'forms.account.change_password.title': 'Modifier mon mot de passe',
  'forms.account.current_password.label': 'Mot de passe actuel',
  'forms.account.current_password.placeholder': '',
  'forms.account.new_password.placeholder': '',
  'forms.account.new_password.label': 'Nouveau mot de passe',
  'forms.account.confirm_password.label':
    'Confirmer votre nouveau mot de passe',
  'forms.account.confirm_password.placeholder': '',
  'forms.field.updated': 'été correctement mise à jour.',
  'forms.field.category.disponibilite': 'Ma disponibilité a',
  'forms.field.category.mobilite': 'Ma mobilité a',
  'forms.field.category.profil': 'Mon profil a',
  'forms.field.category.langues': 'Mes langues ont',
  'forms.field.category.professions': 'Mes professions ont',
  'forms.field.category.experiences': 'Mes experiences ont',
  'forms.field.category.formations': 'Mes formations ont',
  'forms.field.category.details': 'Mes informations personnelles ont',
  'forms.field.category.adresse': 'Ma localisation a',
  'forms.field.category.communications':
    'Mes préférences de communications ont',
  'forms.field.category.experience.remove':
    "L'expérience a été supprimée de mon profil.",
  'forms.field.category.formation.remove':
    'La formation a été supprimée de mon profil.',
  'forms.field.category.language.remove':
    'La langue a été supprimée de mon profil.',
};

const validator = {
  'validator.global.valid': 'Field is valid.',
  'validator.global.save': 'Field is saved.',
  'validator.global.empty': 'Field must not be empty.',
  'validator.global.empty_adresse': 'Put an address.',
};

const notifications = {
  'notifications.user.logged_in': 'User is now authenticated.',
  'notifications.user.password.reset.title': 'User',
  'notifications.user.password.reset.text':
    'Le mot de passe a bien été mis à jour',
};

const modals = {
  'modal.password.edit.title': 'Modifier mon mot de passe',
  'modal.language.remove.title': 'Supprimer la langue',
  'modal.experience.remove.title':
    'Êtes vous sur de vouloir supprimer cette expérience ?',
  'modal.formation.remove.title':
    'Êtes vous sur de vouloir supprimer cette formation ?',
  'modal.documents.add.title': 'Ajouter un CV',
  'modal.documents.add.delete': 'supprimer',
  'modal.incomplete_address.edit.title': "Enrichissement de l'adresse",
  'modal.incomplete_address.street_number.label': 'Numéro',
  'modal.incomplete_address.route.label': 'Rue',
  'modal.incomplete_address.postal_code.label': 'Code Postal',
  'modal.incomplete_address.locality.label': 'Ville',
  'modal.profile.completion.title': 'Complétion du profil',
  'modal.profile.completion.category.profile': 'Mes métiers recherchés',
  'modal.profile.completion.category.mobility': 'Lieu de recherche',
  'modal.profile.completion.category.tags': 'Mes compétences',
  'modal.profile.completion.category.documents': 'Mon CV',
  'modal.profile.completion.category.formations': 'Ma formation',
  'modal.profile.completion.category.experiences': 'Mon expérience',
  'modal.profile.completion.description':
    "Afin de multiplier vos chances d'être recruté, pensez à renseigner votre profil !",
  'modal.profile.completion.complete': 'completés',
};

const ads = {
  'ads.title': 'My suggested ads',
  'ads.no_results':
    'We invite you to complete your profile in order to provide you relevant job offers.',
  'ads.list.all': 'All',
  'ads.list.sort.score': 'Pertinence',
  'ads.list.sort.start_date': 'Start date',
  'ads.cta_title': 'Update your profile',
  'ads.applied': 'Applied sent',
  'ads.start_date_asap': 'As soon as possible',
};

const application = {
  'application.title': 'Mes application',
  'application.no_results': '',
  'application.list.all': 'All',
  'application.cta_title': 'Make new search',
  'application.applied': 'Application sent',
  'application.start_date_asap': 'As soon as possible',
  'application.status.0': 'In Progress',
  'application.status.1': 'In Progress',
  'application.status.2': 'Choosen',
  'application.status.3': 'KO',
};

const my_list = {
  'my_list.sort_by': 'Sort by',
  'my_list.by_page': 'By page',
};

const document = {
  'document.delete': 'Delete',
  'document.updated_at': 'Updated at',
  'documents.title': 'My documents',
  'documents.title.cv': 'My Resumes',
  'documents.title.mission': 'Mission',
  'documents.title.pending_documents': 'Demande de documents',
  'documents.cta.text': 'Add a document',
  'documents.delete.success': 'Document is now deleted',
  'documents.notification.success': 'Document is now added',
  'documents.errors.filesize': 'Le document is too large, maximum 5Mo',
  'modal.documents.delete.title': 'Delete document',
  'document.delete.message': 'Are you sure to delete the document',
  'documents.empty': "You d'ont have any documents.",
};

const advantages = {
  'advantages.cet.title': 'My CET application',
  'advantages.title.block_1':
    'The passbook Actual: The Reinvented Time Savings Account',
  'advantages.text.block_1':
    'This new innovative savings contributes to the development of the purchasing power of temporary workers.',
  'advantages.title.block_2': 'Actual Sponsorship Offer',
  'advantages.text.block_2':
    'Actual, the job-sharing solution: Be rewarded if:\n' +
    'you recommend us\n' +
    'a person in search of a mission, and whose profile corresponds to our main needs;\n' +
    'a person (including yourself), already having a mission but not yet registered with an Actual agency\n' +
    'you refer to Actual a company with recruitment needs (interim, others, etc.)\n' +
    'Put us in touch, and receive a Cadhoc check.\n' +
    'To find out all the conditions of these sponsorship operations, do not hesitate to come and talk to your Actual agency.',
};

const dashboard = {
  'pages.dashboard.my_suggested_ads.empty':
    "Consulter toutes nos offres d'emploi",
  'pages.dashboard.my_active_applications.empty':
    "Consulter toutes nos offres d'emploi",
  'pages.dashboard.agency.description':
    'Agence généraliste spécialisé sur les domaines de la Grande Distribution.',
  'pages.dashboard.agency.link': 'Faire une demande d’acompte',
  'pages.dashboard.add_skills': '+ Ajouter des compétences',
  'pages.dashboard.add_experiences': '+ Ajouter des experiences',
  'pages.dashboard.add_formations': '+ Ajouter des formations',
  'pages.dashboard.add_documents': '+ Ajouter un document d’identité',
  'pages.dashboard.profile.description':
    'Renseignez vos compétences, votre expérience, votre formation…',
  'pages.dashboard.add_documents.description':
    'Tous vos documents sont regroupés ici.',
  'pages.dashboard.advantage.description':
    'Parrainage, livret CET... Collaborez avec Actual et découvrez vos avantages !',
  'pages.dashboard.account.description':
    'Mettez à jour votre mot de passe, réglez vos préférences ici.',
  'pages.dashboard.search_job.search': 'Je recherche un poste de',
  'pages.dashboard.search_job.around': 'around',
  'pages.dashboard.search_job.modify': 'Modify',
  'pages.dashboard.search_job.at': 'at',
  'pages.dashboard.search_job.and': 'and',
  'pages.dashboard.search_job.jobs_empty':
    'Quel(s) métier()s recherchez-vous ?',
  'pages.dashboard.search_job.cities_empty':
    'Définissez une zone de recherche.',
};

export default {
  ...system,
  ...navigation,
  ...layout,
  ...pages,
  ...forms,
  ...validator,
  ...notifications,
  ...modals,
  ...ads,
  ...application,
  ...my_list,
  ...document,
  ...advantages,
  ...dashboard,
};
