import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { WithTranslation, withTranslation } from 'react-i18next';
import useStore from '@stores/store';
import { ReactNode } from 'react';
import { Button } from '@groupeactual/ui-kit';

interface Props {
  message: string | ReactNode;
  onConfirm: () => void;
  hideCancelBtn?: boolean;
}

const ConfirmDialog = ({
  message,
  onConfirm,
  hideCancelBtn = false,
  t: trans,
}: Props & WithTranslation): JSX.Element => {
  const { closeModal } = useStore();

  return (
    <Box width={500}>
      <Box mb={4}>
        <Alert
          severity="warning"
          variant="outlined"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'orangeWarning',
            borderColor: 'orangeWarning',
            '& .MuiAlert-icon': {
              color: 'orangeWarning',
            },
          }}
        >
          {message}
        </Alert>
      </Box>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item mb={1}>
          <Button
            disableElevation
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              onConfirm();
              closeModal();
            }}
          >
            {trans('action.confirm.label')}
          </Button>
        </Grid>
        {!hideCancelBtn && (
          <Grid item mr={1.25} mb={1}>
            <Button variant="secondary" onClick={() => closeModal()}>
              {trans('action.cancel.label')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default withTranslation()(ConfirmDialog);
