import ActionsContainer from '@components/Layout/ActionsContainer';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Skeleton from '@mui/material/Skeleton';

const RepositorySkeleton = (): JSX.Element => {
  return (
    <Box display="flex" flexDirection="column" height="100%" mt={5}>
      <>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={30}
          width={300}
        />
        <Grid container item justifyContent="flex-end">
          <Box mb={2}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={33}
              width={206}
            />
          </Box>
        </Grid>
      </>
      <Box flexGrow={1}>
        <TableContainer component={Paper} style={{ minHeight: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={40} />
                <TableCell align="right">
                  <Skeleton variant="rectangular" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="rectangular" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="rectangular" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="rectangular" />
                </TableCell>
                <TableCell width={150} />
              </TableRow>
            </TableHead>
            {[...Array(5).keys()].map((i) => (
              <TableBody key={i}>
                <TableRow>
                  <TableCell component="th" scope="row" width={40}>
                    <Skeleton variant="rectangular" width={20} height={20} />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell>
                    <ActionsContainer>
                      <Skeleton variant="circular" width={20} height={20} />
                      <Skeleton variant="circular" width={20} height={20} />
                    </ActionsContainer>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default RepositorySkeleton;
