import day from '@_plugins/dayjs';
import { CandidateTypeHttp } from '@data/models/candidate-type.interface';
import { DeviceTypeHttp } from '@data/models/device-type.interface';
import {
  PecRate,
  PecRateHttp,
  PecRateIncluded,
  PecRateRawIncluded,
  PecRateWizard,
} from '@data/models/pec-rate.interface';

import { toApiFloat } from './api.dto';
import { toCandidateType } from './candidate-type.dto';
import { toDeviceType } from './device-type.dto';

export const toPecRateIncluded = (
  included: PecRateRawIncluded = [],
): PecRateIncluded => ({
  deviceTypes: included.filter(
    ({ type }) => type === 'device_type_repositories',
  ) as DeviceTypeHttp[],
  candidateTypes: included.filter(
    ({ type }) => type === 'candidate_type_repositories',
  ) as CandidateTypeHttp[],
});

export const toPecRate = (
  { id, attributes, relationships }: Partial<PecRateHttp>,
  { candidateTypes, deviceTypes }: Partial<PecRateIncluded>,
): PecRate => {
  const candidateTypesId = relationships?.candidate_types?.data?.map(
    ({ id }) => id,
  );

  const candidateTypesHttp: CandidateTypeHttp[] =
    candidateTypes?.filter(({ id }) => candidateTypesId?.includes(id)) || [];

  const deviceTypeHttp: DeviceTypeHttp | {} =
    deviceTypes?.find(
      ({ id }) => id === relationships?.device_type?.data?.id,
    ) || {};

  return {
    id: id || '',
    label: attributes?.label || '',
    rate: attributes?.rate || '0',
    cofiRate: attributes?.cofi_rate || '0',
    candidateTypes: candidateTypesHttp.map(toCandidateType),
    deviceType: toDeviceType(deviceTypeHttp || {}),
    createdAt: day(attributes?.createdAt),
    updatedAt: day(attributes?.updatedAt),
  };
};

export const toPecRateWizard = (pecRate: Partial<PecRate>): PecRateWizard => {
  return {
    type: 'pec_rate_repositories',
    id: pecRate.id || '',
    attributes: {
      label: pecRate.label || '',
      rate: toApiFloat(pecRate.rate),
      cofi_rate: toApiFloat(pecRate.cofiRate),
    },
    relationships: {
      device_type: {
        data:
          (pecRate.deviceType?.id && {
            type: 'device_type_repositories',
            id: pecRate.deviceType?.id || '',
          }) ||
          null,
      },
      candidate_types: {
        data:
          pecRate.candidateTypes?.map((type) => ({
            type: 'candidate_type_repositories',
            id: type.id,
          })) || undefined,
      },
    },
  };
};
