import { TrainingCourse } from '@data/models/training-course.interface';
import TrainingCourseService from '@data/services/training-course.service';

import { useInjection } from 'context/Ioc.context';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import useStore from '@stores/store';

const useTrainingCourseRetriever = (
  ref: string,
): {
  trainingCourse: TrainingCourse | undefined;
  setTrainingCourse: (trainingCourse: TrainingCourse) => void;
} => {
  const trainingCourse = useStore((state) =>
    state.findTrainingCourseByRef(`ERPF#${ref}`),
  );

  const { addShowTrainingCourse, updateShowTrainingCourse } = useStore();

  const service = useInjection<TrainingCourseService>(TrainingCourseService);

  useEffect(() => {
    document.title = ` ALEC - ERPF#${ref}`;

    service.getTrainingCourseByRef(ref).then((trainingCourseRetrieved) => {
      if (!trainingCourseRetrieved) {
        Sentry.captureException({
          title: 'Get training course by ref',
          message: `Cannot retrieve training course with ${ref}`,
        });
        return;
      }
      if (trainingCourseRetrieved.id !== trainingCourse?.id)
        addShowTrainingCourse(trainingCourseRetrieved);
    });
  }, [ref]);

  const setTrainingCourse = (trainingCourse: TrainingCourse) =>
    updateShowTrainingCourse(trainingCourse);

  return {
    trainingCourse,
    setTrainingCourse,
  };
};

export default useTrainingCourseRetriever;
