import dayjs from '@_plugins/dayjs';
import { SelectOption } from '@data/models/select.interface';
import {
  Training,
  TrainingHttp,
  TrainingIncluded,
  TrainingIncludedHttp,
  TrainingOption,
  TrainingOptionHttp,
} from '@data/models/training.interface';

export const toTrainingAutocomplete = (training: Training): SelectOption => {
  return {
    label: `${training.label} - ${String(training.parent?.label)}`,
    value: training.id,
  };
};

export const toTrainingOption = (
  option: Partial<TrainingOptionHttp>,
): TrainingOption => {
  return {
    id: option.id || '',
    createdAt: dayjs(option.attributes?.createdAt || ''),
    description: option.attributes?.description || '',
    label: option.attributes?.label || '',
    updatedAt: dayjs(option.attributes?.updatedAt || ''),
  };
};

export const toTraining = (
  training: Partial<TrainingHttp>,
  included: Partial<TrainingIncluded>,
): Training => {
  const parent = (included.trainings || []).find(({ id }) => {
    return id === training.relationships?.parent?.data?.id;
  });
  const root = (included.trainings || []).find(({ id }) => {
    return id === training.relationships?.root?.data?.id;
  });

  const optionsId =
    training.relationships?.options.data?.map((data) => data.id) || [];

  const options = (included.options || []).filter(({ id }) => {
    return optionsId.includes(id);
  });

  return {
    options: options.map(toTrainingOption) || [],
    id: training.id || '',
    label: training.attributes?.label || '',
    parent: parent && toTraining(parent, {}),
    root: root && toTraining(root, {}),
  };
};

export const toTrainingIncluded = (
  included: TrainingIncludedHttp,
): TrainingIncluded => {
  return {
    trainings: included.filter(
      ({ type }) => type === 'trainings',
    ) as TrainingHttp[],
    options: included.filter(
      ({ type }) => type === 'training_options',
    ) as TrainingOptionHttp[],
  };
};

export const fromStoredSearchTraining = ({
  id,
  label,
}: Pick<Training, 'id' | 'label'>): Training => {
  return { id: id, label: label, options: [] };
};
