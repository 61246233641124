import { useEffect, useState } from 'react';
import { useInjection } from 'context/Ioc.context';

// region
import RegionService from '@data/services/region.service';
import { Region } from '@data/models/region.interface';
import Checkbox from '@mui/material/Checkbox';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Autocomplete from '@mui/material/Autocomplete';
import { withTranslation, WithTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
interface Props {
  label: string;
  cacheOptions?: boolean;
  placeholder?: string;
  value?: Region[];
  defaultOptions?: Region[];
  onChange: (region: Region[]) => void;
  error?: string[];
  fromDelegation?: boolean;
}

// eslint-disable-next-line react/display-name
const RegionSelectMultiple = forwardRef(
  (
    {
      label,
      defaultOptions = [],
      placeholder,
      onChange,
      value = [],
      t: trans,
      error,
      fromDelegation = false,
    }: Props & WithTranslation,
    ref,
  ): JSX.Element => {
    const [regions, setRegions] = useState<Region[]>(defaultOptions);

    const service = useInjection<RegionService>(RegionService);

    useEffect(() => {
      if (fromDelegation) return;
      if (!regions.length) {
        service
          .listRegions({
            page: 1,
            orderBy: 'position',
            itemsPerPage: 100,
          })
          .then(({ items }) => setRegions(items));
      }
    }, []);

    useEffect(() => {
      if (!fromDelegation) return;
      if (defaultOptions.length || regions.length) {
        setRegions(defaultOptions);
      }
    }, [defaultOptions]);

    const handleChange = (devices: Region[] | null) => {
      onChange(devices || []);
    };

    return (
      <>
        <Autocomplete
          openText={trans('action.autocomplete.open')}
          value={value}
          multiple
          size="small"
          limitTags={1}
          disableCloseOnSelect
          onChange={(e, regions) => handleChange(regions)}
          options={regions}
          PopperComponent={(props) => (
            <Popper {...props} placement="bottom-start" />
          )}
          getOptionLabel={({ name }) => name}
          renderOption={(props, { name }, { selected }) => (
            <Box component="li" {...props}>
              <Checkbox size="small" checked={selected} />
              <Typography variant="button">{name}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={ref}
              variant="outlined"
              placeholder={placeholder}
              label={label}
              error={!!error}
            />
          )}
        />
        {error && (
          <FormHelperText className="Mui-error">{error}</FormHelperText>
        )}
      </>
    );
  },
);

export default withTranslation('translation', { withRef: true })(
  RegionSelectMultiple,
);
