import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

import TrainCourseStepWizardSkeleton from './TrainCourseStepWizardSkeleton';

const TrainingCourseTrainingsSkeleton = (): JSX.Element => {
  return (
    <Box mt={5}>
      <TrainCourseStepWizardSkeleton step={2} />
      <Card>
        <CardContent>
          <Box my={3} maxWidth={480}>
            <Skeleton variant="rectangular" height={30} />
          </Box>

          <Box my={3} maxWidth={480}>
            <Alert
              severity="info"
              variant="outlined"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'blueClickable',
                borderColor: 'blueClickable',
                '& .MuiAlert-icon': {
                  color: 'blueClickable',
                },
              }}
            >
              <Box height={40} />
            </Alert>
          </Box>

          <Box maxWidth={480}>
            <Skeleton variant="rectangular" height={30} />
          </Box>

          <Grid container>
            <Grid item md={6} xs={12}>
              <Box my={2}>
                <Skeleton variant="rectangular" height={100} />
              </Box>
              <Box my={2}>
                <Skeleton variant="rectangular" height={100} />
              </Box>

              <Box my={3}>
                <Skeleton variant="rectangular" height={30} />
              </Box>
              <Box my={3}>
                <Skeleton variant="rectangular" height={30} />
              </Box>

              <Skeleton variant="rectangular" height={10} />
            </Grid>
            <Grid item xs={1} />
            <Grid item md={5} xs={12}>
              <Box mb={2}>
                <Skeleton variant="text" height={30} />
              </Box>
              {[...Array(9).keys()].map((i) => (
                <Grid container spacing={3} key={i}>
                  <Grid item xs={1}>
                    <Skeleton variant="rectangular" width={20} height={20} />
                  </Grid>
                  <Grid item xs={10}>
                    <Box maxWidth={400}>
                      <Skeleton variant="text" height={20} />
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Box my={3}>
            <Skeleton variant="text" width={200} height={30} />
          </Box>

          <Alert
            severity="info"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'blueClickable',
              borderColor: 'blueClickable',
              '& .MuiAlert-icon': {
                color: 'blueClickable',
              },
            }}
          >
            <Box height={40}></Box>
          </Alert>

          <Box my={3} maxWidth={500}>
            <Skeleton variant="text" height={10} />
          </Box>

          <Box mt={3}>
            <Grid container justifyContent="flex-end" spacing={3}>
              <Grid item>
                <Skeleton variant="rectangular" width={80} height={30} />
              </Grid>
              <Grid item>
                <Skeleton variant="rectangular" width={80} height={30} />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TrainingCourseTrainingsSkeleton;
