/* eslint-disable complexity */
import { MinWage } from '@data/models/min-wage.interface';
import { OpcoCharge } from '@data/models/opco-charge.interface';
import { RELIEF_TYPE } from '@data/models/relief-type.interface';
import {
  EducationalCost,
  EducationalCostHttp,
  EducationalCostWizard,
  EstimatorCandidateType,
  EstimatorCandidateTypeHttp,
  EstimatorCandidateTypeWizard,
  TrainingCourseEstimator,
  TrainingCourseEstimatorHttp,
  TrainingCourseEstimatorStatus,
  TrainingCourseEstimatorWizard,
} from '@data/models/training-course-estimator.interface';
import {
  TrainingCourseTraining,
  TrainingCourseTrainingInvoiceType,
} from '@data/models/training-course-training.interface';
import {
  TrainingCourse,
  TrainingCourseIncluded,
} from '@data/models/training-course.interface';
import compact from 'lodash/compact';
import { toDeviceType } from './device-type.dto';

import {
  computeEstimatorValues,
  getEducationalCostLabel,
} from '@helpers/training-course-estimator.helper';
import { toMinWage } from './min-wage.dto';
import { toOpcoCharge } from './opco-charge.dto';
import { toApiFloat, toBoolean } from './api.dto';
import { toManager } from './manager.dto';
import { EstimatorConfiguration } from '@data/models/estimator-configuration.interface';
import { toValidationType } from './validation-type.dto';
import { PecRate } from '@data/models/pec-rate.interface';
import { toCoFunder } from './co-funder.dto';
import { DeviceType } from '@data/models/device-type.interface';
import { CandidateType } from '@data/models/candidate-type.interface';
import { toTrainingCourseEducationalPath } from '@data/dto/training-course-educational-path.dto';
import { TrainingCourseEducationalPath } from '@data/models/training-course-educational-path.interface';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';

export const fromEstimatorCandidateTypeHttp = ({
  attributes,
  relationships,
  id,
}: Partial<EstimatorCandidateTypeHttp>): EstimatorCandidateType => {
  return {
    id: id || '',
    label: attributes?.label || '',
    quantity: attributes?.quantity || 0,
    position: attributes?.position || 0,
    pecRate: parseFloat(attributes?.pec_rate || '0'),
    candidateTypeId: relationships?.candidate_type?.data?.id || '',
  };
};

export const fromPecRatesToEstimatorCandidateType = (
  pecRates: PecRate[],
  pecRateCandidateTypes: CandidateType[],
  candidateType: CandidateType | undefined,
  withCofi: boolean,
): EstimatorCandidateType => {
  const match = pecRateCandidateTypes.find(
    ({ id }) => candidateType?.id === id,
  );

  if (match) {
    const pecRateFromCandidateType = pecRates.find((pecRate) =>
      pecRate.candidateTypes.find(
        (pecRateCandidateType) => pecRateCandidateType.id === match.id,
      ),
    );
    return {
      id: '',
      label: match.label,
      quantity: 1,
      position: match.position || 0,
      pecRate:
        (withCofi && parseFloat(pecRateFromCandidateType?.cofiRate || '0')) ||
        parseFloat(pecRateFromCandidateType?.rate || '0'),
      candidateTypeId: match.id,
    };
  }
  if (candidateType) {
    return {
      id: '',
      label: candidateType.label,
      quantity: 1,
      position: candidateType.position,
      pecRate: 0,
      candidateTypeId: candidateType.id,
    };
  }
  return fromEstimatorCandidateTypeHttp({});
};

export const toEstimatorCandidateTypeWizard = (
  {
    id,
    label,
    quantity,
    pecRate,
    position,
    candidateTypeId,
  }: Partial<EstimatorCandidateType>,
  estimatorId: string,
): EstimatorCandidateTypeWizard => ({
  attributes: {
    label: label || '',
    quantity: quantity || 0,
    position: position || 0,
    pec_rate: toApiFloat(pecRate, 8),
  },
  id: id || '',
  type: 'estimator_candidate_types',
  relationships: {
    training_course_estimator: {
      data: {
        id: estimatorId,
        type: 'training_course_estimators',
      },
    },
    candidate_type: {
      data:
        (candidateTypeId && {
          id: candidateTypeId,
          type: 'candidate_type_repositories',
        }) ||
        undefined,
    },
  },
});

export const fromEduationalCostHttp = ({
  attributes,
  id,
}: Partial<EducationalCostHttp>): EducationalCost => {
  const amount = parseFloat(attributes?.amount || '0');
  const invoiceType =
    attributes?.invoice_type ||
    TrainingCourseTrainingInvoiceType.INDIVIDUAL_COST;
  return {
    amount,
    invoiceType,
    id: id || '',
    duration: parseFloat(attributes?.duration || '0'),
    label: getEducationalCostLabel(invoiceType, amount),
    organizationName: attributes?.organization_name || '',
    total: parseFloat(attributes?.total || '0'),
  };
};

export const toEducationalCostWizard = (
  {
    amount,
    id,
    duration,
    invoiceType,
    organizationName,
    total,
  }: Partial<EducationalCost>,
  estimatorId: string,
): EducationalCostWizard => ({
  attributes: {
    amount: toApiFloat(amount || '0', 8),
    duration: toApiFloat(duration || '0', 8),
    invoice_type:
      invoiceType || TrainingCourseTrainingInvoiceType.INDIVIDUAL_COST,
    organization_name: organizationName || '',
    total: toApiFloat(total || '0', 8),
  },
  id: id || '',
  type: 'estimator_educational_costs',
  relationships: {
    training_course_estimator: {
      data: {
        id: estimatorId,
        type: 'training_course_estimators',
      },
    },
  },
});

export const toEducationalCost = ({
  organizationName,
  duration,
  invoiceType,
  cost,
}: Partial<TrainingCourseTraining>): EducationalCost => ({
  id: '',
  invoiceType: invoiceType || TrainingCourseTrainingInvoiceType.GROUP_COST,
  organizationName: organizationName || '',
  duration: parseFloat(duration || '0'),
  amount: parseFloat(cost || '0'),
  label: getEducationalCostLabel(
    invoiceType || undefined,
    parseFloat(cost || '0'),
  ),
  total: 0,
});

export const fromTrainingCourseEstimatorHttp = (
  { id, attributes, relationships }: Partial<TrainingCourseEstimatorHttp>,
  included: Partial<TrainingCourseIncluded>,
): TrainingCourseEstimator => {
  const minWageHttp =
    included.minWages?.find(
      ({ id }) => id === relationships?.min_wage.data?.id,
    ) || {};

  const opcoChargeHttp =
    included.opcoCharges?.find(
      ({ id }) => id === relationships?.opco_charge.data?.id,
    ) || {};

  const validationTypeHttp =
    included.validationTypes?.find(
      ({ id }) => id === relationships?.validation_type.data?.id,
    ) || {};

  const deviceTypeHttp =
    included.deviceTypes?.find(
      ({ id }) => id === relationships?.device_type?.data?.id,
    ) || {};

  const managerHttp =
    included.managers?.find(
      ({ id }) => id === relationships?.approver?.data?.id,
    ) || {};

  const educationalPathHttp =
    included.educationalPaths?.find(
      ({ id }) => id === relationships?.educational_path?.data?.id,
    ) || {};

  const educationalCostsId =
    relationships?.educational_costs.data?.map(({ id }) => id) || [];

  const educationalCostsHttp =
    included.educationalCosts?.filter(({ id }) =>
      educationalCostsId.includes(id),
    ) || [];

  const candidateTypesId =
    relationships?.candidate_types.data?.map(({ id }) => id) || [];

  const candidatesTypesHttp =
    included.estimatorCandidateTypes?.filter(({ id }) =>
      candidateTypesId.includes(id),
    ) || [];

  const coFundersId = relationships?.co_funders.data?.map(({ id }) => id) || [];

  const coFundersHttp =
    included.coFunders?.filter(({ id }) => coFundersId.includes(id)) || [];

  const estimator = {
    id: id || '',
    name: attributes?.name || '',
    coFunders: coFundersHttp.map(toCoFunder),
    minWage: toMinWage(minWageHttp || {}),
    opcoCharge: toOpcoCharge(opcoChargeHttp || {}),
    validationType: toValidationType(validationTypeHttp || {}),
    reliefType:
      attributes?.relief_type === 'FILLON'
        ? RELIEF_TYPE.FILLON
        : RELIEF_TYPE.LODEOM,
    educationalCosts: educationalCostsHttp.map(fromEduationalCostHttp),
    educationalPath: toTrainingCourseEducationalPath(
      educationalPathHttp,
      included,
    ),
    candidateTypes: candidatesTypesHttp
      .map(fromEstimatorCandidateTypeHttp)
      .sort(({ position: a }, { position: b }) => a - b),
    deviceType: toDeviceType(deviceTypeHttp || {}),
    CPFAmount: parseFloat(attributes?.cpf_amount || '0'),
    CDIITraineeNumber: attributes?.cdii_trainee_number || 0,
    A2ITraineeNumber: attributes?.a2i_trainee_number || 0,
    seniorTraineeNumber: attributes?.senior_trainee_number || 0,
    localMissionTraineeNumber: attributes?.local_mission_trainee_number || 0,
    customerDelegationRate: parseFloat(
      attributes?.customer_delegation_rate || '0',
    ),
    POECTraineeNumber: attributes?.poec_trainee_number || 0,
    educationalCostTotalDuration: parseFloat(
      attributes?.educational_costs_total_duration || '0',
    ),
    organizationName: attributes?.positioning_review_organization_name || '',
    positioningReviewCost: parseFloat(
      attributes?.positioning_review_cost || '0',
    ),
    positioningReviewDuration: parseFloat(
      attributes?.positioning_review_duration || '0',
    ),
    candidatesNumber: attributes?.candidates_number || 0,
    educationalCostMinDuration: parseFloat(
      attributes?.educational_costs_min_duration || '0',
    ),
    educationalCostMaxDuration: parseFloat(
      attributes?.educational_costs_max_duration || '0',
    ),
    educationalCostAmountPerHour: parseFloat(
      attributes?.educational_costs_amount_per_hour || '0',
    ),
    educationalCostAmountPerCandidate: parseFloat(
      attributes?.educational_costs_amount_per_candidate || '0',
    ),
    educationalCostPraticalDuration: parseFloat(
      attributes?.educational_costs_practical_duration || '0',
    ),
    hasAdditionalCosts: toBoolean(attributes?.has_additional_costs || 0),
    additionalCostsAmount: parseFloat(
      attributes?.additional_costs_total_amount || '0',
    ),
    missionAverageRate: parseFloat(attributes?.mission_average_rate || '0'),
    trainingCourseAverageRate: parseFloat(
      attributes?.training_course_average_rate || '0',
    ),
    classicTraineeNumber: attributes?.classic_trainee_number || 0,

    handicappedTraineeNumber: attributes?.handicapped_trainee_number || 0,
    refugeeTraineeNumber: attributes?.refugee_trainee_number || 0,

    skillTraineeNumber: attributes?.skilled_trainee_number || 0,
    AFPRTraineeNumber: attributes?.afpr_trainee_number || 0,
    classicTraineePEC: parseFloat(attributes?.classic_trainee_pec || '0'),
    handicappedTraineePEC: parseFloat(
      attributes?.handicapped_trainee_pec || '0',
    ),
    refugeeTraineePEC: parseFloat(attributes?.refugee_trainee_pec || '0'),
    skillTraineePEC: parseFloat(attributes?.skilled_trainee_pec || '0'),
    POECTraineePEC: parseFloat(attributes?.poec_trainee_pec || '0'),
    AFPRTraineePEC: parseFloat(attributes?.afpr_trainee_pec || '0'),
    localMissionTraineePEC: parseFloat(
      attributes?.local_mission_trainee_pec || '0',
    ),
    averagePECRate: parseFloat(attributes?.average_pec_rate || '0'),
    isNationalNeed: toBoolean(attributes?.is_national_need || 0),
    totalMissionDurationInMonths: parseFloat(
      attributes?.total_mission_duration_in_months || '0',
    ),
    ddcTrainingDurationInMonths: parseFloat(
      attributes?.ddc_duration_in_months || '0',
    ),
    ddcComplementaryTrainingDuration: parseFloat(
      attributes?.ddc_complementary_training_duration_in_months || '0',
    ),
    ddcTrainingDurationPerMonth: parseFloat(
      attributes?.ddc_training_duration_per_month || '0',
    ),
    ddcTrainingMissionDurationInHours: parseFloat(
      attributes?.ddc_training_duration_in_hours || '0',
    ),
    ddcTrainingMissionDurationInDays: parseFloat(
      attributes?.ddc_training_duration_in_days || '0',
    ),
    ddcTrainingMissionDurationInMonths: parseFloat(
      attributes?.ddc_total_mission_duration_in_months || '0',
    ),
    isValidMissionDuration: toBoolean(
      attributes?.ddc_is_valid_mission_duration || 0,
    ),
    trainingMissionRatio: parseFloat(
      attributes?.ddc_training_mission_ratio || '0',
    ),
    hasExternalCoFunding: toBoolean(attributes?.has_external_co_funding || 0),
    externalCoFundingSupportedAmount: parseFloat(
      attributes?.external_co_funding_supported_amount || '0',
    ),
    hasFinancialAid: toBoolean(attributes?.has_financial_aid || 0),
    customersName: attributes?.customer_name || '',
    customersManagementRate: parseFloat(
      attributes?.customer_management_rate || '0',
    ),
    delegationRate: parseFloat(attributes?.delegation_rate || '0'),
    RFARate: parseFloat(attributes?.rfa_rate || '0'),
    sourcingAmount: parseFloat(attributes?.sourcing_amount || '0'),
    reInvoicingCostRate: parseFloat(attributes?.re_invoicing_cost_rate || '0'),
    reInvoicingCostAmount: parseFloat(
      attributes?.re_invoicing_cost_amount || '0',
    ),
    reInvoicingWageRate: parseFloat(attributes?.re_invoicing_wage_rate || '0'),
    reInvoicingWageAmount: parseFloat(
      attributes?.re_invoicing_wage_amount || '0',
    ),
    reInvoicingExtraCostRate: parseFloat(
      attributes?.re_invoicing_extra_cost_rate || '0',
    ),
    reInvoicingExtraCostAmount: parseFloat(
      attributes?.re_invoicing_extra_cost_amount || '0',
    ),
    engineeringCostAmount: parseFloat(attributes?.engineering_cost || '0'),
    recruitmentAmount: parseFloat(attributes?.recruitment_cost || '0'),
    managementFeesAmount: parseFloat(attributes?.management_fees || '0'),
    totalInvoiceAmount: parseFloat(attributes?.total_invoice_amount || '0'),
    additionalDelegationInMonths: parseFloat(
      attributes?.additional_delegation_in_months || '0',
    ),
    totalWagesAmount: parseFloat(attributes?.total_wages_amount || '0'),
    averageCoefficientOnMissionHours: parseFloat(
      attributes?.average_coefficient_on_mission_hours || '0',
    ),
    delegationBillingAmount: parseFloat(
      attributes?.delegation_billing_amount || '0',
    ),
    delegationBillingPostTrainingAmount: parseFloat(
      attributes?.delegation_billing_post_training_amount || '0',
    ),
    averageCoefficientOnMissionAndTrainingHours: parseFloat(
      attributes?.average_coefficient_on_mission_and_training_hours || '0',
    ),
    coefficientDelegationEvolution: parseFloat(
      attributes?.coefficient_delegation_evolution || '0',
    ),
    totalTrainingExpensesPerTrainee: parseFloat(
      attributes?.total_training_expenses_per_trainee || '0',
    ),
    totalTrainingExpensesPerGroup: parseFloat(
      attributes?.total_training_expenses_per_group || '0',
    ),
    totalTrainingRevenuePerTrainee: parseFloat(
      attributes?.total_training_revenue_per_trainee || '0',
    ),
    totalTrainingRevenueGroup: parseFloat(
      attributes?.total_training_revenue_per_group || '0',
    ),
    opcoRefund: parseFloat(attributes?.opco_refund || '0'),
    trainingAssessmentAmount: parseFloat(
      attributes?.training_assessment_amount || '0',
    ),
    externalTrainingAmount: parseFloat(
      attributes?.external_training_amount || '0',
    ),
    practicalTrainingAmount: parseFloat(
      attributes?.practical_training_amount || '0',
    ),
    turnOverPerTrainee: parseFloat(attributes?.turn_over_per_trainee || '0'),
    turnOverPerGroup: parseFloat(attributes?.turn_over_per_group || '0'),
    turnOverInMonthsPerTrainee: parseFloat(
      attributes?.turn_over_in_months_per_trainee || '0',
    ),
    turnOverInMonthsPerGroup: parseFloat(
      attributes?.turn_over_in_months_per_group || '0',
    ),
    grossMarginPerTraineeWithEnd: parseFloat(
      attributes?.gross_margin_per_trainee_with_end || '0',
    ),
    trainingCostWithCofiAmount: parseFloat(
      attributes?.training_cost_with_cofi_amount || '0',
    ),
    totalTrainingReliefCost: parseFloat(
      attributes?.total_training_relief_cost || '0',
    ),
    totalMissionReliefCost: parseFloat(
      attributes?.total_mission_relief_cost || '0',
    ),
    financialAidSupportedAmount: parseFloat(
      attributes?.financial_aid_supported_amount || '0',
    ),
    totalMissionRevenue: parseFloat(attributes?.total_mission_revenue || '0'),
    agreementETTIAmount: parseFloat(attributes?.etti_agreement_amount || '0'),
    agreementETTIPostTrainingAmount: parseFloat(
      attributes?.etti_agreement_post_training_amount || '0',
    ),
    ifmGainsAmount: parseFloat(attributes?.ifm_gains_amount || '0'),
    ifmGainsPostTrainingAmount: parseFloat(
      attributes?.ifm_gains_post_training_amount || '0',
    ),
    totalPostTrainingRevenue: parseFloat(
      attributes?.total_post_training_revenue || '0',
    ),
    totalPostTrainingReliefCost: parseFloat(
      attributes?.total_post_training_relief_cost || '0',
    ),
    missionWageExpenses: parseFloat(attributes?.mission_wage_expenses || '0'),
    missionRFAExpenses: parseFloat(attributes?.mission_rfa_expenses || '0'),
    totalMissionExpenses: parseFloat(attributes?.mission_total_expenses || '0'),
    postTrainingWageExpenses: parseFloat(
      attributes?.post_training_wage_expenses || '0',
    ),
    postTrainingRFAExpenses: parseFloat(
      attributes?.post_training_rfa_expenses || '0',
    ),
    totalPostTrainingExpenses: parseFloat(
      attributes?.post_training_total_expenses || '0',
    ),
    grossMarginPerTraineeWithoutEnd: parseFloat(
      attributes?.gross_margin_per_trainee_without_end || '0',
    ),
    trainingCostWithoutCOFIAmount: parseFloat(
      attributes?.training_cost_without_cofi_amount || '0',
    ),
    grossMarginTargetAmount: parseFloat(
      attributes?.gross_margin_target_amount || '0',
    ),
    grossMarginInMonthsPerTraineeWithEnd: parseFloat(
      attributes?.gross_margin_in_months_per_trainee_with_end || '0',
    ),
    grossMarginInMonthsPerTraineeWithoutEnd: parseFloat(
      attributes?.gross_margin_in_months_per_trainee_without_end || '0',
    ),
    grossMarginRateWithEnd: parseFloat(
      attributes?.gross_margin_rate_with_end || '0',
    ),
    grossMarginRateWithoutEnd: parseFloat(
      attributes?.gross_margin_rate_without_end || '0',
    ),
    grossMarginRateAgainstExtraCostWithEnd: parseFloat(
      attributes?.gross_margin_rate_against_extra_cost_with_end || '0',
    ),
    grossMarginRateAgainstExtraCostWithoutEnd: parseFloat(
      attributes?.gross_margin_rate_against_extra_cost_without_end || '0',
    ),
    invoiceRateAgainstExtraCost: parseFloat(
      attributes?.invoice_rate_against_extra_costs || '0',
    ),
    breakEvenWithEndAmount: parseFloat(
      attributes?.break_even_with_end_amount || '0',
    ),
    breakEvenWithoutEndAmount: parseFloat(
      attributes?.break_even_without_end_amount || '0',
    ),
    totalGrossMargin: parseFloat(attributes?.total_gross_margin || '0'),
    educationalCostAmountGroup: parseFloat(
      attributes?.educational_costs_amount_group || '0',
    ),
    status: attributes?.status || TrainingCourseEstimatorStatus.DRAFT_STATUS,
    approver: toManager(managerHttp),
  };

  return estimator;
};

// eslint-disable-next-line complexity
export const toTrainingCourseEstimatorWizard = (
  estimator: Partial<TrainingCourseEstimator>,
  trainingCourseId: string | null,
): TrainingCourseEstimatorWizard => {
  const estimatorWizard: TrainingCourseEstimatorWizard = {
    type: 'training_course_estimators',
    id: estimator.id || '',
    attributes: {
      name: estimator.name || '',
      status: estimator.status || TrainingCourseEstimatorStatus.DRAFT_STATUS,
      relief_type: estimator.reliefType || RELIEF_TYPE.FILLON,
      ddc_duration_in_months: toApiFloat(
        estimator.ddcTrainingDurationInMonths || '0' || '0',
        8,
      ),
      candidates_number: estimator.candidatesNumber || 0,
      positioning_review_duration: toApiFloat(
        estimator.positioningReviewDuration || '0' || '0',
        8,
      ),
      positioning_review_cost: toApiFloat(
        estimator.positioningReviewCost || '0' || '0',
        8,
      ),
      positioning_review_organization_name: estimator.organizationName || '',
      educational_costs_min_duration: toApiFloat(
        estimator.educationalCostMinDuration || '0',
        8,
      ),
      educational_costs_max_duration: toApiFloat(
        estimator.educationalCostMaxDuration || '0',
        8,
      ),
      educational_costs_total_duration: toApiFloat(
        estimator.educationalCostTotalDuration || '0',
        8,
      ),
      educational_costs_amount_per_candidate: toApiFloat(
        estimator.educationalCostAmountPerCandidate || '0',
        8,
      ),
      educational_costs_amount_per_hour: toApiFloat(
        estimator.educationalCostAmountPerHour || '0',
        8,
      ),
      educational_costs_practical_duration:
        toApiFloat(estimator.educationalCostPraticalDuration || '0', 8) || '',
      has_additional_costs: estimator.hasAdditionalCosts || false,
      additional_costs_total_amount: toApiFloat(
        estimator.additionalCostsAmount || '0',
        8,
      ),
      mission_average_rate: toApiFloat(estimator.missionAverageRate || '0', 8),
      training_course_average_rate: toApiFloat(
        estimator.trainingCourseAverageRate || '0',
        8,
      ),
      senior_trainee_number: estimator.seniorTraineeNumber || 0,
      a2i_trainee_number: estimator.A2ITraineeNumber || 0,
      cdii_trainee_number: estimator.CDIITraineeNumber || 0,
      average_pec_rate: toApiFloat(estimator.averagePECRate || '0', 8),
      is_national_need: estimator.isNationalNeed || false,
      cpf_amount: toApiFloat(estimator.CPFAmount || '0', 8),
      ddc_total_mission_duration_in_months:
        toApiFloat(estimator.ddcTrainingMissionDurationInMonths || '0', 8) ||
        '',
      ddc_training_duration_per_month: toApiFloat(
        estimator.ddcTrainingDurationPerMonth || '0',
        8,
      ),
      ddc_complementary_training_duration_in_months: toApiFloat(
        estimator.ddcComplementaryTrainingDuration || '0',
        8,
      ),
      ddc_training_duration_in_days: toApiFloat(
        estimator.ddcTrainingMissionDurationInDays || '0',
        8,
      ),
      ddc_training_duration_in_hours: toApiFloat(
        estimator.ddcTrainingMissionDurationInHours || '0',
        8,
      ),
      ddc_is_valid_mission_duration: estimator.isValidMissionDuration || false,
      ddc_training_mission_ratio: toApiFloat(
        estimator.trainingMissionRatio || 0 || '0',
        8,
      ),
      has_external_co_funding: estimator.hasExternalCoFunding || false,
      external_co_funding_supported_amount: toApiFloat(
        estimator.externalCoFundingSupportedAmount || '0',
        8,
      ),
      has_financial_aid: estimator.hasFinancialAid || false,
      financial_aid_supported_amount: toApiFloat(
        estimator.financialAidSupportedAmount || '0',
        8,
      ),
      customer_name: estimator.customersName || '',
      customer_management_rate: toApiFloat(
        estimator.customersManagementRate || '0',
        8,
      ),
      customer_delegation_rate: toApiFloat(
        estimator.customerDelegationRate || '0',
        8,
      ),
      delegation_rate: toApiFloat(estimator.delegationRate || '0', 8),
      rfa_rate: toApiFloat(estimator.RFARate || '0', 8),
      sourcing_amount: toApiFloat(estimator.sourcingAmount || '0', 8),
      re_invoicing_cost_rate: toApiFloat(
        estimator.reInvoicingCostRate || '0',
        8,
      ),
      re_invoicing_cost_amount: toApiFloat(
        estimator.reInvoicingCostAmount || '0',
        8,
      ),
      re_invoicing_wage_amount: toApiFloat(
        estimator.reInvoicingWageAmount || '0',
        8,
      ),
      re_invoicing_wage_rate: toApiFloat(
        estimator.reInvoicingWageRate || '0',
        8,
      ),
      re_invoicing_extra_cost_rate: toApiFloat(
        estimator.reInvoicingExtraCostRate || '0',
        8,
      ),
      re_invoicing_extra_cost_amount: toApiFloat(
        estimator.reInvoicingExtraCostAmount || '0',
        8,
      ),
      engineering_cost: toApiFloat(estimator.engineeringCostAmount || '0', 8),
      recruitment_cost: toApiFloat(estimator.recruitmentAmount || '0', 8),
      management_fees: toApiFloat(estimator.managementFeesAmount || '0', 8),
      total_invoice_amount: toApiFloat(estimator.totalInvoiceAmount || '0', 8),
      additional_delegation_in_months: toApiFloat(
        estimator.additionalDelegationInMonths || '0',
        8,
      ),
      average_coefficient_on_mission_hours: toApiFloat(
        estimator.averageCoefficientOnMissionHours || '0',
        8,
      ),
      delegation_billing_amount: toApiFloat(
        estimator.delegationBillingAmount || '0',
        8,
      ),
      delegation_billing_post_training_amount: toApiFloat(
        estimator.delegationBillingPostTrainingAmount || '0',
        8,
      ),
      delegation_billing_post_training_hours: toApiFloat(
        estimator.delegationBillingPostTrainingAmount || '0',
        8,
      ),
      average_coefficient_on_mission_and_training_hours: toApiFloat(
        estimator.averageCoefficientOnMissionAndTrainingHours || '0',
        8,
      ),
      coefficient_delegation_evolution: toApiFloat(
        estimator.coefficientDelegationEvolution || '0',
        8,
      ),
      total_training_expenses_per_trainee: toApiFloat(
        estimator.totalTrainingExpensesPerTrainee || '0',
        8,
      ),
      total_training_expenses_per_group: toApiFloat(
        estimator.totalTrainingExpensesPerGroup || '0',
        8,
      ),
      total_training_revenue_per_trainee: toApiFloat(
        estimator.totalTrainingRevenuePerTrainee || '0',
        8,
      ),
      total_training_revenue_per_group: toApiFloat(
        estimator.totalTrainingRevenueGroup || '0',
        8,
      ),
      opco_refund: toApiFloat(estimator.opcoRefund || '0', 8),
      training_assessment_amount: toApiFloat(
        estimator.trainingAssessmentAmount || '0',
        8,
      ),
      external_training_amount: toApiFloat(
        estimator.externalTrainingAmount || '0',
        8,
      ),
      practical_training_amount: toApiFloat(
        estimator.practicalTrainingAmount || '0',
        8,
      ),
      turn_over_per_trainee: toApiFloat(estimator.turnOverPerTrainee || '0', 8),
      turn_over_per_group: toApiFloat(estimator.turnOverPerGroup || '0', 8),
      turn_over_in_months_per_trainee: toApiFloat(
        estimator.turnOverInMonthsPerTrainee || '0',
        8,
      ),
      turn_over_in_months_per_group: toApiFloat(
        estimator.turnOverInMonthsPerGroup || '0',
        8,
      ),
      gross_margin_per_trainee_with_end: toApiFloat(
        estimator.grossMarginPerTraineeWithEnd || '0',
        8,
      ),
      gross_margin_per_trainee_without_end: toApiFloat(
        estimator.grossMarginPerTraineeWithoutEnd || '0',
        8,
      ),
      gross_margin_target_amount: toApiFloat(
        estimator.grossMarginTargetAmount || '0',
        8,
      ),
      gross_margin_in_months_per_trainee_with_end: toApiFloat(
        estimator.grossMarginInMonthsPerTraineeWithEnd || '0',
        8,
      ),
      gross_margin_in_months_per_trainee_without_end: toApiFloat(
        estimator.grossMarginInMonthsPerTraineeWithoutEnd || '0',
        8,
      ),
      gross_margin_rate_with_end: toApiFloat(
        estimator.grossMarginRateWithEnd || '0',
        8,
      ),
      gross_margin_rate_without_end: toApiFloat(
        estimator.grossMarginRateWithoutEnd || '0',
        8,
      ),
      gross_margin_rate_against_extra_cost_with_end: toApiFloat(
        estimator.grossMarginRateAgainstExtraCostWithEnd || '0',
        8,
      ),
      gross_margin_rate_against_extra_cost_without_end: toApiFloat(
        estimator.grossMarginRateAgainstExtraCostWithoutEnd || '0',
        8,
      ),
      total_gross_margin: toApiFloat(estimator.totalGrossMargin || '0', 8),
      training_cost_with_cofi_amount: toApiFloat(
        estimator.trainingCostWithCofiAmount || '0',
        8,
      ),
      training_cost_without_cofi_amount: toApiFloat(
        estimator.trainingCostWithoutCOFIAmount || '0',
        8,
      ),
      total_mission_revenue: toApiFloat(
        estimator.totalMissionRevenue || '0',
        8,
      ),
      total_training_relief_cost: toApiFloat(
        estimator.totalTrainingReliefCost || '0',
        8,
      ),
      total_mission_relief_cost: toApiFloat(
        estimator.totalMissionReliefCost || '0',
        8,
      ),
      etti_agreement_amount: toApiFloat(
        estimator.agreementETTIAmount || '0',
        8,
      ),
      etti_agreement_post_training_amount: toApiFloat(
        estimator.agreementETTIPostTrainingAmount || '0',
        8,
      ),
      ifm_gains_amount: toApiFloat(estimator.ifmGainsAmount || '0', 8),
      ifm_gains_post_training_amount: toApiFloat(
        estimator.ifmGainsPostTrainingAmount || '0',
        8,
      ),
      total_post_training_revenue: toApiFloat(
        estimator.totalPostTrainingRevenue || '0',
        8,
      ),
      total_post_training_relief_cost: toApiFloat(
        estimator.totalPostTrainingReliefCost || '0',
        8,
      ),
      mission_wage_expenses: toApiFloat(
        estimator.missionWageExpenses || '0',
        8,
      ),
      mission_rfa_expenses: toApiFloat(estimator.missionRFAExpenses || '0', 8),
      mission_total_expenses: toApiFloat(
        estimator.totalMissionExpenses || '0',
        8,
      ),
      post_training_wage_expenses: toApiFloat(
        estimator.postTrainingWageExpenses || '0',
        8,
      ),
      post_training_rfa_expenses: toApiFloat(
        estimator.postTrainingRFAExpenses || '0',
        8,
      ),
      post_training_total_expenses: toApiFloat(
        estimator.totalPostTrainingExpenses || '0',
        8,
      ),
      invoice_rate_against_extra_costs: toApiFloat(
        estimator.invoiceRateAgainstExtraCost || '0',
        8,
      ),
      break_even_with_end_amount: toApiFloat(
        estimator.breakEvenWithEndAmount || '0',
        8,
      ),
      break_even_without_end_amount: toApiFloat(
        estimator.breakEvenWithoutEndAmount || '0',
        8,
      ),
      educational_costs_amount_group: toApiFloat(
        estimator.educationalCostAmountGroup || '0',
        8,
      ),
      total_mission_duration_in_months: toApiFloat(
        estimator.totalMissionDurationInMonths || '0',
        8,
      ),
      total_wages_amount: toApiFloat(estimator.totalWagesAmount || '0', 8),
      classic_trainee_number: 1,
      classic_trainee_pec: toApiFloat(1, 8),
      local_mission_trainee_number: estimator.localMissionTraineeNumber || 0,
      local_mission_trainee_pec: toApiFloat(1, 8),
      handicapped_trainee_number: estimator.handicappedTraineeNumber || 0,
      handicapped_trainee_pec: toApiFloat(1, 8),
      refugee_trainee_number: estimator.refugeeTraineeNumber || 0,
      refugee_trainee_pec: toApiFloat(1, 8),
      skilled_trainee_number: estimator.skillTraineeNumber || 0,
      skilled_trainee_pec: toApiFloat(1, 8),
      poec_trainee_number: estimator.POECTraineeNumber || 0,
      poec_trainee_pec: toApiFloat(1, 8),
      afpr_trainee_number: estimator.AFPRTraineeNumber || 0,
      afpr_trainee_pec: toApiFloat(1, 8),
    },
    relationships: {
      training_course: {
        data: {
          id: trainingCourseId || '',
          type: 'training_courses',
        },
      },
      co_funders: {
        data: (estimator?.coFunders || []).map(({ id }) => ({
          id: id || '',
          type: 'co_funder_repositories',
        })),
      },
      min_wage: {
        data: {
          id: estimator.minWage?.id || '',
          type: 'min_wage_repositories',
        },
      },
      opco_charge: {
        data: {
          id: estimator.opcoCharge?.id || '',
          type: 'opco_charge_repositories',
        },
      },
    },
  };

  if (estimator.validationType?.id) {
    estimatorWizard.relationships.validation_type = {
      data: {
        type: 'validation_type_repositories',
        id: estimator.validationType.id,
      },
    };
  }

  if (estimator.educationalPath?.id) {
    estimatorWizard.relationships.educational_path = {
      data: {
        type: 'training_course_educational_paths',
        id: estimator.educationalPath.id,
      },
    };
  }

  if (estimator.deviceType?.id) {
    estimatorWizard.relationships.device_type = {
      data: {
        type: 'device_type_repositories',
        id: estimator.deviceType.id,
      },
    };
  }

  return estimatorWizard;
};

export const toTrainingCourseEstimator = (
  {
    deviceType,
    candidatesNumber = 1,
    candidates,
    customers,
    totalFees,
  }: Partial<TrainingCourse>,
  educationalPath: TrainingCourseEducationalPath,
  minWage: MinWage,
  opcoCharge: OpcoCharge,
  config: EstimatorConfiguration,
  currentEstimatorName?: string,
  currentEstimatorId?: string,
  currentEstimatorDeviceType?: DeviceType,
): TrainingCourseEstimator => {
  const educationalCosts: EducationalCost[] = (
    educationalPath.trainingCourseTrainings || []
  )
    .filter(({ organizationName }) => organizationName)
    .map(toEducationalCost);

  const localMissionTraineeNumber =
    candidates?.filter(({ followedByMissionLocale }) => followedByMissionLocale)
      .length || 0;

  const handicappedTraineeNumber =
    candidates?.filter(({ isRqth }) => isRqth).length || 0;

  const seniorTraineeNumber =
    candidates?.filter(({ age }) => age >= config.seniorAge).length || 0;

  const A2ITraineeNumber = candidates?.filter(({ isA2i }) => isA2i).length || 0;

  const CDIITraineeNumber =
    candidates?.filter(({ isCdii }) => isCdii).length || 0;

  const CPFAmount =
    candidates?.reduce(
      (acc, { cpfAmount }) => acc + parseFloat(cpfAmount || '0'),
      0,
    ) || 0;

  const customersManagementRates =
    compact(
      customers?.map(({ managementRate }) => parseFloat(managementRate)),
    ) || [];

  const RFARates =
    compact(customers?.map(({ RFARate }) => parseFloat(RFARate))) || [];

  const customerDelegationRates =
    compact(
      customers?.map(({ delegationRate }) => parseFloat(delegationRate)),
    ) || [];

  const customerDelegationRate =
    customerDelegationRates.reduce((acc, cur) => acc + cur, 0) /
    customerDelegationRates.length;

  const rawEstimator: TrainingCourseEstimator = {
    id: currentEstimatorId || '',
    name: currentEstimatorName || '',
    minWage,
    validationType: educationalPath.validationType || toValidationType({}),
    opcoCharge,
    localMissionTraineeNumber,
    handicappedTraineeNumber,
    AFPRTraineeNumber: 0,
    POECTraineeNumber: 0,
    classicTraineeNumber: 0,
    refugeeTraineeNumber: 0,
    skillTraineeNumber: 0,
    CPFAmount,
    CDIITraineeNumber,
    A2ITraineeNumber,
    seniorTraineeNumber,
    customerDelegationRate,
    reliefType: RELIEF_TYPE.FILLON,
    status: TrainingCourseEstimatorStatus.DRAFT_STATUS,
    educationalCostTotalDuration: 0,
    educationalCosts,
    educationalPath: educationalPath || toTrainingCourseEducationalPath({}, {}),
    candidateTypes: [],
    coFunders: [],
    organizationName: educationalPath.organizationName || '',
    positioningReviewCost: parseFloat(
      educationalPath.positioningReviewCost || '0',
    ),
    positioningReviewDuration: parseFloat(
      educationalPath.positioningReviewDuration || '0',
    ),
    candidatesNumber: candidatesNumber || 1,
    educationalCostMinDuration: parseInt(deviceType?.durationMin || '0'),
    educationalCostMaxDuration: parseInt(deviceType?.durationMax || '999'),
    educationalCostAmountPerHour: 0,
    educationalCostAmountPerCandidate: 0,
    educationalCostPraticalDuration: parseFloat(
      educationalPath.practicalTrainingDuration || '0',
    ),
    hasAdditionalCosts: false,
    additionalCostsAmount:
      parseFloat(totalFees || '0') / (candidatesNumber || 1),
    missionAverageRate: 0,
    trainingCourseAverageRate: 0,
    averagePECRate: 0,
    deviceType: currentEstimatorDeviceType || toDeviceType({}),
    isNationalNeed: false,
    totalMissionDurationInMonths:
      customers?.reduce(
        (acc, { missionDuration }) => acc + parseFloat(missionDuration || '0'),
        0,
      ) || 0,
    ddcTrainingDurationInMonths: 0,
    ddcComplementaryTrainingDuration: 0,
    ddcTrainingDurationPerMonth: parseFloat(
      config.defaultMissionDurationPerMonth,
    ),
    ddcTrainingMissionDurationInHours: 0,
    ddcTrainingMissionDurationInDays: 0,
    ddcTrainingMissionDurationInMonths: 0,
    isValidMissionDuration: false,
    trainingMissionRatio: null,
    hasExternalCoFunding: false,
    externalCoFundingSupportedAmount: 0,
    hasFinancialAid: false,
    customersName: customers?.map(({ name }) => name).join(' / ') || '',
    customersManagementRate:
      customersManagementRates.reduce((acc, cur) => acc + cur, 0) /
      customersManagementRates.length,
    delegationRate: customerDelegationRate,
    RFARate:
      (RFARates.length &&
        RFARates.reduce((acc, cur) => acc + cur, 0) / RFARates.length) ||
      0,
    sourcingAmount: 0,
    reInvoicingCostRate: 0,
    reInvoicingCostAmount: 0,
    reInvoicingWageRate: 0,
    reInvoicingWageAmount: 0,
    reInvoicingExtraCostRate: 0,
    reInvoicingExtraCostAmount: 0,
    engineeringCostAmount: 0,
    recruitmentAmount: 0,
    managementFeesAmount: 0,
    totalInvoiceAmount: 0,
    additionalDelegationInMonths: 0,
    totalWagesAmount: 0,
    averageCoefficientOnMissionHours: 0,
    delegationBillingAmount: 0,
    delegationBillingPostTrainingAmount: 0,
    averageCoefficientOnMissionAndTrainingHours: 0,
    coefficientDelegationEvolution: 0,
    totalTrainingExpensesPerTrainee: 0,
    totalTrainingExpensesPerGroup: 0,
    totalTrainingRevenuePerTrainee: 0,
    totalTrainingRevenueGroup: 0,
    opcoRefund: 0,
    trainingAssessmentAmount: 0,
    externalTrainingAmount: 0,
    practicalTrainingAmount: 0,
    turnOverPerTrainee: 0,
    turnOverPerGroup: 0,
    turnOverInMonthsPerTrainee: 0,
    turnOverInMonthsPerGroup: 0,
    grossMarginPerTraineeWithEnd: 0,
    trainingCostWithCofiAmount: 0,
    totalTrainingReliefCost: 0,
    totalMissionReliefCost: 0,
    financialAidSupportedAmount: 0,
    totalMissionRevenue: 0,
    agreementETTIAmount: 0,
    agreementETTIPostTrainingAmount: 0,
    ifmGainsAmount: 0,
    ifmGainsPostTrainingAmount: 0,
    totalPostTrainingRevenue: 0,
    totalPostTrainingReliefCost: 0,
    missionWageExpenses: 0,
    missionRFAExpenses: 0,
    totalMissionExpenses: 0,
    postTrainingWageExpenses: 0,
    postTrainingRFAExpenses: 0,
    totalPostTrainingExpenses: 0,
    grossMarginPerTraineeWithoutEnd: 0,
    trainingCostWithoutCOFIAmount: 0,
    grossMarginTargetAmount: 0,
    grossMarginInMonthsPerTraineeWithEnd: 0,
    grossMarginInMonthsPerTraineeWithoutEnd: 0,
    grossMarginRateWithEnd: 0,
    grossMarginRateWithoutEnd: 0,
    grossMarginRateAgainstExtraCostWithEnd: 0,
    grossMarginRateAgainstExtraCostWithoutEnd: 0,
    invoiceRateAgainstExtraCost: 0,
    breakEvenWithEndAmount: 0,
    breakEvenWithoutEndAmount: 0,
    totalGrossMargin: 0,
    educationalCostAmountGroup: 0,
    approver: toManager({}),
  };

  return {
    ...rawEstimator,
    ...omitBy<TrainingCourseEstimator>(
      computeEstimatorValues(rawEstimator, config, candidates || []),
      (value) => isNull(value) || isNaN(value as number),
    ),
  };
};
