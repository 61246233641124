/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  TrainingActionCandidatePeriod,
  TrainingActionCandidatePeriodHttp,
  TrainingActionCandidatePeriodWizard,
} from '@data/models/training-action-candidate-period.interface';
import { toTrainingActionCandidatePeriod } from '@data/dto/training-action-candidate-period.dto';
import ErrorService from 'core/error.service';
import i18n from '@_plugins/i18n';

@injectable()
export default class TrainingActionCandidatePeriodService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingActionCandidatePeriodEndpoint =
    '/training-action-candidate-periods';

  private errorService = container.get<ErrorService>(ErrorService);

  public async upsertTrainingActionCandidatePeriod({
    id,
    ...trainingActionCandidatePeriodWizard
  }: TrainingActionCandidatePeriodWizard): Promise<TrainingActionCandidatePeriod | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        {
          data: Omit<TrainingActionCandidatePeriodWizard, 'id'>;
        },
        {
          data: TrainingActionCandidatePeriodHttp;
        }
      >(`${this.trainingActionCandidatePeriodEndpoint}`, {
        data: trainingActionCandidatePeriodWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toTrainingActionCandidatePeriod(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingActionCandidatePeriodWizard },
      {
        data: TrainingActionCandidatePeriodHttp;
      }
    >(`${this.trainingActionCandidatePeriodEndpoint}/${id}`, {
      data: { ...trainingActionCandidatePeriodWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingActionCandidatePeriod(data?.data || {});
  }

  public async deleteTrainingActionCandidatePeriod(
    id: string,
  ): Promise<TrainingActionCandidatePeriod | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: TrainingActionCandidatePeriodHttp;
    }>(`${this.trainingActionCandidatePeriodEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingActionCandidatePeriod(data?.data || {});
  }
}
