import { Dayjs } from 'dayjs';

export enum TrainingActionTrainingCandidateInvoiceType {
  Individual = 'individual',
  Group = 'group',
}

export interface TrainingActionTrainingCandidateInvoiceHttp {
  type: 'training_action_invoices';
  id: string;
  attributes: {
    reference: string;
    from_date: string;
    to_date: string;
    invoice_type: TrainingActionTrainingCandidateInvoiceType;
    is_hour_cost: boolean | number;
    hour_rate: string;
    hours: string;
    total_amount: string;
    total_amount_per_candidate: string;
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    estimator_candidate: {
      data: {
        type: 'estimator_candidates';
        id: string;
      };
    };
    training_course_training: {
      data: {
        type: 'training_course_trainings';
        id: string;
      };
    };
  };
}

export interface TrainingActionTrainingCandidateInvoice {
  id: string;
  trainingCourseTrainingId: string;
  reference: string;
  fromDate: Dayjs;
  toDate: Dayjs;
  type: TrainingActionTrainingCandidateInvoiceType;
  isHourCost: boolean;
  hourRate: string;
  numberHours: string;
  totalAmount: string;
  totalAmountPerCandidate: string;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}

export interface TrainingActionTrainingCandidateInvoiceWizard {
  type: 'training_action_invoices';
  id: string;
  attributes: {
    reference: string;
    from_date: string;
    to_date: string;
    invoice_type: TrainingActionTrainingCandidateInvoiceType;
    is_hour_cost: boolean;
    hour_rate: string;
    hours: string;
    total_amount: string;
    total_amount_per_candidate: string;
  };
  relationships: {
    estimator_candidate: {
      data: {
        type: 'estimator_candidates';
        id: string;
      };
    };
    training_course_training: {
      data: {
        type: 'training_course_trainings';
        id: string;
      };
    };
  };
}
