/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import { FilterParams } from '@data/models/filter.interface';
import { PaginateResult } from '@data/models/api.interface';
import ErrorService from 'core/error.service';
import GeoApi from '@data/api/geo.api';
import {
  City,
  CityGeo,
  CityHttp,
  CityWizard,
} from '@data/models/city.interface';
import { fromGeoApi, toCity } from '@data/dto/city.dto';
import LaravelAPI from '@data/api/laravel.api';
import flatten from 'lodash/flatten';

@injectable()
export default class CityService {
  private GEO_API = container.get<GeoApi>(GeoApi);
  private API = container.get<LaravelAPI>(LaravelAPI);

  private readonly geoApiEndpoint = '/communes';
  private readonly cityEndpoint = '/cities';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listCities(
    filters: FilterParams,
  ): Promise<PaginateResult<City[]>> {
    const { data: response, error } = await this.GEO_API?.get<CityGeo[]>(
      this.geoApiEndpoint,
      filters,
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const items =
      flatten(
        response?.map((city) => {
          return city.codesPostaux.map((postCode) =>
            fromGeoApi({
              ...city,
              codesPostaux: [postCode],
            }),
          );
        }),
      ) || [];

    return {
      items,
      totalSize: response?.length || 0,
    };
  }

  public async upsertCity({
    id,
    ...cityWizard
  }: CityWizard): Promise<City | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<CityWizard, 'id'> },
        { data: CityHttp }
      >(this.cityEndpoint, { data: cityWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toCity(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: CityWizard },
      { data: CityHttp }
    >(`${this.cityEndpoint}/${id}`, {
      data: { ...cityWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toCity(data?.data || {});
  }
}
