import day from '@_plugins/dayjs';
import { Customer, CustomerHttp } from '@data/models/customer.interface';
import dayjs from 'dayjs';

import { toBoolean } from './api.dto';

export const toCustomer = (customer: Partial<CustomerHttp>): Customer => {
  return {
    id: customer.id || '',
    name: customer.attributes?.company_name || '',
    SIRET: customer.attributes?.siret || '',
    city: customer.attributes?.city || '',
    postalCode: customer.attributes?.zip_code || '',
    registrationNumber: customer.attributes?.registration_number || '',
    type: customer.attributes?.company_type || '',
    hasAgreement: toBoolean(customer.attributes?.has_agreement),
    agreementEndDate: customer.attributes?.agreement_end_date
      ? day(customer.attributes?.agreement_end_date)
      : null,
    agreementNumber: customer.attributes?.agreement_number || '',
    createdAt: day(customer.attributes?.createdAt),
    updatedAt: day(customer.attributes?.updatedAt),
  };
};

export const fromStoredSearchCustomer = ({
  SIRET,
  name,
}: Pick<Customer, 'SIRET' | 'name'>): Customer => {
  return {
    SIRET: SIRET,
    agreementEndDate: dayjs(),
    agreementNumber: '',
    city: '',
    createdAt: dayjs(),
    hasAgreement: false,
    id: '',
    name: name,
    postalCode: '',
    registrationNumber: '',
    type: '',
    updatedAt: dayjs(),
  };
};
