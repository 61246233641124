import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { WithTranslation, withTranslation } from 'react-i18next';

const Error404 = ({ t: trans }: WithTranslation) => {
  return (
    <Box mt={10}>
      <Card>
        <CardContent>
          <Typography variant="h6">{trans('pages.404.title')}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default withTranslation()(Error404);
