import day from '@_plugins/dayjs';
import dayjs from '@_plugins/dayjs';
import {
  DeviceType,
  DeviceTypeHttp,
  DeviceTypeWizard,
} from '@data/models/device-type.interface';
import { FilterParams } from '@data/models/filter.interface';

import { toApiFloat, toBoolean } from './api.dto';

export const fromStoredSearchDeviceType = ({
  id,
  initials,
}: Pick<DeviceType, 'id' | 'initials'>): DeviceType => {
  return {
    createdAt: dayjs(),
    id: id,
    initials: initials,
    label: '',
    nationalAgency: false,
    planInitials: '',
    updatedAt: dayjs(),
  };
};

export const toDeviceType = (
  deviceType: Partial<DeviceTypeHttp>,
): DeviceType => {
  return {
    id: deviceType.id || '',
    durationMax: deviceType.attributes?.duration_max || '',
    durationMin: deviceType.attributes?.duration_min || '',
    label: deviceType.attributes?.label || '',
    planInitials: deviceType.attributes?.plan_initials || '',
    createdAt: day(deviceType.attributes?.createdAt),
    initials: deviceType.attributes?.initials || '',
    updatedAt: day(deviceType.attributes?.updatedAt),
    nationalAgency: toBoolean(deviceType.attributes?.national_agency),
  };
};

export const toDeviceTypeWizard = (
  deviceType: Partial<DeviceType>,
): DeviceTypeWizard => {
  const wizard: DeviceTypeWizard = {
    type: 'device_type_repositories',
    id: deviceType.id || '',
    attributes: {
      duration_max: toApiFloat(deviceType.durationMax),
      duration_min: toApiFloat(deviceType.durationMin),
      initials: deviceType.initials || '',
      label: deviceType.label || '',
      plan_initials: deviceType.planInitials || '',
      national_agency: toBoolean(deviceType.nationalAgency),
    },
  };

  return wizard;
};

export const toDeviceTypeFilterParams = ({
  orderBy,
  filter,
  ...filterParams
}: FilterParams): FilterParams => {
  const mapPropsToHttp = (prop?: string): string | undefined => {
    switch (prop) {
      case 'durationMax':
        return 'duration_max';
      case 'durationMin':
        return 'duration_min';
      case 'planInitials':
        return 'plan_initials';
      case 'nationalAgency':
        return 'national_agency';
      default:
        return prop;
    }
  };

  const renameFilter = Object.keys(filter || {}).reduce(
    (acc, cur) => {
      const propName = mapPropsToHttp(cur);
      if (!propName) {
        return acc;
      }
      acc[propName] = (filter || {})[cur] || '';
      return acc;
    },
    {} as {
      [key: string]: string | number | string[] | undefined;
    },
  );

  return {
    ...filterParams,
    orderBy: mapPropsToHttp(orderBy),
    filter: renameFilter,
  };
};
