import { memo, ReactNode } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { animations } from './animations';
import { presets } from './presets';
import { PageTransitionGroup, PageTransitionWrapper } from './styles';

interface Props {
  children: ReactNode;
  enterAnimation?: {
    delay?: number;
    onTop?: boolean;
    name: keyof typeof animations;
  };
  exitAnimation?: {
    delay?: number;
    onTop?: boolean;
    name: keyof typeof animations;
  };
  preset?: keyof typeof presets;
  transitionKey: string;
}
const PageTransition = ({
  children,
  transitionKey,
  ...rest
}: Props): JSX.Element => {
  return (
    <PageTransitionGroup {...rest}>
      <TransitionGroup component={null}>
        <Transition key={transitionKey} timeout={0}>
          <PageTransitionWrapper id="page-transition-wrapper">
            {children}
          </PageTransitionWrapper>
        </Transition>
      </TransitionGroup>
    </PageTransitionGroup>
  );
};

export default memo(PageTransition);
