/* eslint-disable max-len */

const trainingSteps = {
  'training.steps.groups': 'Agences',
  'training.steps.trainings': 'Formations',
  'training.steps.candidates': 'Candidats',
  'training.steps.missions': 'Missions',
};

const groups = {
  'group.select.tap_to_search': 'Commencez pour voir les suggestions',
  'group.warning.title': 'Important',
  'group.warning.list1':
    "Avant toute demande de formation, vous devez vous assurer qu'aucun intérimaire ayant cette compétence ne soit disponible dans Lucie.",
  'group.warning.list2':
    "Tout besoin en formation concernant un EPSP doit passer par ce process, accompagné de la charte d'engagement en pièce-jointe.",
  'group.info.text1':
    'Le formulaire vous propose par défaut une agence et vos coordonnées. Modifier ou supprimer cette agence si nécessaire.',
  'group.info.text2':
    "S'il s'agit d'un projet commun entre plusieurs agences, veuillez ajouter une autre agence et indiquer un contact.",
  'group.info.text3':
    'Cette agence sera la société émettrice des contrats de travail.',
  'training.group.group': 'Agence',
  'training.group.job': 'Direction régionale',
  'training.group.phoneNumber': 'Téléphone agence',
  'training.group.fullName': 'Nom et prénom du recruteur',
  'training.group.email': 'E-mail du recruteur',
  'training.group.userPhone': 'Téléphone du recruteur',
  'training.group.formTitle': 'Ajouter une agence',
  'training.group.company_fullname': 'Société',
  'training-course.group.select-duplicate': 'Agence déjà sélectionnée',
  'training-course.group.no-value': 'Veuillez sélectionner une agence',
  'training-course.group.add': 'Agence ajoutée',
  'training-course.group.delete': 'Agence supprimée',
};

const trainings = {
  'training-course.status': 'Statut',
  'training-course.status.update': 'Le statut à bien été mis à jour',
  'training-course.manager.update': 'Le gestionnaire BO à bien été mis à jour',
  'training-course.training.select-duplicate': 'Formation déjà sélectionnée',
  'training-course.training.OF': 'Organisme de formation',
  'training-course.training.OF_contacted': 'Organisme déjà contacté',
  'training-course.training.considered_training_dates':
    'Date de formation envisagée : ',
  'training-course.training.add': 'Formation ajoutée',
  'training-course.training.delete': 'Formation supprimée',
  'training-course.link.see-detail': 'Consulter la demande de formation',
  'training-course.quick-search.placeholder': 'Recherche rapide',
  'training-course.status.pending': 'En attente',
  'training-course.status.in_progress': 'Formation en cours',
  'training-course.status.todo': 'A traiter',
  'training-course.status.waiting_for_of_date': 'En attente OF - Date',
  'training-course.status.waiting_for_of_convention':
    'En attente OF - Convention',
  'training-course.status.waiting_for_ae': 'En attente AE',
  'training-course.status.waiting_for_validation': 'En attente de validation',
  'training-course.status.done': 'Clos',
  'training-course.status.to_check': 'Contrat à vérifier',
  'training-course.status.cancel': 'Annulé',
  'training-course.status.waiting_for_retour_ae': 'Attente retour AE',
  'training-course.status.waiting_for_opco': 'En attente OPCO',
  'training-course.status.relaunch_opco': 'En relance OPCO',
  'training-course.status.complete': 'Dossier complet',
  'training-course.status.payroll_control': 'Contrôle paie',
  'training-course.status.dispute': 'Litige',
  'training-course.status.todo_dr': 'DR à faire',
  'training-course.status.scoring': 'Pointage',
  'training-course.status.closed': 'Clos',
  'training-course.status.cancelled': 'Annulé',
  'training-course.training.label': 'Intitulé de la formation',
  'training-course.training.holidays': 'Jour férié',
  'training-course.training.training_sessions': 'Période de formation',
  'training-course.training.unpaid_sessions': 'Période de suspension non payée',
  'training-course.training.practical_training': 'Formation pratique',
  'training-course.training.customer_sessions': 'Période de mission',
  'training-course.training.type': 'Type',
  'training-course.training.add_period': 'Ajouter une période',
  'training-course.training.preconizationReason':
    'Un organisme de formation a déjà été contacté ?',
  'training-course.training.preconizationReason-placeholder':
    'Indiquez ci-dessus le nom de celui-ci, les dates pressenties, la durée de formation estimée, le tarif proposé et le lieu',
  'training-course.training.expected_city': 'Lieu de formation souhaité',
  'training-course.training.options': 'Catégories / Indices',
  'training-course.training.no_options':
    'Pas de sous catégories ou indices à préciser.',
  'training-course.training.no_document': 'Aucun document de sélectionné',
  'training-course.training.required':
    'Vous devez choisir au moins une formation.',
  'training-course.training.prescriber.customer': 'Client',
  'training-course.training.prescriber.agency': 'Agence',
  'training-course.training.prescriber.cpef': 'CPEF',
  'training-course.training.prescriber.label': 'Par qui',
  'training-course.training.description':
    'Commentaire réservé au gestionnaire formation',
  'training-course.training.organizationName': 'Nom OF',
  'training-course.training.organizationCity': 'Ville OF',
  'training-course.training.interlocutor': 'Interlocuteur',
  'training-course.training.city': 'Lieu',
  'training-course.training.is-out-of-catalog': 'Formation hors-catalogue',
  'training-course.training.is-akto': 'Référencement AKTO',
  'training-course.training.is-datadock': 'DataDock',
  'training-course.training.is-qualiopi': 'Qualiopi',
  'training-course.training.is_training_organism_contacted':
    'Organisme déjà contacté ?',
  'training-course.training.has-quote': 'Devis déjà proposé',
  'training-course.training.duration': 'Durée de formation',
  'training-course.training.cost': 'Coût pédagogique HT',
  'training-course.training.invoice-type.label': 'Type de facturation',
  'training-course.training.invoice-type.daily_cost': 'Coût jour',
  'training-course.training.invoice-type.hour_cost': 'Coût horaire',
  'training-course.training.invoice-type.individual_cost': 'Coût individuel',
  'training-course.training.invoice-type.group_cost': 'Coût groupe',
  'training.group.selectAgencyPlaceholder': 'Analytique, ville, nom…',
  'training.courses.info':
    'Merci de décrire précisément le contexte de votre demande de formation afin de vous proposer la solution de financement la plus adaptée. Vous pouvez ajouter une ou plusieurs formations afin de construire un vrai parcours de compétences pour votre salarié intérimaire.',
  'training.courses.description_help':
    'Merci de décrire précisément le contexte (exemple: Nous manquons régulièrement de profils cariste...)',
  'training-course.state.context.agency': 'Agence(s) concernée(s)',
  'training-course.state.context.training': 'Formation concerné',
  'training-course.state.context.context': 'Contexte du besoin',
  'training-course.state.context.mission': 'Mission envisagée',
  'training-course.state.candidates.trainees': 'Identité stagiaire(s)',
  'training-course.state.candidates.fees': 'Frais annexes',
  'training-course.state.candidates.public': 'Catégorie du public',
  'training-course.context-stepper.context': 'Contexte du projet de formation',
  'training-course.context-stepper.schedule': 'Planning',
  'training-course.context-stepper.program': 'Programme pédagogique',
  'training-course.context-stepper.trainings': 'Parcours pédagogique',
  'training-course.context-stepper.candidates': 'Stagiaire(s)',
  'training-course.context-stepper.estimator': 'Deviseur',
  'training-course.context-stepper.validation': 'Validation',
  'training-course.training.dates.add': 'Ajouter une date',
  'training-course.training.dates.at': 'Le',
  'training-course.training.period': 'Période',
  'training-course.training.all_day_period': 'Journée entière',
  'training-course.training.morning_period': 'Matin',
  'training-course.training.afternoon_period': 'Après-midi',
  'training-course.training.envisaged-dates.from':
    'Début de formation envisagée',
  'training-course.training.envisaged-dates.to': 'Fin de formation envisagée',
  'training-course.training.dates.from': 'Du',
  'training-course.training.dates.to': 'Au',
  'training-course.program.list-training-organism': 'Lien annuaire OF',
  'training-course.educational_path.delete_info_one':
    'Le deviseur lié à ce parcours pédagogique sera supprimé :',
  'training-course.educational_path.delete_info_other':
    'Les deviseurs liés à ce parcours pédagogique seront supprimés :',
  'training-course.estimator.delete_one': 'Deviseur supprimé avec succès',
  'training-course.estimator.delete_other': 'Deviseurs supprimés avec succès',
  'training-course.educational_path.delete':
    'Parcours pédagogique supprimé avec succès',
  'training-course.training.dates.full_date':
    'Du {{- from}} au {{- to}} : {{- duration }} h',
  'training-course.training.dates.same_day':
    'Le {{- date}} - {{- period}} : {{- duration }} h',
  'training-course.training.dates.title': 'Dates de formation',
  'training-course.state.program.trainings': 'Formation(s)',
  'training-course.state.program.validation': 'Type de validation',
  'training-course.state.program.organism': 'Identification OF',
  'training-course.state.program.commercial_proposal':
    'Proposition commerciale',
  'training-course.state.program.positioning_review': 'Bilan de positionnement',
  'training-course.state.program.status': 'Mise à jour du statut',
  'training-course.state.program.agency_validation': 'Confirmation agence',
  'training-course.state.program.organism_validation': 'Confirmation OF',
  'training-course.state.estimator.preconisation':
    'Préconisation dispositif(s)',
  'training-course.state.estimator.trainees': 'Profil des candidats',
  'training-course.state.estimator.duration': 'Durée de contrat recommandée',
  'training-course.state.estimator.funding': 'Financements complémentaires',
  'training-course.state.estimator.partnership': 'Partenariat client',
  'training-course.state.estimator.analysis': 'Critères d’analyse',
  'training-course.state.estimator.expenses': 'Dépenses/Recettes',
  'training-course.edit.duration': 'Durée totale estimée (h)',
  'training-course.training.external-training-duration': 'Formation externe',
  'training-course.training.practical-training-duration': 'Formation pratique',
  'training-course.edit.validation': 'Validation',
  'training-course.edit.candidates': 'Stagiaire(s) pressenti(s)',
  'training-course.edit.estimator': 'Deviseur',
  'training-course.edit.estimator.educational-path-associated':
    'Parcours pédagogique associé',
  'training-course.training.validation-type': 'Type de validation',
  'training-course.training.validation-course': 'Validation du parcours',
  'training-course.training.validation-description':
    'Précisez, si qualification CCN, laquelle ?',
  'training-course.training.validation-level': 'Niveau',
  'training-course.training.validation-coefficient': 'Coef',
  'training-course.training.course-type': 'Type de parcours',
  'training-course.edit.fees': 'Frais annexes',
  'training-course.edit.is-skill-training': 'Langues et Compétences',
  'training-course.training.has-fees-payment':
    'Le versement de frais est envisagé',
  'training-course.training.accommodation-fees': 'Montant hébergement',
  'training-course.training.transport-fees': 'Montant transport',
  'training-course.training.meal-fees': 'Montant repas',
  'training-course.training.total-fees': 'Montant total estimé',
  'training-course.training.fees-description': 'Explications / Justifications ',
  'training-course.training.skill-level.title': "Niveau d'expérience",
  'training-course.training.skill-level.beginner': 'Initial/débutant',
  'training-course.training.skill-level.skilled': 'Expérimenté',
  'training-course.training.skill-level.mac':
    'Recyclage/MAC = Maintien et Actualisation des Compétences',
  'training-course.training.skill-level.catching-up': 'Rattrapage',
  'training-course.training.type.title': 'Type de formation',
  'training-course.training.type.inter': 'Inter',
  'training-course.training.type.intra': 'Intra',
  'training-course.training.type.distancial': 'Distanciel',
  'training-course.training.type.blended-learning': 'Blended Learning',
};

const list = {
  'training-course.list.chip.domains': 'Domaines = ({{- value}})',
  'training-course.list.chip.managers': 'Gestionnaires = ({{- value}})',
  'training-course.list.chip.cpef_managers':
    'Interlocuteurs Direction Ressource = ({{- value}})',
  'training-course.list.chip.status': 'Statuts = ({{- value}})',
  'training-course.list.chip.regions': 'Regions = ({{- value}})',
  'training-course.list.chip.device-types': 'Dispositifs = ({{- value}})',
  'training-course.list.chip.trainings': 'Formations = ({{- value}})',
  'training-course.list.chip.customers': 'Clients = ({{- value}})',
  'training-course.list.chip.candidates': 'Candidats = ({{- value}})',
  'training-course.list.chip.groups': 'Groupes = ({{- value}})',
  'training-course.list.chip.reference': 'Reference = "{{- value}}"',
  'training-course.list.total_one': '{{count}} dossier',
  'training-course.list.total_other': '{{count}} dossiers',
  'training-course.list.filter.total':
    '{{count}} dossier trouvé sur un total de {{totalSize}}',
  'training-course.list.filter.total_other':
    '{{count}} dossiers trouvés sur un total de {{totalSize}}',
  'training-course.list.total_plural': '{{count}} dossiers',
  'training-course.list.add_training': 'Nouvelle demande de formation',
  'training-course.list.filter_title': 'Filtrer ma recherche',
  'training-course.list.reinitialize': 'Réinitialiser',
  'training-course.list.title': 'Tous les dossiers',
  'training-course.dashboard.list.title':
    'Mes dossiers en attente de traitement',
  'training-course.dashboard.list.filter': 'Filtrer ma recherche',
  'training-course.list.id': 'UID-ERP',
  'training-course.list.agency': 'Agences',
  'training-course.list.customer': 'Clients',
  'training-course.list.training': 'Formations',
  'training-course.list.candidate_number': 'Candidat',
  'training-course.list.plan': 'Dispositif',
  'training-course.list.status': 'Statut',
  'training-course.list.area': 'Région',
  'training-course.list.search': 'Rechercher un dossier',
  'training-course.list.mine': 'Mes dossiers uniquement',
  'training-course.list.trashed': 'Dossiers supprimés',
  'training-course.list.actions': 'Actions de formation uniquement',
  'training-course.list.reference': 'Référence',
  'training-course.list.groups': 'Agence(s)',
  'training-course.list.motif': 'Motif',
  'training-course.list.candidates': 'Candidat(s)',
  'training-course.list.customers': 'Client(s)',
  'training-course.list.trainings': 'Formation(s)',
  'training-course.list.of_contacted': 'OF déjà contacté',
  'training-course.list.starting_date_of': 'Début envisagé',
  'training-course.list.created_between': 'Crée entre le',
  'training-course.list.educational_path_created_between': 'Formation entre le',
  'training-course.list.created_to': 'Au',
  'training-course.list.created_end': 'Fin',
  'training-course.list.device_types': 'Dispositif(s)',
  'training-course.list.regions': 'Region(s)',
  'training-course.list.managers': 'Gestionnaire(s)',
  'training-course.list.cpef_managers': 'Interlocuteur(s) Direction Ressource',
  'training-course.list.domains': 'Domaines',
  'training-course.list.button_search': 'Rechercher',
  'training-course.list.priority': 'Priorité',
  'training-course.list.delete_saved_search': 'Supprimer la recherche',
  'training-course.list.saved_searches': 'Recherches sauvegardées',
  'training-course.title.drawer': 'Rechercher',
  'training-course.list.search.save': 'Sauvegarder la recherche',
  'training-course.list.search.name': 'Nom de la recherche',
  'training-course.list.search.created': 'La recherche a été créé',
};

const candidates = {
  'candidates.unknown_candidate': 'Candidat inconnu',
  'candidates.unknown_candidate.label': 'Candidat inconnu',
  'candidates.unknown_candidate.info':
    'Ce profil inconnu sera inclus dans le calcul du deviseur. Et pourra être associé à un candidat dans la partie action de formation.',
  'candidates.informed_candidate_one': '{{count}} candidat renseigné',
  'candidates.informed_candidate_other': '{{count}} candidats renseignés',
  'candidates.add.unknown_candidate': 'Ajouter un candidat inconnu',
  'candidates.has_fees-payment.label': 'Le versement de frais est envisagé ?',
  'candidates.info.title':
    'Indiquez le volume de personnes à former et vous pouvez préciser les candidats potentiellement pressentis.',
  'candidates.info.subtitle':
    "Tout candidat doit être renseigné dans Lucie et disposer d’un matricule Anael pour pouvoir bénéficier d'une formation.",
  'candidates.add.already_add': 'Le candidat est déjà présent dans la liste',
  'candidates.candidates_number.label': 'Volume de personnes à former',
  'candidates.unidentified_candidates': 'Stagiaires non identifiés',
  'candidates.has_fees-payment.link':
    'Pour le calcul des montants, veuillez vous référer au barème en vigueur',
  'candidates.ina.link': 'Gestion des INA',
  'candidates.fees_amount.label': 'Montant estimé',
  'candidates.fees_amount.tooltip':
    'Précisez ici le détail des montants estimés envisagés',
  'candidates.fees_description.label': 'Explications / Justifications',
  'candidates.candidate_select.label': 'Ajouter un stagiaire',
  'candidates.candidate_link.label': 'Lier le profil à un candidat',
  'candidates.associate': 'Associer',
  'candidates.candidate_link.placeholder': 'Nom, IDBO, matricule',
  'candidates.associate.done': 'Candidat associé avec succès',
  'candidates.candidate_select.placeholder': 'Nom, IDBO, matricule',
  'candidates.candidate.current_profession': 'Profession',
  'candidates.candidate.age': 'Age',
  'candidates.candidate.driving_license': 'Permis',
  'candidates.candidate.no_driving_license': 'Pas de permis',
  'candidates.candidate.no_means_of_transport': 'Pas de moyen de transport',
  'candidates.candidate.means_of_transport': 'Moyen de transport',
  'candidates.candidate.birthday_date': 'Date de naissance',
  'candidates.candidate.last_name': 'Nom',
  'candidates.candidate.first_name': 'Prénom',
  'candidates.candidate.id': 'IDBO Lucie / matricule Anael',
  'candidates.candidate.training_course_rate': 'Taux horaire, formation',
  'candidates.candidate.mission_rate': 'Taux horaire, mission',
  'candidates.candidate.profession_expected': 'Qualification visée',
  'candidates.candidate.followed_by_mission_locale': 'Suivi par mission locale',
  'candidates.candidate.followed_by_pole_emploi': 'Suivi par Pôle Emploi',
  'candidates.candidate.cpf_amount': 'Droits CPF acquis en Euros',
  'candidates.candidate.has_interim_experience': "Ancienneté dans l'intérim",
  'candidates.candidate.has_interim_experience.tooltip':
    'Toutes ETT confondues',
  'candidates.candidate.followed_by_mission_locale.tooltip':
    'Possible uniquement si moins de 26 ans.',
  'candidates.candidate.has_diag_competences.tooltip':
    'Veuillez télécharger le document dans la GDL du candidat',
  'candidates.candidate.hours_interim_experience': 'Nb heures 12 derniers mois',
  'candidates.candidate.is_rqth': 'RQTH',
  'candidates.candidate.is_a2i': 'Intérimaires ETTi',
  'candidates.candidate.is_a2i.tooltip': 'Public A2i',
  'candidates.candidate.need_open_cpf_account': 'Ouverture du compte CPF',
  'candidates.candidate.has_diag_competences': "Réalisation Diag'Compétences",
  'candidates.candidate.has_permanent_contract': 'CDI I',
  'candidates.candidate.is_epsp_approach': 'Besoin détecté lors d’un EPSP',
  'candidates.candidate.is_epsp_approach.tooltip':
    "A ne cocher seulement si la formation a été mentionnée dans la charte d'engagement signée",
  'candidates.candidate.is_recycling': 'Recyclage',
  'candidates.candidate.candidate_type': 'Profil intérimaire',
  'candidates.candidate.training_course_expected_rate':
    'Taux horaire envisagé, en formation',
  'candidates.candidate.mission_expected_rate':
    'Taux horaire envisagé, en mission',
  'candidates.candidate.update': 'Candidats mis à jour',
  'candidates.candidate.add': 'Candidat ajouté',
  'candidates.candidate.delete': 'Candidat supprimé',
  'candidates.link.lucie': 'Accéder à la fiche Lucie du candidat.',
  'candidates.link.lucie.need': 'Accéder à la liste des besoins Lucie.',
  'candidates.lucie.pre_completed':
    'Champ pré complété à partir des données Lucie.',
  'mission.lucie.link.label': 'Se rendre sur lucie',
  'init.continue_without_candidate': 'Continuer sans le(s) candidat(s)',
  'init.back_to_lucie': 'Retour sur Lucie pour corriger avant de revenir',
  'init.training_course.not_found':
    'Le dossier n’a pas été trouvé via la référence',
  'init.training_course.locked': 'Le dossier est verrouillé',
  'init.error_message':
    'Attention certains candidats ne peuvent être joints à la demande de formation car il manque des éléments obligatoires sur leur fiche Lucie.',
};

const mission = {
  'mission.info.title_1':
    'Pour tout projet de formation, il est important que vos clients s’engagent avec vous !',
  'mission.info.title_2':
    'De ce fait, précisez ici la mission envisagée après l’action de formation.',
  'mission.customertitle': 'Quelle(s) Entreprise(s) Utilisatrice(s) visée(s)',
  'mission.billing.title': 'Coefficients de facturation habituels',
  'mission.need_history.title': 'Historique du besoin',
  'mission.customer.already_add': 'Le client est déjà présent dans la liste',
  'mission.customer.add': 'Client ajouté',
  'mission.customer.delete': 'Client supprimé',
  'mission.customersearch.label': 'Ajouter une société',
  'mission.customersearch.placeholder': 'SIRET',
  'mission.name.label': 'Nom de la société',
  'mission.siret.label': 'Siret',
  'mission.id.label': 'Matricule Anael',
  'mission.ines_import_customer.label':
    'Grand-Compte (Accord strat, date, ...)',
  'mission.project_origin.label': 'Typologie de compte',
  'mission.agreement_date.label': "Date de fin d'accord",
  'mission.agreement_number.label': 'N° accord cadre',
  'mission.is_rfa.label': 'RFA',
  'mission.rfa_rate.label': 'Taux de RFA',
  'mission.management_rate.label': 'Gestion',
  'mission.deletegation_rate.label': 'Délégation',
  'mission.has_negociation_marge.label': 'Marge de négociation possible',
  'mission.mission_duration.label': 'Durée envisagée',
  'mission.mission_duration_in_hours.label': 'Précision automatique en heure',
  'mission.is_customer_optional.label':
    'Pour le moment aucun client n’est identifié.',
  'mission.has_Lucie_ad.label': 'Une annonce a été publiée dans Lucie ',
  'mission.save.error':
    "Une erreur est survenue pendant l'enregistrement de votre demande",
  'mission.lucie_ad_ref.label': 'Référence du besoin ?',
  'mission.is_recurring_need.label': "Il s'agit d'un besoin récurrent ",
  'mission.ines.link.label': 'Se rendre sur Inès',
};

const confirmation = {
  'confirmation.text.title':
    'Votre demande de formation a bien été enregistrée !',
  'confirmation.text.subtitle':
    'Vous serez recontacté dans les plus brefs délais.',
  'confirmation.link.submit_new_training':
    'Créer une nouvelle demande de formation ',
  'confirmation.link.dashboard': 'Tableau de bord',
  'confirmation.link.training_courses': 'Tous les dossiers',
  'confirmation.link.go_to_lucie': 'Se rendre sur Lucie ',
  'confirmation.cancel.label': 'Annuler la demande de formation ?',
};

const error = {
  'error.text.title':
    'Attention votre demande de formation n’est pas enregistrée !',
  'error.text.subtitle':
    'Une erreur système est survenue. Merci de réessayer l’envoi dans une minute en cliquant ci-dessous.',
  'error.text.subtitle2':
    ' Si le problème persiste, veuillez contacter le support.',
  'error.text.title_max_retry':
    'Votre dossier ne peut être enregistré pour le moment',
  'error.text.subtitle_max_retry': 'Veuillez contacter le support.',
  'error.save.error':
    'Une erreur système est survenue. Merci de réessayer l’envoi dans une minute en cliquant ci-dessous',
  'error.action.retry': 'Réessayez',
  'error.action.return': "Retourner à l'édition",
};

const courses = {
  'courses.trainings.no-value': ' Veuillez indiquer la formation demandée',
  'courses.info.title':
    'Merci de décrire précisément le contexte de votre demande de formation afin de vous proposer la solution de financement la plus adaptée.',
  'courses.info.title2':
    'Vous pouvez ajouter une ou plusieurs formations afin de construire un vrai parcours de compétences pour votre salarié intérimaire.',
  'courses.description.help_text':
    'Description et contexte de la formation demandée',
  'courses.description.placeholder':
    'Nous manquons régulièrement de profils cariste...',
  'courses.context.help_text': 'Éléments de contexte complémentaires éventuels',
  'courses.context.placeholder': "Dans le cadre de l'accord...",
  'courses.form.label': 'Contexte du besoin',
  'courses.project_origin.label': 'Origine du projet',
  'courses.project_origin.placeholder': 'Client',
  'courses.job.label': 'Qualification/Emploi visé(e)',
  'courses.job.placeholder': 'Cariste',
  'courses.job.help_text':
    "Référentiel métiers Lucie. Contacter votre CER pour toute demande d'évolution.",
  'courses.period.label': 'Opérationnel pour quelle période',
  'courses.strategic_axis.label': 'Axe(s) stratégique(s)',
  'courses.other.help_text': 'Autre :',
  'courses.content.label': 'Formation(s)',
  'courses.content.info':
    'Vous pouvez ajouter une ou plusieurs formation afin de structurer afin de structurer le parcours de formation demandé',
  'courses.training.label': 'Intitulé de la formation',
  'courses.training.reason':
    'Informations complémentaires pour cette formation',
  'courses.training.has_organism':
    'Un organisme de formation a déjà été contacté',
  'training.groups.required': 'Vous devez choisir au moins une agence',
  'training.trainings.required': 'Vous devez choisir au moins une formation',
  'training.groups.invalid-group': 'Veuillez compléter les champs obligatoires',
  'training.email.invalid': "L'email est invalide",
  'training.phone.invalid': 'Le numéro de téléphone est invalide',
  'training.select.create':
    'Ajouter une valeur hors catalogue formation "{{label}}"',
};

const preview = {
  'training-course.preview.title': 'Dossier de formation',
  'training-course.preview.consult': 'Consulter le dossier',
  'training-course.preview.documents': 'Documents',
  'training-course.preview.messages': 'Messagerie',
  'training-course.preview.status': 'Statut',
  'training-course.preview.followed-by': 'Suivi par {{name}}',
  'training-course.preview.agencies': 'Agence(s)',
  'training-course.preview.training': 'Formation',
  'training-course.preview.has-organism-contacted':
    'Un organisme a été contacté',
  'training-course.preview.deadline': 'Deadline',
  'training-course.preview.context': 'Contexte',
  'training-course.preview.mission': 'Mission',
  'training-course.preview.id': 'IDBO',
  'training-course.preview.first-name': 'Prénom',
  'training-course.preview.last-name': 'Nom',
  'training-course.preview.phone-number': 'Téléphone',
  'training-course.preview.email': 'Email',
  'training-course.preview.city': 'Ville',
  'training-course.preview.candidates': 'Candidats',
  'training-course.preview.ad': 'Besoin {{ref}}',
  'training-course.preview.delegation-rate': 'Coef. deleg : {{rate}}',
  'training-course.preview.duration': 'Durée : {{duration}}h',
  'training-course.preview.management-rate': 'Coef. gestion : {{rate}}',
};

const edit = {
  'training-course.404.title': '404 - Dossier indisponible',
  'training-course.404.message':
    ' Le dossier que vous recherchez n est pas disponible. Cliquez sur le lien ci-dessous pour retourner à la liste des dossiers.',
  'training-course.404.link': 'Voir tous les dossiers',
  'training-course.edit.reference': 'Dossier de formation {{reference}}',
  'training-course.edit.group': 'AGENCE',
  'training-course.edit.card.training': 'FORMATION',
  'training-course.edit.card.mission': 'MISSION',
  'training-course.edit.project_context': 'Contexte du projet de formation',
  'training-course.edit.training_groups': 'Agence(s) concernée(s)',
  'training-course.edit.context': 'Contexte du besoin',
  'training-course.edit.trainings': 'Parcours pédagogique',
  'training-course.edit.educational_path.label': 'Nom du parcours pédagogique',
  'training-course.edit.updated_at': 'Dernière mise à jour du projet',
  'training-course.edit.region': 'Région',
  'training-course.edit.priority': 'Niveau de priorité',
  'training-course.edit.acknowledgement_date': 'Date de connaissance du projet',
  'training-course.edit.regional_director': 'Directeur(trice) régional',
  'training-course.edit.resource_direction_interlocutor':
    'Interlocuteur Direction Resource',
  'training-course.edit.back_office_manager': 'Gestionnaire BO',
  'training-course.edit.mission': 'Mission envisagée',
  'training-course.edit.training_project': 'Projet de formation',
  'training-course.edit.training_action': 'Action de formation',
  'training-course.edit.training_plan': 'Plan de formation',
  'training-course.edit.tab.form': 'Formulaire',
  'training-course.edit.tab.documents': 'Documents',
  'training-course.edit.tab.messages': 'Messagerie',
  'training-course.edit.tab.history': 'Historique',
  'training-course.edit.badge.candidates': 'CANDIDATS',
  'training-course.edit.save-draft': 'Enregistrer le brouillon',
  'training-course.edit.valid-step': "Valider l'étape",
  'training-course.edit.print_planning': 'Imprimer',
  'training-course.edit.planning': 'Planning',
  'training-course.edit.reconversion': 'Reconversion en demande de formation',
};

const ged = {
  'training-course.ged.counter_title': 'Documents attendus',
  'training-course.ged.title': 'Gestion Électronique Documentaire',
  'training-course.ged.add_file': 'Ajouter un document',
  'training-course.ged.add_version': 'Ajouter une version',
  'training-course.ged.file_list.type': 'Type',
  'training-course.ged.file_list.name': 'Nom',
  'training-course.ged.file_list.version_count': 'Nombre de versions',
  'training-course.ged.file_list.last_edit': 'Dernière modification',
  'training-course.ged.file_list.concern': 'Concerne',
  'training-course.ged.file_list.comment': 'Commentaire',
  'training-course.ged.file_list.all_candidates': 'Tous les candidats',
  'training-course.ged.file_list.training': 'Formation',
  'training-course.ged.file_list.status': 'Status',
  'training-course.ged.add.computer': 'Depuis mon PC',
  'training-course.ged.add.drive': 'Depuis le Drive',
  'training-course.ged.title.new-documents':
    'Nouveaux documents à qualifier ({{total}})',
  'training-course.ged.title.documents-with-type':
    'Documents qualifiés ({{total}})',
  'training-course.ged.title.documents': 'Documents ({{total}})',
  'training-course.ged.type.undefined': 'Non défini',
  'training-course.ged.type.unknown': 'Type inconnu',
  'training-course.ged.saved_document': 'Document sauvegardé',
  'training-course.ged.type.convocation': 'Convocation',
  'training-course.ged.type.quotation': 'Devis',
  'training-course.ged.type.training_agreement': 'Convention de formation',
  'training-course.ged.type.program': 'Programme',
  'training-course.ged.type.attendance ': 'Feuilles d’émargement',
  'training-course.ged.type.achievement_certificate':
    'Certificat de réalisation',
  'training-course.ged.type.invoice': 'Facture',
  'training-course.ged.type.training_certificate':
    'Attestation de formation / Titre / Diplôme',
  'training-course.ged.type.title': 'Titre',
  'training-course.ged.type.qualification': 'Diplôme',
  'training-course.ged.type.cerfas ': 'Cerfas / Contrats-types',
  'training-course.ged.type.youth_mission_commitment_charter ':
    "Charte d'engagement Mission Jeunes",
  'training-course.ged.type.tripartite_agreement ':
    'Convention tripartite (si formation en EU)',
  'training-course.ged.type.aid_application_form ':
    'Demandes d’aides (Pôle Emploi, Agefiph, Etat...)',
  'training-course.ged.type.drf':
    'DRF = Demandes de Réservation de Financement',
  'training-course.ged.type.drf_agreement': 'Accord DRF',
  'training-course.ged.type.generation_standard_contracts':
    'Génération des contrats-type / cerfas',
  'training-course.ged.type.parts_transmission ': 'Transmissions de pièces',
  'training-course.ged.type.opco_agreement': 'Accord OPCO',
  'training-course.ged.type.dr': 'DR = Demandes de Remboursement',
  'training-course.ged.type.cofinancing_requests ':
    'Demandes de Cofinancements',
  'training-course.ged.type.cofunding_agreement ':
    'Accord cofinancement (FSE, FNE, PIC IAE...)',
  'training-course.ged.type.proof_of_refund': 'Justificatif de remboursement',
  'training-course.ged.type.epsp_charter ': 'Charte EPSP',
  'training-course.ged.type.request_absence':
    "Demande d'autorisation d'absence",
  'training-course.ged.type.training_contract': 'Contrats de formation',
  'training-course.ged.type.mission_contract ': 'Contrats de mission',
  'training-course.ged.type.payslips ': 'Bulletins de salaire',
  'training-course.ged.type.cv ': 'CV',
  'training-course.ged.type.eligibility_proof': "Justificatif d'éligibilité",
  'training-course.ged.type.due_acknowledgment_of_receipt':
    'Accusé réception DUE',
  'training-course.ged.type.national_training_contract ':
    'Contrats de formation',
  'training-course.ged.type.national_payslip ': 'Bulletins de salaire',
  'training-course.ged.type.diag_competence': 'Diag’Compétences',
  'training-course.ged.type.other': 'Autres documents',
  'training-course.ged.optgroup-type.training_organization':
    'Organisme de formation',
  'training-course.ged.optgroup-type.generated_documents': 'Documents générés',
  'training-course.ged.optgroup-type.opco': 'OPCO',
  'training-course.ged.optgroup-type.internal': 'Interne : Lucie, Anaël...',
  'training-course.ged.optgroup-type.national_agency':
    'Agence Nationale Formation',
  'training-course.ged.optgroup-type.external':
    'Restitutions d’outils externes',
  'training-course.ged.optgroup-type.other': 'Autre',
  'training-course.ged.status.todo': 'A traiter',
  'training-course.ged.status.in_progress': 'En cours',
  'training-course.ged.subject.training_course': 'Le dossier complet',
  'training-course.ged.optgroup-attribute.groups': 'Groupes',
  'training-course.ged.optgroup-attribute.candidates': 'Candidats',
  'training-course.ged.optgroup-attribute.trainings': 'Organismes de formation',
  'training-course.ged.optgroup-attribute.customers': 'Clients',
  'training-course.ged.optgroup-attribute.estimators': 'Deviseurs',
  'training-course.ged.updated_by': 'Modifié le {{- date}} par {{- updatedBy}}',
  'training-course.ged.version.created_by':
    'Créée le {{- date}} par {{- createdBy}}',
  'training-course.ged.version.created_by_simple':
    '{{- date}} - {{- createdBy}}',
  'training-course.ged.versions': 'Versions',
  'training-course.ged.version.error':
    'Erreur lors de la sauvegarde de la version',
  'training-course.ged.document.action.deleted': 'Le document a été supprimé',
  'training-course.ged.document.action.export': 'Exporter en zip',
};

const message = {
  'training-course.message.no-message': "Vous n'avez pas encore de message",
  'training-course.message.unread': '{{n}} messages non lus',
  'training-course.message.no-conversation': 'Pas de conversation en cours',
  'training-course.message.filter': 'Filtrer par :',
  'training-course.message.new': 'Nouveau message',
  'training-course.message.send': 'Envoyer',
  'training-course.message.forward': 'Transférer',
  'training-course.message.respond': 'Répondre',
  'training-course.message.templates.certificate':
    'Certificat de réalisation - Demande de document',
  'training-course.message.templates.control': 'Contrôl mensuel',
  'training-course.message.templates.facture':
    'Établir deux factures car un ou plusieurs absents',
  'training-course.message.templates.end_training':
    "Lorsque l'agence retransmet à nouveau les éléments de fin de formation sur GLPI",
  'training-course.message.templates.certificate_realisation':
    'Demande du certificat de réalisation (obligatoire à partir du 1er/07)',
  'training-course.lucie.message.write': 'Écrivez votre message ...',
  'training-course.lucie.message.with_interlocutor': 'Échanger avec {{agence}}',
};

const estimator = {
  'training-course.estimator.pec-rate': 'Taux PEC',
  'training-course.estimator.pec-rate-undefined':
    'Ce taux PEC n’a pas été paramétré pour ce dispositif et ce profil',
  'training-course.estimator.total': 'Total',
  'training-course.estimator.hourly': 'Horaire',
  'training-course.estimator.candidate': 'Stagiaire',
  'training-course.estimator.candidates': 'Les candidats',
  'training-course.estimator.training-course': 'Formation',
  'training-course.estimator.training-mission': 'Mission',
  'training-course.estimator.training-average-rate': 'Taux horaire moyen',
  'training-course.estimator.training-course-average-rate':
    'Taux horaire formation moyen',
  'training-course.estimator.mission-average-rate':
    'Taux horaire mission moyen',
  'training-course.estimator-candidates-at-start':
    'Nbre de personnes à former (au départ du projet)',
  'training-course.estimator-candidates-at-launch':
    'Nbre total de personnes à former (au lancement)',
  'training-course.estimator.additional-costs': 'Frais annexes',
  'training-course.estimator.has-additional-costs':
    'Frais annexes estimés (moyenne par individu)',
  'training-course.estimator.group': 'Groupe',
  'training-course.estimator.educational-cost-pratical-duration':
    'Durée de formation pratique',
  'training-course.training.practical-training-duration.tooltip':
    'Disponible uniquement dans le cadre d’un POEC.',
  'training-course.estimator.project': 'Le projet',
  'training-course.estimator.name': 'Nom du deviseur',
  'training-course.estimator.positioning-review': 'Bilan de positionnement',
  'training-course.estimator.positioning-review-cost': 'Coût',
  'training-course.estimator.positioning-review-duration': 'Durée',
  'training-course.estimator.organization-name':
    "Nom de l'organisme de formation",
  'training-course.estimator.duration': 'Durée',
  'training-course.estimator.cost': 'Coût',
  'training-course.estimator.quantity': 'Quantité',
  'training-course.estimator.type': 'Type',
  'training-course.estimator.educational-cost':
    'Coûts liés au parcours pédagogique',
  'training-course.estimator.educational-cost-name': "Nom de l'organisme",
  'training-course.estimator.educational-cost-duration': 'Durée de f°',
  'training-course.estimator.educational-cost-cost': 'Tarif',
  'training-course.estimator.hourly-rate': 'Taux horaire',
  'training-course.estimator.pec-according-to-profil':
    'PEC selon profils candidats',
  'training-course.estimator.classic-trainee-number': 'Stagiaire "classique"',
  'training-course.estimator.mission-locale-trainee-number':
    'Stagiaire éligible Mission Locale',
  'training-course.estimator.handicapped-trainee-number':
    'Travailleur handicapé',
  'training-course.estimator.refugee-trainee-number': 'Réfugié (HOPE)',
  'training-course.estimator.skill-trainee-number': 'Langues et Compétences',
  'training-course.estimator.poec-trainee-number': 'Demandeurs d’emploi POEC',
  'training-course.estimator.afpr-trainee-number': 'Demandeurs d’emploi AFPR',
  'training-course.estimator.device-type': 'Dispositif envisagé',
  'training-course.estimator.is-national-need':
    'Porter le dossier de formation sur Agence nationale',
  'training-course.estimator.pec-average-rate': 'Moyenne taux PEC',
  'training-course.estimator.senior-trainee': 'dont Stagiaire +45 ans',
  'training-course.estimator.a2i-trainee': 'dont Public A2i',
  'training-course.estimator.cdii-trainee': 'dont CDI I',
  'training-course.estimator.cpf-amount': 'Droits acquis CPF',
  'training-course.estimator.ddc-duration-recommanded':
    'Durée de contrat recommandée par la DDC',
  'training-course.estimator.ddc-duration': 'Durée',
  'training-course.estimator.ddc-duration-in-month': 'En mois',
  'training-course.estimator.ddc-complementary-duration-in-month':
    '+ complément',
  'training-course.estimator.ddc-duration-per-month': 'Mensuelle',
  'training-course.estimator.ddc-mission-duration': 'Durée mission',
  'training-course.estimator.ddc-duration-in-hours': '{{value}} heures',
  'training-course.estimator.ddc-duration-in-days': '{{value}} jours',
  'training-course.estimator.ddc-duration-in-months': '{{value}} mois',
  'training-course.estimator.is-valid-mission-duration':
    'Respect durée de mission minimum liée au dispositif',
  'training-course.estimator.training-mission-ratio':
    ' Respect % Formation // mission (CPi)',
  'training-course.estimator.additional-funding':
    'Financements complémentaires éventuels (moyenne par individu)',
  'training-course.estimator.has-additional-cofunding':
    'Cofinancement extérieurs',
  'training-course.estimator.external-cofunding-supported-amount':
    'Montant pris en compte',
  'training-course.estimator.external-cofunding-not-supported-amount':
    'Montant non pris en compte',
  'training-course.estimator.has-financial-aid':
    'Aide diverses (Pôle Emploi, état...)',
  'training-course.estimator.customers-name': 'Nom du client',
  'training-course.estimator.customers-management-rate':
    'Coefficient de gestion habituel',
  'training-course.estimator.customers-delegation-rate':
    'Coefficient de délégation habituel',
  'training-course.estimator.delegation-rate':
    'Coefficient de délégation spécifique',
  'training-course.estimator.rfa-rate': 'Taux RFA (en %)',
  'training-course.estimator.tax-relief': 'Allègements',
  'training-course.estimator.sourcing-amount': 'Sourcing',
  'training-course.estimator.re-invoicing-cost-rate':
    'Refacturation Frais Annexes',
  'training-course.estimator.re-invoicing-wage-rate':
    'Refacturation Salaire formation',
  'training-course.estimator.re-invoicing-extra-cost-rate':
    'Refacturation Surcoût formation',
  'training-course.estimator.engineering-cost-amount':
    'Frais d’ingéniérie formation',
  'training-course.estimator.recruitment-amount':
    'Prestation Recrutement/Placement',
  'training-course.estimator.management-fees-amount':
    'Frais de gestion/Pilotage',
  'training-course.estimator.total-invoice-amount':
    'Total facturations diverses',
  'training-course.estimator.additional-delegation-in-month':
    'Complément délégation POST-FORMATION (durée en mois)',
  'training-course.estimator.min-wage': 'SMIC',
  'training-course.estimator.opco-charge': 'Taux de charges (màj OPCO)',
  'training-course.estimator.average-coef': " Coef moyen de l'opération",
  'training-course.estimator.average-coef-on-mission-hours': 'Heures mission',
  'training-course.estimator.average-coef-on-mission-and-training-hours':
    'Heures mission formation',
  'training-course.estimator.evolution-coef-delegation':
    'Evolution du coef délégation (%)',
  'training-course.estimator.analysis-criteria': 'Critères d’analyse',
  'training-course.estimator.element': 'Element',
  'training-course.estimator.cost-in-euros': 'Coût (en euros)',
  'training-course.estimator.total-cost-per-trainee': 'Par stagiaire',
  'training-course.estimator.total-cost-per-group': 'Groupe',
  'training-course.estimator.actual-investment': 'Investissement Actual',
  'training-course.estimator.total-training-revenue-per-trainee':
    'Surcoût Formation',
  'training-course.estimator.turnover-project': 'CA Projet',
  'training-course.estimator.turnover-in-month': 'CA Mensuel',
  'training-course.estimator.resource-management-recommandation':
    'Préconisations Direction Ressource',
  'training-course.estimator.proposed-device': 'Dispositif envisagé',
  'training-course.estimator.client-partnership': 'Le partenariat client',
  'training-course.estimator.cost-in-pourcent': 'Coût (%)',
  'training-course.estimator.gross-margin-per-trainee':
    'Marge brute du projet (stagiaire)',
  'training-course.estimator.gross-margin-per-trainee-with-end':
    'Marge brute du projet (stagiaire) avec Fin +',
  'training-course.estimator.gross_margin_in_month_per_trainee_with_end':
    'Marge brute mensuelle (stagiaire) avec Fin +',
  'training-course.estimator.gross_margin_in_month_per_trainee_without_end':
    'Marge brute mensuelle (stagiaire) sans Fin +',
  'training-course.estimator.training-cost-without-cofi-amount':
    'Marge brute du projet (stagiaire) sans Fin +',
  'training-course.estimator.gross-margin-target-amount':
    'Objectif minimum marge brute à atteindre (50% du surcoût)',
  'training-course.estimator.minimum_margin_info':
    'Assouplissement possible entre 20 et 30% pour les projets Candidat (EPSP et CDI I) - à titre dérogatoire et exceptionnel',
  'training-course.estimator.gross_margin_in_month_per_trainee':
    'Marge brute mensuelle (stagiaire)',
  'training-course.estimator.gross_margin_rate':
    'Taux de marge brute (projet) ',
  'training-course.estimator.gross_margin_rate_with_end':
    'Taux de marge brute (projet) avec Fin +',
  'training-course.estimator.gross_margin_rate_without_end':
    'Taux de marge brute (projet) sans Fin +',
  'training-course.estimator.gross_margin_rate_against_extra_cost':
    'Taux de marge brute vs surcoût (stagiaire)',
  'training-course.estimator.gross_margin_rate_against_extra_cost_with_end':
    'Taux de marge brute vs surcoût (stagiaire) avec Fin +',
  'training-course.estimator.gross_margin_rate_against_extra_cost_without_end':
    'Taux de marge brute vs surcoût (stagiaire) sans Fin +',
  'training-course.estimator.invoice_rate_against_extra_cost':
    'Taux de facturation vs surcoût (projet)',
  'training.course.estimator.break_even_with_end_amount':
    'Coef. seuil rentabilité avec Fin +',
  'training.course.estimator.break_even': 'Coef. seuil rentabilité',
  'training.course.estimator.break_even_without_end_amount':
    'Coef. seuil rentabilité sans Fin +',
  'training-course.estimator.expenses-income': 'Dépenses / Recettes',
  'training-course.estimator.show-detail': 'Voir le détail',
  'training-course.estimator.positioning_review_cost':
    'Coût bilan de positionnement',
  'training-course.estimator.training_expenses':
    'Dépenses liées à la Formation',
  'training-course.estimator.mission_expenses': 'Dépenses liées à la Mission',
  'training-course.estimator.additionnal_post-mission_expenses':
    'Dépenses liées au Complément de Mission Post-Formation',
  'training-course.estimator.additionnal_post-mission_incomes':
    'Recettes liées au Complément de Mission Post-Formation',
  'training-course.estimator.training_incomes': 'Recettes liées à la Formation',
  'training-course.estimator.mission_incomes': 'Recettes liées à la Mission',
  'training-course.estimator.ifm_gains': 'Valorisation gains IFM',
  'training-course.estimator.duration_in_hours': 'Durées en heures',
  'training-course.estimator.contract_duration_in_months':
    'Durées de contrat Mensuelle',
  'training-course.estimator.with_mission_duration': '- Dont durée de mission',
  'training-course.estimator.duration_in_months': 'Durées en mois',
  'training-course.estimator.total_contract_duration':
    'Durée de contrat totale',
  'training-course.estimator.total_mission_duration': 'Durée de mission totale',
  'training-course.estimator.additional_delegation':
    '+ Complément post contrat formation préconisé',
  'training-course.estimator.precognized_contract_duration':
    'Durée de contrat préconisé',
  'training-course.estimator.wages': 'Salaires',
  'training-course.estimator.education_cost_amount_per_candidate': 'CP',
  'training-course.estimator.additional_costs_amount': 'FA',
  'training-course.estimator.total_training_expenses_per_trainee_amount':
    'Total dépenses formation',
  'training-course.estimator.delegation_billing_amount':
    'Facturation Délégation',
  'training-course.estimator.total_invoice_amount':
    'Facturation Presta diverses',
  'training-course.estimator.mission_wage_expenses_amount': 'Salaires',
  'training-course.estimator.mission_rfa_expenses_amount': 'RFA',
  'training-course.estimator.total_mission_expenses': 'Total dépenses mission',
  'training-course.estimator.total_post_training_expenses':
    'Total dépenses complément mission',
  'training-course.estimator.opco_refund': 'Remboursement OPCO',
  'training-course.estimator.training_assessment_amount': 'Bilan',
  'training-course.estimator.external_training_amount': 'F° externe',
  'training-course.estimator.practical_training_amount': 'F° pratique',
  'training-course.estimator.total_training_cost_relief_amount': 'Allègements',
  'training-course.estimator.external_co_funding_supported_amount': 'COFI',
  'training-course.estimator.financialAidSupportedAmount': 'Aide Pôle Emploi',
  'training-course.estimator.training_cost_with_cofi_amount':
    ' Total recette formation avec cofi et aide',
  'training-course.estimator.training_cost_without_cofi_amount':
    'Total recette formation sans cofi et aide',
  'training-course.estimator.total_training_revenue_per_trainee':
    'Total recettes formation',
  'training-course.estimator.total_training_revenue_group':
    'Surcoût Formation (groupe)',
  'training-course.estimator.total_mission_cost_relief_amount': 'Allègements',
  'training-course.estimator.agreement_etti_amount': 'Conventionnement ETTi',
  'training-course.estimator.total_mission_revenue_amount':
    'Total recettes mission',
  'training-course.estimator.total_post_training_revenue_amount':
    'Total recettes complément mission',
  'training-course.estimator.total_gross_margin': 'Marge brute',
  'training-course.estimator.see-synthesis': 'Voir la synthèse',
  'training-course.estimator.request-validation': 'Demander la validation',
  'training-course.estimator.calculation-tooltip': 'Calcul automatique',
  'training-course.estimator.with-end': 'Avec cofi/aides',
  'training-course.estimator.without-end': 'Sans cofi/aides',
  'training-course.estimator.validate': 'Valider',
  'training-course.estimator.billing_and_services':
    'Facturation et prestations',
  'training-course.estimator.cost_in_euros': 'Coût (€)',
  'training-course.estimator.status.draft_status': 'Brouillon',
  'training-course.estimator.status.request_validation_status':
    'En attente de validation',
  'training-course.estimator.status.validate_status': 'Validé',
  'training-course.estimator.duration-calculator':
    "Simulateur d'aide au calcul des durées de contrats",
  'training-course.estimator.delete-error_one':
    'Une erreur est survenue lors de la supression du deviseur',
  'training-course.estimator.delete-error_other':
    'Une erreur est survenue lors de la supression des deviseurs',
  'training-course.estimator.info.contract_duration':
    'La durée du contrat ne respecte pas la durée minimale ou maximale imposée par le dispositif sélectionné.',
};

const estimatorPreview = {
  'training-course.estimator-preview.title': 'Synthèse',
};

const validation = {
  'training-course.validation.no-validation-needed': 'Aucun deviseur à valider',
  'training-course.validation.title': 'Validation du projet',
  'training-course.validation.alert':
    ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam consectetur quam vitae turpis fermentum, sit amet blandit tortor ullamcorper. Sed sit amet dolor risus.',
  'training-course.validation.resource-management-recommandation':
    'Préconisations Direction Ressource',
  'training-course.validation.export': 'Exporter',
  'training-course.validation.interlocutor':
    'Interlocuteur Direction Ressource',
  'training-course.validation.project-origin': 'Origine du projet',
  'training-course.validation.device-type': 'Dispositif envisagé',
  'training-course.validation.duration': ' Durée du contrat',
  'training-course.validation.duration-value': '{{hours}} heures',
  'training-course.validation.training-duration-in-hours':
    'heures de formation',
  'training-course.validation.mission-duration-in-hours': 'heures de mission',
  'training-couse.validation.additional-delegation-in-month':
    'complément de délégation post-projet',
  'training-couse.validation.additional-delegation-in-month-value':
    '{{value}} mois',
  'training-course.validation.actual-investment': 'Investissement Actual',
  'training-course.validation.per-trainee': 'par stagiaire',
  'training-course.validation.group': 'pour le groupe',
  'training-course.validation.total-training-revenue-per-trainee':
    'Surcoût Formation',
  'training-course.validation.project-turn-over': 'CA Projet',
  'training-course.validation.delegation': 'délégation',
  'training-course.validation.customers-delegation':
    'Coef. de délégation habituel',
  'training-course.validation.delegation-rate':
    ' Coef. de délégation du projet',
  'training-course.validation.evolution': " d'évolution",
  'training-course.validation.gross-margin': 'Marge Brute',
  'training-course.validation.without': 'SANS',
  'training-course.validation.with': 'AVEC',
  'training-course.validation.cofi-amount': 'FIN +',
  'training-course.validation.gross-margin-against-extra-cost':
    ' Taux MB vs Surcoût',
  'training-course.validation.project-gross-margin': 'Taux MB Projet',
  'training-course.estimator.add-estimator': 'Ajouter un deviseur',
  'training-course.estimator.add-educational-path':
    'Ajouter un parcours pédagogique',
};

const comments = {
  'training-course.comments.label': 'Commentaire ({{count}})',
  'training-course.comments.label_other': 'Commentaires ({{count}})',
  'training-course.comments.title': 'Commentaires',
  'training-course.comments.select.label': 'Filter par étape',
  'training-course.comments.add-comment': 'Ajouter un commentaire',
  'training-course.comments.validate': 'Valider',
  'training-course.comments.edit': 'Modifier',
  'training-course.comments.edited': '(Modifié)',
  'training-course.comments.cancel': 'Annuler',
  'training-course.comments.step.context': 'Contexte du projet de formation',
  'training-course.comments.step.candidates': 'Stagiaire(s)',
  'training-course.comments.step.program': 'Parcours pédagogique',
  'training-course.comments.step.estimator': 'Deviseur(s)',
  'training-course.comments.step.schedule': 'Planning',
  'training-course.comments.step.validation': 'Validation',
  'training-course.comments.step.ged': 'GED',
  'training-course.comments.step.summary': 'Résumé',
  'training-course.comments.category.action': 'Actions de formation ',
  'training-course.comments.step.control': 'Suivi des coûts',
  'training-course.comments.step.control_cost': 'Suivi des financements',
  'training-course.comments.step.action_schedule': 'Planning',
  'training-course.comments.answer': 'Répondre',
  'training-course.comments.category.training_project': 'Projet de formation',
  'training-course.comments.category.documents': 'Documents',
};

const contacts = {
  'training-course.contacts.title': 'Contacts',
  'training-course.contacts.add': 'Ajouter un contact',
  'training-course.contacts.new': 'Nouveau contact',
  'training-course.contacts.selection': 'Sélection des contacts',
  'training-course.contacts.grouped_actions': 'Actions groupées',
  'training-course.contacts.count_zero': 'Pas de contact',
  'training-course.contacts.count_one': 'contact',
  'training-course.contacts.count_other': 'contacts',
  'training-course.contacts.send_message': 'Envoyer un message',
  'training-course.contacts.update_contacts': 'Modifier les contacts',
  'training-course.contacts.remove_contacts': 'Retirer les contacts du dossier',
  'training-course.contacts.first_name': 'Prénom',
  'training-course.contacts.last_name': 'Nom',
  'training-course.contacts.email': 'Adresse email',
  'training-course.contacts.phoneNumber': 'Numéro de téléphone',
  'training-course.contacts.job': 'Poste',
  'training-course.contacts.company': 'Entreprise',
  'training-course.contacts.category': 'Catégorie',
  'training-course.contacts.agency': 'Agence',
  'training-course.contacts.candidate': 'Candidat',
  'training-course.contacts.training_organization': 'OF',
  'training-course.contact.required': 'Vous devez choisir au moins un candidat',
  'training-course.contact.filter_select':
    'Rechercher un candidat, une catégorie...',
  'training-course.contact.action.added_one': 'Le contact a été ajouté',
  'training-course.contact.action.added_other': 'Les contacts ont été ajoutés',
  'training-course.contact.action.deleted': 'Le contact a été supprimé',
  'training-course.contact.action.edited': 'Le contact a été modifié',
};

const schedule = {
  'training-course.schedule.title': 'Synthèse',
  'training-course.schedule.subtitle':
    'Récapitulatif des durées en heures et en jours',
  'training-course.schedule.training': 'Formation',
  'training-course.schedule.mission': 'Mission',
  'training-course.schedule.unpaid': 'Suspension non payée',
  'training-course.schedule.positioning_review': 'Bilan de positionnement',
  'training-course.schedule.hours': 'Heures',
  'training-course.schedule.days': 'Jours',
};

const history = {
  'training-course.activity.title': 'Historique',
  'training-course.activity.restored': 'Récupération du dossier',
  'training-course.activity.start-activity': "Début de l'activité",
  'training-course.activity.end-activity': "Fin de l'activité",
  'training-course.activity.reinitialize': 'Réinitialiser',
  'training-course.activity.version': 'Versions',
  'training-course.activity.categories': 'Catégories',
  'training-course.activity.project': 'Projet',
  'training-course.activity.context': 'Contexte du projet de formation',
  'training-course.activity.program': 'Programme pédagogique',
  'training-course.activity.candidates': 'Stagiaires',
  'training-course.activity.estimator': 'Deviseur',
  'training-course.activity.documents': 'Documents',
  'training-course.activity.document': 'Document',
  'training-course.activity.document_version': 'Version de document',
  'training-course.activity.validation': 'Validation',
  'training-course.activity.action': 'Action',
  'training-course.activity.authors': 'Auteurs',
  'training-course.activity.recruiters': 'Recruteur',
  'training-course.activity.managers': 'Gestionnaires',
  'training-course.activity.search_by_author': 'Rechercher par auteur',
  'training-course.activity.periods': 'Périodes',
  'training-course.activity.period_from': 'Du',
  'training-course.activity.period_to': 'Au',
  'training-course.activity.training_course_comment': 'Commentaire',
  'training-course.activity.training_course_comment_zero': 'Pas de commentaire',
  'training-course.activity.training_course_comment_one': 'Commentaire',
  'training-course.activity.training_course_comment_other': 'Commentaires',
  'training-course.activity.training_course_customer': 'Mission',
  'training-course.activity.training_course_estimator': 'Deviseur',
  'training-course.activity.training_course_group': 'Agence',
  'training-course.activity.training_course_potential_candidate': 'Stagiaire',
  'training-course.activity.training_course_session': 'Session',
  'training-course.activity.training_course_state': 'Case à cocher',
  'training-course.activity.training_course_training': 'Formation',
  'training-course.activity.training_course': 'Dossier',
  'training-course.activity.training_action_device_map': 'Résumé',
  'training-course.activity.communication': 'Communication',
  'training-course.activity.label': 'Intitulé',
  'training-course.activity.old_value': 'Ancienne valeur',
  'training-course.activity.new_value': 'Nouvelle valeur',
  'training-course.activity.summary': 'Résumé',
  'training-course.activity.control_cost': 'Suivi de coûts',
  'training-course.activity.control_funding': 'Suivi de financements',
  'training-course.activity.training_action_training_device_type_candidate':
    'Suivi de coûts',
  'training-course.activity.training_action_candidate_period': 'Période',
  'training-course.activity.training_action_candidate_invoice': 'Facture',
  'training-course.activity.training_action_candidate': 'Candidat',
  'training-course.activity.training_course_strategic_axis':
    'Axes stratégiques',
  'training-course.activity.create.training_course':
    'Création du dossier de formation',
  'training-course.activity.create.training_course_training':
    "Ajout d'une formation",
  'training-course.activity.create.training_course_group': "Ajout d'une agence",
  'training-course.activity.create.training_course_customer':
    "Ajout d'un client",
  'training-course.activity.create.training_action_candidate':
    "Ajout d'un candidat",
  'training-course.activity.create.training_course_potential_candidate':
    "Ajout d'un stagiaire",
  'training-course.activity.create.training_action_device_map': 'Résumé',
  'training-course.activity.create.training_course_estimator':
    "Ajout d'un deviseur",
  'training-course.activity.create.document': "Ajout d'un document",
  'training-course.activity.create.document_version':
    'Nouvelle version du document',
  'training-course.activity.create.training_action_candidate_invoice':
    "Ajout d'une facture",
  'training-course.activity.create.training_action_candidate_period':
    "Ajout d'une période",
  'training-course.activity.create.training_action_training_device_type_candidate':
    'Création type de dispositif du candidat',
  'training-course.activity.create.communication': 'Nouveau message envoyé',
  'training-course.activity.create.training_course_strategic_axis':
    'Création des axes stratégiques',
  'training-course.activity.delete.training_course':
    'Suppression du dossier de formation',
  'training-course.activity.delete.training_course_training':
    "Suppression d'une formation",
  'training-course.activity.delete.training_course_group':
    "Suppression d'une agence",
  'training-course.activity.delete.training_course_customer':
    "Suppression d'un client",
  'training-course.activity.delete.training_action_candidate':
    "Suppression d'un candidat",
  'training-course.activity.delete.training_course_potential_candidate':
    "Suppression d'un stagiaire",
  'training-course.activity.delete.training_action_device_map': 'Résumé',
  'training-course.activity.delete.training_course_estimator':
    "Suppression d'un deviseur",
  'training-course.activity.delete.document': "Suppression d'un document",
  'training-course.activity.delete.document_version':
    'Suppression version du document',
  'training-course.activity.delete.training_action_candidate_invoice':
    "Suppression d'une facture",
  'training-course.activity.delete.training_action_candidate_period':
    "Suppression d'une période",
  'training-course.activity.delete.training_action_training_device_type_candidate':
    'Suppression type de dispositif du candidat',
  'training-course.activity.delete.training_course_strategic_axis':
    'Suppression des axes stratégiques',
};

const durationCalculator = {
  'training-course.duration-calculator.from-date': 'Date de début',
  'training-course.duration-calculator.weeks': 'Semaines',
  'training-course.duration-calculator.days': 'Jours',
  'training-course.duration-calculator.months': 'Mois',
  'training-course.duration-calculator.to-date': 'Date de fin',
  'training-course.duration-calculator.date-nb-days': 'Nb de jours',
  'training-course.duration-calculator.date-nb-months':
    'Nb mois (30,42 jrs/mois)',
  'training-course.duration-calculator.date-nb-weeks': 'Nb semaines',
  'training-course.duration-calculator.date-work-duration':
    'Temps de travail hebdo',
  'training-course.duration-calculator.date-total-hours':
    'Nb heures total contrat',
  'training-course.duration-calculator.date-hours-15':
    'Nb heures formation 15%',
  'training-course.duration-calculator.date-hours-25':
    'Nb heures formation 25%',
  'training-course.duration-calculator.date-hours-50':
    'Nb heures formation 50%',
  'training-course.duration-calculator.select-label': 'Base de calcul ',
  'training-course.duration-calculator.select-dates': 'Date de contrat',
  'training-course.duration-calculator.select-months': 'Nb mois',
  'training-course.duration-calculator.select-hours': 'Nb heures de formation',
  'training-course.duration-calculator.date-label':
    "Calcul du nombre d'heures de formation et nombre de mois en fonction des dates de début et fin de contrat",
  'training-course.duration-calculator.month-label':
    "Calcul du nombre d'heures de formation en fonction d'un % d'actions de formation et d'un nombre de mois",
  'training-course.duration-calculator.nb-months': 'Nb mois',
  'training-course.duration-calculator.worked-month': 'Durée mensuelle',
  'training-course.duration-calculator.percent-action':
    "% d'action de formation",
  'training-course.duration-calculator.training-hours': 'Nb heures formation',
  'training-course.duration-calculator.hours-label':
    "Calcul du nombre de mois en fonction du nombre d'heures de formation et du % souhaité",
};
export default {
  ...groups,
  ...trainings,
  ...courses,
  ...candidates,
  ...mission,
  ...confirmation,
  ...trainingSteps,
  ...list,
  ...preview,
  ...edit,
  ...estimator,
  ...ged,
  ...message,
  ...validation,
  ...estimatorPreview,
  ...comments,
  ...contacts,
  ...error,
  ...schedule,
  ...history,
  ...durationCalculator,
};
