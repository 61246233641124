export const toBase64 = async (file: File | Blob): Promise<string> => {
  return await new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve((fileReader.result || '') as string);
    fileReader.readAsDataURL(file);
  });
};

export const humanFileSize = (size: number): string => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    parseFloat((size / Math.pow(1024, i)).toFixed(2)) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
};
