import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';

import TrainCourseStepWizardSkeleton from './TrainCourseStepWizardSkeleton';

const TrainingCourseCandidatesSkeleton = (): JSX.Element => {
  return (
    <Box mt={5}>
      <Card>
        <CardContent>
          <TrainCourseStepWizardSkeleton step={3} />

          <Box my={3} maxWidth={480}>
            <Skeleton variant="rectangular" height={30} />
          </Box>

          <Box my={3}>
            <Alert
              severity="info"
              variant="outlined"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'blueClickable',
                borderColor: 'blueClickable',
                '& .MuiAlert-icon': {
                  color: 'blueClickable',
                },
              }}
            >
              <Box height={40} />
            </Alert>
          </Box>

          <Box maxWidth={500}>
            <Skeleton variant="rectangular" height={30} />
          </Box>
          <Box my={3} maxWidth={500}>
            <Skeleton variant="text" height={46} />
          </Box>

          {[...Array(1).keys()].map((i) => (
            <Box my={1} key={i}>
              <Skeleton variant="rectangular" height={70} />
            </Box>
          ))}

          <Box my={5} maxWidth={300}>
            <Skeleton variant="text" height={46} />
          </Box>

          <Box maxWidth={500}>
            <Skeleton variant="text" height={30} />
          </Box>

          <Box maxWidth={500}>
            <Skeleton variant="text" height={200} />
          </Box>

          <Box mt={4}>
            <Grid container justifyContent="flex-end" spacing={3}>
              <Grid item>
                <Skeleton variant="rectangular" width={80} height={30} />
              </Grid>
              <Grid item>
                <Skeleton variant="rectangular" width={80} height={30} />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TrainingCourseCandidatesSkeleton;
