/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import { toContact } from '@data/dto/contact.dto';
import { FilterParams } from '@data/models/filter.interface';
import { Meta, PaginateResult } from '@data/models/api.interface';
import ErrorService from 'core/error.service';
import {
  Contact,
  ContactHttp,
  ContactWizard,
} from '@data/models/contact.interface';

@injectable()
export default class ContactService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly contactEndpoint = '/contacts';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listContacts(
    filters: FilterParams,
  ): Promise<PaginateResult<Contact[]>> {
    const { data: response, error } = await this.API?.get<{
      data: ContactHttp[];
      meta: Meta;
    }>(this.contactEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }
    return {
      items: response?.data?.map(toContact) || [],
      totalSize: response?.meta?.page?.total || 0,
    };
  }

  public async upsertContact({
    id,
    ...contactWizard
  }: ContactWizard): Promise<Contact | null> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<ContactWizard, 'id'> },
        { data: ContactHttp }
      >(this.contactEndpoint, { data: contactWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return null;
      }

      return toContact(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: ContactWizard },
      { data: ContactHttp }
    >(`${this.contactEndpoint}/${id}`, {
      data: { ...contactWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }
    return toContact(data?.data || {});
  }

  public async deleteContact(id: string): Promise<Contact | void> {
    if (!id) {
      throw new Error('Id is missing');
    }

    const { data, error } = await this.API?.delete<{ data: ContactHttp }>(
      `${this.contactEndpoint}/${id}`,
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toContact(data?.data || {});
  }

  public async detachContact({
    id,
    ...contactWizard
  }: ContactWizard): Promise<Contact | void> {
    if (!id) {
      throw new Error('Id is missing');
    }

    const { data, error } = await this.API?.patch<
      { data: ContactWizard },
      { data: ContactHttp }
    >(`${this.contactEndpoint}/${id}/detach-training-course`, {
      data: { ...contactWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toContact(data?.data || {});
  }
}
