/* eslint-disable complexity */
import day from '@_plugins/dayjs';
import { Candidate, CandidateHttp } from '@data/models/candidate.interface';
import { TrainingCourseCandidate } from '@data/models/training-course-candidate.interface';
import { generateAvatarFromFullName } from '@helpers/avatar.helper';
import dayjs, { Dayjs } from 'dayjs';

export const toAge = (birthdayDate: Dayjs | undefined): number => {
  return (birthdayDate && dayjs().diff(birthdayDate, 'y')) || 18;
};

export const toFullAge = (birthdayDate: Dayjs | undefined): string => {
  if (!birthdayDate) {
    return '';
  }
  const diff = new Date(
    new Date().getTime() - dayjs(birthdayDate).toDate().getTime(),
  );
  return `${diff.getUTCFullYear() - 1970}ans, ${Math.floor(
    diff.getUTCMonth(),
  )}mois, ${Math.floor(diff.getUTCDate() - 1)}jours`;
};
export const toBasicAge = (birthdayDate: Dayjs | undefined): string => {
  if (!birthdayDate) {
    return '';
  }
  const diff = new Date(
    new Date().getTime() - dayjs(birthdayDate).toDate().getTime(),
  );
  return `${diff.getUTCFullYear() - 1970}ans`;
};

export const fromStoredSearchCandidate = ({
  id,
  fullName,
}: Pick<
  TrainingCourseCandidate,
  'id' | 'fullName'
>): TrainingCourseCandidate => {
  return {
    position: 0,
    firstName: '',
    lastName: '',
    fullName,
    age: 18,
    id: id,
    birthdayDate: day(),
    address: '',
    city: '',
    postalCode: '',
    country: '',
    currentProfession: '',
    isA2i: false,
    isCdii: false,
    isRqth: false,
    followedByPoleEmploi: false,
    followedByMissionLocale: false,
    emails: [],
    phones: [],
    avatar: generateAvatarFromFullName(fullName),
    accommodationFees: '',
    cpfAmount: '',
    feesDescription: '',
    hasDiagCompetences: false,
    hasInterimExperience: false,
    hoursInterimExperience: 0,
    isEPSPApproach: false,
    lucieId: '',
    lucieRef: '',
    mealFees: '',
    missionRate: '',
    needOpenCPFAccount: false,
    totalFees: '',
    trainingCourseRate: '',
    transportFees: '',
    validateCandidateType: false,
    drivingLicenses: [],
    meansOfTransport: [],
  };
};

export const toCandidate = (candidate: Partial<CandidateHttp>): Candidate => {
  const firstName = candidate.attributes?.first_name || '';
  const lastName = candidate.attributes?.last_name || '';
  const fullName = `${firstName} ${lastName}`;
  const firstRegistrationNumber =
    candidate.attributes?.registration_numbers?.[0];
  const idBo = `#IDBO ${String(candidate?.id)}`;
  return {
    firstName,
    lastName,
    fullName,
    idBo,
    age: candidate.attributes?.age || 18,
    id: candidate.id || '',
    birthdayDate: day(candidate.attributes?.birthday_date),
    birthCountry: candidate.attributes?.birth_country || '',
    address: candidate.attributes?.address || '',
    city: candidate.attributes?.city || '',
    postalCode: candidate.attributes?.postal_code || '',
    country: candidate.attributes?.country || '',
    agency: candidate.attributes?.agency || '',
    current_profession: candidate.attributes?.current_profession || '',
    recruiterId: candidate.attributes?.recruiter_id || null,
    isA2i: candidate.attributes?.is_a2i || false,
    isCdii: candidate.attributes?.is_cdii || false,
    isRqth: candidate.attributes?.is_rqth || false,
    followedByPoleEmploi:
      candidate.attributes?.followed_by_pole_emploi || false,
    followedByMissionLocale:
      candidate.attributes?.followed_by_mission_locale || false,
    registrationNumbers: candidate.attributes?.registration_numbers || [],
    fullRegistrationNumber: `${idBo} ${
      (firstRegistrationNumber && `/ Matricule ${firstRegistrationNumber}`) ||
      ''
    }`.trim(),
    emails: candidate.attributes?.emails || [],
    phones: candidate.attributes?.phones || [],
    createdAt: day(candidate.attributes?.createdAt),
    updatedAt: day(candidate.attributes?.updatedAt),
    avatar: generateAvatarFromFullName(fullName),
    drivingLicenses: candidate.attributes?.driving_licenses || [],
    meansOfTransport: candidate.attributes?.means_of_transport || [],
  };
};

export const toCandidates = (
  candidate: Partial<CandidateHttp>,
): Candidate[] => {
  const baseCandidate: Candidate = {
    firstName: candidate.attributes?.first_name || '',
    lastName: candidate.attributes?.last_name || '',
    fullName: `${candidate.attributes?.first_name || ''} ${candidate.attributes?.last_name || ''}`,
    idBo: `#IDBO ${String(candidate?.id)}`,
    age: candidate.attributes?.age || 18,
    id: candidate.id || '',
    birthdayDate: day(candidate.attributes?.birthday_date),
    birthCountry: candidate.attributes?.birth_country || '',
    address: candidate.attributes?.address || '',
    city: candidate.attributes?.city || '',
    postalCode: candidate.attributes?.postal_code || '',
    country: candidate.attributes?.country || '',
    agency: candidate.attributes?.agency || '',
    current_profession: candidate.attributes?.current_profession || '',
    recruiterId: candidate.attributes?.recruiter_id || null,
    isA2i: candidate.attributes?.is_a2i || false,
    isCdii: candidate.attributes?.is_cdii || false,
    isRqth: candidate.attributes?.is_rqth || false,
    followedByPoleEmploi:
      candidate.attributes?.followed_by_pole_emploi || false,
    followedByMissionLocale:
      candidate.attributes?.followed_by_mission_locale || false,
    registrationNumbers: candidate.attributes?.registration_numbers || [],
    emails: candidate.attributes?.emails || [],
    phones: candidate.attributes?.phones || [],
    createdAt: day(candidate.attributes?.createdAt),
    updatedAt: day(candidate.attributes?.updatedAt),
    avatar: generateAvatarFromFullName(
      `${candidate.attributes?.first_name || ''} ${candidate.attributes?.last_name || ''}`,
    ),
    drivingLicenses: candidate.attributes?.driving_licenses || [],
    meansOfTransport: candidate.attributes?.means_of_transport || [],
    fullRegistrationNumber: '',
  };

  return (candidate.attributes?.registration_numbers || []).map(
    (registrationNumber) => ({
      ...baseCandidate,
      fullRegistrationNumber:
        `${baseCandidate.idBo} / Matricule ${registrationNumber}`.trim(),
    }),
  );
};
