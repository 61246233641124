import { WithTranslation, withTranslation } from 'react-i18next';

import { routes } from '@_plugins/router/router';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import useStore from '@stores/store';

import Navigation from './Navigation';

const NavigationTop = ({ t: trans }: WithTranslation): JSX.Element => {
  const { user } = useStore();
  return (
    <>
      <div>
        <AppBar
          position="relative"
          color="default"
          sx={{
            position: 'inherit',
            zIndex: 'mobileStepper',
            boxShadow: '0 2px 10px 0 rgb(39 39 39 / 12%)',
            backgroundColor: 'background.paper',
          }}
        >
          <Toolbar
            sx={{
              px: '0 !important',
              minHeight: '40px !important',
              overflow: 'hidden',
              maxHeight: '46px',
            }}
          >
            <Container maxWidth="xl">
              <Box display="flex">
                <Box alignItems="center" display="flex">
                  {routes.map((navRoute, key) => (
                    <Navigation
                      navRoute={navRoute}
                      key={`${navRoute.name}-${key}`}
                    />
                  ))}
                </Box>
                <Box alignItems="center" display="flex">
                  {user.emulatedBy && (
                    <Chip
                      icon={
                        <Box mr={1} mt={0.5}>
                          <IconProvider icon={faCircleCheck} color="primary" />
                        </Box>
                      }
                      label={trans('navigation.emulated_by', {
                        name: user.fullName,
                      })}
                      color="error"
                    />
                  )}
                </Box>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default withTranslation()(NavigationTop);
