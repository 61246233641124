import {
  EstimatorConfiguration,
  EstimatorConfigurationHttp,
  EstimatorConfigurationWizard,
} from '@data/models/estimator-configuration.interface';
import { toApiFloat } from './api.dto';

export const toEstimatorConfiguration = ({
  id,
  attributes,
}: Partial<EstimatorConfigurationHttp>): EstimatorConfiguration => {
  return {
    id: id || '',
    a2iGrant: attributes?.a2i_grant || '4351.00',
    additionalPecRate: attributes?.additional_pec_rate || '27.00',
    afprPecRate: attributes?.afpr_pec_rate || '8.00',
    daysWorkedPerMonth: attributes?.days_worked_per_month || '21.67',
    defaultMissionDurationPerMonth:
      attributes?.default_mission_duration_per_month || '151.67',
    defaultPecRate: attributes?.default_pec_rate || '17.00',
    hoursWorkedInYear: attributes?.hours_worked_in_year || '1600',
    poecPecRate: attributes?.poec_pec_rate || '14.00',
    seniorAge: attributes?.senior_age || 45,
    teleoperatorPecRate: attributes?.teleoperator_pec_rate || '9.15',
    additionalTeleoperatorPecRate:
      attributes?.additional_teleoperator_pec_rate || '15.00',
    wagesRate: attributes?.wages_rate || '1.10',
    tParameter: attributes?.t_parameter || '0.32',
  };
};

export const toEstimatorConfigurationWizard = (
  estimatorConfig: Partial<EstimatorConfiguration>,
): EstimatorConfigurationWizard => {
  return {
    id: estimatorConfig?.id || '',
    type: 'estimator_configurations',
    attributes: {
      a2i_grant: toApiFloat(estimatorConfig.a2iGrant) || '4351.00',
      additional_pec_rate:
        toApiFloat(estimatorConfig.additionalPecRate) || '27.00',
      afpr_pec_rate: toApiFloat(estimatorConfig.afprPecRate) || '8.00',
      days_worked_per_month:
        toApiFloat(estimatorConfig.daysWorkedPerMonth) || '21.67',
      default_mission_duration_per_month:
        toApiFloat(estimatorConfig.defaultMissionDurationPerMonth) || '151.67',
      default_pec_rate: toApiFloat(estimatorConfig.defaultPecRate) || '17.00',
      hours_worked_in_year:
        toApiFloat(estimatorConfig.hoursWorkedInYear) || '1600.00',
      poec_pec_rate: toApiFloat(estimatorConfig.poecPecRate) || '14.00',
      senior_age: estimatorConfig.seniorAge || 45,
      teleoperator_pec_rate:
        toApiFloat(estimatorConfig.teleoperatorPecRate) || '9.15',
      wages_rate: toApiFloat(estimatorConfig.wagesRate) || '1.10',
      additional_teleoperator_pec_rate:
        toApiFloat(estimatorConfig.additionalTeleoperatorPecRate) || '15.00',
      t_parameter: toApiFloat(estimatorConfig.tParameter, 4) || '0.32',
    },
  };
};
