/* eslint-disable no-unsafe-optional-chaining */
import FileAPI from '@data/api/file.api';
import LaravelAPI from '@data/api/laravel.api';
import { toUser } from '@data/dto/user.dto';
import { USER_STORAGE, User, UserHttp } from '@data/models/user.interface';
import { container } from '@_plugins/ioc';
import { useStorage } from '@_plugins/storage';
import useStore from '@stores/store';
import { injectable } from 'inversify';

@injectable()
export default class AuthService {
  public TOKEN_SESSION_NAME = 'TOKEN_SESSION_NAME';
  private API = container.get<LaravelAPI>(LaravelAPI);
  private FileAPI = container.get<FileAPI>(FileAPI);
  private readonly tokenSessionName = 'TOKEN_SESSION_NAME';
  private storage = useStorage();
  private authEndpoint = '/auth/me';

  public getToken(): string | null {
    return this.storage.get<string>(this.tokenSessionName);
  }

  public setToken(token: string): void {
    return this.storage.set(this.tokenSessionName, token, true);
  }

  public initializeToken(): void {
    const url = new URL(window.location.toString());
    const apiToken = url.searchParams.get('scud_api_token');

    if (apiToken) {
      this.setToken(apiToken);
      url.searchParams.delete('scud_api_token');
      window.location.href = url.toString();
    }
  }

  public async getMe(): Promise<User> {
    const loginUri = `${String(process.env.VITE_APP_LOGIN_URL)}?redirect_uri=${
      window.location.href
    }`;

    if (!this.getToken()) {
      window.location.href = loginUri;
      return toUser({});
    }

    const { data, error } = await this.API?.get<{ data: UserHttp }>(
      this.authEndpoint,
    );

    if (error) {
      this.removeToken();
      window.location.href = loginUri;
    }
    return toUser(data?.data || {});
  }

  public hasPermissionsTo(guard: string): boolean {
    const permissions = useStore.getState().user.permissions;
    return permissions.includes(guard);
  }

  public removeToken(): void {
    this.storage.remove(this.tokenSessionName);
    this.storage.remove(USER_STORAGE);
  }

  public async downloadSignature(): Promise<{
    name: string;
    content: Blob | undefined;
    type: string;
  } | void> {
    const { data, headers } = await this.FileAPI.get<Blob>(
      `${this.authEndpoint}/signature`,
    );

    if (!headers) {
      return;
    }

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(headers['content-disposition']);
    return {
      type: headers['content-type'],
      name: matches?.[1].replace(/['"]/g, '') || '',
      content: data,
    };
  }
}
