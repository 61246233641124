import day from '@_plugins/dayjs';
import dayjs from '@_plugins/dayjs';
import { toCandidateType } from '@data/dto/candidate-type.dto';
import { toAge } from '@data/dto/candidate.dto';
import { CandidateTypeHttp } from '@data/models/candidate-type.interface';
import { Candidate } from '@data/models/candidate.interface';
import { ProfessionHttp } from '@data/models/profession.interface';
import {
  TrainingCourseCandidate,
  TrainingCourseCandidateHttp,
} from '@data/models/training-course-candidate.interface';
import { generateAvatarFromFullName } from '@helpers/avatar.helper';

import { toApiDate, toApiFloat, toBoolean } from './api.dto';
import { toProfession } from './profession.dto';

const DEFAULT_AGE = 18;

// eslint-disable-next-line complexity
export const fromTrainingCourseCandidateHttp = (
  { attributes, id, relationships }: Partial<TrainingCourseCandidateHttp>,
  professions: ProfessionHttp[],
  candidateTypes: CandidateTypeHttp[] = [],
): TrainingCourseCandidate => {
  const professionHttp = professions.find(
    ({ id }) => id === relationships?.profession?.data?.id,
  );
  const candidateTypeHttp = candidateTypes.find(
    ({ id }) => id === relationships?.candidate_type?.data?.id,
  );

  return {
    id: id || '',
    lucieId: attributes?.lucie_id?.toString() || null,
    position: attributes?.position || 0,
    lucieRef: attributes?.lucie_reference || '',
    age: attributes?.age || DEFAULT_AGE,
    birthdayDate: day(attributes?.birthday_date || ''),
    firstName: attributes?.first_name || '',
    lastName: attributes?.last_name || '',
    fullName: attributes?.full_name || '',
    address: attributes?.address || '',
    city: attributes?.city || '',
    postalCode: attributes?.postal_code || '',
    country: attributes?.country || '',
    currentProfession: attributes?.current_profession || '',
    trainingCourseRate: attributes?.training_course_rate || '',
    missionRate: attributes?.mission_rate || '',
    followedByPoleEmploi: toBoolean(attributes?.followed_by_pole_emploi),
    followedByMissionLocale: toBoolean(attributes?.followed_by_mission_locale),
    cpfAmount: attributes?.cpf_amount || '',
    hasInterimExperience: toBoolean(attributes?.has_interim_experience),
    hoursInterimExperience:
      parseInt(attributes?.interim_experience_hours || '0') || 0,
    isA2i: toBoolean(attributes?.is_a2i),
    isCdii: toBoolean(attributes?.is_cdii),
    isRqth: toBoolean(attributes?.is_rqth),
    validateCandidateType: toBoolean(attributes?.validate_candidate_type),
    candidateType: toCandidateType(candidateTypeHttp || {}),
    needOpenCPFAccount: toBoolean(attributes?.open_cpf_account),
    hasDiagCompetences: toBoolean(attributes?.has_competences_diagnostic),
    isEPSPApproach: toBoolean(attributes?.is_epsp_approach),
    emails: attributes?.emails || [],
    phones: attributes?.phones || [],
    professionExpected: toProfession(professionHttp || {}),
    avatar: generateAvatarFromFullName(attributes?.full_name || ''),
    accommodationFees: attributes?.accommodation_fees || '',
    feesDescription: attributes?.fees_description || '',
    mealFees: attributes?.meal_fees || '',
    totalFees: attributes?.total_fees || '',
    transportFees: attributes?.transport_fees || '',
    pecRate: attributes?.pec_rate || '',
    drivingLicenses: attributes?.driving_licenses || [],
    meansOfTransport: attributes?.means_of_transport || [],
  };
};
export const toTrainingCourseCandidate = (
  candidate: Partial<Candidate>,
  position = 0,
): TrainingCourseCandidate => {
  return {
    id: '',
    position,
    lucieId: candidate.id || null,
    lucieRef: candidate.fullRegistrationNumber || '',
    age: toAge(candidate.birthdayDate),
    birthdayDate: day(candidate.birthdayDate),
    firstName: candidate.firstName || '',
    lastName: candidate.lastName || '',
    fullName: candidate.fullName || '',
    address: candidate.address || '',
    city: candidate.city || '',
    postalCode: candidate.postalCode || '',
    country: candidate.country || '',
    currentProfession: candidate.current_profession || '',
    trainingCourseRate: '',
    missionRate: '',
    followedByPoleEmploi: candidate.followedByPoleEmploi || false,
    followedByMissionLocale: candidate.followedByMissionLocale || false,
    cpfAmount: '',
    hasInterimExperience: false,
    hoursInterimExperience: 0,
    isA2i: candidate.isA2i || false,
    isCdii: candidate.isCdii || false,
    isRqth: candidate.isRqth || false,
    needOpenCPFAccount: false,
    hasDiagCompetences: false,
    isEPSPApproach: false,
    emails: candidate.emails || [],
    phones: candidate.phones || [],
    avatar: candidate.avatar || '',
    accommodationFees: '',
    feesDescription: '',
    mealFees: '',
    totalFees: '',
    transportFees: '',
    validateCandidateType: false,
    pecRate: '',
    drivingLicenses: candidate.drivingLicenses || [],
    meansOfTransport: candidate.meansOfTransport || [],
  };
};

// eslint-disable-next-line complexity
export const toTrainingCourseCandidateHttp = (
  candidate: Partial<TrainingCourseCandidate>,
  trainingCourseId: string | null,
): TrainingCourseCandidateHttp => {
  const candidateHttp: TrainingCourseCandidateHttp = {
    type: 'training_course_candidates',
    id: candidate.id || '',
    attributes: {
      position: candidate.position || 0,
      lucie_id: candidate.lucieId ? parseInt(candidate.lucieId) : null,
      age: candidate.age || DEFAULT_AGE,
      birthday_date:
        (dayjs(candidate.birthdayDate)?.isValid() &&
          toApiDate(dayjs(candidate.birthdayDate))) ||
        day().toISOString(),
      lucie_reference: candidate.lucieRef || '',
      first_name: candidate.firstName || '',
      last_name: candidate.lastName || '',
      full_name: candidate.fullName || '',
      address: candidate.address || '',
      postal_code: candidate.postalCode || '',
      city: candidate.city || '',
      current_profession: candidate.currentProfession || '',
      country: candidate.country || '',
      followed_by_pole_emploi: candidate.followedByPoleEmploi || false,
      followed_by_mission_locale: candidate.followedByMissionLocale || false,
      has_interim_experience: candidate.hasInterimExperience || false,
      is_a2i: candidate.isA2i || false,
      is_cdii: candidate.isCdii || false,
      is_rqth: candidate.isRqth || false,
      validate_candidate_type: candidate.validateCandidateType || false,
      open_cpf_account: candidate.needOpenCPFAccount || false,
      has_competences_diagnostic: candidate.hasDiagCompetences || false,
      is_epsp_approach: candidate.isEPSPApproach || false,
      cpf_amount: toApiFloat(candidate.cpfAmount),
      interim_experience_hours: `${candidate.hoursInterimExperience || '0'}.00`,
      emails: candidate.emails || [],
      phones: candidate.phones || [],
      accommodation_fees: toApiFloat(candidate.accommodationFees),
      fees_description: candidate.feesDescription || '',
      meal_fees: toApiFloat(candidate.mealFees),
      total_fees: toApiFloat(candidate.totalFees),
      transport_fees: toApiFloat(candidate.transportFees),
      pec_rate: toApiFloat(candidate.pecRate),
      driving_licenses: candidate.drivingLicenses || [],
      means_of_transport: candidate.meansOfTransport || [],
    },
    relationships: {
      training_course: {
        data: {
          id: trainingCourseId || '',
          type: 'training_courses',
        },
      },
      profession: {
        data:
          (candidate.professionExpected?.id && {
            id: candidate.professionExpected?.id || '',
            type: 'profession_repositories',
          }) ||
          null,
      },
      candidate_type: {
        data:
          (candidate.candidateType?.id && {
            id: candidate.candidateType?.id || '',
            type: 'candidate_type_repositories',
          }) ||
          null,
      },
    },
  };

  if (candidate.missionRate) {
    candidateHttp.attributes.mission_rate = toApiFloat(candidate.missionRate);
  }

  if (candidate.trainingCourseRate) {
    candidateHttp.attributes.training_course_rate = toApiFloat(
      candidate.trainingCourseRate,
    );
  }

  return candidateHttp;
};
