/* eslint-disable no-console */
import * as React from 'react';
import Konami from 'react-konami-code';
import {
  SettingsContainer,
  StatusContainer,
  BoardContainer,
} from './Minesweeper/containers';
import { Wrapper, Title } from './Minesweeper/AppStyle';
import useStore from '@stores/store';

const KonamiComponent = (): JSX.Element => {
  const { openModal } = useStore();
  const easterEgg = () =>
    openModal({
      title: 'Démineur',
      component: (
        <Wrapper>
          <Title>Minesweeper by Digital Factory</Title>
          <SettingsContainer />
          <StatusContainer />
          <BoardContainer />
        </Wrapper>
      ),
    });
  return (
    <Konami action={easterEgg}>
      <div style={{ display: 'none' }}>
        Congratulation <br />
        You find the konomi code !! <br />
        Now, press keys to play : ↑ ↑ ↓ ↓ ← → ← → B A
      </div>
    </Konami>
  );
};

export default KonamiComponent;
