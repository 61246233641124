import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import Timeline from '@mui/lab/Timeline';
import Skeleton from '@mui/material/Skeleton';

const NotificationSkeleton = (): JSX.Element => {
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Skeleton variant="rectangular" height={40} width={73} />
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot>
          <Skeleton variant="circular" height={32} width={32} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Skeleton variant="rectangular" height={116} />
      </TimelineContent>
    </TimelineItem>
  );
};
const UserNotificationListSkeleton = (): JSX.Element => {
  return (
    <Box mt={5}>
      <Card>
        <CardHeader>
          <Skeleton variant="rectangular" width={200} height={26} />
        </CardHeader>
        <CardContent>
          <Timeline>
            {Array.from(Array(20).keys()).map((i) => (
              <NotificationSkeleton key={`notification-skeleton-${i}`} />
            ))}
          </Timeline>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserNotificationListSkeleton;
