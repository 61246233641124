import { DocumentVersionRaw } from '@data/models/document-version.interface';
import { TrainingCourse } from '@data/models/training-course.interface';
import { StateCreator } from 'zustand';

const isTemporaryDocument = (
  versions: TemporaryDocument | TemporaryDocument[] | undefined,
): versions is TemporaryDocument => {
  return !Array.isArray(versions);
};

export interface TemporaryDocument {
  trainingId: string;
  version: DocumentVersionRaw;
}

export interface TemporaryDocumentState {
  temporaryDocuments: TemporaryDocument[];
  setTemporaryDocuments: (documents: TemporaryDocument[]) => void;
  addTemporaryDocument: (document: TemporaryDocument) => void;
  removeTemporaryDocument: (document: TemporaryDocument) => void;
  findAvalaibleTemporaryDocuments: (
    trainingCourse: TrainingCourse,
  ) => TemporaryDocument[];
}

export const createTemporaryDocumentSlice: StateCreator<
  TemporaryDocumentState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  TemporaryDocumentState
> = (set, get) => ({
  temporaryDocuments: [],
  setTemporaryDocuments: (documents: TemporaryDocument[]) => {
    if (isTemporaryDocument(documents)) {
      return;
    }
    set(() => ({ temporaryDocuments: documents || [] }));
  },
  addTemporaryDocument: (document: TemporaryDocument) => {
    if (!isTemporaryDocument(document)) {
      return;
    }
    set((state) => ({
      temporaryDocuments: [...state.temporaryDocuments, document],
    }));
  },
  removeTemporaryDocument: (document: TemporaryDocument) => {
    if (!isTemporaryDocument(document)) {
      return;
    }
    set((state) => ({
      temporaryDocuments: state.temporaryDocuments.filter(
        ({ version }) => version.name !== document.version.name,
      ),
    }));
  },
  findAvalaibleTemporaryDocuments: (
    trainingCourse: TrainingCourse,
  ): TemporaryDocument[] => {
    const uuids = trainingCourse.educationalPaths.flatMap(
      ({ trainingCourseTrainings }) =>
        trainingCourseTrainings.flatMap(({ uuid }) => uuid),
    );

    return get().temporaryDocuments.filter(({ trainingId }) =>
      uuids.includes(trainingId),
    );
  },
});
