import day from '@_plugins/dayjs';
import dayjs from '@_plugins/dayjs';
import { toApiDate } from '@data/dto/api.dto';
import { toTrainingCourseEducationalPath } from '@data/dto/training-course-educational-path.dto';
import { CandidateTypeHttp } from '@data/models/candidate-type.interface';
import { CityHttp } from '@data/models/city.interface';
import { CoFunderHttp } from '@data/models/co-funder.interface';
import { CourseTypeHttp } from '@data/models/course-type.interface';
import { CustomerTypeHttp } from '@data/models/customer-type.interface';
import { DeviceTypeHttp } from '@data/models/device-type.interface';
import { ManagerHttp } from '@data/models/manager.interface';
import { MinWageHttp } from '@data/models/min-wage.interface';
import { OpcoChargeHttp } from '@data/models/opco-charge.interface';
import { ProfessionHttp } from '@data/models/profession.interface';
import { ProjectOriginHttp } from '@data/models/project-origin.interface';
import { RegionHttp } from '@data/models/region.interface';
import { StrategicAxisHttp } from '@data/models/strategic-axis.interface';
import { TrainingActionCandidateTrainingHttp } from '@data/models/training-action-candidate-training.interface';
import { TrainingActionTrainingCandidateInvoiceHttp } from '@data/models/training-action-training-candidate-invoice.interface';
import { TrainingCourseCandidateHttp } from '@data/models/training-course-candidate.interface';
import { TrainingCourseCustomerHttp } from '@data/models/training-course-customer.interface';
import { TrainingCourseEducationalPathHttp } from '@data/models/training-course-educational-path.interface';
import {
  EducationalCostHttp,
  EstimatorCandidateTypeHttp,
  TrainingCourseEstimatorHttp,
} from '@data/models/training-course-estimator.interface';
import {
  TrainingCourseGroup,
  TrainingCourseGroupHttp,
} from '@data/models/training-course-group.interface';
import {
  TrainingCourseSessionHttp,
  TrainingCourseSessionType,
} from '@data/models/training-course-session.interface';
import { TrainingCourseTrainingHttp } from '@data/models/training-course-training.interface';
import {
  EditStep,
  TRAINING_COURSE_STATUSES,
  TrainingCourse,
  TrainingCourseHttp,
  TrainingCourseIncluded,
  TrainingCourseRawIncluded,
  TrainingCourseStorage,
  TrainingCourseWizard,
} from '@data/models/training-course.interface';
import { TrainingOptionHttp } from '@data/models/training.interface';
import { User } from '@data/models/user.interface';
import { ValidationTypeHttp } from '@data/models/validation-type.interface';

import { TRAINING_COURSE_PRIORITIES } from '../models/training-course.interface';
import { toApiFloat, toBoolean } from './api.dto';
import { toDeviceType } from './device-type.dto';
import { toManager } from './manager.dto';
import { toProfession } from './profession.dto';
import { toProjectOrigin } from './project-origin.dto';
import { toRegion } from './region.dto';
import { toStrategicAxis } from './strategic-axis.dto';
import { fromTrainingCourseCandidateHttp } from './training-course-candidate.dto';
import { fromTrainingCourseCustomerHttp } from './training-course-customer.dto';
import { fromTrainingCourseEstimatorHttp } from './training-course-estimator.dto';
import {
  fromTrainingCourseGroupHttp,
  toTrainingCourseGroup,
} from './training-course-group.dto';
import { toTrainingCourseSession } from './training-course-session.dto';

export const toTrainingCourseIncluded = (
  included: TrainingCourseRawIncluded = [],
): TrainingCourseIncluded => {
  return {
    groups: included.filter(
      ({ type }) => type === 'training_course_groups',
    ) as TrainingCourseGroupHttp[],
    potentialCandidates: included.filter(
      ({ type }) => type === 'training_course_candidates',
    ) as TrainingCourseCandidateHttp[],
    candidateTypes: included.filter(
      ({ type }) => type === 'candidate_type_repositories',
    ) as CandidateTypeHttp[],
    customers: included.filter(
      ({ type }) => type === 'training_course_customers',
    ) as TrainingCourseCustomerHttp[],
    customerTypes: included.filter(
      ({ type }) => type === 'customer_type_repositories',
    ) as CustomerTypeHttp[],
    strategicAxes: included.filter(
      ({ type }) => type === 'strategic_axis_repositories',
    ) as StrategicAxisHttp[],
    trainings: included.filter(
      ({ type }) => type === 'training_course_trainings',
    ) as TrainingCourseTrainingHttp[],
    educationalPaths: included.filter(
      ({ type }) => type === 'training_course_educational_paths',
    ) as TrainingCourseEducationalPathHttp[],
    professions: included.filter(
      ({ type }) => type === 'profession_repositories',
    ) as ProfessionHttp[],
    projectOrigin: included.filter(
      ({ type }) => type === 'project_origin_repositories',
    ) as ProjectOriginHttp[],
    regions: included.filter(
      ({ type }) => type === 'region_repositories',
    ) as RegionHttp[],
    deviceTypes: included.filter(
      ({ type }) => type === 'device_type_repositories',
    ) as DeviceTypeHttp[],
    managers: included.filter(
      ({ type }) => type === 'manager_repositories',
    ) as ManagerHttp[],
    cpefManagers: included.filter(
      ({ type }) => type === 'manager_repositories',
    ) as ManagerHttp[],
    courseTypes: included.filter(
      ({ type }) => type === 'course_type_repositories',
    ) as CourseTypeHttp[],
    validationTypes: included.filter(
      ({ type }) => type === 'validation_type_repositories',
    ) as ValidationTypeHttp[],
    cities: included.filter(({ type }) => type === 'cities') as CityHttp[],
    options: included.filter(
      ({ type }) => type === 'training_options',
    ) as TrainingOptionHttp[],
    sessions: included.filter(
      ({ type }) => type === 'training_course_sessions',
    ) as TrainingCourseSessionHttp[],
    estimators: included.filter(
      ({ type }) => type === 'training_course_estimators',
    ) as TrainingCourseEstimatorHttp[],
    educationalCosts: included.filter(
      ({ type }) => type === 'estimator_educational_costs',
    ) as EducationalCostHttp[],
    minWages: included.filter(
      ({ type }) => type === 'min_wage_repositories',
    ) as MinWageHttp[],
    opcoCharges: included.filter(
      ({ type }) => type === 'opco_charge_repositories',
    ) as OpcoChargeHttp[],
    estimatorCandidateTypes: included.filter(
      ({ type }) => type === 'estimator_candidate_types',
    ) as EstimatorCandidateTypeHttp[],
    coFunders: included.filter(
      ({ type }) => type === 'co_funder_repositories',
    ) as CoFunderHttp[],
    invoices: included.filter(
      ({ type }) => type === 'training_action_invoices',
    ) as TrainingActionTrainingCandidateInvoiceHttp[],
    candidateTrainings: included.filter(
      ({ type }) => type === 'candidate_trainings',
    ) as TrainingActionCandidateTrainingHttp[],
  };
};

// eslint-disable-next-line complexity
export const toTrainingCourse = (
  { id, attributes, relationships }: Partial<TrainingCourseHttp>,
  user: User,
  included: Partial<TrainingCourseIncluded> = {},
): TrainingCourse => {
  const projectOriginHttp =
    included.projectOrigin?.find(
      ({ id }) => id === relationships?.project_origin.data?.id,
    ) || {};

  const professionHttp =
    included.professions?.find(
      ({ id }) => id === relationships?.profession.data?.id,
    ) || {};

  const regionHttp =
    included.regions?.find(
      ({ id }) => id === relationships?.region?.data?.id,
    ) || {};

  const deviceTypeHttp =
    included.deviceTypes?.find(
      ({ id }) => id === relationships?.device_type?.data?.id,
    ) || {};

  const managerHttp =
    included.managers?.find(
      ({ id }) => id === relationships?.manager?.data?.id,
    ) || {};

  const cpefManagerHttp =
    included.cpefManagers?.find(
      ({ id }) => id === relationships?.cpef_manager?.data?.id,
    ) || {};

  const sessionsHttp = included.sessions?.filter(
    ({ attributes }) =>
      attributes.session_type !== TrainingCourseSessionType.TRAINING,
  );

  const groupsId = relationships?.groups.data?.map(({ id }) => id) || [];

  const groupsHttp =
    included.groups?.filter(({ id }) => groupsId.includes(id)) || [];

  const educationalPathsId =
    relationships?.educational_paths?.data?.map(({ id }) => id) || [];

  const educationalPathsHttp = included.educationalPaths?.filter(({ id }) =>
    educationalPathsId.includes(id),
  ) || [toTrainingCourseEducationalPath({}, {})];

  const candidatesId =
    relationships?.potential_candidates.data?.map(({ id }) => id) || [];

  const candidatesHttp =
    included.potentialCandidates?.filter(({ id }) =>
      candidatesId.includes(id),
    ) || [];

  const estimatorsId =
    relationships?.estimators?.data?.map(({ id }) => id) || [];

  const estimatorsHttp =
    included.estimators?.filter(({ id }) => estimatorsId.includes(id)) || [];

  const customersId = relationships?.customers.data?.map(({ id }) => id) || [];

  const customersHttp =
    included.customers?.filter(({ id }) => customersId.includes(id)) || [];

  const strategicAxesId =
    relationships?.strategic_axes.data?.map(({ id }) => id) || [];

  const strategicAxesHttp =
    included.strategicAxes?.filter(({ id }) => strategicAxesId.includes(id)) ||
    [];

  const profession = toProfession(professionHttp || {});

  return {
    user,
    profession,
    professionId: relationships?.profession.data?.id || '',
    id: id || '',
    status: attributes?.status || TRAINING_COURSE_STATUSES.TODO,
    priority:
      attributes?.priority || TRAINING_COURSE_PRIORITIES.MEDIUM_PRIORITY,
    educationalPaths: educationalPathsHttp.map((educationalPath) =>
      toTrainingCourseEducationalPath(educationalPath, {
        trainings: included.trainings?.filter(
          (training) =>
            training.relationships.educational_path?.data?.id ===
            educationalPath.id,
        ),
        cities: included.cities || [],
        options: included.options || [],
        sessions: included.sessions || [],
        validationTypes: included.validationTypes || [],
        courseTypes: included.courseTypes || [],
      }),
    ),
    reference: attributes?.reference || '',
    deadline: (attributes?.deadline && day(attributes?.deadline)) || undefined,
    projectOriginId: relationships?.project_origin.data?.id || '',
    description: attributes?.description || '',
    otherStrategicAxes: attributes?.other_strategic_axis || '',
    projectOrigin: toProjectOrigin(projectOriginHttp || {}),
    candidates: candidatesHttp.map((candidates) =>
      fromTrainingCourseCandidateHttp(
        candidates,
        included.professions || [],
        included.candidateTypes,
      ),
    ),
    candidatesNumber: attributes?.volume || null,
    unidentifiedCandidates: attributes?.unidentified_candidates || false,
    feesDescription: attributes?.fees_description || '',
    lucieAdRef: attributes?.lucie_advertisement_reference || '',
    hasLucieAd: toBoolean(attributes?.has_lucie_advertisement),
    isRecurringNeed: toBoolean(attributes?.is_recurring_need),
    isNationalNeed: toBoolean(attributes?.is_national_need),
    isLocked: toBoolean(attributes?.is_locked),
    estimators: estimatorsHttp.map((estimator) =>
      fromTrainingCourseEstimatorHttp(estimator, included),
    ),
    customers: customersHttp.map((customer) =>
      fromTrainingCourseCustomerHttp(customer, included.customerTypes),
    ),
    strategicAxes: strategicAxesHttp.map(toStrategicAxis),
    groups:
      (groupsHttp.length && groupsHttp.map(fromTrainingCourseGroupHttp)) ||
      (user.division && [toTrainingCourseGroup(user, user.division)]) ||
      ([] as TrainingCourseGroup[]),
    region: toRegion(regionHttp || {}),
    deviceType: toDeviceType(deviceTypeHttp || {}),
    manager: toManager(managerHttp || {}),
    cpefManager: toManager(cpefManagerHttp || {}),
    createdAt: dayjs(attributes?.createdAt || ''),
    updatedAt: dayjs(attributes?.updatedAt || ''),
    acknowledgedAt: dayjs(attributes?.acknowledged_at || ''),
    isCustomerOptional: (id && customersHttp.length === 0) || false,
    accommodationFees: attributes?.accommodation_fees || '',
    mealFees: attributes?.meal_fees || '',
    totalFees: attributes?.total_fees || '',
    transportFees: attributes?.transport_fees || '',

    interlocutor: attributes?.interlocutor || '',
    sessions: sessionsHttp?.map(toTrainingCourseSession) || [],
    isTeleoperatorTraining:
      profession.isTeleoperator || attributes?.is_teleoperator === 1 || false,
    isEPSPApproach: toBoolean(attributes?.is_epsp_approach),
    cancellationType: attributes?.cancellation_type || null,
    cancellationReason: attributes?.cancellation_reason || null,
    currentStep: attributes?.current_step || EditStep.CONTEXT,
    lockedAt: (attributes?.locked_at && dayjs(attributes.locked_at)) || null,
    deletedAt: (attributes?.deletedAt && dayjs(attributes.deletedAt)) || null,
  };
};

export const fromTrainingCourseStorage = ({
  deadline,
  ...training
}: TrainingCourseStorage): TrainingCourse => {
  return {
    ...training,
    deadline: day(deadline),
    customers: (training.customers || []).map((customer) => ({
      ...customer,
      agreementDate: day(customer.agreementDate),
    })),
    candidates: (training.candidates || []).map((candidate) => ({
      ...candidate,
      birthdayDate: day(candidate.birthdayDate),
    })),
  };
};

export const toTrainingCourseWizard = ({
  id,
  user,
  candidatesNumber,
  candidates,
  deviceType,
  hasLucieAd,
  isRecurringNeed,
  lucieAdRef,
  feesDescription,
  accommodationFees,
  cancellationReason,
  cancellationType,
  transportFees,
  mealFees,
  totalFees,
  description,
  otherStrategicAxes,
  deadline,
  profession,
  projectOrigin,
  manager,
  cpefManager,
  region,
  isNationalNeed,
  strategicAxes,
  interlocutor,
  unidentifiedCandidates,
  isTeleoperatorTraining,
  currentStep,
  status,
  priority,
  deletedAt,
}: TrainingCourse): TrainingCourseWizard => {
  const wizard: TrainingCourseWizard = {
    type: 'training_courses',
    id: id || '',
    attributes: {
      interlocutor,
      user_id: parseInt(user.id),
      volume: candidatesNumber || 1,
      has_lucie_advertisement: hasLucieAd,
      is_recurring_need: isRecurringNeed,
      is_national_need: isNationalNeed,
      description: description,
      other_strategic_axis: otherStrategicAxes,
      deadline: toApiDate(deadline),
      unidentified_candidates: toBoolean(unidentifiedCandidates),
      is_teleoperator: toBoolean(isTeleoperatorTraining),
      is_epsp_approach: candidates.some(({ isEPSPApproach }) => isEPSPApproach),
      current_step: currentStep || EditStep.CONTEXT,
      cancellation_type: cancellationType || null,
      cancellation_reason: cancellationReason || null,
      status: status,
      priority: priority,
      deletedAt: deletedAt,
    },
    relationships: {
      strategic_axes: {
        data: strategicAxes.map((axis) => ({
          id: axis.id || '',
          type: 'strategic_axis_repositories',
        })),
      },
      project_origin: {
        data:
          (projectOrigin?.id && {
            type: 'project_origin_repositories',
            id: projectOrigin.id,
          }) ||
          null,
      },
      profession: {
        data:
          (profession?.id && {
            type: 'profession_repositories',
            id: profession.id,
          }) ||
          null,
      },
    },
  };

  if (hasLucieAd) {
    wizard.attributes.lucie_advertisement_reference = lucieAdRef;
  }

  if (accommodationFees) {
    wizard.attributes.accommodation_fees = toApiFloat(accommodationFees);
    wizard.attributes.transport_fees = toApiFloat(transportFees);
    wizard.attributes.meal_fees = toApiFloat(mealFees);
    wizard.attributes.total_fees = toApiFloat(totalFees);
    wizard.attributes.fees_description = feesDescription;
  }

  if (manager.id) {
    wizard.relationships.manager = {
      data: {
        id: manager.id,
        type: 'manager_repositories',
      },
    };
  }

  if (cpefManager.id) {
    wizard.relationships.cpef_manager = {
      data: {
        id: cpefManager.id,
        type: 'manager_repositories',
      },
    };
  }

  if (region.id) {
    wizard.relationships.region = {
      data: {
        id: region.id,
        type: 'region_repositories',
      },
    };
  }

  if (deviceType.id) {
    wizard.relationships.device_type = {
      data: {
        id: deviceType.id,
        type: 'device_type_repositories',
      },
    };
  }

  return wizard;
};
