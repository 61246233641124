import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { PaginateResult } from '@data/models/api.interface';
import {
  DatatableParams,
  DatatableReorderParams,
} from '@data/models/datatable.interface';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import {
  IColumn,
  TypeColumn,
  TypeDataGridProps,
} from '@inovua/reactdatagrid-enterprise/types';
import { datatable } from '@locales/fr';
import constants from 'constants/constants';
import isEqual from 'lodash/isEqual';

import './index.scss';

interface Props extends Partial<Omit<TypeDataGridProps, 'dataSource'>> {
  columns: TypeColumn[];
  onChange: (params: DatatableParams) => Promise<PaginateResult<unknown[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReorder?: (params: DatatableReorderParams<any>) => void;
}

// eslint-disable-next-line react/display-name
const Datatable = forwardRef(
  ({ onChange, onReorder, ...props }: Props, ref): JSX.Element => {
    const [needReload, setNeedReload] = useState(true);

    const [datatableParams, setDatatableParams] = useState<DatatableParams>({
      limit: 0,
      skip: 0,
      sortInfo: null,
      filterValue: [],
    });

    const [cacheResult, setCacheResult] = useState<PaginateResult<unknown[]>>({
      items: [],
      totalSize: 0,
    });

    useImperativeHandle(ref, () => ({
      reload() {
        setNeedReload(true);
      },
      getData(): PaginateResult<unknown> {
        return cacheResult;
      },
    }));

    const loadData = async (
      params: DatatableParams,
    ): Promise<{ data: unknown[]; count: number }> => {
      if (!needReload && isEqual(params, datatableParams)) {
        return {
          data: cacheResult.items,
          count: cacheResult.totalSize,
        };
      }
      const result = await onChange(params);
      setDatatableParams(params);
      setCacheResult(result);
      setNeedReload(false);

      return {
        data: result.items,
        count: result.totalSize,
      };
    };

    const dataSource = useCallback(loadData, [needReload]);

    const onRowReorder = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ({ data, insertRowIndex }: { data?: any; insertRowIndex?: any }) => {
        if (!onReorder) {
          return;
        }

        const isLastElement = insertRowIndex === cacheResult.items.length;

        const beforeElement =
          cacheResult.items[insertRowIndex - (isLastElement ? 1 : 0)];

        onReorder({
          current: data,
          target: beforeElement,
          position: (isLastElement && 'after') || 'before',
        });
      },
      [cacheResult],
    );

    const sort = Array.isArray(datatableParams.sortInfo)
      ? datatableParams.sortInfo[0]
      : datatableParams.sortInfo;

    const isReorderEnabled = (onReorder &&
      (!sort || sort.id === 'position')) as unknown as IColumn;

    return (
      <ReactDataGrid
        idProperty="id"
        style={{
          minHeight: '100%',
        }}
        licenseKey={constants.System.DATAGRID_LICENCE_KEY}
        dataSource={dataSource}
        pagination
        rowReorderColumn={isReorderEnabled}
        onRowReorder={isReorderEnabled && onRowReorder}
        emptyText={datatable.emptyText}
        loadingText={datatable.loading}
        i18n={datatable}
        {...props}
        material-primary-color
      />
    );
  },
);

export default Datatable;
