let storage: Storage | null = null;

class Storage {
  get<T>(key: string): T | null {
    const value = sessionStorage.getItem(key) || localStorage.getItem(key);
    return (value && JSON.parse(value)) || null;
  }

  set(key: string, value: unknown, permanent: boolean): void {
    const store = (permanent && localStorage) || sessionStorage;
    store.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }
}

export const useStorage = (): Storage => {
  if (storage) {
    return storage;
  }
  storage = new Storage();
  return storage;
};
