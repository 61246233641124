import dayjs from '@_plugins/dayjs';
import { FinancingTypeWizard } from '@data/models/financing-type.interface';
import {
  FinancingType,
  FinancingTypeRawIncluded,
} from '@data/models/financing-type.interface';
import {
  FinancingTypeHttp,
  FinancingTypeIncluded,
} from '@data/models/financing-type.interface';

export const toFinancingTypeIncluded = (
  included: FinancingTypeRawIncluded = [],
): FinancingTypeIncluded => {
  return {
    financingTypes: included.filter(
      ({ type }) => type === 'financing_type_repositories',
    ) as FinancingTypeHttp[],
  };
};

export const toFinancingType = (
  { id, attributes, relationships }: Partial<FinancingTypeHttp>,
  included: Partial<FinancingTypeIncluded>,
): FinancingType => {
  const childrenId = relationships?.children?.data?.map(({ id }) => id) || [];

  const childrenHttp =
    included.financingTypes?.filter(({ id }) => childrenId.includes(id)) || [];

  const childrens = childrenHttp.map((child) =>
    toFinancingType(child, included),
  );
  const rowFinancingType: FinancingType = {
    id: id || '',
    label: attributes?.label || '',
    position: attributes?.position || 0,
    financingType: attributes?.financing_type || null,

    createdAt: dayjs(attributes?.createdAt || ''),
    updatedAt: dayjs(attributes?.updatedAt || ''),
  };

  if (childrens.length) {
    rowFinancingType.childrens = childrens;
  }
  return rowFinancingType;
};

export const toFinancingTypeWizard = ({
  id,
  ...financingType
}: Partial<FinancingType>): FinancingTypeWizard => {
  return {
    id: id || '',
    type: 'financing_type_repositories',
    attributes: {
      label: financingType?.label || '',
      financing_type: Number(financingType.financingType) || null,
    },
  };
};

export const toFinancingTypeChildrenWizard = (
  { id, ...financingType }: Partial<FinancingType>,
  parentId: string,
): FinancingTypeWizard => {
  return {
    id: id || '',
    type: 'financing_type_repositories',
    attributes: {
      label: financingType?.label || '',
      financing_type: Number(financingType.financingType) || null,
    },
    relationships: {
      parent: {
        data: {
          type: 'financing_type_repositories',
          id: parentId,
        },
      },
    },
  };
};
