/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import { FilterParams } from '@data/models/filter.interface';
import { Meta, PaginateResult } from '@data/models/api.interface';
import ErrorService from 'core/error.service';
import { Customer, CustomerHttp } from '@data/models/customer.interface';
import { toCustomer } from '@data/dto/customer.dto';
import ScudApi from '@data/api/scud.api';

@injectable()
export default class CustomerService {
  private API = container.get<ScudApi>(ScudApi);
  private readonly customerEndpoint = '/customer-repositories';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listCustomers(
    filters: FilterParams,
  ): Promise<PaginateResult<Customer[]>> {
    const { data: response, error } = await this.API?.get<{
      data: CustomerHttp[];
      meta: Meta;
    }>(this.customerEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toCustomer) || [],
      totalSize: response?.meta?.page?.total || 0,
    };
  }
}
