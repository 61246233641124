import { Suspense, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  Location,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import {
  getRouteTransitionByPathname,
  getUserRoutes,
} from '@_plugins/router/router';
import PageTransition from '@components/Transition/PageTransition';
import { presets } from '@components/Transition/presets';
import { User } from '@data/models/user.interface';
import Container from '@mui/material/Container';
import Error404 from '@pages/Error/404';

import { Footer } from '.';
import { Loader } from './Loader';

interface Props {
  user: User;
}

const Router = ({ user, t: trans }: Props & WithTranslation): JSX.Element => {
  const [previousLocation, setPreviousLocation] = useState<Location>();
  const [transition, setTransition] =
    useState<keyof typeof presets>('scaleDownScaleUp');

  const location = useLocation();

  const userRoutes = getUserRoutes(user);

  useEffect(() => {
    if (location.pathname === previousLocation?.pathname) {
      return;
    }

    setTransition(
      getRouteTransitionByPathname(
        location.pathname,
        previousLocation?.pathname,
      ),
    );
    setPreviousLocation(location);

    const { name } = userRoutes.find(
      ({ path }) => path === location.pathname,
    ) || {
      name: 'ALEC',
    };
    document.title = `ALEC - ${trans(name)}`;
  }, [location, setTransition, setPreviousLocation]);

  return (
    (user.isAuthenticated && (
      <PageTransition
        preset={transition}
        transitionKey={(previousLocation || location).pathname}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: 'grid',
            minHeight: 'calc(100% - 54px)',
            flexDirection: 'column',
            pb: 2,
          }}
        >
          <Suspense fallback={<Loader />}>
            <Routes location={location}>
              <Route
                path="/"
                element={
                  <Navigate
                    to={userRoutes?.filter(({ hidden }) => !hidden)[0].path}
                  />
                }
              />

              {userRoutes.map((navRoute) => (
                <Route
                  key={navRoute.path}
                  path={navRoute.path}
                  element={navRoute.component || <></>}
                />
              ))}
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Suspense>
        </Container>
        <Footer />
      </PageTransition>
    )) || <></>
  );
};

export default withTranslation()(Router);
