const navigation = {
  'dashboard.navigation.update': 'Journal des mises à jour',
  'dashboard.navigation.delegation': 'Gestion des délégations',
  'dashboard.navigation.actual': 'Groupe actual',
  'dashboard.navigation.lucie': 'Lucie',
  'dashboard.navigation.akto': 'Akto',
};

const stats = {
  'dashboard.stats.projects': 'Projets',
  'dashboard.stats.pending-tooltip':
    'Mes nouveaux dossiers en attente de traitement / L’ensemble des nouveaux dossiers en attente tous gestionnaires confondus.',
  'dashboard.stats.actions': 'Actions',
  'dashboard.stats.in_progress-tooltip':
    'Mes dossiers en cours de traitement / L’ensemble des dossiers en cours tous gestionnaires confondus.',
  'dashboard.stats.to_do': 'A traiter',
  'dashboard.stats.waiting_for_ae': 'En attente AE',
  'dashboard.stats.waiting_for_retour_ae': 'Attente retour AE',
  'dashboard.stats.waiting_for_of': 'En attente OF',
  'dashboard.stats.waiting_for_of_date': 'En attente OF - Date',
  'dashboard.stats.waiting_for_of_convention': 'En attente OF - Convention',
  'dashboard.stats.waiting_for_validation': 'En attente de validation',
  'dashboard.stats.in_progress': 'Formation en cours',
  'dashboard.stats.done': 'Clos',
  'dashboard.stats.to_check': 'Contrat à vérifier',
  'dashboard.stats.cancel': 'Annulé',
  'dashboard.stats.waiting_for_opco': 'En attente OPCO',
  'dashboard.stats.relaunch_opco': 'En relance OPCO',
  'dashboard.stats.complete': 'Dossier complet',
  'dashboard.stats.payroll_control': 'Contrôle paie',
  'dashboard.stats.dispute': 'Litige',
  'dashboard.stats.todo_dr': 'DR à faire',
  'dashboard.stats.scoring': 'Pointage',
  'dashboard.stats.closed': 'Clos',
  'dashboard.stats.cancelled': 'Annulé',
  'dashboard.stats.priority.highest': 'Haute priorité',
  'dashboard.stats.priority.high': 'Prioritaire',
  'dashboard.stats.priority.medium': 'Normal',
  'dashboard.stats.priority.low': 'Basse priorité',
  'dashboard.stats.done-tooltip':
    'Mes dossiers clôturés / L’ensembles des dossiers clôturés tous gestionnaires confondus.',
  'dashboard.stats.cancellation_type.1': 'Doublon/Erreur',
  'dashboard.stats.cancellation_type.2': 'Intérimaire',
  'dashboard.stats.cancellation_type.3': 'Client',
  'dashboard.stats.cancellation_type.4': 'Autre',
  'dashboard.stats.training.cancelled': 'Le dossier à été supprimé',
  'dashboard.stats.cancellation_type.null': '',
  'dashboard.stats.cancellation_type.undefined': '',
};

const notification = {
  'dashboard.notification.welcome': 'Bienvenue',
  'dashboard.notification.notifications': 'Notifications',
  'dashboard.notification.mark-all-as-read': 'Tout marquer comme lu',
  'dashboard.notification.no_notification': 'Aucune nouvelle notification',
  'dashboard.notification.is_training_organism_contacted': 'OF contacté',
};

const graph = {
  'dashboard.graph.title': 'Analyse des demandes',
  'dashboard.graph.type': ' Répartition par type',
  'dashboard.graph.type-tooltip':
    'Évolution de la répartition des dossiers par type de dispositif tous dossiers confondus, représenté par semaines, mois, années.',
  'dashboard.graph.area': ' Répartition par région',
  'dashboard.graph.area-tooltip':
    'Évolution de la répartition des dossiers par région, représenté par semaines, mois, années.',
  'dashboard.graph.week': 'Semaine',
  'dashboard.graph.month': 'Mois',
  'dashboard.graph.year': 'Année',
};
export default {
  ...navigation,
  ...stats,
  ...notification,
  ...graph,
};
