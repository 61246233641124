import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ClearStorage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();

    const dbName = 'erpf';
    const request = indexedDB.open(dbName);
    request.onsuccess = (event: Event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      db.close();
      indexedDB.deleteDatabase(dbName);
    };
    navigate('/');
  }, []);

  return <></>;
};

export default ClearStorage;
