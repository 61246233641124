import day from '@_plugins/dayjs';
import {
  ProjectOrigin,
  ProjectOriginHttp,
  ProjectOriginWizard,
} from '@data/models/project-origin.interface';

export const toProjectOrigin = (
  projectOrigin: Partial<ProjectOriginHttp>,
): ProjectOrigin => {
  return {
    id: projectOrigin.id || '',
    label: projectOrigin.attributes?.label || '',
    createdAt: day(projectOrigin.attributes?.createdAt),
    updatedAt: day(projectOrigin.attributes?.updatedAt),
  };
};

export const toProjectOriginWizard = (
  projectOrigin: Partial<ProjectOrigin>,
): ProjectOriginWizard => {
  return {
    type: 'project_origin_repositories',
    id: projectOrigin.id || '',
    attributes: {
      label: projectOrigin.label || '',
    },
  };
};
