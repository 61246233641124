import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useRoutingInstrumentation from 'react-router-v6-instrumentation';

import Breadcrumb from '@components/Breadcrumb/Breadcrumb';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

/* Sentry */
import * as Sentry from '@sentry/react';
import useStore from '@stores/store';

import Header from './Header';
import Router from './Router';
import TrainingHeader from './TrainingHeader';

const isDevelopment = process.env.VITE_APP_BUILD_ENV !== 'prod';

const Body = () => {
  const { user } = useStore();
  const { pathname } = useLocation();
  const isRepository = pathname.startsWith('/configuration/repositories/');
  const isTraining = pathname.startsWith('/training');

  const routingInstrumentation = useRoutingInstrumentation();

  useEffect(() => {
    if (isDevelopment) {
      return;
    }
    Sentry.init({
      environment: 'production',
      dsn: 'https://0e7503d0a2534ed9927123d5afe5fcb6@o494482.ingest.sentry.io/5932289',
      beforeSendTransaction(event: Sentry.Event) {
        const ignoredTransactions = [
          '/api/v1/user-notifications',
          'v1.user_notifications.index',
        ];
        if (
          event.transaction &&
          ignoredTransactions.includes(event.transaction)
        ) {
          return null;
        }
        return event;
      },
      tracesSampleRate: 0,
    } as Sentry.BrowserOptions);
  }, [routingInstrumentation]);

  if (isRepository) {
    return (
      <>
        <Header />
        <Box display="flex" flexDirection="column" height="100%">
          <Box my={3}>
            <Container maxWidth="xl">
              <Breadcrumb />
            </Container>
          </Box>
          <Box flexGrow={1} position="relative">
            <Router user={user} />
          </Box>
        </Box>
      </>
    );
  }

  if (isTraining) {
    return (
      <>
        <TrainingHeader />
        <Box flexGrow={1} zIndex={1}>
          <Router user={user} />
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <Box display="flex" flexDirection="column" height="100%">
        <Box position="relative" flexGrow={1}>
          <Router user={user} />
        </Box>
      </Box>
    </>
  );
};

export default withTranslation()(Body);
