import { StateCreator } from 'zustand';

export interface LoadingState {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const createLoadingSlice: StateCreator<
  LoadingState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  LoadingState
> = (set) => ({
  isLoading: false,
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
});
