/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import ErrorService from 'core/error.service';
import LaravelAPI from '@data/api/laravel.api';

// data
import {
  toTraining,
  toTrainingIncluded,
  toTrainingOption,
} from '@data/dto/training.dto';
import {
  Training,
  TrainingHttp,
  TrainingIncludedHttp,
  TrainingOption,
  TrainingOptionHttp,
} from '@data/models/training.interface';
import {
  TrainingOutsideCatalog,
  TrainingOutsideCatalogHttp,
} from '@data/models/training-outside-catalog.interface';
import { toTrainingOutsideCatalog } from '@data/dto/training-outside-catalog.dto';
import { Meta, PaginateResult } from '@data/models/api.interface';
import { FilterParams } from '@data/models/filter.interface';

@injectable()
export default class TrainingService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private errorService = container.get<ErrorService>(ErrorService);
  private readonly trainingCourseTrainingsEndpoint =
    '/training-course-trainings';
  private readonly trainingsEndpoint = '/trainings';

  public async listTrainings(
    filters: FilterParams,
  ): Promise<PaginateResult<Training[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingHttp[];
      meta: Meta;
      included: TrainingIncludedHttp;
    }>(this.trainingsEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toTrainingIncluded(response?.included || []);

    return {
      items:
        response?.data?.map((training) => toTraining(training, included)) || [],
      totalSize: response?.data?.length || 0,
    };
  }

  public async listTrainingOptions(
    trainingId: string,
  ): Promise<PaginateResult<TrainingOption[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingOptionHttp[];
      meta: Meta;
    }>(`${this.trainingsEndpoint}/${trainingId}/options`);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toTrainingOption) || [],
      totalSize: response?.meta?.count || 0,
    };
  }
  public async listTrainingsOutsideCatalog(
    filters: FilterParams,
  ): Promise<PaginateResult<TrainingOutsideCatalog[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingOutsideCatalogHttp[];
      meta: Meta;
    }>(this.trainingCourseTrainingsEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toTrainingOutsideCatalog) || [],
      totalSize: response?.meta?.page.total || 0,
    };
  }
}
