import day from '@_plugins/dayjs';
import {
  Blocklist,
  BlocklistHttp,
  BlocklistWizard,
} from '@data/models/blocklist.interface';

export const toBlocklist = (blocklist: Partial<BlocklistHttp>): Blocklist => {
  return {
    id: blocklist.id || '',
    label: blocklist.attributes?.label || '',
    createdAt: day(blocklist.attributes?.createdAt),
    updatedAt: day(blocklist.attributes?.updatedAt),
  };
};

export const toBlocklistWizard = (
  blocklist: Partial<Blocklist>,
): BlocklistWizard => {
  return {
    type: 'blocklist_repositories',
    id: blocklist.id || '',
    attributes: {
      label: blocklist.label || '',
    },
  };
};
