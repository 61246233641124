import { useStorage } from '@_plugins/storage';
import { toUser } from '@data/dto/user.dto';
import { USER_STORAGE, User, UserHttp } from '@data/models/user.interface';
import { StateCreator } from 'zustand';

export interface UserState {
  user: User;
  setUser: (user: User) => void;
}

const storage = useStorage();
const user = toUser(storage.get<UserHttp>(USER_STORAGE) || {});

export const createUserSlice: StateCreator<
  UserState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  UserState
> = (set) => ({
  user,
  setUser: (user: User) =>
    set((state) => ({ user: { ...state.user, ...user } })),
});
