import { Group, GroupHttp } from '@data/models/group.interface';

// eslint-disable-next-line complexity
export const toGroup = (group: Partial<GroupHttp>): Group => {
  return {
    head_office: {
      address: group.attributes?.head_office.address || '',
      address_2: group.attributes?.head_office.address_2 || '',
      city: group.attributes?.head_office.city || '',
      postal_code: group.attributes?.head_office.postal_code || '',
    },
    rcs_city: group.attributes?.rcs_city || '',
    social_capital: group.attributes?.social_capital || '',
    id: group.id || '',
    analytic: {
      code: group.attributes?.analytic_code || '',
      label: group.attributes?.analytic_label || '',
    },
    unit: {
      label: group.attributes?.unit?.label || '',
      code: group.attributes?.unit?.code || '',
    },
    direction: {
      label: group.attributes?.direction?.label || '',
      code: group.attributes?.direction?.code || '',
    },
    operational_agency: {
      phone_number:
        group.attributes?.operational_agency.phone_number ||
        '+33 2 43 01 20 34',
      code: group.attributes?.operational_agency.code || '',
      email: group.attributes?.operational_agency.email || '',
      status: group.attributes?.operational_agency.status || 0,
    },
    company: {
      code: group.attributes?.company.code || '',
      label: group.attributes?.company.label || '',
      legal_form: group.attributes?.company.legal_form || '',
      siren: group.attributes?.company.siren || '',
      siret: group.attributes?.company.siret || '',
    },
    is_user_division: group.attributes?.is_user_division || false,
  };
};

export const fromStoredSearchGroup = ({
  analytic,
}: Pick<Group, 'analytic'>): Group => {
  return {
    head_office: { address: '', address_2: '', city: '', postal_code: '' },
    rcs_city: '',
    social_capital: '',
    id: '',
    analytic: { code: analytic.code, label: analytic.label },
    company: { code: '', label: '', legal_form: '', siren: '', siret: '' },
    direction: { code: '', label: '' },
    is_user_division: false,
    operational_agency: { phone_number: '', code: '', email: '', status: 0 },
    unit: { code: '', label: '' },
  };
};
