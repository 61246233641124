const financing_types = {
  'financing_type.list.title': 'Liste des types de financements',
  'financing_type.action.add': 'Ajouter un type de financement',
  'financing_type.action.edit': 'Modifier un type de financement',
  'financing_type.action.create': 'Créér un type de financement',
  'financing_type.action.updated': 'Le type de financement a été mise à jour',
  'financing_type.action.created': 'Le type de financement a été créé',
  'financing_type.action.deleted': 'Le type de financement a été supprimé',
  'financing_type.props.label': 'Financement',
  'financing_type.select.title.label': 'Intitulé du financement',
  'financing_type.select.title.type': 'Intitulé du sous-type de financement',
  'financing_type.select.list.label': 'Liste des financements',
  'financing_type.props.financingType': 'Type de financement',
  'financing_type.props.financing_type.1': 'OPCO',
  'financing_type.props.financing_type.2': 'Presta Diverses',
  'financing_type.props.financing_type.3': 'COFI',
  'financing_type.props.financing_type.4': 'Aides diverses',
  'financing_type.validation.type': 'Sélectionner un type de financement',
};
const relief_types = {
  'relief_type.list.title': "Liste des types d'allègements",
  'relief_type.action.add': "Ajouter un type d'allègement",
  'relief_type.action.edit': "Modifier un type d'allègement",
  'relief_type.action.create': "Créér un type d'allègements",
  'relief_type.action.updated': "Le type d'allègement a été mise à jour",
  'relief_type.action.created': "Le type d'allègement a été créé",
  'relief_type.action.deleted': "Le type d'allègement a été supprimé",
  'relief_type.props.label': 'Intitulé',
  'relief_type.props.area': 'Type',
  'relief_type.props.training_rate': 'Taux formation',
  'relief_type.props.mission_rate': 'Taux mission',
  'relief_type.action.sort': "L'ordre des types d'allègements a été modifié",
  'relief_type.lodeom': 'LODEOM',
  'relief_type.fillon': 'Fillon',
};

const device_types = {
  'device_type.list.title': 'Liste des types de dispositifs',
  'device_type.action.add': 'Ajouter un type de dispositif',
  'device_type.action.edit': 'Modifier un type de dispositif',
  'device_type.action.create': 'Créér un type de dispositif',
  'device_type.action.updated': 'Le type de dispositif a été mise à jour',
  'device_type.action.created': 'Le type de dispositif a été créé',
  'device_type.action.deleted': 'Le type de dispositif a été supprimé',
  'device_type.props.label': 'Intitulé',
  'device_type.props.initials': 'Initiales',
  'device_type.props.durationMin': 'Durée min.',
  'device_type.props.durationMax': 'Durée max.',
  'device_type.props.description': 'Description',
  'device_type.props.planInitials': 'Initiales plan',
  'device_type.props.planLabel': 'Intitulé du plan',
  'device_type.props.national_agency': 'Agence nationale',
  'device_type.action.sort': "L'ordre des types de dispositifs a été modifié",
  'device_type.props.validation_type': 'Type de validation',
  'device_type.props.validation_type.fixed': 'Durée fixe',
  'device_type.props.validation_type.ratio': 'Prorata durée de formation',
  'device_type.props.validation_type.none': 'Aucun',
};

const activity_fields = {
  'activity_field.list.title': "Liste des domaines d'activités",
  'activity_field.action.add': "Ajouter un domaine d'activité",
  'activity_field.action.edit': "Modifier un domaine d'activité",
  'activity_field.action.create': "Créér un domaine d'activité",
  'activity_field.action.updated': "Le domaine d'activité a été mise à jour",
  'activity_field.action.created': "Le domaine d'activité a été créé",
  'activity_field.action.deleted': "Le domaine d'activité a été supprimé",
  'activity_field.props.label': 'Intitulé',
  'activity_field.action.sort':
    "L'ordre des domaines d'activités a été modifié",
};

const candidate_type = {
  'candidate_type.list.title': 'Liste des publics',
  'candidate_type.action.add': 'Ajouter un public',
  'candidate_type.action.edit': 'Modifier un public',
  'candidate_type.action.create': 'Créér un public',
  'candidate_type.action.updated': 'Le public a été mise à jour',
  'candidate_type.action.created': 'Le public a été créé',
  'candidate_type.action.deleted': 'Le public a été supprimé',
  'candidate_type.props.label': 'Intitulé',
  'candidate_type.props.mapping': 'Champ spécifique',
  'candidate_type.mapping.undefined': 'Aucun',
  'candidate_type.mapping.local_mission': 'Mission locale',
  'candidate_type.mapping.handicapped_candidate': 'Travailleur handicapé',
  'candidate_type.action.sort': "L'ordre des publics a été modifié",
};

const managers = {
  'manager.list.title': 'Liste des utilisateurs',
  'manager.action.add': 'Ajouter un utilisateur',
  'manager.action.edit': 'Modifier un utilisateur',
  'manager.action.create': 'Créer un utilisateur',
  'manager.action.updated': "L'utilisateur a été mis à jour",
  'manager.action.created': "L'utilisateur a été crée",
  'manager.action.deleted': "L'utilsateur a été supprimé",
  'manager.props.avatar': 'Avatar',
  'manager.props.email': 'Email',
  'manager.props.fullName': 'Nom complet',
  'manager.props.firstName': 'Prénom',
  'manager.props.hierarchy': 'Hierarchie',
  'manager.props.initials': 'Initiale',
  'manager.props.lastConnection': 'Dernière connexion',
  'manager.props.lastName': 'Nom',
  'manager.props.otherRole': 'Rôle complémentaire',
  'manager.props.role': 'Rôle',
  'manager.props.service': 'Service',
  'manager.props.title': 'Titre',
  'manager.props.supervisor': 'Manager',
  'manager.props.hasSignature': 'Signature',
  'manager.props.internalRole': 'Rôle interne',
  'manager.props.internal_role.admin': 'Admin',
  'manager.props.internal_role.manager': 'Gestionnaire',
  'manager.props.internal_role.cpef': 'CPEF',
  'user.action.sort': "L'ordre des utilisateurs a été modifié",
};

const min_wages = {
  'min_wage.list.title': 'Liste des SMIC par date',
  'min_wage.action.add': 'Ajouter un SMIC',
  'min_wage.action.edit': 'Modifier un SMIC',
  'min_wage.action.create': 'créér un SMIC',
  'min_wage.action.updated': 'Le SMIC a été mise à jour',
  'min_wage.action.created': 'Le SMIC a été créé',
  'min_wage.action.deleted': 'Le SMIC a été supprimée',
  'min_wage.props.label': 'Intitulé',
  'min_wage.props.from_date': 'A partir du',
  'min_wage.props.rate': 'Taux',
  'min_wage.action.sort': "L'ordre des SMIC a été modifiée",
};

const pec_rates = {
  'pec_rate.list.title': 'Liste des taux PEC par public',
  'pec_rate.action.add': 'Ajouter un taux PEC',
  'pec_rate.action.edit': 'Modifier un taux PEC',
  'pec_rate.action.create': 'créér un taux PEC',
  'min_wage.action.updated': 'Le taux PEC a été mise à jour',
  'pec_rate.action.created': 'Le taux PEC a été créé',
  'pec_rate.action.deleted': 'Le taux PEC a été supprimée',
  'pec_rate.props.label': 'Intitulé',
  'pec_rate.props.rate': 'Taux',
  'pec_rate.props.cofi_rate': 'Taux si cofi',
  'pec_rate.props.device_type': 'Type de dipositif',
  'pec_rate.props.candidate_types': 'Publics',
  'pec_rate.action.sort': "L'ordre des taux PEC a été modifiée",
};

const co_funders = {
  'co_funders.list.title': 'Liste des cofinanceurs',
  'co_funders.action.add': 'Ajouter un cofinanceur',
  'co_funders.action.edit': 'Modifier un cofinanceur',
  'co_funders.action.create': 'Créér un cofinanceur',
  'co_funders.action.updated': 'Le cofinanceur a été mis à jour',
  'co_funders.action.created': 'Le cofinanceur a été créé',
  'co_funders.action.deleted': 'Le cofinanceur a été supprimé',
  'co_funders.props.label': 'Intitulé',
  'co_funders.action.sort': "L'ordre des cofinanceurs a été modifié",
};

const group_migrations = {
  'group_migrations.list.title': 'Liste des changements SIRET',
  'group_migrations.action.add': 'Ajouter un changement SIRET',
  'group_migrations.action.edit': 'Modifier un changement SIRET',
  'group_migrations.action.create': 'Créér un changement SIRET',
  'group_migrations.action.updated': 'Le changement SIRET a été mis à jour',
  'group_migrations.action.created': 'Le changement SIRET a été créé',
  'group_migrations.action.deleted': 'Le changement SIRET a été supprimé',
  'group_migrations.props.legacy_siret': 'Ancien SIRET',
  'group_migrations.props.new_siret': 'Nouveau SIRET',
  'group_migrations.props.from_date': 'A partir de',
  'group_migrations.props.training_courses': 'Exclure les dossiers',
};

const project_origins = {
  'project_origin.list.title': 'Liste des origines du projet',
  'project_origin.action.add': 'Ajouter une origine',
  'project_origin.action.edit': 'Modifier une origine',
  'project_origin.action.create': 'Créér une origine',
  'project_origin.action.updated': "L'origine a été mise à jour",
  'project_origin.action.created': "L'origine a été créée",
  'project_origin.action.deleted': "L'origine a été supprimée",
  'project_origin.props.label': 'Intitulé',
  'project_origin.action.sort': "L'ordre des origines a été modifié",
};

const regions = {
  'region.list.title': 'Liste des régions Actual',
  'region.action.add': 'Ajouter une région',
  'region.action.edit': 'Modifier une région',
  'region.action.create': 'Créér une région',
  'region.action.updated': 'La région a été mise à jour',
  'region.action.created': 'La région a été créé',
  'region.action.deleted': 'La région a été supprimée',
  'region.props.name': 'Régions Actual',
  'region.props.initials': 'Initiales',
  'region.props.director': 'Directeur Régional',
  'region.props.manager': 'Gestionnaire',
  'region.props.direction_code': 'Code direction',
  'region.action.sort': "L'ordre des régions a été modifié",
};

const strategic_axis = {
  'strategic_axis.list.title': 'Liste des axes stratégiques',
  'strategic_axis.action.add': 'Ajouter un axe stratégique',
  'strategic_axis.action.edit': 'Modifier un axe stratégique',
  'strategic_axis.action.create': 'Créér un axe stratégique',
  'strategic_axis.action.updated': "L'axe stratégique a été mise à jour",
  'strategic_axis.action.created': "L'axe stratégique a été créé",
  'strategic_axis.action.deleted': "L'axe stratégique a été supprimé",
  'strategic_axis.props.label': 'Intitulé',
  'strategic_axis.action.sort': "L'ordre des axes stratégiques a été modifié",
};

const statistics = {
  'statistics.list.title': 'Statistiques',
  'statistics.list.total': '{{total}} dossiers en cours',
  'statistics.average.communications': 'Moyenne des communications par dossier',
  'statistics.average.documents': 'Moyenne des documents par dossier',
  'statistics.average.candidates': 'Moyenne des candidats par dossier',
  'statistics.average.customers': 'Moyenne des clients par dossier',
  'statistics.average.estimators': 'Moyenne des deviseurs par dossier',
  'statistics.average.groups': 'Moyenne des agences par dossier',
  'statistics.average.sessions': 'Moyenne des sessions par dossier',
  'statistics.average.strategicAxes':
    'Moyenne des axes stratégiques par dossier',
  'statistics.average.trainings': 'Moyenne des formations par dossier',
  'statistics.total.communications': 'Total des communications',
  'statistics.total.documents': 'Total des documents',
  'statistics.total.candidates': 'Total des candidats',
  'statistics.total.customers': 'Total des clients',
  'statistics.total.estimators': 'Total des deviseurs',
  'statistics.total.groups': 'Total des agences',
  'statistics.total.sessions': 'Total des sessions',
  'statistics.total.strategicAxes': 'Total des axes stratégiques',
  'statistics.total.trainings': 'Total des formations',
};

const customer_type = {
  'customer_type.list.title': 'Liste des types de clients',
  'customer_type.action.add': 'Ajouter un type de client',
  'customer_type.action.edit': 'Modifier un type de compte',
  'customer_type.action.create': 'Créér un type de compte',
  'customer_type.action.updated': 'Le type de compte a été mise à jour',
  'customer_type.action.created': 'Le type de compte a été créé',
  'customer_type.action.deleted': 'Le type de compte a été supprimé',
  'customer_type.props.label': 'Intitulé',
  'customer_type.props.is_important_customer': 'Grand compte',
  'customer_type.props.is_important_customer_help':
    'Permet d\'activer les champs "Accord" et "RFA" sur la dernière étape du formulaire de demande de formation',
  'customer_type.action.sort': "L'ordre des types de comptes a été modifié",
};

const validation_type = {
  'validation_type.list.title': 'Liste des types de validation',
  'validation_type.action.add': 'Ajouter un type de validation',
  'validation_type.action.edit': 'Modifier un type de validation',
  'validation_type.action.create': 'Créér un type de validation',
  'validation_type.action.updated': 'Le type de validation a été mise à jour',
  'validation_type.action.created': 'Le type de validation a été créé',
  'validation_type.action.deleted': 'Le type de validation a été supprimé',
  'validation_type.props.label': 'Intitulé',
  'validation_type.props.is_ccn': 'CCN ',
  'validation_type.action.sort':
    "L'ordre des types de validation a été modifié",
};

const course_type = {
  'course_type.list.title': 'Liste des types de parcours',
  'course_type.action.add': 'Ajouter un type de parcour',
  'course_type.action.edit': 'Modifier un type de parcour',
  'course_type.action.create': 'Créér un type de parcour',
  'course_type.action.updated': 'Le type de parcour a été mise à jour',
  'course_type.action.created': 'Le type de parcour a été créé',
  'course_type.action.deleted': 'Le type de parcour a été supprimé',
  'course_type.props.label': 'Intitulé',
  'course_type.action.sort': "L'ordre des types de parcour a été modifié",
};

const training_outside_catalog = {
  'training-outside-catalog.list.title': 'Liste des formations hors catalogue',
  'training-outside-catalog.props.label': 'Formation',
  'training-outside-catalog.props.reference': 'Référence',
};

const control_organization = {
  'control_organization.list.title': 'Liste des organismes de suivi',
  'control_organization.action.add': 'Ajouter un organisme de suivi',
  'control_organization.action.edit': 'Modifier un organisme de suivi',
  'control_organization.action.create': 'Créér un organisme de suivi',
  'control_organization.action.updated':
    "L'organisme de suivi a été mise à jour",
  'control_organization.action.created': "L'organisme de suivi a été créé",
  'control_organization.action.deleted': "L'organisme de suivi a été supprimé",
  'control_organization.props.label': 'Intitulé',
  'control_organization.action.sort':
    "L'ordre des organismes de suivi a été modifié",
};

const blocklist = {
  'blocklist.list.title': 'Liste des blocklists',
  'blocklist.action.add': 'Ajouter une valeur',
  'blocklist.action.edit': 'Modifier une valeur',
  'blocklist.action.create': 'Créér une valeur',
  'blocklist.action.updated': 'La valeur a été mise à jour',
  'blocklist.action.created': 'La valeur a été créée',
  'blocklist.action.deleted': 'La valeur a été supprimée',
  'blocklist.props.label': 'Intitulé',
  'blocklist.action.sort': "L'ordre des valeurs a été modifiée",
};

const opco_charge = {
  'opco_charge.list.title': 'Liste des taux de charges OPCO par année',
  'opco_charge.action.add': 'Ajouter une charge',
  'opco_charge.action.edit': 'Modifier une charge',
  'opco_charge.action.create': 'Créér une charge OPCO',
  'opco_charge.action.updated': 'La charge a été mise à jour',
  'opco_charge.action.created': 'La charge a été créée',
  'opco_charge.action.deleted': 'La charge a été supprimée',
  'opco_charge.props.label': 'Intitulé',
  'opco_charge.props.rate': 'Taux',
  'opco_charge.props.year': 'Année',
  'opco_charge.action.sort': "L'ordre des charges a été modifié",
};

const activity = {
  'activity.props.id': 'Id',
  'activity.props.user_id': 'N° utilisateur',
  'activity.props.volume': 'Nombre de candidats',
  'activity.props.is_locked': 'Verrouillé',
  'activity.props.has_lucie_advertisement':
    'Une annonce a été publiée dans Lucie',
  'activity.props.is_recurring_need': 'Besoin récurrent',
  'activity.props.is_national_need':
    'Porter le dossier de formation sur Agence nationale',
  'activity.props.unidentified_candidates': 'Stagiaires non identifiés',
  'activity.props.lucie_advertisement_reference': 'Référence du besoin ',
  'activity.props.fees_description': 'Explications des frais',
  'activity.props.description': 'Description',
  'activity.props.other_strategic_axis': 'Autre axes stratégiques',
  'activity.props.interlocutor': 'Interlocuteur',
  'activity.props.reference': 'Référence',
  'activity.props.deadline': 'Deadline',
  'activity.props.accommodation_fees': 'Montant hébergement',
  'activity.props.transport_fees': 'Montant transport',
  'activity.props.meal_fees': 'Montant repas',
  'activity.props.total_fees': 'Total des frais',
  'activity.props.createdAt': 'Crée le',
  'activity.props.updatedAt': 'Mise à jour le',
  'activity.props.acknowledged_at': 'Pris connaissance le',
  'activity.props.manager': 'Gestionnaire',
  'activity.props.status': 'Statut',
  'activity.props.external_training_duration': 'Durée formation externe',
  'activity.props.practical_training_duration': 'Durée formation pratique',
  'activity.props.validation_coefficient': 'Coef. validation',
  'activity.props.validation_description':
    'Précisez, si qualification CCN, laquelle',
  'activity.props.validation_level': 'Niveau',
  'activity.props.is_teleoperator': 'Téléopérateur ',
  'activity.props.is_epsp_approach': 'Besoin détecté lors d’un EPSP',
  'activity.props.positioning_review_duration': 'Durée bilan de positionnement',
  'activity.props.positioning_review_cost': 'Coût bilan de positionnement',
  'activity.props.positioning_review_organization_name':
    "Nom de l'organisation bilan comp.",
  'activity.props.current_step': 'Etape courante',
  'activity.props.is_rfa': 'RFA ? ',
  'activity.props.has_negotiation_marge': 'Marge de négociation possible',
  'activity.props.name': 'Nom',
  'activity.props.siret': 'SIRET',
  'activity.props.rfa_rate': 'Taux RFA',
  'activity.props.management_rate': 'Taux gestion',
  'activity.props.delegation_rate': 'Taux délégation',
  'activity.props.mission_duration_in_months': 'Durée mission en mois',
  'activity.props.mission_duration_in_hours': 'Durée mission en heures',
  'activity.props.agreement_number': 'N° accord cadre',
  'activity.props.agreement_end_date': "Date de fin d'accord",
  'activity.props.registration_number': 'Numéro ',
  'activity.props.analytic_code': 'Code analytic',
  'activity.props.analytic_label': 'Libellé analytic',
  'activity.props.direction_code': 'Code de direction',
  'activity.props.direction_label': 'Libellé de direction',
  'activity.props.operational_agency_phone_number': 'Téléphone agence',
  'activity.props.recruiter_full_name': 'Nom recruteur',
  'activity.props.recruiter_email': 'Email recruteur',
  'activity.props.recruiter_phone_number': 'Téléphone recruteur',
  'activity.props.is_main_group': 'Agence principale',
  'activity.props.company_code': 'Code société',
  'activity.props.company_label': 'Nom de la société',
  'activity.props.lucie_id': 'ID Lucie',
  'activity.props.age': 'Age',
  'activity.props.birthday_date': 'Date de naissance',
  'activity.props.lucie_reference': 'Référence Lucie',
  'activity.props.first_name': 'Prénom',
  'activity.props.last_name': 'Nom',
  'activity.props.full_name': 'Nom complet',
  'activity.props.address': 'Adresse',
  'activity.props.city': 'Ville',
  'activity.props.postal_code': 'Code postal',
  'activity.props.country': 'Pays',
  'activity.props.followed_by_pole_emploi': 'Suivi par Pôle emploi',
  'activity.props.followed_by_mission_locale': 'Suivi par mission locale',
  'activity.props.has_interim_experience': "Expérience dans l'intérim",
  'activity.props.is_a2i': 'Intérimaires ETTi',
  'activity.props.is_cdii': 'CDI I',
  'activity.props.is_rqth': 'RQTH',
  'activity.props.open_cpf_account': "Ouverture d'un compte CPF",
  'activity.props.has_competences_diagnostic': "Réalisation Diag'Compétences",
  'activity.props.cpf_amount': 'Droits CPF acquis en Euros',
  'activity.props.mission_rate': 'Taux horaire mission',
  'activity.props.training_course_rate': 'Taux horaire formation',
  'activity.props.interim_experience_hours': 'Nombre heures exp. intérim',
  'activity.props.emails': 'Emails',
  'activity.props.phones': 'Numéros de téléphone',
  'activity.props.label': 'Intitulé',
  'activity.props.preconization_reason':
    'Un organisme de formation a déjà été contacté ',
  'activity.props.skill_level': "Niveau d'expérience",
  'activity.props.prescriber': 'Par qui',
  'activity.props.organization_name': 'Nom société',
  'activity.props.is_out_of_catalog': 'Hors catalogue',
  'activity.props.is_akto': 'Référencement AKTO',
  'activity.props.is_datadock': 'Datadock',
  'activity.props.is_qualiopi': 'Qualiopi',
  'activity.props.has_quote': 'Devis déjà proposé',
  'activity.props.duration': 'Durée',
  'activity.props.invoice_type': 'Type de facturation',
  'activity.props.cost': 'Coût',
  'activity.props.candidates_number': 'Nombre candidats',
  'activity.props.educational_costs_min_duration': 'Durée formation min.',
  'activity.props.educational_costs_max_duration': 'Durée formation max.',
  'activity.props.educational_costs_total_duration': 'Durée totale formation',
  'activity.props.educational_costs_amount_per_candidate':
    'Montant formation par candidat',
  'activity.props.educational_costs_amount_per_hour':
    'Montant formation par heure',
  'activity.props.educational_costs_practical_duration':
    'Durée formation pratique',
  'activity.props.has_additional_costs': 'Frais annexes',
  'activity.props.additional_costs_total_amount': 'Total frais annexe',
  'activity.props.mission_average_rate': 'Taux horaire mission moyen',
  'activity.props.training_course_average_rate': 'Taux horaire formation moyen',
  'activity.props.classic_trainee_number': 'Stagiaire "classique"',
  'activity.props.classic_trainee_pec': 'Taux PEC Stagiaire "classique"',
  'activity.props.local_mission_trainee_number':
    'Stagiaire éligible Mission Locale',
  'activity.props.local_mission_trainee_pec':
    'Taux PEC Stagiaire éligible Mission Locale',
  'activity.props.handicapped_trainee_number': 'Travailleur handicapé',
  'activity.props.handicapped_trainee_pec': 'Taux PEC Travailleur handicapé',
  'activity.props.refugee_trainee_number': 'Réfugié (HOPE)',
  'activity.props.refugee_trainee_pec': 'Taux PEC Réfugié (HOPE)',
  'activity.props.skilled_trainee_number': 'Langues et Compétences',
  'activity.props.skilled_trainee_pec': 'Taux PEC Langues et Compétences',
  'activity.props.poec_trainee_number': 'Demandeurs d’emploi POEC',
  'activity.props.poec_trainee_pec': 'Taux PEC Demandeurs d’emploi POEC',
  'activity.props.afpr_trainee_number': 'Demandeurs d’emploi AFPR',
  'activity.props.afpr_trainee_pec': 'Taux PEC Demandeurs d’emploi AFPR',
  'activity.props.senior_trainee_number': 'dont Stagiaire +45 ans',
  'activity.props.a2i_trainee_number': 'dont Public A2i',
  'activity.props.cdii_trainee_number': 'dont CDI I',
  'activity.props.average_pec_rate': 'Moyenne taux PEC',
  'activity.props.ddc_duration_in_months': 'Durée DDC en mois',
  'activity.props.ddc_total_mission_duration_in_months': 'Durée mission DDC',
  'activity.props.ddc_training_duration_per_month':
    'Durée formation par mois DDC',
  'activity.props.ddc_complementary_training_duration_in_months':
    'Complément formation DDC',
  'activity.props.ddc_training_duration_in_days': 'Durée DDC jours',
  'activity.props.ddc_training_duration_in_hours': 'Durée DDC heures',
  'activity.props.ddc_is_valid_mission_duration':
    'Respect durée de mission minimum liée au dispositif',
  'activity.props.ddc_training_mission_ratio':
    'Respect % Formation // mission (CPi)',
  'activity.props.has_external_co_funding': 'COFI',
  'activity.props.external_co_funding_supported_amount':
    'Montant supporté financement extérieur',
  'activity.props.has_financial_aid': 'Aide diverses (Pôle Emploi, état...)',
  'activity.props.financial_aid_supported_amount': 'Montant aide diverses',
  'activity.props.customer_name': 'Nom client',
  'activity.props.customer_management_rate': 'Coefficient de gestion habituel',
  'activity.props.customer_delegation_rate':
    'Coefficient de délégation habituel',
  'activity.props.sourcing_amount': 'Sourcing',
  'activity.props.re_invoicing_cost_rate': 'Refacturation Frais Annexes',
  'activity.props.re_invoicing_cost_amount':
    'Montant refacturation frais annexe',
  'activity.props.re_invoicing_wage_amount': 'Refacturation Salaire formation',
  'activity.props.re_invoicing_wage_rate':
    'Taux refacturation salaire formation',
  'activity.props.re_invoicing_extra_cost_rate':
    'Taux refacturation surcoût formation',
  'activity.props.re_invoicing_extra_cost_amount':
    'Refacturation Surcoût formation',
  'activity.props.engineering_cost': 'Frais d’ingéniérie formation',
  'activity.props.recruitment_cost': 'Prestation Recrutement/Placement',
  'activity.props.management_fees': 'Frais de gestion/Pilotage',
  'activity.props.total_invoice_amount': 'Total facturations diverses',
  'activity.props.additional_delegation_in_months':
    'Complément délégation POST-FORMATION (durée en mois)',
  'activity.props.average_coefficient_on_mission_hours': 'Heures mission',
  'activity.props.delegation_billing_amount': 'Facturation Délégation',
  'activity.props.delegation_billing_post_training_amount':
    'Facturation Délégation Post Formation',
  'activity.props.delegation_billing_post_training_hours':
    'Facturation Délégation Post Formation en heures',
  'activity.props.average_coefficient_on_mission_and_training_hours':
    'Heures mission formation',
  'activity.props.coefficient_delegation_evolution':
    'Evolution du coef délégation (%)',
  'activity.props.total_training_expenses_per_trainee':
    'Total dépenses formation',
  'activity.props.total_training_expenses_per_group':
    'Total dépenses formation du groupe',
  'activity.props.total_training_revenue_per_trainee':
    'Total recettes formation',
  'activity.props.total_training_revenue_per_group': 'Total dépenses du groupe',
  'activity.props.opco_refund': 'Remboursement OPCO',
  'activity.props.training_type': 'Type de formation',
  'activity.props.training_assessment_amount': 'Bilan',
  'activity.props.external_training_amount': 'F° externe',
  'activity.props.practical_training_amount': 'F° pratique',
  'activity.props.turn_over_per_trainee': 'CA Projet par candidat',
  'activity.props.turn_over_per_group': 'CA Projet',
  'activity.props.turn_over_in_months_per_trainee': 'CA mensuel par candidat',
  'activity.props.turn_over_in_months_per_group': 'CA mensuel',
  'activity.props.gross_margin_per_trainee_with_end':
    'Marge brute par candidat avec Fin+',
  'activity.props.gross_margin_per_trainee_without_end':
    'Marge brute par candidat avec Fin+',
  'activity.props.gross_margin_target_amount':
    'Objectif minimum marge brute à atteindre (50% du surcoût)',
  'activity.props.gross_margin_in_months_per_trainee_with_end':
    'Marge brute mensuelle (stagiaire) avec Fin +',
  'activity.props.gross_margin_in_months_per_trainee_without_end':
    'Marge brute mensuelle (stagiaire) sans Fin +',
  'activity.props.gross_margin_rate_with_end': 'Marge brute avec Fin+',
  'activity.props.gross_margin_rate_without_end': 'Marge brute sans Fin+',
  'activity.props.gross_margin_rate_against_extra_cost_with_end':
    'Taux de marge brute vs surcoût (stagiaire) avec Fin +',
  'activity.props.gross_margin_rate_against_extra_cost_without_end':
    'Taux de marge brute vs surcoût (stagiaire) sans Fin +',
  'activity.props.total_gross_margin': 'Marge brute',
  'activity.props.training_cost_with_cofi_amount':
    'Total recette formation avec cofi et aide PE',
  'activity.props.training_cost_without_cofi_amount':
    'Total recette formation sans cofi et aide PE',
  'activity.props.total_mission_revenue': 'Total recettes mission',
  'activity.props.total_training_relief_cost': 'Allègements',
  'activity.props.total_mission_relief_cost': 'Allègements',
  'activity.props.etti_agreement_amount': 'Conventionnement ETTi',
  'activity.props.etti_agreement_post_training_amount':
    'Conventionnement ETTi Post formation',
  'activity.props.ifm_gains_amount': 'Gains IFM',
  'activity.props.ifm_gains_post_training_amount': 'Gains IFM Post formation',
  'activity.props.total_post_training_revenue':
    'Total recettes complément mission',
  'activity.props.total_post_training_relief_cost':
    'Allègements post formation',
  'activity.props.mission_wage_expenses': 'Salaires',
  'activity.props.mission_rfa_expenses': 'RFA',
  'activity.props.mission_total_expenses': 'Total dépenses mission',
  'activity.props.post_training_wage_expenses': 'Salaires post formation',
  'activity.props.post_training_rfa_expenses': 'RFA Post formation',
  'activity.props.post_training_total_expenses':
    'Total dépenses Post formation',
  'activity.props.invoice_rate_against_extra_costs': 'Taux MB vs Surcoût',
  'activity.props.break_even_with_end_amount':
    'Coef. seuil rentabilité avec Fin +',
  'activity.props.break_even_without_end_amount':
    'Coef. seuil rentabilité sans Fin +',
  'activity.props.total_wages_amount': 'Salaires',
  'activity.props.total_mission_duration_in_months': 'Durée mission en mois',
  'activity.props.educational_costs_amount_group': 'Montant coût formation',
  'activity.props.ddc_training_duration_in_months':
    'Durée formation DDC en mois',
  'activity.props.manager.full_name': 'Manager',
  'activity.props.cpef_manager.full_name': 'CPEF',
  'activity.props.device_type.label': 'Type de dispositif',
  'activity.props.deviceType.label': 'Type de dispositif',
  'activity.props.project_origin.label': 'Origine du projet',
  'activity.props.profession.label': 'Profession',
  'activity.props.projectOrigin.label': 'Origine du projet',
  'activity.props.type': 'Type',
  'activity.props.training.label': 'Formation',
  'activity.props.expectedCity.name': 'Lieu souhaité',
  'activity.props.city.name': 'Lieu',
  'activity.props.customerType.label': 'Type de client',
  'activity.props.approver.full_name': 'Approuvé par',
  'activity.props.deviceType.initials': 'Type de dispositif',
  'activity.props.opcoCharge.label': 'Charge OPCO',
  'activity.props.minWage.label': 'SMIC',
  'activity.props.reliefType.label': "Type d'allègement",
  'activity.props.validationType.label': 'Type de validation',
  'activity.props.document_type': 'Type de documents',
  'activity.props.subject': 'Sujet',
  'activity.props.transmitter': 'Emetteur',
  'activity.props.updated_by': 'Mise à jour par',
  'activity.props.version_count': 'Nombre versions',
  'activity.props.recipient': 'Destinataire',
  'activity.props.user_email': 'Email',
  'activity.props.created_by': 'Crée par',
  'activity.props.document.name': 'Document',
  'activity.props.training_action_id': 'Action de formation',
  'activity.props.locked_at': 'Vérrouillé le',
  'activity.props.comment': 'Commentaire',
  'activity.props.sent_at': 'Envoyé le',
  'activity.props.refund_at': 'Remboursé le',
  'activity.props.received_at': 'Reçu le',
  'activity.props.validated_at': 'Validé le',
  'activity.props.parent.label': 'Catégorie',
  'activity.props.checked': 'Coché',
  'activity.props.total_expected_duration': 'Durées prévisionnelles',
  'activity.props.external_training_expected_duration':
    'Durées formations externes prévisionnelles',
  'activity.props.external_expected_duration': 'Durées formations externes',
  'activity.props.total_paid_duration': 'Durées payées',
  'activity.props.total_real_duration': 'Durées réalisées',
  'activity.props.positioning_report_paid_duration':
    'Durée bilan positionnement payée',
  'activity.props.positioning_report_real_duration':
    'Durée bilan positionnement réalisées',
  'activity.props.to_date': 'Au',
  'activity.props.from_date': 'Du',
  'activity.props.external_training_real_duration':
    'Durées formations externes réalisées',
  'activity.props.educational_real_cost': 'Coûts pédagogique réalisés',
  'activity.props.external_real_duration':
    'Durées formations externes réalisées',
  'activity.props.total_real_cost': 'Coûts réalisés',
  'activity.props.is_hour_cost': "Coût à l'heure ? ",
  'activity.props.total_amount': 'Montant total',
  'activity.props.total_amount_per_candidate': 'Montant par candidat',
  'activity.props.hours': "Nombre d'heures",
  'activity.props.hour_rate': 'Taux horaire',
  'activity.props.wage_real_cost': 'Coût salaires formation réalisés',
  'activity.props.wage_expected_cost': 'Coût salaires formation prévisionnels',
  'activity.props.total_expected_cost': 'Coûts réalisés',
  'activity.props.practical_training_real_duration':
    'Durée formation pratique réalisées',
  'activity.props.practical_training_expected_duration':
    'Durée formation pratique prévisionnels',
  'activity.props.practical_real_duration':
    'Durée formation pratique réalisées',
  'activity.props.practical_expected_duration':
    'Durée formation pratique prévisionnels',
  'activity.props.meal_real_fees': 'Restauration réalisée',
  'activity.props.meal_expected_fees': 'Restauration prévisionnelle',
  'activity.props.total_real_fees': 'Frais annexes réalisés',
  'activity.props.total_expected_fees': 'Frais annexes prévisionnelle',
  'activity.props.accommodation_real_fees': 'Restauration prévisionnelle',
  'activity.props.accomodation_real_fees': 'Hébergement réalisée',
  'activity.props.accommodation_expected_fees': 'Hébergement prévisionnel',
  'activity.props.transport_real_fees': 'Transport réalisée',
  'activity.props.transport_expected_fees': 'Transport prévisionnel',
  'activity.props.period_type': 'Type de période',
  'activity.props.expected_duration': 'Durée prévue',
  'activity.props.current_profession': 'Profession',
  'activity.props.rcs_city': 'Ville RCS',
  'activity.props.legal_form': 'Forme juridique',
  'activity.props.position': 'Position',
  'activity.props.siren': 'SIREN',
  'activity.props.social_capital': 'Capital social',
  'activity.props.head_office_city': 'Ville du siège social',
  'activity.props.head_office_address': 'Adresse du siège social',
  'activity.props.head_office_address_2': 'Adresse du siège social 2',
  'activity.props.head_office_postal_code': 'Code postal du siège social',
  'activity.props.operational_agency_code': "Code postal de l'agence",
  'activity.props.content': 'Message',
  'activity.props.sender_name': 'Nom Expéditeur',
  'activity.props.sender_mail': 'Mail Expéditeur',
  'activity.props.positioning_report_expected_duration':
    'Durée bilan positionnement prévisionnels',
  'activity.props.strategicAxes': 'Axes stratégiques',
  'activity.props.trainingOptions': 'Options',
  'activity.props.read_at': 'Lu le',
  'activity.props.is_agency_charge': 'Charge agence',
};

const delegation = {
  'delegation.delegate': 'Délégataire',
  'delegation.representative': 'Délégué',
  'delegation.create_delegation': 'Créer une délégation',
  'delegation.edit_delegation': 'Modifier une délégation',
  'delegation.delegation_management': 'Gestion des délégations',
  'delegation.no_delegation': 'Aucune délégation active',
  'delegation.regions': 'Région(s)',
  'delegation.period': 'Période',
  'delegation.form.error_date_from': 'La date de début est requise',
  'delegation.form.error_date_to': 'La date de fin est requise',
  'delegation.form.error_regions': 'Le champ doit contenir une region minimum',
  'delegation.form.error_motif': 'Le motif est requis',
  'delegation.form.error_delegatary': 'Le délégataire est requis',
  'delegation.form.error_delegation-representative': 'Le délégué est requis',
  'delegation.form.error_different-manager':
    'Le délégué ne peut pas être le même que le délegataire',
  'delegation.action_confirm_delete':
    'Lorsque vous cliquez sur “Confirmer”, la délégation sera définitivement supprimée.',
  'delegation.action_deleted': 'Votre délégation à bien été supprimée',
  'delegation.action_edit': 'Votre délégation à bien été modifiée',
  'delegation.action_created': 'Votre délégation à bien été crée',
  'delegation.active_delegation_msg':
    "Vous possédez une délégation de l'utilisateur {{user}} pour la période du {{fromDate}} au {{toDate}}",
};

export default {
  ...project_origins,
  ...regions,
  ...activity_fields,
  ...co_funders,
  ...device_types,
  ...min_wages,
  ...relief_types,
  ...strategic_axis,
  ...statistics,
  ...customer_type,
  ...validation_type,
  ...course_type,
  ...training_outside_catalog,
  ...control_organization,
  ...blocklist,
  ...managers,
  ...opco_charge,
  ...pec_rates,
  ...candidate_type,
  ...activity,
  ...group_migrations,
  ...financing_types,
  ...delegation,
};
