import { injectable } from 'inversify';
import { CallbackDoc } from 'react-google-drive-picker/dist/typeDefs';

@injectable()
export default class GCloudService {
  private EXPORT_TYPE_MAP = {
    document: 'application/pdf',
    drawing: 'image/png',
    presentation: 'application/pdf',
    script: 'application/vnd.google-apps.script+json',
    spreadsheet: 'application/pdf',
  };

  private getMimeType({ mimeType }: CallbackDoc): string {
    const typeSplit = mimeType.split('.');
    const type = typeSplit[typeSplit.length - 1].toLowerCase() as 'document';

    return this.EXPORT_TYPE_MAP[type];
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public async downloadFile(
    file: CallbackDoc,
    accessToken: string,
  ): Promise<File | null> {
    const isDoc = file.type.toLowerCase() === 'document';

    const mimeType = isDoc && this.getMimeType(file);

    if (isDoc && !mimeType) {
      // eslint-disable-next-line no-console
      console.warn(
        `No corresponding mime type for selected file type (${file.mimeType})`,
      );

      return new Promise((res) => res(null));
    }

    const FILE_URL = 'https://www.googleapis.com/drive/v3/files';

    const url = isDoc
      ? `${FILE_URL}/${file.id}/export?mimeType=${String(mimeType)}`
      : `${FILE_URL}/${file.id}?alt=media&mimeType=${file.mimeType}`;

    return fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => res.blob())
      .then(
        (blob) =>
          new File([blob], file.name, {
            type: mimeType || file.mimeType,
          }),
      );
  }
}
