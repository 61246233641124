/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';

// api
import LaravelAPI from '@data/api/laravel.api';

// services
import ErrorService from 'core/error.service';

// data
import { Meta, PaginateResult } from '@data/models/api.interface';
import { FilterParams } from '@data/models/filter.interface';
import {
  DocumentVersion,
  DocumentVersionHttp,
  DocumentVersionWizard,
} from '@data/models/document-version.interface';
import { toDocumentVersion } from '@data/dto/document-version.dto';
import FileAPI from '@data/api/file.api';

@injectable()
export default class DocumentVersionService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private FileAPI = container.get<FileAPI>(FileAPI);

  private readonly documentVersionEndpoint = '/document-versions';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listDocumentVersions(
    filters: FilterParams,
  ): Promise<PaginateResult<DocumentVersion[]>> {
    const { data: response, error } = await this.API?.get<{
      data: DocumentVersionHttp[];
      meta?: Meta;
    }>(this.documentVersionEndpoint, {
      ...filters,
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const items =
      response?.data?.map((documentVersion) =>
        toDocumentVersion(documentVersion),
      ) || [];

    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async getDocumentVersion(
    documentVersionId: string,
  ): Promise<DocumentVersion | null> {
    const { data: response, error } = await this.API?.get<{
      data: DocumentVersionHttp;
      meta?: Meta;
    }>(`${this.documentVersionEndpoint}/${documentVersionId}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    return toDocumentVersion(response?.data || {});
  }

  public async upsertDocumentVersion({
    id,
    ...documentVersionWizard
  }: DocumentVersionWizard): Promise<DocumentVersion | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<DocumentVersionWizard, 'id'> },
        { data: DocumentVersionHttp }
      >(this.documentVersionEndpoint, { data: documentVersionWizard });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toDocumentVersion(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: DocumentVersionWizard },
      { data: DocumentVersionHttp }
    >(`${this.documentVersionEndpoint}/${id}`, {
      data: { ...documentVersionWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    return toDocumentVersion(data?.data || {});
  }

  public async downloadDocumentVersionFile({ id }: DocumentVersion): Promise<{
    name: string;
    content: Blob | undefined;
    type: string;
  } | void> {
    const { data, headers } = await this.FileAPI.get<Blob>(
      `${this.documentVersionEndpoint}/${id}/download`,
    );

    if (!headers) {
      return {
        type: '',
        name: '',
        content: new Blob(),
      };
    }

    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(headers['content-disposition']);
    return {
      type: headers['content-type'],
      name: matches?.[1].replace(/['"]/g, '') || '',
      content: data,
    };
  }
}
