import React, { useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import useStore from '@stores/store';

interface InactivitySpyProps {
  timeout?: number;
}

const InactivitySpy = ({
  t: trans,
  timeout = 7200000, // 2 h
}: InactivitySpyProps & WithTranslation) => {
  const redirectToLogin = (): void => {
    const loginUri = `${String(process.env.VITE_APP_LOGIN_URL)}?redirect_uri=${
      window.location.href
    }`;
    window.location.href = loginUri;
  };

  const { openModal, closeModal, setInactive } = useStore();

  useEffect(() => {
    let inactivityTimer: ReturnType<typeof setTimeout>;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        setInactive(true);
        openModal({
          title: trans('action.inactivity.spy.title'),
          type: 'dialog',
          customCloseModal: redirectToLogin,
          component: (
            <ConfirmDialog
              message={trans('action.inactivity.spy.message')}
              hideCancelBtn={true}
              onConfirm={() => {
                setInactive(false);
                redirectToLogin();
                closeModal();
              }}
            />
          ),
        });
      }, timeout);
    };

    resetTimer();

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [timeout]);

  return <></>;
};

export default withTranslation()(InactivitySpy);
