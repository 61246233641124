/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import ErrorService from 'core/error.service';
import i18n from '@_plugins/i18n';
import { toTrainingActionCandidateTraining } from '@data/dto/training-action-candidate-trainings.dto';
import {
  TrainingActionCandidateTraining,
  TrainingActionCandidateTrainingHttp,
  TrainingActionCandidateTrainingWizard,
} from '@data/models/training-action-candidate-training.interface';

@injectable()
export default class TrainingActionCandidateTrainingService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingActionCandidateTrainingEndpoint =
    '/candidate-trainings';

  private errorService = container.get<ErrorService>(ErrorService);

  public async upsertTrainingActionCandidateTraining({
    id,
    ...trainingActionCandidateTrainingWizard
  }: TrainingActionCandidateTrainingWizard): Promise<TrainingActionCandidateTraining | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        {
          data: Omit<TrainingActionCandidateTrainingWizard, 'id'>;
        },
        {
          data: TrainingActionCandidateTrainingHttp;
        }
      >(`${this.trainingActionCandidateTrainingEndpoint}`, {
        data: trainingActionCandidateTrainingWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toTrainingActionCandidateTraining(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingActionCandidateTrainingWizard },
      {
        data: TrainingActionCandidateTrainingHttp;
      }
    >(`${this.trainingActionCandidateTrainingEndpoint}/${id}`, {
      data: { ...trainingActionCandidateTrainingWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingActionCandidateTraining(data?.data || {});
  }

  public async deleteTrainingActionCandidateTraining(
    id: string,
  ): Promise<TrainingActionCandidateTraining | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: TrainingActionCandidateTrainingHttp;
    }>(`${this.trainingActionCandidateTrainingEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingActionCandidateTraining(data?.data || {});
  }
}
