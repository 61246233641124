import { Dayjs } from 'dayjs';

export const TELEOPERATOR_PROFESSION_SLUGS = ['employe-centre-dappel-hf'];

export interface ProfessionHttp {
  type: 'profession_repositories';
  id: string;
  attributes: {
    label: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface Profession {
  id: string;
  label: string;
  isTeleoperator: boolean;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}

export interface ProfessionWizard {
  type: 'profession_repositories';
  id: string;
  attributes: {
    label: string;
  };
}
