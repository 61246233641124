import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import TableSkeleton from '@components/Skeleton/TableSkeleton';

const ListSkeleton = (): JSX.Element => {
  return (
    <Box mt={5}>
      <Card>
        <CardHeader
          title={<Skeleton width={200} height={32} variant="rectangular" />}
          avatar={<Skeleton width={40} height={40} variant="circular" />}
          subheader={<Skeleton width={80} />}
          action={
            <Box display="flex" alignContent="center" m={2} mr={1}>
              <Skeleton width={300} height={36} variant="rectangular" />
            </Box>
          }
        />
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Box mb={2}>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item>
                  <Skeleton width={24} height={24} variant="rectangular" />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={29} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton height={29} />
                </Grid>
                <Grid item>
                  <Box mb={1.5} ml={1}>
                    <Skeleton height={22} width={81} variant="rectangular" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <TableSkeleton rows={10} columns={8} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ListSkeleton;
