import dayjs from '@_plugins/dayjs';
// data
import { fromStoredSearchCandidate } from '@data/dto/candidate.dto';
import { fromStoredSearchCustomer } from '@data/dto/customer.dto';
import { fromStoredSearchDeviceType } from '@data/dto/device-type.dto';
import { fromStoredSearchGroup } from '@data/dto/group.dto';
import { fromStoredSearchManager } from '@data/dto/manager.dto';
import { fromStoredSearchRegion } from '@data/dto/region.dto';
import { fromStoredSearchTraining } from '@data/dto/training.dto';
import {
  StoredSearch,
  StoredSearchHttp,
  StoredSearchSearch,
  StoredSearchSearchHttp,
  StoredSearchWizard,
} from '@data/models/stored-search.interface';
import {
  CachedSearchProps,
  SearchProps,
} from '@pages/TrainingCourseList/components/Searchbar';

export const fromStoredSearchSearchHttp = (
  search: SearchProps,
): StoredSearchSearchHttp => {
  return {
    candidates: search.candidates,
    customers: search.customers,
    device_types: search.deviceTypes,
    groups: search.groups,
    managers: search.managers,
    domains: search.domains,
    cpef_managers: search.cpefManagers,
    mine: search.mine,
    is_locked: search.isLocked,
    is_trashed: search.isTrashed,
    reference: search.reference,
    regions: search.regions,
    status: search.status || [],
    priority: search.priority || [],
    from_date: search.createdAt[0]?.toISOString() || '',
    to_date: search.createdAt[1]?.toISOString() || '',
    trainings: search.trainings,
    from_date_educational_path:
      search.dateEducationalPaths[0]?.toISOString() || '',
    to_date_educational_path:
      search.dateEducationalPaths[1]?.toISOString() || '',
  };
};

export const toStoredSearchWizard = (
  name: string,
  userEmail: string,
  search: SearchProps,
): StoredSearchWizard => {
  return {
    attributes: {
      description: '',
      name: name,
      search: fromStoredSearchSearchHttp(search),
      user_email: userEmail,
    },
    id: '',
    type: 'stored_searches',
  };
};

export const toStoredSearchSearch = (
  search: StoredSearchSearchHttp,
): StoredSearchSearch => {
  return {
    reference: search.reference || '',
    fromDate: search.from_date || '',
    toDate: search.to_date || '',
    mine: search.mine,
    isLocked: search.is_locked,
    isTrashed: search.is_trashed,
    groups: search.groups,
    candidates: search.candidates,
    customers: search.customers,
    status: search.status,
    priority: search.priority,
    deviceTypes: search.device_types,
    managers: search.managers,
    domains: search.domains,
    cpefManagers: search.cpef_managers,
    trainings: search.trainings,
    regions: search.regions,
    fromDateEducationalPath: search.from_date_educational_path || '',
    toDateEducationalPath: search.to_date_educational_path || '',
  };
};

export const toStoredSearch = (
  search: Partial<StoredSearchHttp>,
): StoredSearch => {
  return {
    id: search.id || '',
    userEmail: search.attributes?.user_email || '',
    name: search.attributes?.name || '',
    description: search.attributes?.description || '',
    search: toStoredSearchSearch(
      search.attributes?.search || {
        reference: '',
        from_date: '',
        to_date: '',
        mine: false,
        is_locked: true,
        is_trashed: true,
        groups: [],
        candidates: [],
        customers: [],
        status: [],
        priority: [],
        device_types: [],
        managers: [],
        cpef_managers: [],
        trainings: [],
        domains: [],
        regions: [],
        from_date_educational_path: '',
        to_date_educational_path: '',
      },
    ),
    createdAt: dayjs(search.attributes?.createdAt || ''),
    updatedAt: dayjs(search.attributes?.updatedAt || ''),
  };
};

export const toSearchProps = ({ search }: StoredSearch): SearchProps => {
  return {
    reference: search.reference,
    mine: search.mine,
    isLocked: search.isLocked,
    isTrashed: search.isTrashed,
    candidates: search.candidates.map((candidate) =>
      fromStoredSearchCandidate(candidate),
    ),
    groups: search.groups.map((group) => fromStoredSearchGroup(group)),
    createdAt: [
      (search.fromDate && new Date(search.fromDate)) || null,
      (search.toDate && new Date(search.toDate)) || null,
    ],
    dateEducationalPaths: [
      (search.fromDateEducationalPath &&
        new Date(search.fromDateEducationalPath)) ||
        null,
      (search.fromDateEducationalPath &&
        new Date(search.toDateEducationalPath)) ||
        null,
    ],
    customers: search.customers.map((customer) =>
      fromStoredSearchCustomer(customer),
    ),
    deviceTypes: search.deviceTypes.map((deviceType) =>
      fromStoredSearchDeviceType(deviceType),
    ),
    managers: search.managers.map((manager) =>
      fromStoredSearchManager(manager),
    ),
    cpefManagers: search.cpefManagers.map((manager) =>
      fromStoredSearchManager(manager),
    ),
    domains: search.domains.map((domain) => fromStoredSearchTraining(domain)),
    regions: search.regions.map((region) => fromStoredSearchRegion(region)),
    status: search.status,
    trainings: search.trainings.map((training) =>
      fromStoredSearchTraining(training),
    ),
    priority: search.priority,
  };
};

export const fromCachedSearchProps = (
  cachedSearchProps: CachedSearchProps | null,
): SearchProps | null => {
  if (cachedSearchProps === null) {
    return null;
  }
  const search: SearchProps = {
    reference: cachedSearchProps.reference,
    mine: cachedSearchProps.mine,
    isLocked: cachedSearchProps.isLocked,
    isTrashed: cachedSearchProps.isTrashed,
    candidates: cachedSearchProps.candidates,
    groups: cachedSearchProps.groups,
    customers: cachedSearchProps.customers,
    deviceTypes: cachedSearchProps.deviceTypes,
    managers: cachedSearchProps.managers,
    cpefManagers: cachedSearchProps.cpefManagers,
    domains: cachedSearchProps.domains,
    regions: cachedSearchProps.regions,
    status: cachedSearchProps.status,
    trainings: cachedSearchProps.trainings,
    priority: cachedSearchProps.priority,
    createdAt: [null, null],
    dateEducationalPaths: [null, null],
  };

  if (
    cachedSearchProps.createdAt?.[0] !== null &&
    cachedSearchProps.createdAt?.[1] !== null
  ) {
    search.createdAt = [
      new Date(cachedSearchProps.createdAt[0]),
      new Date(cachedSearchProps.createdAt[1]),
    ];
  }

  if (
    cachedSearchProps.dateEducationalPaths?.[0] !== null &&
    cachedSearchProps.dateEducationalPaths?.[1] !== null
  ) {
    search.dateEducationalPaths = [
      new Date(cachedSearchProps.dateEducationalPaths[0]),
      new Date(cachedSearchProps.dateEducationalPaths[1]),
    ];
  }

  return search;
};
