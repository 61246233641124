import day from '@_plugins/dayjs';
import { toApiDate } from '@data/dto/api.dto';
import {
  GroupMigration,
  GroupMigrationHttp,
  GroupMigrationWizard,
} from '@data/models/group-migration.interface';
import { TrainingCourseHttp } from '@data/models/training-course.interface';

import { toTrainingCourse } from './training-course.dto';
import { toUser } from './user.dto';

export const toGroupMigration = (
  { attributes, id, relationships }: Partial<GroupMigrationHttp>,
  included: TrainingCourseHttp[],
): GroupMigration => {
  const trainingCoursesId =
    relationships?.training_courses?.data?.map(({ id }) => id) || [];

  const trainingCoursesHttp = included.filter(({ id }) =>
    trainingCoursesId.includes(id),
  );
  return {
    id: id || '',
    legacySiret: attributes?.legacy_siret || '',
    newSiret: attributes?.new_siret || '',
    fromDate: day(attributes?.from_date),
    createdAt: day(attributes?.createdAt),
    updatedAt: day(attributes?.updatedAt),
    trainingCourses: trainingCoursesHttp.map((http) =>
      toTrainingCourse(http, toUser({}), {}),
    ),
  };
};

export const toGroupMigrationWizard = (
  groupMigration: Partial<GroupMigration>,
): GroupMigrationWizard => {
  return {
    type: 'group_migration_repositories',
    id: groupMigration.id || '',
    attributes: {
      new_siret: groupMigration.newSiret || '',
      legacy_siret: groupMigration.legacySiret || '',
      from_date: toApiDate(groupMigration.fromDate),
    },
    relationships: {
      training_courses: {
        data:
          groupMigration.trainingCourses?.map(({ id }) => ({
            type: 'training_courses',
            id: id || '',
          })) || [],
      },
    },
  };
};
