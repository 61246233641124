import {
  TrainingCourse,
  TrainingCourseAttribute,
  TRAINING_COURSE_ATTRIBUTE_CATEGORY,
} from '@data/models/training-course.interface';

export const getTrainingCourseAttributes = (
  trainingCourse: TrainingCourse,
): TrainingCourseAttribute[] => {
  return [
    {
      value: 'manager',
      label: 'Actual / Gestionnaire',
      category: TRAINING_COURSE_ATTRIBUTE_CATEGORY.UNDEFINED,
    },
    ...trainingCourse.groups.map(({ id, directionFull }) => ({
      value: id,
      label: directionFull,
      category: TRAINING_COURSE_ATTRIBUTE_CATEGORY.GROUPS,
    })),
    ...trainingCourse.candidates.map(({ id, fullName }) => ({
      value: id,
      label: fullName,
      category: TRAINING_COURSE_ATTRIBUTE_CATEGORY.CANDIDATES,
    })),
    ...trainingCourse.educationalPaths
      .flatMap(({ trainingCourseTrainings }) => trainingCourseTrainings)
      .map(({ id, label, organizationName }) => ({
        value: id,
        label: organizationName || label,
        category: TRAINING_COURSE_ATTRIBUTE_CATEGORY.TRAININGS,
      })),
    ...trainingCourse.customers.map(({ id, name }) => ({
      value: id,
      label: name,
      category: TRAINING_COURSE_ATTRIBUTE_CATEGORY.CUSTOMERS,
    })),
    ...trainingCourse.estimators.map(({ id, name }) => ({
      value: id,
      label: name,
      category: TRAINING_COURSE_ATTRIBUTE_CATEGORY.ESTIMATORS,
    })),
  ];
};

export const getTrainingCourseAttributeByValue = (
  value: string,
  trainingCourse: TrainingCourse,
): TrainingCourseAttribute | null => {
  return (
    getTrainingCourseAttributes(trainingCourse).find(
      ({ value: attributeValue }) => attributeValue === value,
    ) || null
  );
};

export const getTrainingCourseAttributeCategoryLabel = (
  attribute: TrainingCourseAttribute | null,
): string => {
  if (!attribute?.category) {
    return '';
  }

  return `training-course.ged.optgroup-attribute.${attribute.category}`;
};
