export const System = {
  DATAGRID_LICENCE_KEY:
    // eslint-disable-next-line max-len
    'AppName=multi_app,Company=TYSOFT,ExpiryDate=2023-05-19,LicenseDeveloperCount=1,LicenseType=multi_app,Ref=TYSOFTLicenseRef,Z=1235901805-1126857021-443215353-208157853712359018051826537251',
  STORAGE_CACHE_DURATION: 1 * 60 * 60,
  USER_DATA_DURATION: 5, // in minutes
  UPLOAD_MAX_SIZE: 5000000,
  RETRY_MAX_ATTEMPTS: {
    api: 3,
    component: 3,
  },
  STORAGE_CACHE_ID: 3,
};
