import { ReactNode, useCallback, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { container } from '@_plugins/ioc';
import { FilterParams } from '@data/models/filter.interface';
// training-course
import { TrainingCourse } from '@data/models/training-course.interface';
import TrainingCourseService from '@data/services/training-course.service';
import { TextField } from '@groupeactual/ui-kit';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { PopperProps } from '@mui/material/Popper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';

interface Props {
  label: string;
  cacheOptions?: boolean;
  placeholder?: string;
  defaultValue?: TrainingCourse | null;
  defaultOptions?: TrainingCourse[];
  onChange: (trainingCourse: TrainingCourse | null) => void;
  clearAfterChange?: boolean;
  error?: string;
  startAdornment?: ReactNode;
}
const TrainingCourseSelect = ({
  label,
  defaultOptions = [],
  placeholder,
  onChange,
  defaultValue = null,
  clearAfterChange = false,
  t: trans,
  error = '',
  startAdornment = <></>,
}: Props & WithTranslation): JSX.Element => {
  const [value, setValue] = useState<TrainingCourse | null>(defaultValue);
  const [loading, setLoading] = useState(false);
  const [trainingCourses, setTrainingCourses] =
    useState<TrainingCourse[]>(defaultOptions);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => setValue(defaultValue), [defaultValue?.id]);

  const service = container.get<TrainingCourseService>(TrainingCourseService);

  const debounceListTrainingCourses = useCallback(
    debounce(
      async (filters: FilterParams) =>
        service.listTrainingCourse(filters).then(({ items }) => {
          setLoading(false);
          setTrainingCourses(items);
        }),
      500,
    ),
    [],
  );

  useEffect(() => {
    if (!inputValue) {
      setTrainingCourses(defaultOptions);
      return;
    }
    setTrainingCourses([]);
    setLoading(true);

    const filterParams = {
      filter: {
        reference: inputValue,
      },
    };
    debounceListTrainingCourses(filterParams);
  }, [inputValue]);

  const handleChange = (trainingCourse: TrainingCourse | null) => {
    onChange(trainingCourse || null);

    if (clearAfterChange) {
      setValue(null);
      setInputValue('');
      return;
    }

    setValue(trainingCourse);
  };

  const customPopper = (props: PopperProps) => {
    return (
      (!inputValue && !trainingCourses.length && <></>) || (
        <Popper {...props} placement="bottom-start" />
      )
    );
  };

  return (
    <Autocomplete
      popupIcon={<></>}
      openText={trans('action.autocomplete.open')}
      closeText={trans('action.autocomplete.close')}
      PopperComponent={customPopper}
      value={value}
      defaultValue={defaultValue}
      onChange={(event, trainingCourse) => handleChange(trainingCourse)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={({ reference, id }) => `${reference} ${String(id)}`}
      options={trainingCourses}
      loading={loading}
      filterOptions={(options) => options}
      renderOption={(props, { reference, groups }) => {
        return (
          <Box component="li" {...props}>
            <Typography variant="body2">
              <Grid container spacing={1}>
                <Grid item>
                  <b>{reference}</b>
                </Grid>
                <Grid item>- {groups[0]?.analyticFull}</Grid>
              </Grid>
            </Typography>
          </Box>
        );
      }}
      loadingText={trans('form.select.loading')}
      noOptionsText={trans('form.select.no_result')}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          value=""
          name=""
          {...params}
          error={error}
          helperText={error || ''}
          placeholder={placeholder}
          label={label}
          endAdornment={
            <>
              {loading ? (
                <CircularProgress color="secondary" size={20} />
              ) : null}
            </>
          }
          InputProps={{
            ...params.InputProps,
            size: 'small',
            startAdornment,
            sx: { py: '3px !important' },
          }}
        />
      )}
    />
  );
};

export default withTranslation()(TrainingCourseSelect);
