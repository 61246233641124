import day from '@_plugins/dayjs';
import {
  ControlOrganization,
  ControlOrganizationHttp,
  ControlOrganizationWizard,
} from '@data/models/control-organization.interface';

export const toControlOrganization = (
  controlOrganization: Partial<ControlOrganizationHttp>,
): ControlOrganization => {
  return {
    id: controlOrganization.id || '',
    label: controlOrganization.attributes?.label || '',
    createdAt: day(controlOrganization.attributes?.createdAt),
    updatedAt: day(controlOrganization.attributes?.updatedAt),
  };
};

export const toControlOrganizationWizard = (
  controlOrganization: Partial<ControlOrganization>,
): ControlOrganizationWizard => {
  return {
    type: 'control_organization_repositories',
    id: controlOrganization.id || '',
    attributes: {
      label: controlOrganization.label || '',
    },
  };
};
