import { lazy } from 'react';
import TrainingCourseGroupSkeleton from '@components/Skeleton/TrainingCourseGroupSkeleton';
import { NavRoute } from '@data/models/navigation.interface';
import { faHomeLg } from '@fortawesome/pro-solid-svg-icons';
import { IconProvider } from '@groupeactual/ui-kit';

const Control = lazy(
  () => import('@pages/TrainingCourseActionControl/Control'),
);

const Conversion = lazy(
  () => import('@pages/TrainingActionConversion/Conversion'),
);

const Summary = lazy(() => import('@pages/TrainingActionSummary/Summary'));

const Schedule = lazy(() => import('@pages/TrainingActionSchedule/Schedule'));

const Document = lazy(() => import('@pages/TrainingActionDocument/Document'));

const History = lazy(() => import('@pages/TrainingActionHistory/History'));

const Communication = lazy(
  () => import('@pages/TrainingActionCommunication/Communication'),
);

export const trainingCourseActionRoutes: NavRoute[] = [
  {
    name: 'navigation.links.control',
    path: '/control',
    component: <Control />,
    hidden: true,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.conversion',
    path: '/conversion',
    component: <Conversion />,
    hidden: true,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.conversion',
    path: '/summary',
    component: <Summary />,
    hidden: true,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.schedule',
    path: '/action_schedule',
    component: <Schedule />,
    hidden: true,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.ged',
    path: '/action_document',
    component: <Document />,
    hidden: true,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.communication',
    path: '/action_message',
    component: <Communication />,
    hidden: true,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.history',
    path: '/action_history',
    component: <History />,
    hidden: true,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faHomeLg} />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
];
