import { Dayjs } from 'dayjs';
import { Contact, ContactHttp } from './contact.interface';
import { DocumentHttp, Document } from './document.interface';
import { DocumentVersionHttp } from './document-version.interface';

export type CommunicationRawIncluded = Array<
  CommunicationHttp | DocumentHttp | ContactHttp | DocumentVersionHttp
>;

export interface CommunicationIncluded {
  communications: CommunicationHttp[];
  documents: DocumentHttp[];
  document_versions: DocumentVersionHttp[];
  contacts: ContactHttp[];
}

export enum COMMUNICATION_TYPES {
  GOOGLE_EMAIL = 'GOOGLE_EMAIL',
  GOOGLE_EMAIL_ANSWER = 'GOOGLE_EMAIL_ANSWER',
}

export interface CommunicationHttp {
  type: 'communications';
  id: string;
  attributes: {
    user_id?: number;
    from_email: string;
    from_name: string;
    body_html: string;
    body_plain: string;
    communication_type: COMMUNICATION_TYPES;
    subject: string;
    read_at: string;
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    training_course: {
      data?: {
        type: 'training_courses';
        id: string;
      };
    };
    documents: {
      data?: {
        type: 'documents';
        id: string;
      }[];
    };
    children: {
      data?: {
        type: 'communications';
        id: string;
      }[];
    };
    contacts: {
      data?: {
        type: 'contacts';
        id: string;
      }[];
    };
  };
}

export interface Communication {
  id: string;
  userId: string;
  fromName: string;
  fromEmail: string;
  bodyHtml: string;
  bodyPlain: string;
  type: COMMUNICATION_TYPES;
  documents: Document[];
  replies: Communication[];
  recipients: Contact[];
  isRead: boolean;
  readAt: Dayjs;
  createdAt: Dayjs;
  updatedAt: Dayjs;
  subject: string;
}

export interface CommunicationWizard {
  type: 'communications';
  id: string;
  attributes: {
    user_id?: number;
    from_name: string;
    body_plain: string;
    communication_type: COMMUNICATION_TYPES;
    subject: string;
    read_at: string;
  };
  relationships: {
    training_course: {
      data?: {
        type: 'training_courses';
        id: string;
      };
    };
    documents: {
      data?: {
        type: 'documents';
        id: string;
      }[];
    };
    parent: {
      data?: {
        type: 'communications';
        id: string;
      } | null;
    };
    contacts: {
      data?: {
        type: 'contacts';
        id: string;
      }[];
    };
  };
}
