import dayjs from '@_plugins/dayjs';
import {
  UserNotification,
  UserNotificationHttp,
} from '@data/models/user-notification.interface';

export const toUserNotification = (
  userNotification: Partial<UserNotificationHttp>,
): UserNotification => {
  return {
    id: userNotification.id || '',
    message: userNotification.attributes?.message || '',
    title: userNotification.attributes?.title || '',
    is_read: userNotification.attributes?.is_read || false,
    createdAt: dayjs(userNotification.attributes?.createdAt || ''),
    readAt: dayjs(userNotification.attributes?.readAt || ''),
    notification_type: userNotification.attributes?.notification_type || 1,
    isTrainingOrganismContacted:
      userNotification.attributes?.extra_data?.is_training_organism_contacted ||
      false,
    trainingCourseRef:
      userNotification.attributes?.extra_data?.training_course_reference || '',
    estimatorID: userNotification.attributes?.extra_data?.estimator_id || '',
  };
};
