import { StateCreator } from 'zustand';

export interface ToastStore {
  id: string;
  title: string;
  message: string;
  level: 'info' | 'warning' | 'error' | 'success';
}

export interface ToastState {
  toast: ToastStore[];
  addToast: (newToast: Partial<ToastStore>) => void;
}

export const createToastSlice: StateCreator<
  ToastState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  ToastState
> = (set) => ({
  toast: [],
  addToast: async (newToast: Partial<ToastStore>) => {
    set((state) => ({
      toast: [
        ...state.toast,
        {
          id: new Date().getTime().toString(),
          title: newToast.title || '',
          message: newToast.message || '',
          level: newToast.level || 'info',
        },
      ],
    }));
    // clean toast after 10s
    await setTimeout((toast = []) => {
      set(() => ({
        toast,
      }));
    }, 10000);
  },
});
