import {
  TrainingOutsideCatalog,
  TrainingOutsideCatalogHttp,
} from '@data/models/training-outside-catalog.interface';

export const toTrainingOutsideCatalog = (
  training: Partial<TrainingOutsideCatalogHttp>,
): TrainingOutsideCatalog => {
  return {
    id: training.id || '',
    label: training.attributes?.label || '',
    reference: training.attributes?.training_course?.reference || '',
  };
};
