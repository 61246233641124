import { StateCreator } from 'zustand';
import { Manager } from '@data/models/manager.interface';

export interface ManagerState {
  managers: Manager[];
  setManagers: (managers: Manager[]) => void;
}

export const createManagerSlice: StateCreator<
  ManagerState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  ManagerState
> = (set) => ({
  managers: [],
  setManagers: (managers: Manager[]) => set(() => ({ managers })),
});
