import i18n from '@_plugins/i18n';
import * as Yup from 'yup';

const DelegationFormSchema = Yup.object({
  reason: Yup.string().required(i18n.t('delegation.form.error_motif')),
  manager: Yup.string().required(i18n.t('delegation.form.error_delegatary')),
  delegationManager: Yup.string()
    .required(i18n.t('delegation.form.error_delegation-representative'))
    .test(
      'different-manager',
      i18n.t('delegation.form.error_different-manager'),
      (value, { parent }) => value !== parent.manager,
    ),

  fromDate: Yup.string().required(i18n.t('delegation.form.error_date_from')),
  toDate: Yup.string().required(i18n.t('delegation.form.error_date_to')),
});

export default DelegationFormSchema;
