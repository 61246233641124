/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  Communication,
  CommunicationHttp,
  CommunicationWizard,
  CommunicationRawIncluded,
} from '@data/models/communication.interface';
import {
  toCommunication,
  toCommunicationIncluded,
} from '@data/dto/communication.dto';
import ErrorService from 'core/error.service';
import i18n from '@_plugins/i18n';
import { FilterParams } from '@data/models/filter.interface';
import { Meta, PaginateResult } from '@data/models/api.interface';

@injectable()
export default class CommunicationService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly communicationEndpoint = '/communications';

  private errorService = container.get<ErrorService>(ErrorService);

  private included = [
    'documents',
    'children',
    'contacts',
    'children.documents',
  ];

  public async listCommunications(
    filters: FilterParams,
  ): Promise<PaginateResult<Communication[]>> {
    const { data: response, error } = await this.API?.get<{
      data: CommunicationHttp[];
      meta?: Meta;
      included: CommunicationRawIncluded;
    }>(this.communicationEndpoint, {
      ...filters,
      include: this.included,
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toCommunicationIncluded(response?.included || []);

    const items =
      response?.data?.map((trainingDeviceTypeCandidate) =>
        toCommunication(trainingDeviceTypeCandidate, included),
      ) || [];

    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async findCommunication(
    communicationId: string,
  ): Promise<Communication | null> {
    const { data: response, error } = await this.API?.get<{
      data: CommunicationHttp;
      included: CommunicationRawIncluded;
    }>(`${this.communicationEndpoint}/${communicationId}`, {
      include: this.included,
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    const included = toCommunicationIncluded(response?.included || []);

    return toCommunication(response?.data || {}, included);
  }

  public async upsertCommunication({
    id,
    ...trainingDeviceTypeCandidateWizard
  }: CommunicationWizard): Promise<Communication | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        {
          data: Omit<CommunicationWizard, 'id'>;
        },
        {
          data: CommunicationHttp;
          included: CommunicationRawIncluded;
        }
      >(`${this.communicationEndpoint}?include=${this.included.join(',')}`, {
        data: trainingDeviceTypeCandidateWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      const included = toCommunicationIncluded(data?.included || []);

      return toCommunication(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: CommunicationWizard },
      {
        data: CommunicationHttp;
        included: CommunicationRawIncluded;
      }
    >(
      `${this.communicationEndpoint}/${id}?include=${this.included.join(',')}`,
      {
        data: { ...trainingDeviceTypeCandidateWizard, id },
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toCommunicationIncluded(data?.included || []);

    return toCommunication(data?.data || {}, included);
  }

  public async deleteCommunication(id: string): Promise<Communication | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: CommunicationHttp;
      included: CommunicationRawIncluded;
    }>(
      `${this.communicationEndpoint}/${id}?include=${this.included.join(',')}`,
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toCommunicationIncluded(data?.included || []);

    return toCommunication(data?.data || {}, included);
  }

  public async toggleReadAt(
    communication: Communication,
  ): Promise<Communication | null> {
    const endpoint = `${this.communicationEndpoint}/${
      communication.id
    }/toggle-read-at?include=${this.included.join(',')}`;

    const { data, error } = await this.API?.patch<
      { data: Communication },
      { data: CommunicationHttp; included: CommunicationRawIncluded }
    >(endpoint, {
      data: { ...communication },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    const included = toCommunicationIncluded(data?.included || []);

    return toCommunication(data?.data || {}, included);
  }
}
