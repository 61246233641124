import day from '@_plugins/dayjs';
import {
  Profession,
  ProfessionHttp,
  ProfessionWizard,
  TELEOPERATOR_PROFESSION_SLUGS,
} from '@data/models/profession.interface';
import { SelectOption } from '@data/models/select.interface';

export const toProfession = (
  profession: Partial<ProfessionHttp>,
): Profession => {
  return {
    id: profession.id || '',
    label: profession.attributes?.label || '',
    isTeleoperator: TELEOPERATOR_PROFESSION_SLUGS.includes(
      profession.attributes?.slug || '',
    ),
    createdAt: day(profession.attributes?.createdAt),
    updatedAt: day(profession.attributes?.updatedAt),
  };
};

export const toProfessionWizard = (
  profession: Partial<Profession>,
): ProfessionWizard => {
  return {
    type: 'profession_repositories',
    id: profession.id || '',
    attributes: {
      label: profession.label || '',
    },
  };
};

export const toProfessionAutocomplete = ({
  id,
  label,
}: Profession): SelectOption => {
  return {
    label,
    value: id,
  };
};
