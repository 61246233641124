import dayjs from '@_plugins/dayjs';
import { toApiFloat } from '@data/dto/api.dto';
import { toCourseType } from '@data/dto/course-type.dto';
import { fromTrainingCourseTrainingHttp } from '@data/dto/training-course-training.dto';
import { toValidationType } from '@data/dto/validation-type.dto';
import { CityHttp } from '@data/models/city.interface';
import { CourseTypeHttp } from '@data/models/course-type.interface';
import {
  TrainingCourseEducationalPath,
  TrainingCourseEducationalPathHttp,
  TrainingCourseEducationalPathIncluded,
  TrainingCourseEducationalPathRawIncluded,
  TrainingCourseEducationalPathWizard,
} from '@data/models/training-course-educational-path.interface';
import { TrainingCourseSessionHttp } from '@data/models/training-course-session.interface';
import { TrainingCourseTrainingHttp } from '@data/models/training-course-training.interface';
import { TrainingCourseHttp } from '@data/models/training-course.interface';
import { TrainingOptionHttp } from '@data/models/training.interface';
import { ValidationTypeHttp } from '@data/models/validation-type.interface';

import { TrainingActionCandidateTrainingHttp } from '../models/training-action-candidate-training.interface';

export const toTrainingCourseEducationalPathIncluded = (
  included: TrainingCourseEducationalPathRawIncluded = [],
): TrainingCourseEducationalPathIncluded => {
  return {
    trainingCourse: included.filter(
      ({ type }) => type === 'training_courses',
    ) as TrainingCourseHttp[],
    trainings: included.filter(
      ({ type }) => type === 'training_course_trainings',
    ) as TrainingCourseTrainingHttp[],
    validationTypes: included.filter(
      ({ type }) => type === 'validation_type_repositories',
    ) as ValidationTypeHttp[],
    courseTypes: included.filter(
      ({ type }) => type === 'course_type_repositories',
    ) as CourseTypeHttp[],
    cities: included.filter(({ type }) => type === 'cities') as CityHttp[],
    options: included.filter(
      ({ type }) => type === 'training_options',
    ) as TrainingOptionHttp[],
    sessions: included.filter(
      ({ type }) => type === 'training_course_sessions',
    ) as TrainingCourseSessionHttp[],
    candidateTrainings: included.filter(
      ({ type }) => type === 'candidate_trainings',
    ) as TrainingActionCandidateTrainingHttp[],
  };
};

export const toTrainingCourseEducationalPath = (
  { id, attributes, relationships }: Partial<TrainingCourseEducationalPathHttp>,
  included: Partial<TrainingCourseEducationalPathIncluded> = {},
): TrainingCourseEducationalPath => {
  const trainingsId =
    relationships?.training_course_trainings?.data?.map(({ id }) => id) || [];

  const trainingsHttp =
    included.trainings?.filter((training) =>
      trainingsId.includes(training.id),
    ) || [];

  const courseTypeHttp =
    included.courseTypes?.find(
      ({ id }) => id === relationships?.course_type?.data?.id,
    ) || {};

  const validationTypeHttp =
    included.validationTypes?.find(
      ({ id }) => id === relationships?.validation_type?.data?.id,
    ) || {};
  return {
    id: id || '',
    trainingCourseTrainings: trainingsHttp.map((training) =>
      fromTrainingCourseTrainingHttp(
        training,
        included.cities,
        included.options,
        included.sessions,
        included.validationTypes,
        included.candidateTrainings,
      ),
    ),
    label: attributes?.label || '',
    externalTrainingDuration: attributes?.external_training_duration || '',
    practicalTrainingDuration: attributes?.practical_training_duration || '',
    validationCoefficient: attributes?.validation_coefficient || '',
    validationDescription: attributes?.validation_description || '',
    validationLevel: attributes?.validation_level || '',
    organizationName: attributes?.positioning_review_organization_name || '',
    positioningReviewCost: attributes?.positioning_review_cost || '',
    positioningReviewDuration: attributes?.positioning_review_duration || '',
    validationType: toValidationType(validationTypeHttp),
    courseType: toCourseType(courseTypeHttp),
    createdAt: dayjs(attributes?.createdAt || ''),
    updatedAt: dayjs(attributes?.updatedAt || ''),
  };
};

export const toTrainingCourseEducationalPathWizard = (
  {
    id,
    label,
    externalTrainingDuration,
    practicalTrainingDuration,
    validationCoefficient,
    validationDescription,
    validationLevel,
    validationType,
    courseType,
    positioningReviewDuration,
    positioningReviewCost,
    organizationName,
  }: Partial<TrainingCourseEducationalPath>,
  trainingCourseId: string,
): TrainingCourseEducationalPathWizard => {
  const wizard: TrainingCourseEducationalPathWizard = {
    type: 'training_course_educational_paths',
    id: id || '',
    attributes: {
      label: label || '',
      external_training_duration: toApiFloat(externalTrainingDuration || '0'),
      practical_training_duration: toApiFloat(practicalTrainingDuration || '0'),
      validation_coefficient: validationCoefficient || '',
      validation_description: validationDescription || '',
      validation_level: validationLevel || '',
      positioning_review_cost: toApiFloat(positioningReviewCost || '0'),
      positioning_review_duration: toApiFloat(positioningReviewDuration || '0'),
      positioning_review_organization_name: organizationName || '',
    },
    relationships: {
      training_course: {
        data: {
          id: trainingCourseId,
          type: 'training_courses',
        },
      },
    },
  };

  if (validationType?.id) {
    wizard.relationships.validation_type = {
      data: {
        id: validationType.id,
        type: 'validation_type_repositories',
      },
    };
  }
  if (courseType?.id) {
    wizard.relationships.course_type = {
      data: {
        id: courseType.id,
        type: 'course_type_repositories',
      },
    };
  }

  return wizard;
};
