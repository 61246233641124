import day from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormats from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import UTC from 'dayjs/plugin/utc';

import 'dayjs/locale/fr';

day.extend(relativeTime);
day.extend(LocalizedFormats);
day.extend(duration);
day.extend(CustomParseFormat);
day.extend(UTC);
day.locale('fr');

export default day;
