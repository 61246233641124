import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

const DelegationFormSkeleton = (): JSX.Element => {
  return (
    <div>
      <Card
        sx={{
          marginTop: '14px',
          border: '1px solid lightgrey',
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
          }}
        >
          <Grid item xs={4}>
            <Box
              p={2}
              sx={{
                height: '100%',
                backgroundColor: '#F0F0F0',
                borderRight: '1px solid lightgrey',
              }}
            >
              <Skeleton variant="rectangular" height={45} />
              <Box mt={3}></Box>
              <Skeleton variant="rectangular" height={45} />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              p={2}
              sx={{
                height: '100%',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Skeleton
                  variant="rectangular"
                  width={210}
                  height={50}
                  sx={{ marginRight: '10px' }}
                />
                <Skeleton variant="rectangular" width={210} height={50} />
              </Box>
              <Box mt={3}></Box>
              <Skeleton variant="rectangular" height={50} />
              <Box mt={3}></Box>
              <Skeleton variant="rectangular" height={150} />
              <Grid container item justifyContent="flex-end" mt={4}>
                <Box display="flex" alignContent="center">
                  <Skeleton variant="rectangular" width={90} height={44} />
                  <Skeleton
                    variant="rectangular"
                    width={90}
                    height={44}
                    sx={{ marginLeft: '8px' }}
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default DelegationFormSkeleton;
