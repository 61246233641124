import day from '@_plugins/dayjs';
import {
  CourseType,
  CourseTypeHttp,
  CourseTypeWizard,
} from '@data/models/course-type.interface';

export const toCourseType = (
  courseType: Partial<CourseTypeHttp>,
): CourseType => {
  return {
    id: courseType.id || '',
    label: courseType.attributes?.label || '',
    createdAt: day(courseType.attributes?.createdAt),
    updatedAt: day(courseType.attributes?.updatedAt),
  };
};

export const toCourseTypeWizard = (
  courseType: Partial<CourseType>,
): CourseTypeWizard => {
  return {
    type: 'course_type_repositories',
    id: courseType.id || '',
    attributes: {
      label: courseType.label || '',
    },
  };
};
