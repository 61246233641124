interface BadgeTitleOptions {
  background?: string;
  color?: string;
  size?: number;
  position?: string;
  radius?: number;
  src?: string;
  onChange?: () => void;
}

export default class BadgeTitle {
  private canvas: HTMLCanvasElement;
  private src: string;
  private ctx: CanvasRenderingContext2D;

  private faviconEL = document.querySelector('link[rel$=icon]');
  private radius!: number;

  private offset: { x: number; y: number } = { x: 0, y: 0 };

  private img!: HTMLImageElement;
  private badgeSize!: number;
  private backgroundColor!: CanvasGradient | CanvasPattern;
  private color!: CanvasGradient | CanvasPattern;
  private size!: number;
  private position!: 'n' | 'e' | 's' | 'w' | 'nw' | 'ne' | 'sw' | 'se';
  private _value!: string | number;
  private onChange!: () => void;
  private faviconSize!: number;

  constructor(options: BadgeTitleOptions) {
    Object.assign(
      this,
      {
        backgroundColor: '#f00',
        color: '#fff',
        size: 0.6, // 0..1 (Scale in respect to the favicon image size)
        position: 'ne', // Position inside favicon "n", "e", "s", "w", "ne", "nw", "se", "sw"
        radius: 8, // Border radius
        src: '', // Favicon source (dafaults to the <link> icon href)
        onChange: () => {
          return;
        },
      },
      options,
    );
    this.canvas = document.createElement('canvas');
    this.src = options.src || this.faviconEL?.getAttribute('href') || '';
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
  }

  private _drawIcon(): void {
    this.ctx?.clearRect(0, 0, this.faviconSize, this.faviconSize);
    this.ctx?.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
  }

  private _drawShape(): void {
    const r = this.radius;
    const xa = this.offset.x || 0;
    const ya = this.offset.y || 0;
    const xb = (this.offset.x || 0) + this.badgeSize;
    const yb = (this.offset.y || 0) + this.badgeSize;
    this.ctx?.beginPath();
    this.ctx?.moveTo(xb - r, ya);
    this.ctx?.quadraticCurveTo(xb, ya, xb, ya + r);
    this.ctx?.lineTo(xb, yb - r);
    this.ctx?.quadraticCurveTo(xb, yb, xb - r, yb);
    this.ctx?.lineTo(xa + r, yb);
    this.ctx?.quadraticCurveTo(xa, yb, xa, yb - r);
    this.ctx?.lineTo(xa, ya + r);
    this.ctx?.quadraticCurveTo(xa, ya, xa + r, ya);
    this.ctx.fillStyle = this.backgroundColor;
    this.ctx?.fill();
    this.ctx?.closePath();
  }

  private _drawVal() {
    const margin = (this.badgeSize * 0.18) / 2;
    this.ctx?.beginPath();
    this.ctx.textBaseline = 'middle';
    this.ctx.textAlign = 'center';
    this.ctx.font = `bold ${this.badgeSize * 0.82}px Arial`;
    this.ctx.fillStyle = this.color;
    this.ctx?.fillText(
      this.value.toString(),
      this.badgeSize / 2 + (this.offset.x || 0),
      this.badgeSize / 2 + (this.offset.y || 0) + margin,
    );
    this.ctx.closePath();
  }

  private _drawFavicon() {
    this.faviconEL?.setAttribute('href', this.dataURL);
  }

  private _draw() {
    this._drawIcon();
    if (this.value) this._drawShape();
    if (this.value) this._drawVal();

    if (!this.value) {
      this._drawIcon();
    }
    this._drawFavicon();
  }

  private _setup() {
    this.faviconSize = this.img.naturalWidth;
    this.badgeSize = this.faviconSize * this.size;
    this.canvas.width = this.faviconSize;
    this.canvas.height = this.faviconSize;
    const sd = this.faviconSize - this.badgeSize;
    const sd2 = sd / 2;
    this.offset = {
      n: { x: sd2, y: 0 },
      e: { x: sd, y: sd2 },
      s: { x: sd2, y: sd },
      w: { x: 0, y: sd2 },
      nw: { x: 0, y: 0 },
      ne: { x: sd, y: 0 },
      sw: { x: 0, y: sd },
      se: { x: sd, y: sd },
    }[this.position] || { x: sd, y: 0 };
  }

  // Public functions / methods:

  private update() {
    this._value = Math.min(99, parseInt(this._value.toString(), 10));
    if (this.img) {
      this._draw();
      if (this.onChange) this.onChange.call(this);
    } else {
      this.img = new Image();
      this.img.addEventListener('load', () => {
        this._setup();
        this._draw();
        if (this.onChange) this.onChange.call(this);
      });
      this.img.src = this.src;
    }
  }

  get dataURL(): string {
    return this.canvas.toDataURL();
  }

  get value(): string | number {
    return this._value;
  }

  set value(val: string | number) {
    this._value = val;
    this.update();
  }
}
