import day from '@_plugins/dayjs';
import {
  ValidationType,
  ValidationTypeHttp,
  ValidationTypeWizard,
} from '@data/models/validation-type.interface';

import { toBoolean } from './api.dto';

export const toValidationType = (
  validationType: Partial<ValidationTypeHttp>,
): ValidationType => {
  return {
    id: validationType.id || '',
    label: validationType.attributes?.label || '',
    isCCN: toBoolean(validationType.attributes?.is_ccn),
    createdAt: day(validationType.attributes?.createdAt),
    updatedAt: day(validationType.attributes?.updatedAt),
  };
};

export const toValidationTypeWizard = (
  validationType: Partial<ValidationType>,
): ValidationTypeWizard => {
  return {
    type: 'validation_type_repositories',
    id: validationType.id || '',
    attributes: {
      label: validationType.label || '',
      is_ccn: validationType.isCCN || false,
    },
  };
};
