/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, combineReducers } from 'redux';
import { default as controlReducer } from '@components/Konami/Minesweeper/store/modules/control';
import { persistStore, persistReducer } from 'redux-persist';
import indexeddbStorage from 'redux-persist-indexeddb-storage';

const persistConfig = {
  key: 'root',
  storage: indexeddbStorage('erpf'),
  whitelist: ['temporaryDocuments'],
};

const rootReducer = combineReducers({
  control: controlReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);
export { store, persistor };
