import day from '@_plugins/dayjs';
import { toApiDate } from '@data/dto/api.dto';
import {
  COMMUNICATION_TYPES,
  Communication,
  CommunicationHttp,
  CommunicationIncluded,
  CommunicationRawIncluded,
  CommunicationWizard,
} from '@data/models/communication.interface';
import { ContactHttp } from '@data/models/contact.interface';
import { DocumentVersionHttp } from '@data/models/document-version.interface';
import { DocumentHttp } from '@data/models/document.interface';
import { TrainingCourse } from '@data/models/training-course.interface';

import { toContact } from './contact.dto';
import { toDocument } from './document.dto';

export const toCommunicationIncluded = (
  included: CommunicationRawIncluded,
): CommunicationIncluded => {
  return {
    communications: included.filter(
      ({ type }) => type === 'communications',
    ) as CommunicationHttp[],
    documents: included.filter(
      ({ type }) => type === 'documents',
    ) as DocumentHttp[],
    document_versions: included.filter(
      ({ type }) => type === 'document_versions',
    ) as DocumentVersionHttp[],
    contacts: included.filter(
      ({ type }) => type === 'contacts',
    ) as ContactHttp[],
  };
};

export const toCommunication = (
  { id, attributes, relationships }: Partial<CommunicationHttp>,
  included: Partial<CommunicationIncluded>,
): Communication => {
  const documentsId = relationships?.documents.data?.map(({ id }) => id) || [];

  const documentsHttp =
    included?.documents?.filter(({ id }) => documentsId.includes(id)) || [];

  const repliesId = relationships?.children?.data?.map(({ id }) => id) || [];

  const repliesHttp =
    included?.communications?.filter(({ id }) => repliesId.includes(id)) || [];

  const recipientsId = relationships?.contacts.data?.map(({ id }) => id) || [];

  const recipientsHttp =
    included?.contacts?.filter(({ id }) => recipientsId.includes(id)) || [];

  return {
    id: id || '',
    userId: attributes?.user_id?.toString() || '',
    bodyHtml: attributes?.body_html || '',
    bodyPlain: attributes?.body_plain || '',
    isRead: (attributes?.read_at && true) || false,
    fromEmail: attributes?.from_email || '',
    readAt: day(attributes?.read_at),
    createdAt: day(attributes?.createdAt),
    documents: documentsHttp.map((doc) => toDocument(doc, included)),
    replies: repliesHttp.map((communication) =>
      toCommunication(communication, included),
    ),
    fromName: attributes?.from_name || '',
    type: attributes?.communication_type || COMMUNICATION_TYPES.GOOGLE_EMAIL,
    updatedAt: day(attributes?.createdAt),
    recipients: recipientsHttp.map(toContact),
    subject: attributes?.subject || '',
  };
};

export const toCommunicationWizard = (
  communication: Partial<Communication>,
  { id: trainingCourseId }: Pick<TrainingCourse, 'id'>,
  { id: parentId }: Pick<Communication, 'id'>,
): CommunicationWizard => {
  return {
    type: 'communications',
    id: communication.id || '',
    attributes: {
      user_id: parseInt(communication.userId || ''),
      from_name: communication.fromName || '',
      body_plain: communication.bodyPlain || '',
      communication_type:
        communication.type || COMMUNICATION_TYPES.GOOGLE_EMAIL,
      subject: communication.subject || '',
      read_at: toApiDate(communication.readAt),
    },
    relationships: {
      training_course: {
        data: {
          type: 'training_courses',
          id: trainingCourseId || '',
        },
      },
      documents: {
        data: communication.documents?.map(({ id }) => ({
          type: 'documents',
          id: id || '',
        })),
      },
      contacts: {
        data: communication.recipients?.map(({ id }) => ({
          type: 'contacts',
          id: id || '',
        })),
      },
      parent: {
        data:
          (parentId && {
            id: parentId,
            type: 'communications',
          }) ||
          null,
      },
    },
  };
};
