import day from '@_plugins/dayjs';
import { toApiDate } from '@data/dto/api.dto';
import { toTrainingActionCandidateTraining } from '@data/dto/training-action-candidate-trainings.dto';
import { CityHttp } from '@data/models/city.interface';
import { TrainingActionCandidateTrainingHttp } from '@data/models/training-action-candidate-training.interface';
import { TrainingCourseSessionHttp } from '@data/models/training-course-session.interface';
import {
  TrainingCourseTraining,
  TrainingCourseTrainingHttp,
  TrainingCourseTrainingSkillLevel,
  TrainingCourseTrainingType,
} from '@data/models/training-course-training.interface';
import { Training, TrainingOptionHttp } from '@data/models/training.interface';
import { ValidationTypeHttp } from '@data/models/validation-type.interface';

import { toApiFloat, toBoolean } from './api.dto';
import { toCity } from './city.dto';
import { toTrainingCourseSession } from './training-course-session.dto';
import { toTrainingOption } from './training.dto';
import { toValidationType } from './validation-type.dto';

// eslint-disable-next-line complexity
export const toTrainingCourseTrainingHttp = (
  training: Partial<TrainingCourseTraining>,
  trainingCourseId: string | null,
  educationalPathId?: string | null,
): TrainingCourseTrainingHttp => {
  const trainingHttp: TrainingCourseTrainingHttp = {
    type: 'training_course_trainings',
    id: training.id || '',
    attributes: {
      position: training.position || 0,
      label: training.label || '',
      preconization_reason: training.preconizationReason || '',
      cost: toApiFloat(training.cost),
      description: training.description || '',
      duration: toApiFloat(training.duration),
      is_out_of_catalog: training.isOutOfCatalog || false,
      has_quote: training.hasQuote || false,
      interlocutor: training.interlocutor || '',
      is_training_organism_contacted:
        training.isTrainingOrganismContacted || false,
      is_qualiopi: training.isQualiopi || false,
      organization_name: training.organizationName || '',
      organization_city: training.organizationCity || '',
      skill_level:
        training.skillLevel || TrainingCourseTrainingSkillLevel.NOT_DEFINED,
      training_type: training.trainingType || TrainingCourseTrainingType.INTER,
      expected_from_date:
        (training.isTrainingOrganismContacted &&
          toApiDate(training.expectedFromDate)) ||
        '',
      expected_to_date:
        (training.isTrainingOrganismContacted &&
          toApiDate(training.expectedToDate)) ||
        '',
    },
    relationships: {
      training_course: {
        data: {
          id: trainingCourseId || '',
          type: 'training_courses',
        },
      },
      training_options: {
        data: (training.options || []).map((option) => ({
          id: option.id || '',
          type: 'training_options',
        })),
      },
      educational_path: {
        data: {
          id: educationalPathId || '',
          type: 'training_course_educational_paths',
        },
      },
    },
  };

  if (training.invoiceType) {
    trainingHttp.attributes.invoice_type = training.invoiceType;
  }

  if (training.prescriber) {
    trainingHttp.attributes.prescriber = training.prescriber;
  }

  if (training.city?.id) {
    trainingHttp.relationships.city = {
      data: {
        id: training.city?.id || '',
        type: 'cities',
      },
    };
  }

  if (training.expectedCity?.id) {
    trainingHttp.relationships.expected_city = {
      data: {
        id: training.expectedCity?.id || '',
        type: 'cities',
      },
    };
  }

  if (training.trainingId) {
    trainingHttp.relationships.training = {
      data: {
        id: training.trainingId || '',
        type: 'trainings',
      },
    };
  }

  if (training.validationType?.id) {
    trainingHttp.relationships.validation_type_repository = {
      data: {
        id: training.validationType?.id || '',
        type: 'validation_type_repositories',
      },
    };
  }

  return trainingHttp;
};

// eslint-disable-next-line complexity
export const fromTrainingCourseTrainingHttp = (
  { attributes, relationships, id }: Partial<TrainingCourseTrainingHttp>,
  cities: CityHttp[] = [],
  options: TrainingOptionHttp[] = [],
  sessions: TrainingCourseSessionHttp[] = [],
  validation_type: ValidationTypeHttp[] = [],
  candidateTrainings: TrainingActionCandidateTrainingHttp[] = [],
): TrainingCourseTraining => {
  const resultHttp: Partial<TrainingActionCandidateTrainingHttp> =
    candidateTrainings.find(
      (candidateTraining) =>
        candidateTraining.relationships.training_course_training.data?.id ===
        id,
    ) || {};

  const optionsId = relationships?.training_options?.data?.map(({ id }) => id);
  const optionsHttp: TrainingOptionHttp[] = options.filter(({ id }) =>
    optionsId?.includes(id),
  );

  const sessionsHttp: TrainingCourseSessionHttp[] = sessions.filter(
    (session) =>
      session.relationships.training_course_training?.data?.id === id,
  );

  const expectedCityHttp: Partial<CityHttp> =
    cities.find(({ id }) => id === relationships?.expected_city?.data?.id) ||
    {};

  const cityHttp: Partial<CityHttp> =
    cities.find(({ id }) => id === relationships?.city?.data?.id) || {};

  const validationTypeHttp: Partial<ValidationTypeHttp> =
    validation_type.find(
      ({ id }) => id === relationships?.validation_type_repository?.data?.id,
    ) || {};

  const training: TrainingCourseTraining = {
    id: id || '',
    uuid: new Date().valueOf().toString(),
    trainingId: relationships?.training?.data?.id || '',
    preconizationReason: attributes?.preconization_reason || '',
    position: attributes?.position || 0,
    label: attributes?.label || '',
    options: optionsHttp.map(toTrainingOption),
    expectedCity: toCity(expectedCityHttp),
    city: toCity(cityHttp),
    cost: toApiFloat(attributes?.cost),
    sessions: sessionsHttp.map(toTrainingCourseSession),
    description: attributes?.description || '',
    duration: toApiFloat(attributes?.duration),
    isTrainingOrganismContacted: toBoolean(
      attributes?.is_training_organism_contacted,
    ),
    isOutOfCatalog: toBoolean(attributes?.is_out_of_catalog),
    hasQuote: toBoolean(attributes?.has_quote),
    interlocutor: attributes?.interlocutor || '',
    invoiceType: attributes?.invoice_type || null,
    isQualiopi: toBoolean(attributes?.is_qualiopi),
    organizationName: attributes?.organization_name || '',
    organizationCity: attributes?.organization_city || '',
    prescriber: attributes?.prescriber || null,
    skillLevel:
      attributes?.skill_level || TrainingCourseTrainingSkillLevel.BEGINNER,
    availableOptions: optionsHttp.map(toTrainingOption),
    trainingType: attributes?.training_type || TrainingCourseTrainingType.INTER,
    validationType: toValidationType(validationTypeHttp),
    expectedFromDate: day(attributes?.expected_from_date),
    expectedToDate: day(attributes?.expected_to_date),
  };
  if (candidateTrainings.length > 0) {
    training.result = toTrainingActionCandidateTraining(resultHttp);
  }

  return training;
};

export const toTrainingCourseTraining = (
  training: Partial<Training>,
  position = 0,
): TrainingCourseTraining => {
  return {
    id: '',
    uuid: new Date().valueOf().toString(),
    trainingId: training.id || '',
    position,
    label: training.label || '',
    preconizationReason: '',
    options: [],
    expectedCity: toCity({}),
    city: toCity({}),
    cost: '',
    sessions: [],
    description: '',
    duration: '',
    isOutOfCatalog: !training.id,
    hasQuote: false,
    interlocutor: '',
    invoiceType: null,
    isTrainingOrganismContacted: false,
    isQualiopi: false,
    organizationName: '',
    organizationCity: '',
    prescriber: null,
    skillLevel: TrainingCourseTrainingSkillLevel.NOT_DEFINED,
    trainingType: TrainingCourseTrainingType.INTER,
    availableOptions: training.options || [],
    validationType: toValidationType({}),
    expectedFromDate: day(''),
    expectedToDate: day(''),
  };
};
