import { City } from './city.interface';
import { TrainingCourseSession } from './training-course-session.interface';
import { TrainingOption, TrainingOptionHttp } from './training.interface';
import { ValidationType } from './validation-type.interface';
import { Dayjs } from 'dayjs';
import { TrainingActionCandidateTraining } from './training-action-candidate-training.interface';

export enum TrainingCourseTrainingPrescriber {
  CUSTOMER = 'CUSTOMER',
  AGENCY = 'AGENCY',
  CPEF = 'CPEF',
}

export enum TrainingCourseTrainingInvoiceType {
  DAILY_COST = 'DAILY_COST',
  HOUR_COST = 'HOUR_COST',
  INDIVIDUAL_COST = 'INDIVIDUAL_COST',
  GROUP_COST = 'GROUP_COST',
}

export enum TrainingCourseTrainingSkillLevel {
  NOT_DEFINED = 'NOT_DEFINED',
  BEGINNER = 'BEGINNER',
  SKILLED = 'SKILLED',
  MAC = 'MAC',
  CATCHING_UP = 'CATCHING_UP',
}

export enum TrainingCourseTrainingType {
  INTER = 'INTER',
  INTRA = 'INTRA',
  DISTANCIAL = 'DISTANCIAL',
  BLENDED_LEARNING = 'BLENDED_LEARNING',
}

export type TrainingCourseTrainingRawIncluded = Array<TrainingOptionHttp>;

export interface TrainingCourseTrainingHttp {
  type: 'training_course_trainings';
  id: string;
  attributes: {
    position: number;
    label: string;
    preconization_reason: string;
    skill_level: TrainingCourseTrainingSkillLevel;
    prescriber?: TrainingCourseTrainingPrescriber | null;
    description: string | null;
    organization_name: string | null;
    organization_city: string | null;
    interlocutor: string | null;
    is_out_of_catalog: boolean | number;
    is_training_organism_contacted: boolean | number;
    is_qualiopi: boolean | number;
    has_quote: boolean | number;
    duration: string;
    invoice_type?: TrainingCourseTrainingInvoiceType | null;
    training_type?: TrainingCourseTrainingType | null;
    cost: string;
    expected_from_date: string | null;
    expected_to_date: string | null;
  };
  relationships: {
    training_course: {
      data?: {
        type: 'training_courses';
        id: string;
      };
    };
    educational_path?: {
      data?: {
        type: 'training_course_educational_paths';
        id: string;
      };
    };
    training?: {
      data?: {
        type: 'trainings';
        id: string;
      } | null;
    };
    expected_city?: {
      data?: {
        type: 'cities';
        id: string;
      } | null;
    };
    city?: {
      data?: {
        type: 'cities';
        id: string;
      } | null;
    };
    training_options?: {
      data?: {
        type: 'training_options';
        id: string;
      }[];
    };
    sessions?: {
      data?: {
        type: 'training_course_sessions';
        id: string;
      }[];
    };
    validation_type_repository?: {
      data?: {
        type: 'validation_type_repositories';
        id: string;
      } | null;
    };
    candidate_trainings?: {
      data?: {
        type: 'candidate_trainings';
        id: string;
      }[];
    };
  };
}

export interface TrainingCourseTraining {
  id: string;
  uuid: string;
  trainingId: string;
  position: number;
  label: string;
  preconizationReason: string;
  skillLevel: TrainingCourseTrainingSkillLevel;
  options: TrainingOption[];
  availableOptions: TrainingOption[];
  expectedCity: City;
  prescriber: TrainingCourseTrainingPrescriber | null;
  description: string;
  organizationName: string;
  organizationCity: string;
  interlocutor: string;
  city: City;
  isTrainingOrganismContacted: boolean;
  isOutOfCatalog: boolean;
  isQualiopi: boolean;
  hasQuote: boolean;
  duration: string;
  invoiceType: TrainingCourseTrainingInvoiceType | null;
  trainingType: TrainingCourseTrainingType | null;
  cost: string;
  sessions: TrainingCourseSession[];
  validationType: ValidationType;
  expectedFromDate: Dayjs | null;
  expectedToDate: Dayjs | null;
  result?: TrainingActionCandidateTraining;
}
