import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ChatState, createChatSlice } from './slices/chat.slice';
import { CommentState, createCommentSlice } from './slices/comment.slice';
import {
  createUnreadCommunicationSlice,
  UnreadCommunicationState,
} from './slices/communication.slice';
import { createLoadingSlice, LoadingState } from './slices/is-loading.slice';
import {
  createDocumentToQualifySlice,
  DocumentToQualifyState,
} from './slices/document-to-qualify.slice';
import { createManagerSlice, ManagerState } from './slices/manager.slice';
import { createMinWageSlice, MinWageState } from './slices/min-wage.slice';
import { createToastSlice, ToastState } from './slices/toast.slice';
import { createModalSlice, ModalState } from './slices/modal.slice';
import { createUserSlice, UserState } from './slices/user.slice';
import {
  createRefundRequestSlice,
  RefundRequestState,
} from './slices/refund-request.slice';
import {
  createTrainingCourseSlice,
  TrainingCourseState,
} from './slices/training-course.slice';
import {
  createNotificationSlice,
  NotificationState,
} from './slices/notification.slice';
import {
  createNewCommentSlice,
  NewCommentState,
} from './slices/new-comment.slice';
import {
  createTemporaryDocumentSlice,
  TemporaryDocumentState,
} from './slices/temporary-document.slice';
import {
  createTrainingActionDeviceMapSlice,
  TrainingActionDeviceMapState,
} from './slices/training-action-device-map.slice';
import {
  createTrainingActionSlice,
  TrainingActionState,
} from './slices/training-action.slice';
import { PecRateState, createPecRateSlice } from './slices/pec-rate.slice';
import {
  DelegationState,
  createDelegationSlice,
} from './slices/delegation.slice';
import {
  InactivityState,
  createInactivitySlice,
} from './slices/inactivity.slice';

const useStore = create<
  ChatState &
    CommentState &
    UnreadCommunicationState &
    LoadingState &
    DocumentToQualifyState &
    ManagerState &
    MinWageState &
    ToastState &
    ModalState &
    NewCommentState &
    UserState &
    NotificationState &
    TemporaryDocumentState &
    TrainingActionDeviceMapState &
    TrainingCourseState &
    TrainingActionState &
    RefundRequestState &
    PecRateState &
    DelegationState &
    InactivityState
>()(
  devtools(
    (...a) => ({
      ...createChatSlice(...a),
      ...createCommentSlice(...a),
      ...createUnreadCommunicationSlice(...a),
      ...createLoadingSlice(...a),
      ...createDocumentToQualifySlice(...a),
      ...createManagerSlice(...a),
      ...createMinWageSlice(...a),
      ...createToastSlice(...a),
      ...createModalSlice(...a),
      ...createNewCommentSlice(...a),
      ...createUserSlice(...a),
      ...createNotificationSlice(...a),
      ...createTemporaryDocumentSlice(...a),
      ...createTrainingActionDeviceMapSlice(...a),
      ...createTrainingCourseSlice(...a),
      ...createTrainingActionSlice(...a),
      ...createRefundRequestSlice(...a),
      ...createPecRateSlice(...a),
      ...createDelegationSlice(...a),
      ...createInactivitySlice(...a),
    }),
    {
      name: 'ALEC - ERP FORMATION (ZUSTAND)',
    },
  ),
);
export default useStore;
