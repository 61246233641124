export const Delays = {
  APP_READY_DELAY: 1600,
  PAGE_READY_DELAY: 450,
  NOTIFICATION_DEFAULT_DELAY: 10000,
  ANCHOR_DELAY: 500,
  COMPONENT_DEBOUNCE_DELAY: 200,
  RETRY_DELAY: {
    api: 1500,
    component: 1000,
  },
};
