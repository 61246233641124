import { Dayjs } from 'dayjs';

export type FinancingTypeRawIncluded = Array<FinancingTypeHttp>;

export type FinancingTypeIncluded = {
  financingTypes: FinancingTypeHttp[];
};

export enum FINANCING_TYPE_TYPE {
  OPCO = 1,
  MISCELLANEOUS_SERVICES = 2,
  COFI = 3,
  VARIOUS_AIDS = 4,
}

export interface FinancingTypeHttp {
  type: 'financing_type_repositories';
  id: string;
  attributes: {
    label: string;
    position: number;
    financing_type: FINANCING_TYPE_TYPE;
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    children: {
      data?: {
        type: 'financing_type_repositories';
        id: string;
      }[];
    };
    parent: {
      data?: {
        type: 'financing_type_repositories';
        id: string;
      };
    };
  };
}

export interface FinancingType {
  id: string;
  label: string;
  position: number;
  financingType: FINANCING_TYPE_TYPE | null;
  childrens?: FinancingType[];
  parentLabel?: string;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}

export interface FinancingTypeWizard {
  type: 'financing_type_repositories';
  id: string;
  attributes: {
    label: string;
    financing_type: number | null;
  };
  relationships?: {
    parent?: {
      data: {
        type: 'financing_type_repositories';
        id: string;
      };
    };
  };
}
