import { Dayjs } from 'dayjs';

export enum PERIOD_TYPE {
  EXTERNAL = 'external',
  PRACTICAL = 'practical',
  ABSENCE = 'absence',
}

export interface TrainingActionCandidatePeriodHttp {
  type: 'training_action_candidate_periods';
  id: string;
  attributes: {
    label: string;
    from_date: string;
    to_date: string;
    expected_duration: string;
    real_duration: string;
    period_type: PERIOD_TYPE;
    hour_cost: string;
    expected_cost: string;
    real_cost: string;
    anael_cost: string;
    training_course_rate: string;
    absence_reason: string;
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    training_action?: {
      data?: {
        type: 'training_actions';
        id: string;
      };
    };
    training_course_training: {
      data?: {
        type: 'training_course_trainings';
        id: string;
      };
    };
    training_action_candidate: {
      data?: {
        type: 'training_action_candidates';
        id: string;
      };
    };
  };
}

export interface TrainingActionCandidatePeriod {
  id: string;
  label: string;
  expectedDuration: string;
  realDuration: string;
  periodType: PERIOD_TYPE;
  hourCost: string;
  expectedCost: string;
  realCost: string;
  anaelCost: string;
  createdAt: Dayjs;
  updatedAt: Dayjs;
  fromDate: Dayjs;
  toDate: Dayjs;
  absenceReason: string;
  trainingCourseTrainingId: string;
  trainingCourseRate: string;
  trainingActionCandidateId: string;
}

export interface TrainingActionCandidatePeriodWizard {
  type: 'training_action_candidate_periods';
  id: string;
  attributes: {
    label: string;
    expected_duration: string;
    real_duration: string;
    period_type: PERIOD_TYPE;
    hour_cost: string;
    expected_cost: string;
    real_cost: string;
    anael_cost: string;
    from_date: string;
    to_date: string;
    absence_reason: string;
    training_course_rate: string;
  };
  relationships: {
    training_course_training: {
      data: {
        type: 'training_course_trainings';
        id: string;
      };
    };
    training_action_candidate: {
      data: {
        type: 'training_action_candidates';
        id: string;
      };
    };
  };
}

export interface MonthPeriod {
  ids: string[];
  month: number;
  trainingDayPerMonth: number;
  expectedDurationPerMonth: number;
  realDurationPerMonth: number;
  monthName: string;
}
