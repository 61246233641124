import day from '@_plugins/dayjs';
import {
  CandidateType,
  CandidateTypeHttp,
  CandidateTypeType,
  CandidateTypeWizard,
} from '@data/models/candidate-type.interface';

export const toCandidateType = (
  candidateType: Partial<CandidateTypeHttp>,
): CandidateType => {
  return {
    id: candidateType.id || '',
    label: candidateType.attributes?.label || '',
    position: candidateType?.attributes?.position || 0,
    mapping:
      candidateType?.attributes?.mapping ||
      CandidateTypeType.UNDEFINED_TYPE_CANDIDATE,
    createdAt: day(candidateType.attributes?.createdAt),
    updatedAt: day(candidateType.attributes?.updatedAt),
  };
};

export const toCandidateTypeWizard = (
  candidateType: Partial<CandidateType>,
): CandidateTypeWizard => {
  return {
    type: 'candidate_type_repositories',
    id: candidateType.id || '',
    attributes: {
      label: candidateType.label || '',
      mapping:
        candidateType.mapping || CandidateTypeType.UNDEFINED_TYPE_CANDIDATE,
    },
  };
};
