import { Dayjs } from 'dayjs';
import { TrainingActionCandidate } from '@data/models/training-action-candidate.interface';

export enum DeviceTypePlan {
  PPRO = 'Plan de professionnalisation',
  PPLAN = 'Plan de formation',
}

export enum DeviceTypeType {
  POEC = 'POEC',
  AFPR = 'AFPR',
  PLAN = 'PLAN',
  CIPI = 'CIPI',
  CDPI = 'CDPI',
  CPro = 'CPro',
}

export enum PlanInitials {
  PRO = 'PRO',
  PLAN = 'PLAN',
}
export interface DeviceTypeHttp {
  type: 'device_type_repositories';
  id: string;
  attributes: {
    id: string;
    createdAt: string;
    duration_max: string;
    duration_min: string;
    initials: string;
    label: string;
    plan_initials: string;
    position: number;
    updatedAt: string;
    national_agency: boolean | number;
  };
  links: {
    self: string;
  };
}

export interface DeviceType {
  id: string;
  durationMax?: string;
  durationMin?: string;
  initials: string;
  label: string;
  planInitials: string;
  nationalAgency: boolean;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}

export interface DeviceTypeWizard {
  type: 'device_type_repositories';
  id: string;
  attributes: {
    duration_max?: string;
    duration_min?: string;
    initials: string;
    label: string;
    plan_initials: string;
    national_agency: boolean;
  };
}

export interface DeviceTypeCandidate extends DeviceType {
  associatedCandidates: TrainingActionCandidate[];
}
