import styled from 'styled-components';

export interface AnimationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
// const createAnimationStyles = ({
//   keyframes,
//   delay,
//   duration,
//   timing,
//   fill,
//   origin,
//   onTop
// }: AnimationProps) => css`
//   animation-name: ${keyframes};
//   animation-delay: ${delay};
//   animation-duration: ${duration}ms;
//   animation-timing-function: ${timing};
//   animation-fill-mode: ${fill};
//   transform-origin: ${origin || '50% 50%'};

//   ${onTop &&
//   css`
//     z-index: 1;
//   `}
// `;

// const stateMap = {
//     entering: ({ enterAnimation }: { enterAnimation: AnimationProps }) => {
//         return css`
//             ${createAnimationStyles(enterAnimation)};
//         `;
//     },
//     exiting: ({ exitAnimation }: { exitAnimation: AnimationProps }) => {
//         return css`
//             ${createAnimationStyles(exitAnimation)};
//         `;
//     }
// };

export const PageTransitionGroup = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const PageTransitionWrapper = styled.div`
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  width: 100%;
  transform: none !important;
`;
