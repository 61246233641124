import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const ActionsContainer = ({ children }: Props): JSX.Element => {
  return <div>{children}</div>;
};

export default ActionsContainer;
