export const datatable = {
  loading: 'Chargement',
  pageText: 'Page ',
  ofText: ' sur ',
  perPageText: 'Résultats par page',
  showingText: 'Affichage ',
  clearAll: 'Tout effacer',
  clear: 'Effacer',
  showFilteringRow: 'Afficher les filters',
  hideFilteringRow: 'Masquer les filtres',
  dragHeaderToGroup: 'Déplacer le titre au groupe',
  disable: 'Désactiver',
  enable: 'Activer',
  sortAsc: 'Tri croissant',
  sortDesc: 'Tri décroissant',
  unsort: 'Supprimer le tri',
  group: 'Grouper',
  ungroup: 'Dégrouper',
  lockStart: 'Bloquer avant',
  lockEnd: 'Bloquer après',
  unlock: 'Débloquer',
  columns: 'Colonnes',
  contains: 'Contient',
  startsWith: 'Commence par',
  endsWith: 'Termine par',
  notContains: 'Ne contient pas',
  neq: 'Différent de',
  eq: 'Egal',
  notEmpty: "N'est pas vide",
  empty: 'Vide',
  lt: 'Inférieur à',
  lte: 'Inférieur ou égal à',
  gt: 'Supérieur à',
  gte: 'Supérieur ou égal à',
  'calendar.todayButtonText': "Aujourd'hui",
  'calendar.clearButtonText': 'Effacer',
  'calendar.okButtonText': 'OK',
  'calendar.cancelButtonText': 'Annuler',
  emptyText: 'Aucune donnée à afficher',
};
