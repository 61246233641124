import { Dayjs } from 'dayjs';

export enum GraduateEducation {
  Qualification = 'Diplôme',
  RNCP = 'Titre RNCP',
}
export interface ValidationTypeHttp {
  type: 'validation_type_repositories';
  id: string;
  attributes: {
    label: string;
    is_ccn: number;
    createdAt: string;
    updatedAt: string;
  };
}

export interface ValidationType {
  id: string;
  label: string;
  isCCN: boolean;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}

export interface ValidationTypeWizard {
  type: 'validation_type_repositories';
  id: string;
  attributes: {
    label: string;
    is_ccn: boolean;
  };
}
