import { lazy } from 'react';
import TrainingCourseGroupSkeleton from '@components/Skeleton/TrainingCourseGroupSkeleton';
import TrainingCourseTrainingsSkeleton from '@components/Skeleton/TrainingCourseTrainingsSkeleton';
import { NavRoute } from '@data/models/navigation.interface';
import TrainingCourseCandidatesSkeleton from '@components/Skeleton/TrainingCourseCandidatesSkeleton';
import ConfirmationSkeleton from '@components/TrainingCourse/Skeleton/ConfirmationSkeleton';
import {
  faHomeLg,
  faUserGroup,
  faBookReader,
  faBuilding,
} from '@fortawesome/pro-solid-svg-icons';

import { IconProvider } from '@groupeactual/ui-kit';

const Trainings = lazy(
  () => import('@pages/TrainingCourseForm/pages/TrainingCourseTraining'),
);

const Groups = lazy(
  () => import('@pages/TrainingCourseForm/pages/TrainingCourseGroup'),
);

const PotentialCandidates = lazy(
  () => import('@pages/TrainingCourseForm/pages/TrainingCourseCandidates'),
);

const Mission = lazy(
  () => import('@pages/TrainingCourseForm/pages/TrainingCourseMission'),
);

const Initializer = lazy(
  () => import('@pages/TrainingCourseForm/pages/TrainingCourseInitializer'),
);

const Confirmation = lazy(
  () => import('@pages/TrainingCourseForm/pages/TrainingCourseConfirmation'),
);

const Error = lazy(
  () => import('@pages/TrainingCourseForm/pages/TrainingCourseError'),
);

export const trainingCourseRoutes: NavRoute[] = [
  {
    name: 'navigation.links.initializer',
    path: '/initialize',
    component: <Initializer />,
    hidden: true,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.training_groups',
    path: '/groups',
    component: <Groups />,
    skeleton: <TrainingCourseGroupSkeleton />,
    icon: <IconProvider icon={faBuilding} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.training_courses',
    path: '/trainings',
    component: <Trainings />,
    skeleton: <TrainingCourseTrainingsSkeleton />,
    icon: <IconProvider icon={faBookReader} size="sm" />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.potential_candidates',
    path: '/potential-candidates',
    skeleton: <TrainingCourseCandidatesSkeleton />,
    icon: <IconProvider icon={faUserGroup} size="sm" />,
    component: <PotentialCandidates />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.training_mission',
    path: '/training-mission',
    skeleton: <TrainingCourseCandidatesSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    component: <Mission />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.training_confirmation',
    path: '/confirmation',
    skeleton: <ConfirmationSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    hidden: true,
    component: <Confirmation />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
  {
    name: 'navigation.links.training_error',
    path: '/error',
    skeleton: <ConfirmationSkeleton />,
    icon: <IconProvider icon={faHomeLg} size="sm" />,
    hidden: true,
    component: <Error />,
    transitions: {
      prev: 'moveToRightFromLeft',
      next: 'moveToLeftFromRight',
    },
  },
];
