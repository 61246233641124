/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import ErrorService from 'core/error.service';

// internal
import { FilterParams } from '@data/models/filter.interface';
import { Meta, PaginateResult } from '@data/models/api.interface';
import {
  UserNotification,
  UserNotificationHttp,
} from '@data/models/user-notification.interface';
import { toUserNotification } from '@data/dto/user-notification.dto';

@injectable()
export default class UserNotificationService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly notificationEndpoint = '/user-notifications';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listUserNotifications(
    filters: FilterParams,
  ): Promise<PaginateResult<UserNotification[]>> {
    const { data: response, error } = await this.API?.get<{
      data: UserNotificationHttp[];
      meta: Meta;
    }>(this.notificationEndpoint, filters);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    return {
      items: response?.data?.map(toUserNotification) || [],
      totalSize: response?.meta?.page?.total || 0,
    };
  }

  public async toggleReadAt(
    userNotification: UserNotification,
  ): Promise<UserNotification | null> {
    const endpoint = `${this.notificationEndpoint}/${userNotification.id}/toggle-read-at`;

    const { data, error } = await this.API?.patch<
      { data: UserNotification },
      { data: UserNotificationHttp }
    >(endpoint, {
      data: { ...userNotification },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    return toUserNotification(data?.data || {});
  }

  public async markAllAsRead(): Promise<UserNotification[]> {
    const endpoint = `${this.notificationEndpoint}/mark-all-as-read`;

    const { data, error } = await this.API?.patch<
      { data: {} },
      { data: UserNotificationHttp[] }
    >(endpoint, {
      data: {},
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return [];
    }
    return (data?.data || []).map(toUserNotification);
  }
}
