import { Dayjs } from 'dayjs';

export enum CandidateTypeType {
  UNDEFINED_TYPE_CANDIDATE = 'UNDEFINED_TYPE_CANDIDATE',
  LOCAL_MISSION_CANDIDATE = 'LOCAL_MISSION_CANDIDATE',
  HANDICAPPED_CANDIDATE = 'HANDICAPPED_CANDIDATE',
}
export interface CandidateTypeHttp {
  type: 'candidate_type_repositories';
  id: string;
  attributes: {
    label: string;
    mapping: CandidateTypeType;
    position: number;
    createdAt: string;
    updatedAt: string;
  };
}

export interface CandidateType {
  id: string;
  label: string;
  mapping: CandidateTypeType;
  position: number;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}

export interface CandidateTypeWizard {
  type: 'candidate_type_repositories';
  id: string;
  attributes: {
    label: string;
    mapping: CandidateTypeType;
  };
}
