import dayjs from '@_plugins/dayjs';
import { DeviceTypeHttp } from '@data/models/device-type.interface';
import {
  TrainingActionDeviceMap,
  TrainingActionDeviceMapHttp,
  TrainingActionDeviceMapIncluded,
  TrainingActionDeviceMapRawIncluded,
  TrainingActionDeviceMapType,
  TrainingActionDeviceMapWizard,
} from '@data/models/training-action-device-map.interface';
import { TrainingAction } from '@data/models/training-action.interface';

import { toApiDate, toBoolean } from './api.dto';
import { toDeviceType } from './device-type.dto';

export const toTrainingActionDeviceMapIncluded = (
  included: TrainingActionDeviceMapRawIncluded = [],
): TrainingActionDeviceMapIncluded => {
  return {
    trainingActionDeviceMaps: included.filter(
      ({ type }) => type === 'training_action_device_maps',
    ) as TrainingActionDeviceMapHttp[],
    deviceTypes: included.filter(
      ({ type }) => type === 'device_type_repositories',
    ) as DeviceTypeHttp[],
  };
};

export const toTrainingActionDeviceMap = (
  { id, attributes, relationships }: Partial<TrainingActionDeviceMapHttp>,
  included: Partial<TrainingActionDeviceMapIncluded>,
): TrainingActionDeviceMap => {
  const childrenId = relationships?.children?.data?.map(({ id }) => id) || [];

  const childrenHttp =
    included.trainingActionDeviceMaps?.filter(({ id }) =>
      childrenId.includes(id),
    ) || [];

  const deviceTypeHttp =
    included.deviceTypes?.find(
      ({ id }) => id === relationships?.device_type?.data?.id,
    ) || {};

  return {
    id: id || '',
    label: attributes?.label || '',
    isChecked: toBoolean(attributes?.checked),
    deviceType: toDeviceType(deviceTypeHttp),
    children: childrenHttp.map((child) =>
      toTrainingActionDeviceMap(child, included),
    ),
    receivedAt:
      (attributes?.received_at && dayjs(attributes.received_at)) || null,
    refundAt: (attributes?.refund_at && dayjs(attributes.refund_at)) || null,
    sentAt: (attributes?.sent_at && dayjs(attributes.sent_at)) || null,
    type: attributes?.type || TrainingActionDeviceMapType.TRAINING_START,
    validatedAt:
      (attributes?.validated_at && dayjs(attributes.validated_at)) || null,
    hasReference: Boolean(attributes?.has_reference),
    reference: attributes?.reference || null,
    createdAt: dayjs(attributes?.createdAt || ''),
    updatedAt: dayjs(attributes?.updatedAt || ''),
  };
};

export const toTrainingActionDeviceMapWizard = (
  { id, deviceType, ...map }: Partial<TrainingActionDeviceMap>,
  action: Partial<TrainingAction>,
): TrainingActionDeviceMapWizard => {
  return {
    id: id || '',
    type: 'training_action_device_maps',
    attributes: {
      checked: toBoolean(map.isChecked),
      label: map.label || '',
      received_at: toApiDate(map.receivedAt),
      sent_at: toApiDate(map.sentAt),
      validated_at: toApiDate(map.validatedAt),
      refund_at: toApiDate(map.refundAt),
      // type: map.type || TrainingActionDeviceMapType.PARTNERS,
      reference: map.reference || '',
      has_reference: map.hasReference || false,
    },
    relationships: {
      device_type: {
        data: {
          type: 'device_type_repositories',
          id: deviceType?.id || '',
        },
      },
      training_action: {
        data: { type: 'training_actions', id: action?.id || '' },
      },
    },
  };
};
