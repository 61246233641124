import { Group } from '@data/models/group.interface';
import {
  TrainingCourseGroup,
  TrainingCourseGroupHttp,
} from '@data/models/training-course-group.interface';
import { User } from '@data/models/user.interface';
import { toBoolean } from './api.dto';

export const toTrainingCourseGroup = (
  user: Partial<User>,
  group: Partial<Group>,
): TrainingCourseGroup => {
  return <TrainingCourseGroup>{
    id: '',
    groupId: group.id || '',
    analyticLabel: group.analytic?.label || '',
    analyticCode: group.analytic?.code || '',
    analyticFull: `${String(group.analytic?.code)} - ${String(
      group.analytic?.label,
    )}`,
    directionCode: group.direction?.code || '',
    directionLabel: group.direction?.label || '',
    directionFull: `${String(group.direction?.code)} - ${String(
      group.direction?.label,
    )}`,
    operationalAgencyPhoneNumber: group.operational_agency?.phone_number || '',
    operationalAgencyCode: group.operational_agency?.code || '',
    operationalAgencyEmail: group.operational_agency?.email || '',
    fullName: user?.fullName || '',
    userEmail: user?.email || '',
    userPhone: '',
    companyCode: group.company?.code || '',
    companyLabel: group.company?.label || '',
    companyFullName: `${String(group.company?.code)} - ${String(
      group.company?.label,
    )}`,
    legalForm: group.company?.legal_form || '',
    rcsCity: group.rcs_city || '',
    socialCapital: group.social_capital || '',
    headOfficeAddress: group.head_office?.address || '',
    headOfficeAddress2: group.head_office?.address_2 || '',
    headOfficePostalCode: group.head_office?.postal_code || '',
    headOfficeCity: group.head_office?.city || '',
    siren: group.company?.siren || '',
    siret: `${String(group.company?.siren)}${String(group.company?.siret)}`,
    isMainGroup: false,
  };
};

export const fromTrainingCourseGroupHttp = ({
  attributes,
  id,
}: Partial<TrainingCourseGroupHttp>): TrainingCourseGroup => {
  return {
    id: id || '',
    groupId: '',
    analyticCode: attributes?.analytic_code || '',
    analyticLabel: attributes?.analytic_label || '',
    analyticFull: `${String(attributes?.analytic_code)} - ${String(
      attributes?.analytic_label,
    )}`,
    directionCode: attributes?.direction_code || '',
    directionLabel: attributes?.direction_label || '',
    directionFull: `${String(attributes?.direction_code)} - ${String(
      attributes?.direction_label,
    )}`,
    operationalAgencyPhoneNumber:
      attributes?.operational_agency_phone_number || '',
    operationalAgencyCode: attributes?.operational_agency_code || '',
    operationalAgencyEmail: attributes?.operational_agency_email || '',
    fullName: attributes?.recruiter_full_name || '',
    userEmail: attributes?.recruiter_email || '',
    userPhone: attributes?.recruiter_phone_number || '',
    companyCode: attributes?.company_code || '',
    companyLabel: attributes?.company_label || '',
    companyFullName: `${String(attributes?.company_code)} - ${String(
      attributes?.company_label,
    )}`,
    socialCapital: attributes?.social_capital || '',
    rcsCity: attributes?.rcs_city || '',
    legalForm: attributes?.legal_form || '',
    headOfficeAddress: attributes?.head_office_address || '',
    headOfficeAddress2: attributes?.head_office_address_2 || '',
    headOfficePostalCode: attributes?.head_office_postal_code || '',
    headOfficeCity: attributes?.head_office_city || '',
    siren: attributes?.siren || '',
    siret: attributes?.siret || '',
    isMainGroup: toBoolean(attributes?.is_main_group),
  };
};

// eslint-disable-next-line complexity
export const toTrainingCourseGroupHttp = (
  group: Partial<TrainingCourseGroup>,
  isMainGroup = false,
  trainingCourseId: string | null,
): TrainingCourseGroupHttp => {
  return {
    type: 'training_course_groups',
    id: group.id || '',
    attributes: {
      analytic_code: group.analyticCode || '',
      analytic_label: group.analyticLabel || '',
      direction_code: group.directionCode || '',
      direction_label: group.directionLabel || '',
      operational_agency_phone_number: group.operationalAgencyPhoneNumber || '',
      operational_agency_email: group.operationalAgencyPhoneNumber || '',
      operational_agency_code: group.operationalAgencyCode || '',
      recruiter_full_name: group.fullName || '',
      recruiter_email: group.userEmail || '',
      recruiter_phone_number: group.userPhone || '',
      is_main_group: isMainGroup,
      company_code: group.companyCode || '',
      company_label: group.companyLabel || '',
      social_capital: group.socialCapital || '',
      rcs_city: group.rcsCity || '',
      legal_form: group.legalForm || '',
      head_office_address: group.headOfficeAddress || '',
      head_office_address_2: group.headOfficeAddress2 || '',
      head_office_postal_code: group.headOfficePostalCode || '',
      head_office_city: group.headOfficeCity || '',
      siren: group.siren || '',
      siret: group.siret || '',
    },
    relationships: {
      training_course: {
        data: {
          id: trainingCourseId || '',
          type: 'training_courses',
        },
      },
    },
  };
};
