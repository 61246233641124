import { StateCreator } from 'zustand';

export interface InactivityState {
  inactive: boolean;
  setInactive: (bool: boolean) => void;
}

export const createInactivitySlice: StateCreator<
  InactivityState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  InactivityState
> = (set) => ({
  inactive: false,
  setInactive: (bool: boolean) => set(() => ({ inactive: bool })),
});
