import dayjs from '@_plugins/dayjs';
import {
  DeviceTypePlan,
  PlanInitials,
} from '@data/models/device-type.interface';
import {
  StatMe,
  StatMeHttp,
  StatRegionHttp,
  StatTypeHttp,
} from '@data/models/statistic.interface';
import { ChartData } from 'chart.js';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';

import { AverageStatistic } from '../models/statistic.interface';

export const toStatMe = (stats: Partial<StatMeHttp>): StatMe[] => {
  if (isEmpty(stats)) {
    return [];
  }

  const mines =
    stats.attributes?.data.me?.map((stat) => ({
      ...stat,
      type: 'me' as const,
    })) || [];

  const totals =
    stats.attributes?.data.total?.map((stat) => ({
      ...stat,
      type: 'total' as const,
    })) || [];
  return [...mines, ...totals];
};

export const fromStatTypeHttp = (
  stats: Partial<StatTypeHttp>,
  period: 'month' | 'year' | 'week' = 'month',
): ChartData<'line', number[], string> => {
  const groups = groupBy(stats?.attributes?.data || [], 'label');
  const toLabel = (key: string) => {
    switch (period) {
      case 'year':
        return key;
      case 'month':
        return dayjs(key).format('MM-YYYY');
      case 'week':
        return `Sem. ${key}`;
    }
  };
  return {
    labels: Object.keys(groups).map(toLabel),
    datasets: [
      {
        label: 'Non défini',
        data: Object.keys(groups).map(
          (key) => groups[key].find(({ type }) => !type)?.total || 0,
        ),
        fill: true,
        backgroundColor: 'rgba(228, 5, 33, .2)',
        borderColor: 'rgba(228, 5, 33, .7)',
        tension: 0.2,
      },
      {
        label: DeviceTypePlan.PPLAN,
        data: Object.keys(groups).map(
          (key) =>
            groups[key].find(({ type }) => type === PlanInitials.PLAN)?.total ||
            0,
        ),
        fill: true,
        backgroundColor: 'rgba(51, 182 ,121, .2)',
        borderColor: 'rgba(51, 182 ,121 ,.7)',
        tension: 0.2,
      },
      {
        label: DeviceTypePlan.PPRO,
        data: Object.keys(groups).map(
          (key) =>
            groups[key].find(({ type }) => type === PlanInitials.PRO)?.total ||
            0,
        ),
        fill: true,
        backgroundColor: 'rgba(33, 150, 243, .2)',
        borderColor: 'rgba(33, 150, 243, .7)',
        tension: 0.2,
      },
      {
        label: 'Total',
        data: Object.keys(groups).map((key) =>
          sumBy(groups[key], ({ total }) => total),
        ),
        fill: false,
        backgroundColor: 'rgba(246, 191, 38, .2)',
        borderColor: 'rgba(245, 124, 0, .7)',
        tension: 0.2,
      },
    ],
  };
};

export const fromStatRegionHttp = (
  stats: Partial<StatRegionHttp>,
): ChartData<'doughnut', number[], string> => {
  const genRanHex = (string: string | null) => {
    if (!string) {
      return '#1a73e8';
    }
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
  };
  return {
    labels:
      stats?.attributes?.data.map(
        ({ name }) => name || 'Aucune valeur disponible',
      ) || [],
    datasets: [
      {
        label: 'Nombre de demandes',
        data: stats?.attributes?.data.map(({ total }) => total) || [],
        backgroundColor: stats?.attributes?.data.map(({ name }) =>
          genRanHex(name),
        ),
      },
    ],
  };
};

export const toAverageStatistics = ({
  candidates,
  customers,
  estimators,
  groups,
  sessions,
  strategicAxes,
  trainings,
}: AverageStatistic) => {
  return {
    candidates: candidates || 0,
    customers: customers || 0,
    estimators: estimators || 0,
    groups: groups || 0,
    sessions: sessions || 0,
    strategicAxes: strategicAxes || 0,
    trainings: trainings || 0,
  };
};
