import { WithTranslation, withTranslation } from 'react-i18next';

import Logo from '@assets/images/logo-erpf.svg';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProvider, Text } from '@groupeactual/ui-kit';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useStore from '@stores/store';

const TrainingHeader = ({ t: trans }: WithTranslation): JSX.Element => {
  const { user, trainingCourse } = useStore();
  return (
    <div>
      <AppBar
        position="relative"
        color="default"
        sx={{
          boxShadow: 'rgb(39 39 39 / 12%) 0px 2px 10px 0px',
          backgroundColor: 'background.paper',
          zIndex: 2,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              px: '0 !important',
            }}
          >
            <Box
              display="flex"
              width="100%"
              flexDirection="row"
              alignItems="center"
            >
              <Box mr={1} width="45px">
                <Logo />
              </Box>
              <Box mr={3}>
                <Typography
                  variant="h6"
                  fontSize="1.5rem"
                  lineHeight="1"
                  color="greyDark"
                >
                  Alec
                </Typography>
                <Typography
                  variant="subtitle2"
                  lineHeight="1"
                  color="greyDark"
                  fontWeight="400"
                >
                  ERP Formation
                </Typography>
              </Box>
              <Box
                display="flex"
                flexGrow={1}
                alignItems="center"
                pl={3}
                sx={{
                  borderLeft: '1px solid #949494',
                }}
              >
                <Typography
                  variant="h5"
                  color="greyDark"
                  fontSize="1.3rem"
                  fontWeight="500"
                >
                  {(trainingCourse?.fromLucie && (
                    <Box>
                      {trans('navigation.links.modify_training')}{' '}
                      {trainingCourse.reference}
                    </Box>
                  )) || (
                    <Text>{trans('navigation.links.create_training')}</Text>
                  )}
                </Typography>
              </Box>
              <Box alignItems="center" display="flex">
                {user.emulatedBy && (
                  <Chip
                    icon={
                      <Box mr={1} mt={0.5}>
                        <IconProvider icon={faCircleCheck} color="primary" />
                      </Box>
                    }
                    label={trans('navigation.emulated_by', {
                      name: user.fullName,
                    })}
                    color="error"
                  />
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default withTranslation()(TrainingHeader);
