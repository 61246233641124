/* eslint-disable no-unsafe-optional-chaining */
import dayjs from 'dayjs';
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import ErrorService from 'core/error.service';
import HolidayApi from '@data/api/holiday.api';

export enum HolidayArea {
  Metropole = 'metropole',
  Guyane = 'guyane',
}
@injectable()
export default class HolidayService {
  private HOLIDAY_API = container.get<HolidayApi>(HolidayApi);
  private errorService = container.get<ErrorService>(ErrorService);

  public async listHolidays(
    zone = HolidayArea.Metropole,
    year = new Date().getFullYear(),
  ): Promise<Date[]> {
    const { data: response, error } = await this.HOLIDAY_API?.get<{
      [key: string]: string;
    }>(`/${zone}/${year}.json`);

    if (error) {
      this.errorService.handleHttpError(error);
      return [];
    }
    return Object.keys(response || {}).map((key) => {
      return dayjs(key).toDate();
    });
  }
}
