import { container } from '@_plugins/ioc';
import { useStorage } from '@_plugins/storage';
import AuthService from '@core/auth.service';
import { toManager } from '@data/dto/manager.dto';
import { toMinWage } from '@data/dto/min-wage.dto';
import { toPecRate } from '@data/dto/pec-rate.dto';
import { toTrainingCourse } from '@data/dto/training-course.dto';
import {
  TRAINING_COURSE_STORAGE,
  TrainingCourseStorage,
} from '@data/models/training-course.interface';
import DelegationService from '@data/services/delegation.service';
import GroupService from '@data/services/group.service';
import ManagerService from '@data/services/manager.service';
import MinWageService from '@data/services/min-wage.service';
import PecRateService from '@data/services/pec-rate.service';
import useStore from '@stores/store';
import { useInjection } from 'context/Ioc.context';

const useApp = () => {
  const service = useInjection<GroupService>(GroupService);

  const {
    setUser,
    setMinWage,
    setPecRates,
    setManagers,
    setDelegations,
    updateTrainingCourse,
  } = useStore();

  const getUser = async () => {
    const auth = new AuthService();

    const user = await auth.getMe();

    const groups = await service.listGroups({
      filter: {
        mine: 1,
      },
    });

    setUser({ ...user, groups: groups.items });

    const storage = useStorage();
    const trainingCourse = storage.get<TrainingCourseStorage>(
      TRAINING_COURSE_STORAGE,
    );

    if (trainingCourse) {
      return;
    }

    updateTrainingCourse(toTrainingCourse({}, user));
  };

  const getMinWage = async () => {
    if (!container.isBound(MinWageService)) {
      return;
    }
    const minWageService = container.get<MinWageService>(MinWageService);

    const { items } = await minWageService.listMinWages({
      filter: {
        from_date: new Date().toISOString(),
      },
      orderBy: 'from_date',
      orderDirection: 'desc',
      page: 1,
      itemsPerPage: 1,
    });
    setMinWage(items?.[0] || toMinWage({}));
  };

  const getPecRate = async () => {
    if (!container.isBound(PecRateService)) {
      return;
    }
    const pecRateService = container.get<PecRateService>(PecRateService);

    const { items } = await pecRateService.listPecRates({});
    setPecRates(items || toPecRate({}, {}));
  };

  const getManagers = async () => {
    if (!container.isBound(ManagerService)) {
      return;
    }
    const managerService = container.get<ManagerService>(ManagerService);

    const { items } = await managerService.listManagers({});
    setManagers(items || toManager({}));
  };

  const getDelegations = async () => {
    if (!container.isBound(DelegationService)) {
      return;
    }
    const delegationService =
      container.get<DelegationService>(DelegationService);

    const { items } = await delegationService.listDelegations({});
    setDelegations(items || []);
  };

  return {
    getUser,
    getMinWage,
    getPecRate,
    getManagers,
    getDelegations,
  };
};

export default useApp;
