import { Dayjs } from 'dayjs';

export enum RELIEF_TYPE {
  FILLON = 'FILLON',
  LODEOM = 'LODEOM',
}

export interface ReliefTypeHttp {
  type: 'relief_type_repositories';
  id: string;
  attributes: {
    area: string;
    label: string;
    position: number;
    training_rate: string;
    mission_rate: string;
    createdAt: string;
    updatedAt: string;
  };
  links: {
    self: string;
  };
}

export interface ReliefType {
  id: string;
  area: string;
  label: string;
  missionRate: string;
  trainingRate: string;
  createdAt: Dayjs;
  updatedAt: Dayjs;
}
