import { useStorage } from '@_plugins/storage';
import {
  fromTrainingCourseStorage,
  toTrainingCourse,
} from '@data/dto/training-course.dto';
import { toUser } from '@data/dto/user.dto';
import {
  TRAINING_COURSE_STORAGE,
  TrainingCourse,
  TrainingCourseStorage,
} from '@data/models/training-course.interface';
import { USER_STORAGE, UserHttp } from '@data/models/user.interface';
import { StateCreator } from 'zustand';

export interface TrainingCourseState {
  trainingCourse: TrainingCourse;
  showTrainingCourses: TrainingCourse[];
  updateTrainingCourse: (training: Partial<TrainingCourse>) => void;
  addShowTrainingCourse: (trainingCourse: TrainingCourse) => void;
  updateShowTrainingCourse: (trainingCourse: TrainingCourse) => void;
  findTrainingCourseByRef: (ref: string) => TrainingCourse | undefined;
}

const storage = useStorage();
const user = toUser(storage.get<UserHttp>(USER_STORAGE) || {});
const trainingCourse = storage.get<TrainingCourseStorage>(
  TRAINING_COURSE_STORAGE,
);

export const createTrainingCourseSlice: StateCreator<
  TrainingCourseState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  TrainingCourseState
> = (set, get) => ({
  trainingCourse:
    (trainingCourse && fromTrainingCourseStorage(trainingCourse)) ||
    toTrainingCourse({}, user),
  showTrainingCourses: [],
  updateTrainingCourse: (training: Partial<TrainingCourse>) =>
    set((state) => ({
      trainingCourse: { ...state.trainingCourse, ...training },
    })),
  addShowTrainingCourse: (trainingCourse: TrainingCourse) =>
    set((state) => ({
      showTrainingCourses: [...state.showTrainingCourses, trainingCourse],
    })),
  updateShowTrainingCourse: (trainingCourse: TrainingCourse) =>
    set((state) => ({
      showTrainingCourses: state.showTrainingCourses.map(
        (training) =>
          (training.id === trainingCourse.id && trainingCourse) || training,
      ),
    })),
  findTrainingCourseByRef: (ref: string) =>
    get().showTrainingCourses.find(({ reference }) => reference === ref),
});
