import BadgeTitle from '@components/BadgeTitle/BadgeTitle';
import { useEffect } from 'react';
import useStore from '@stores/store';

const badgeTitle = new BadgeTitle({
  size: 0.65,
});

const NotificationBadger = (): JSX.Element => {
  const { userNotifications } = useStore();
  const unreadNotifications = userNotifications.filter(
    ({ is_read }) => !is_read,
  ).length;

  useEffect(() => {
    badgeTitle.value = unreadNotifications;
  }, [unreadNotifications]);
  return <></>;
};

export default NotificationBadger;
