/* eslint-disable complexity */
import day from '@_plugins/dayjs';
import { DocumentVersionRaw } from '@data/models/document-version.interface';
import {
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
  Document,
  DocumentHttp,
  DocumentIncluded,
  DocumentWizard,
} from '@data/models/document.interface';
import { TrainingCourse } from '@data/models/training-course.interface';
import { getDocumentTypeByValue } from '@helpers/document-type.helper';

import { toDocumentVersion } from './document-version.dto';

export const toDocument = (
  document: Partial<DocumentHttp>,
  included: Partial<DocumentIncluded> = {},
): Document => {
  const getDocumentTypeLabel = (): string => {
    const type = getDocumentTypeByValue(
      document.attributes?.document_type || DOCUMENT_TYPE.TYPE_UNDEFINED,
    );
    return (type && type.label) || 'training-course.ged.type.unknown';
  };

  const getStatusLabel = (): string => {
    switch (document.attributes?.status) {
      case DOCUMENT_STATUS.TODO:
        return 'training-course.ged.status.todo';
      case DOCUMENT_STATUS.IN_PROGRESS:
        return 'training-course.ged.status.in_progress';
      default:
        return 'training-course.ged.status.unknown';
    }
  };

  const getStatusColor = (): string => {
    switch (document.attributes?.status) {
      case DOCUMENT_STATUS.TODO:
        return 'secondary';
      default:
        return 'primary';
    }
  };

  const versionHttp = included.document_versions?.find(
    (version) => version.id === document.relationships?.versions.data?.[0].id,
  );

  return {
    id: document.id || '',
    documentType:
      document.attributes?.document_type || DOCUMENT_TYPE.TYPE_UNDEFINED,
    name: document.attributes?.name || '',
    status: document.attributes?.status || DOCUMENT_STATUS.UNDEFINED,
    statusLabel: getStatusLabel(),
    statusColor: getStatusColor(),
    createdAt: day(document.attributes?.createdAt),
    updatedAt: day(document.attributes?.updatedAt),
    documentTypeLabel: getDocumentTypeLabel(),
    recipient: document.attributes?.recipient || '',
    transmitter: document.attributes?.transmitter || '',
    reference: document.attributes?.reference || '',
    subject: document.attributes?.subject || '',
    versionCount: document.attributes?.version_count || 0,
    updatedBy: document.attributes?.updated_by || '',
    comment: document.attributes?.comment || '',
    isAgencyCharge: document.attributes?.is_agency_charge || false,
    lucieHabilitationId:
      document.attributes?.lucie_habilitation_id || undefined,
    habilitationId: document.attributes?.habilitation_id || undefined,
    btpCardNumber: document.attributes?.btp_card_number || undefined,
    habilitationName: document.attributes?.habilitation_name || undefined,
    habilitationCategory:
      document.attributes?.habilitation_category || undefined,
    habilitationCode: document.attributes?.habilitation_code || undefined,
    habilitationEndDate: document.attributes?.habilitation_end_date
      ? day(document.attributes?.habilitation_end_date)
      : undefined,
    isLocked: document.attributes?.is_locked || undefined,
    trainingCourseTrainingId:
      document.relationships?.training_course_training.data?.id || '',
    versions: [toDocumentVersion(versionHttp || {})],
  };
};

export const toDocumentWizardFromRawVersion = (
  version: DocumentVersionRaw,
  trainingCourse: TrainingCourse,
  fromChat?: boolean,
): DocumentWizard => {
  return {
    id: '',
    type: 'documents',
    attributes: {
      comment: '',
      document_type: fromChat
        ? DOCUMENT_TYPE.TYPE_CHAT
        : DOCUMENT_TYPE.TYPE_UNDEFINED,
      name: version.name,
      subject: '',
      btp_card_number: undefined,
      habilitation_name: undefined,
      habilitation_category: undefined,
      habilitation_code: undefined,
      lucie_habilitation_id: undefined,
      habilitation_id: undefined,
      habilitation_end_date: undefined,
      is_agency_charge: false,
      is_locked: undefined,
    },
    relationships: {
      training_course: {
        data: {
          type: 'training_courses',
          id: trainingCourse.id || '',
        },
      },
    },
  };
};

export const toDocumentWizard = (
  document: Partial<Document>,
  trainingCourse: TrainingCourse,
): DocumentWizard => {
  return {
    id: document?.id || '',
    type: 'documents',
    attributes: {
      document_type: document.documentType || DOCUMENT_TYPE.TYPE_UNDEFINED,
      name: document.name || '',
      subject: document.subject || '',
      comment: document.comment || '',
      is_agency_charge: document.isAgencyCharge || false,
      btp_card_number: document.btpCardNumber || undefined,
      habilitation_name: document.habilitationName || undefined,
      habilitation_category: document.habilitationCategory || undefined,
      habilitation_code: document.habilitationCode || undefined,
      lucie_habilitation_id: document.lucieHabilitationId || undefined,
      habilitation_id: document.habilitationId || undefined,
      habilitation_end_date: document.habilitationEndDate
        ? document.habilitationEndDate.toISOString()
        : undefined,
      is_locked: document.isLocked || undefined,
    },
    relationships: {
      training_course: {
        data: {
          type: 'training_courses',
          id: trainingCourse.id || '',
        },
      },
      training_course_training: {
        data: {
          type: 'training_course_trainings',
          id: document.trainingCourseTrainingId || '',
        },
      },
    },
  };
};
