import day from '@_plugins/dayjs';
import {
  CONTACT_TYPES,
  Contact,
  ContactHttp,
  ContactWizard,
} from '@data/models/contact.interface';
import { TrainingCourse } from '@data/models/training-course.interface';

export const toContact = ({
  id,
  attributes,
}: Partial<ContactHttp>): Contact => {
  const stringToColour = (value: string) => {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let y = 0; y < 3; y++) {
      const newValue = (hash >> (y * 8)) & 0xff;
      colour += ('00' + newValue.toString(16)).substr(-2);
    }
    return colour;
  };

  const fullName =
    (attributes?.first_name &&
      attributes?.last_name &&
      `${attributes?.first_name || ''} ${
        attributes?.last_name || ''
      }`.trim()) ||
    attributes?.email.split('@')[0] ||
    '';
  return {
    fullName,
    id: id || '',
    firstName: attributes?.first_name || '',
    lastName: attributes?.last_name || '',
    company: attributes?.company || '',
    job: attributes?.job || '',
    phoneNumber: attributes?.phone_number || '',
    email: attributes?.email || '',
    type: attributes?.contact_type || CONTACT_TYPES.AGENCY,
    readAt: null,
    avatarColor: stringToColour(fullName),
    createdAt: day(attributes?.createdAt),
    updatedAt: day(attributes?.updatedAt),
  };
};

export const toContactWizard = (
  contact: Partial<Contact>,
  { id: trainingCourseId }: Pick<TrainingCourse, 'id'>,
): ContactWizard => {
  return {
    type: 'contacts',
    id: contact.id || '',
    attributes: {
      last_name: contact.lastName || '',
      first_name: contact.firstName || '',
      email: contact.email || '',
      company: contact.company || '',
      job: contact.job || '',
      phone_number: contact.phoneNumber || '',
      contact_type: contact.type || CONTACT_TYPES.AGENCY,
    },
    relationships: {
      training_course: {
        data: {
          type: 'training_courses',
          id: trainingCourseId || '',
        },
      },
    },
  };
};
