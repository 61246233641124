import dayjs from '@_plugins/dayjs';
import {
  Delegation,
  DelegationHttp,
  DelegationIncluded,
  DelegationRawIncluded,
  DelegationWizard,
} from '@data/models/delegation.interface';
import { FilterParams } from '@data/models/filter.interface';
import { Region, RegionHttp } from '@data/models/region.interface';

import { toApiDate } from './api.dto';
import { toRegion } from './region.dto';

export const toDelegationIncluded = (
  included: DelegationRawIncluded = [],
): DelegationIncluded => {
  return {
    regions: included.filter(
      ({ type }) => type === 'region_repositories',
    ) as RegionHttp[],
  };
};

export const toDelegation = (
  delegation: Partial<DelegationHttp>,
  included: Partial<DelegationIncluded>,
): Delegation => {
  const regions: Region[] = (delegation.relationships?.regions.data || [])
    .flatMap((region) =>
      (included?.regions || []).filter((r) => r.id === region.id),
    )
    .map((r) => toRegion(r));

  return {
    id: delegation.id || '',
    status: delegation.attributes?.status || 0,
    manager: delegation.relationships?.manager.data?.id || '',
    delegationManager:
      delegation.relationships?.delegation_manager.data?.id || '',
    reason: delegation.attributes?.reason || '',
    regions: regions || [],
    fromDate: dayjs(delegation.attributes?.from_date),
    toDate: dayjs(delegation.attributes?.to_date),
  };
};

export const toDelegationFilterParams = ({
  orderBy,
  filter,
  ...filterParams
}: FilterParams): FilterParams => {
  const mapPropsToHttp = (prop?: string): string | undefined => {
    switch (prop) {
      case 'manager':
        return 'manager';
      default:
        return prop;
    }
  };

  const renameFilter = Object.keys(filter || {}).reduce(
    (acc, cur) => {
      const propName = mapPropsToHttp(cur);
      if (!propName) {
        return acc;
      }
      acc[propName] = (filter || {})[cur] || '';
      return acc;
    },
    {} as {
      [key: string]: string | number | string[] | undefined;
    },
  );

  return {
    ...filterParams,
    orderBy: mapPropsToHttp(orderBy),
    filter: renameFilter,
  };
};

export const toDelegationWizard = (
  delegation: Partial<Delegation>,
): DelegationWizard => {
  return {
    type: 'delegations',
    id: delegation.id || '',
    attributes: {
      from_date: toApiDate(delegation.fromDate) || '',
      to_date: toApiDate(delegation.toDate) || '',
      reason: delegation?.reason || '',
      status: delegation?.status || 0,
    },
    relationships: {
      manager: {
        data: {
          type: 'manager_repositories',
          id: delegation.manager || '',
        },
      },
      delegation_manager: {
        data: {
          type: 'manager_repositories',
          id: delegation.delegationManager || '',
        },
      },
      regions: {
        data: (delegation.regions || []).map((option: Region) => ({
          id: option.id || '',
          type: 'region_repositories',
        })),
      },
    },
  };
};
