/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-case-declarations */

/* eslint-disable complexity */
import i18n from '@_plugins/i18n';
import {
  Activity,
  ActivityHttp,
  ActivityTypeChange,
  CommunicationActivityProperties,
  DocumentActivityProperties,
  DocumentVersionActivityProperties,
  TrainingActionCandidateActivityProperties,
  TrainingActionCandidateInvoiceActivityProperties,
  TrainingActionCandidatePeriodActivityProperties,
  TrainingActionDeviceMapProperties,
  TrainingActionEstimatorCandidateActivityProperties,
  TrainingCourseActivityProperties,
  TrainingCourseCandidateActivityProperties,
  TrainingCourseCustomerActivityProperties,
  TrainingCourseEstimatorActivityProperties,
  TrainingCourseGroupActivityProperties,
  TrainingCourseTrainingActivityProperties,
} from '@data/models/activity.interface';
import { TrainingCourse } from '@data/models/training-course.interface';
import { getDocumentTypeByValue } from '@helpers/document-type.helper';
import { getTrainingCourseAttributeByValue } from '@helpers/training-course-attribute.helper';
import dayjs from 'dayjs';
import compact from 'lodash/compact';

import { toBoolean } from './api.dto';

const formatFloat = (number: number | string, position: number): string => {
  if (!number) {
    return '';
  }
  return parseFloat(
    [
      number.toString().slice(0, number.toString().length - position),
      '.',
      number.toString().slice(number.toString().length - position),
    ].join(''),
  ).toString();
};

const translate = (prefix: string, key?: string | null): string => {
  if (!key) {
    return '';
  }
  return i18n.t(`${prefix}.${key.toLowerCase()}`);
};

const fromTrainingActionEstimatorCandidateActivity = (
  key: keyof TrainingActionEstimatorCandidateActivityProperties,
  newValue: TrainingActionEstimatorCandidateActivityProperties,
  oldValue?: TrainingActionEstimatorCandidateActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'status':
    case 'training_action_id':
    case 'training_course_candidate_id':
    case 'training_action_candidate_id':
      return null;
    case 'positioning_report_expected_duration':
    case 'positioning_report_real_duration':
    case 'positioning_report_paid_duration':
    case 'external_training_expected_duration':
    case 'external_training_real_duration':
    case 'external_training_paid_duration':
    case 'practical_training_expected_duration':
    case 'practical_training_real_duration':
    case 'practical_training_paid_duration':
    case 'total_expected_duration':
    case 'total_real_duration':
    case 'total_paid_duration':
    case 'transport_expected_fees':
    case 'transport_real_fees':
    case 'transport_anael_fees':
    case 'accommodation_expected_fees':
    case 'accommodation_real_fees':
    case 'accommodation_anael_fees':
    case 'meal_expected_fees':
    case 'meal_real_fees':
    case 'meal_anael_fees':
    case 'wage_expected_cost':
    case 'wage_real_cost':
    case 'additional_expected_cost':
    case 'additional_real_cost':
    case 'educational_expected_cost':
    case 'educational_real_cost':
    case 'total_expected_cost':
    case 'total_real_cost':
    case 'total_expected_fees':
    case 'total_real_fees':
    case 'total_anael_fees':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key] || 0, 2),
        oldValue:
          (oldValue?.[key] && formatFloat(oldValue?.[key] || 0, 2)) || '',
      };
    case 'from_date':
    case 'to_date':
      return {
        label,
        type: 'date',
        newValue: dayjs(newValue[key]),
        oldValue: (oldValue?.[key] && dayjs(oldValue?.[key])) || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingActionCandidatePeriodActivity = (
  key: keyof TrainingActionCandidatePeriodActivityProperties,
  newValue: TrainingActionCandidatePeriodActivityProperties,
  oldValue?: TrainingActionCandidatePeriodActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'training_action_training_device_type_candidate_id':
      return null;
    case 'expected_duration':
    case 'real_duration':
    case 'period_type':
    case 'hour_cost':
    case 'expected_cost':
    case 'real_cost':
    case 'anael_cost':
    case 'training_course_rate':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key] || 0, 2),
        oldValue:
          (oldValue?.[key] && formatFloat(oldValue?.[key] || 0, 2)) || '',
      };
    case 'from_date':
    case 'to_date':
      return {
        label,
        type: 'date',
        newValue: dayjs(newValue[key]),
        oldValue: (oldValue?.[key] && dayjs(oldValue?.[key])) || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingActionCandidateInvoiceActivity = (
  key: keyof TrainingActionCandidateInvoiceActivityProperties,
  newValue: TrainingActionCandidateInvoiceActivityProperties,
  oldValue?: TrainingActionCandidateInvoiceActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'invoice_type':
    case 'training_action_training_device_type_candidate_id':
      return null;
    case 'is_hour_cost':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    case 'hour_rate':
    case 'hours':
    case 'total_amount':
    case 'total_amount_per_candidate':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key] || 0, 2),
        oldValue:
          (oldValue?.[key] && formatFloat(oldValue?.[key] || 0, 2)) || '',
      };
    case 'from_date':
    case 'to_date':
      return {
        label,
        type: 'date',
        newValue: dayjs(newValue[key]),
        oldValue: (oldValue?.[key] && dayjs(oldValue?.[key])) || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingCourseActivity = (
  key: keyof TrainingCourseActivityProperties,
  newValue: TrainingCourseActivityProperties,
  oldValue?: TrainingCourseActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'transport_fees':
    case 'total_fees':
    case 'accommodation_fees':
    case 'meal_fees':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key], 2),
        oldValue: (oldValue?.[key] && formatFloat(oldValue?.[key], 2)) || '',
      };
    case 'current_step':
      return {
        label,
        type: 'string',
        newValue: translate('training-course.context-stepper', newValue[key]),
        oldValue: translate('training-course.context-stepper', oldValue?.[key]),
      };
    case 'status':
      return {
        label,
        type: 'string',
        newValue: translate('training-course.status', newValue[key]),
        oldValue: translate('training-course.status', oldValue?.[key]),
      };
    case 'manager_repository_id':
    case 'cpef_manager_repository_id':
    case 'region_repository_id':
    case 'manager.first_name':
    case 'cpefManager.first_name':
    case 'profession_repository_id':
    case 'project_origin_repository_id':
    case 'course_type_id':
    case 'validation_type_id':
    case 'device_type_repository_id':
    case 'cancellation_type':
      return null;
    case 'manager.last_name':
      return {
        label: 'activity.props.manager.full_name',
        type: 'string',
        newValue: `${newValue?.['manager.first_name'] || ''} ${
          newValue?.['manager.last_name'] || ''
        }`.trim(),
        oldValue: `${oldValue?.['manager.first_name'] || ''} ${
          oldValue?.['manager.last_name'] || ''
        }`.trim(),
      };
    case 'cpefManager.last_name':
      return {
        label: 'activity.props.cpef_manager.full_name',
        type: 'string',
        newValue: `${newValue?.['cpefManager.first_name'] || ''} ${
          newValue?.['cpefManager.last_name'] || ''
        }`.trim(),
        oldValue: `${oldValue?.['cpefManager.first_name'] || ''} ${
          oldValue?.['cpefManager.last_name'] || ''
        }`.trim(),
      };
    case 'has_lucie_advertisement':
    case 'is_recurring_need':
    case 'is_epsp_approach':
    case 'is_national_need':
    case 'is_teleoperator':
    case 'unidentified_candidates':
    case 'is_locked':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    case 'deadline':
    case 'acknowledged_at':
      return {
        label,
        type: 'date',
        newValue: dayjs(newValue[key]),
        oldValue: (oldValue?.[key] && dayjs(oldValue?.[key])) || null,
      };
    case 'user_id':
    case 'volume':
      return {
        label,
        type: 'number',
        newValue: newValue[key],
        oldValue: oldValue?.[key] || null,
      };
    case 'strategicAxes':
      return {
        label,
        type: 'string',
        newValue: Object.values(newValue[key])
          .map((axe) => axe['label' as any])
          .join(', '),
        oldValue:
          (oldValue?.[key] &&
            Object.values(oldValue[key])
              .map((axe) => axe['label' as any])
              .join(', ')) ||
          null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue?.[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingCourseCustomerActivity = (
  key: keyof TrainingCourseCustomerActivityProperties,
  newValue: TrainingCourseCustomerActivityProperties,
  oldValue?: TrainingCourseCustomerActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'training_course_id':
    case 'customer_type_repository_id':
      return null;
    case 'rfa_rate':
    case 'delegation_rate':
    case 'management_rate':
    case 'mission_duration_in_hours':
    case 'mission_duration_in_months':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key] || 0, 3),
        oldValue:
          (oldValue?.[key] && formatFloat(oldValue?.[key] || 0, 3)) || '',
      };
    case 'is_rfa':
    case 'has_negotiation_marge':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    case 'agreement_end_date':
      return {
        label,
        type: 'date',
        newValue: dayjs(newValue[key] || ''),
        oldValue: (oldValue?.[key] && dayjs(oldValue?.[key])) || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingCourseGroupActivity = (
  key: keyof TrainingCourseGroupActivityProperties,
  newValue: TrainingCourseGroupActivityProperties,
  oldValue?: TrainingCourseGroupActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'training_course_id':
      return null;
    case 'is_main_group':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingCourseCandidateActivity = (
  key: keyof TrainingCourseCandidateActivityProperties,
  newValue: TrainingCourseCandidateActivityProperties,
  oldValue?: TrainingCourseCandidateActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'training_course_id':
    case 'profession_repository_id':
    case 'lucie_id':
      return null;
    case 'transport_fees':
    case 'total_fees':
    case 'accommodation_fees':
    case 'meal_fees':
    case 'cpf_amount':
    case 'mission_rate':
    case 'training_course_rate':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key] || 0, 2),
        oldValue:
          (oldValue?.[key] && formatFloat(oldValue?.[key] || 0, 2)) || '',
      };
    case 'followed_by_pole_emploi':
    case 'followed_by_mission_locale':
    case 'has_interim_experience':
    case 'is_a2i':
    case 'is_cdii':
    case 'is_rqth':
    case 'open_cpf_account':
    case 'has_competences_diagnostic':
    case 'is_epsp_approach':
    case 'validate_candidate_type':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    case 'birthday_date':
      return {
        label,
        type: 'date',
        newValue: dayjs(newValue[key]),
        oldValue: (oldValue?.[key] && dayjs(oldValue?.[key])) || null,
      };
    case 'age':
    case 'position':
      return {
        label,
        type: 'number',
        newValue: newValue[key],
        oldValue: oldValue?.[key] || null,
      };
    case 'emails':
    case 'phones':
    case 'driving_licenses':
    case 'means_of_transport':
      return {
        label,
        type: 'string',
        newValue: newValue?.[key]?.join(','),
        oldValue: oldValue?.[key]?.join(',') || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingActionCandidateActivity = (
  key: keyof TrainingActionCandidateActivityProperties,
  newValue: TrainingActionCandidateActivityProperties,
  oldValue?: TrainingActionCandidateActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'training_course_id':
    case 'profession_repository_id':
    case 'training_action_id':
      return null;
    case 'transport_fees':
    case 'total_fees':
    case 'accommodation_fees':
    case 'meal_fees':
    case 'cpf_amount':
    case 'mission_rate':
    case 'training_course_rate':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key] || 0, 2),
        oldValue:
          (oldValue?.[key] && formatFloat(oldValue?.[key] || 0, 2)) || '',
      };
    case 'followed_by_pole_emploi':
    case 'followed_by_mission_locale':
    case 'has_interim_experience':
    case 'is_a2i':
    case 'is_cdii':
    case 'is_rqth':
    case 'open_cpf_account':
    case 'has_competences_diagnostic':
    case 'is_epsp_approach':
    case 'validate_candidate_type':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    case 'birthday_date':
      return {
        label,
        type: 'date',
        newValue: dayjs(newValue[key]),
        oldValue: (oldValue?.[key] && dayjs(oldValue?.[key])) || null,
      };
    case 'lucie_id':
    case 'age':
    case 'position':
      return {
        label,
        type: 'number',
        newValue: newValue[key],
        oldValue: oldValue?.[key] || null,
      };
    case 'emails':
    case 'phones':
    case 'driving_licenses':
    case 'means_of_transport':
      return {
        label,
        type: 'string',
        newValue: newValue[key].join(','),
        oldValue: oldValue?.[key]?.join(',') || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingActionDeviceMapActivity = (
  key: keyof TrainingActionDeviceMapProperties,
  newValue: TrainingActionDeviceMapProperties,
  oldValue?: TrainingActionDeviceMapProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'position':
    case 'device_type_repository_id':
    case 'parent_id':
    case 'training_action_id':
      return null;
    case 'sent_at':
    case 'received_at':
    case 'refund_at':
    case 'validated_at':
      return {
        label,
        type: 'date',
        newValue: (newValue?.[key] && dayjs(newValue[key])) || null,
        oldValue: (oldValue?.[key] && dayjs(oldValue?.[key])) || null,
      };
    case 'checked':
    case 'has_reference':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingCourseTrainingActivity = (
  key: keyof TrainingCourseTrainingActivityProperties,
  newValue: TrainingCourseTrainingActivityProperties,
  oldValue?: TrainingCourseTrainingActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'training_course_id':
    case 'validation_type_repository_id':
    case 'training_id':
    case 'expected_city_id':
    case 'city_id':
      return null;
    case 'cost':
    case 'duration':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key], 2),
        oldValue: (oldValue?.[key] && formatFloat(oldValue?.[key], 2)) || '',
      };
    case 'prescriber':
      return {
        label,
        type: 'string',
        newValue: translate(
          'training-course.training.prescriber',
          newValue[key],
        ),
        oldValue: translate(
          'training-course.training.prescriber',
          oldValue?.[key],
        ),
      };
    case 'invoice_type':
      return {
        label,
        type: 'string',
        newValue: translate(
          'training-course.training.invoice-type',
          newValue[key],
        ),
        oldValue: translate(
          'training-course.training.invoice-type',
          oldValue?.[key],
        ),
      };
    case 'skill_level':
      return {
        label,
        type: 'string',
        newValue: translate(
          'training-course.training.skill-level',
          newValue[key],
        ),
        oldValue: translate(
          'training-course.training.skill-level',
          oldValue?.[key],
        ),
      };
    case 'is_training_organism_contacted':
    case 'is_out_of_catalog':
    case 'is_qualiopi':
    case 'has_quote':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    case 'position':
      return {
        label,
        type: 'number',
        newValue: newValue[key],
        oldValue: oldValue?.[key] || null,
      };
    case 'trainingOptions':
      return {
        label,
        type: 'string',
        newValue: Object.values(newValue[key])
          .map((axe) => axe['label' as any])
          .join(', '),
        oldValue:
          (oldValue?.[key] &&
            Object.values(oldValue[key])
              .map((axe) => axe['label' as any])
              .join(', ')) ||
          null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromTrainingCourseEstimatorActivity = (
  key: keyof TrainingCourseEstimatorActivityProperties,
  newValue: TrainingCourseEstimatorActivityProperties,
  oldValue?: TrainingCourseEstimatorActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'training_course_id':
    case 'approver_id':
    case 'device_type_repository_id':
    case 'opco_charge_repository_id':
    case 'validation_type_repository_id':
    case 'min_wage_repository_id':
    case 'approver.first_name':
      return null;
    case 'positioning_review_duration':
    case 'positioning_review_cost':
    case 'educational_costs_min_duration':
    case 'educational_costs_max_duration':
    case 'educational_costs_total_duration':
    case 'educational_costs_amount_per_candidate':
    case 'educational_costs_amount_per_hour':
    case 'educational_costs_amount_group':
    case 'educational_costs_practical_duration':
    case 'additional_costs_total_amount':
    case 'mission_average_rate':
    case 'training_course_average_rate':
    case 'classic_trainee_pec':
    case 'local_mission_trainee_pec':
    case 'handicapped_trainee_pec':
    case 'refugee_trainee_pec':
    case 'skilled_trainee_pec':
    case 'poec_trainee_pec':
    case 'afpr_trainee_pec':
    case 'average_pec_rate':
    case 'cpf_amount':
    case 'ddc_total_mission_duration_in_months':
    case 'ddc_duration_in_months':
    case 'ddc_complementary_training_duration_in_months':
    case 'ddc_training_duration_per_month':
    case 'ddc_training_duration_in_days':
    case 'ddc_training_duration_in_hours':
    case 'ddc_training_mission_ratio':
    case 'external_co_funding_supported_amount':
    case 'financial_aid_supported_amount':
    case 'customer_management_rate':
    case 'customer_delegation_rate':
    case 'delegation_rate':
    case 'rfa_rate':
    case 'sourcing_amount':
    case 're_invoicing_cost_rate':
    case 're_invoicing_cost_amount':
    case 're_invoicing_wage_amount':
    case 're_invoicing_wage_rate':
    case 're_invoicing_extra_cost_rate':
    case 're_invoicing_extra_cost_amount':
    case 'engineering_cost':
    case 'recruitment_cost':
    case 'management_fees':
    case 'total_invoice_amount':
    case 'additional_delegation_in_months':
    case 'average_coefficient_on_mission_hours':
    case 'delegation_billing_amount':
    case 'delegation_billing_post_training_amount':
    case 'delegation_billing_post_training_hours':
    case 'average_coefficient_on_mission_and_training_hours':
    case 'coefficient_delegation_evolution':
    case 'total_training_expenses_per_trainee':
    case 'total_training_expenses_per_group':
    case 'total_training_revenue_per_trainee':
    case 'total_training_revenue_per_group':
    case 'opco_refund':
    case 'training_assessment_amount':
    case 'external_training_amount':
    case 'practical_training_amount':
    case 'turn_over_per_trainee':
    case 'turn_over_per_group':
    case 'turn_over_in_months_per_trainee':
    case 'turn_over_in_months_per_group':
    case 'gross_margin_per_trainee_with_end':
    case 'gross_margin_per_trainee_without_end':
    case 'gross_margin_target_amount':
    case 'gross_margin_in_months_per_trainee_with_end':
    case 'gross_margin_in_months_per_trainee_without_end':
    case 'gross_margin_rate_with_end':
    case 'gross_margin_rate_without_end':
    case 'gross_margin_rate_against_extra_cost_with_end':
    case 'gross_margin_rate_against_extra_cost_without_end':
    case 'total_gross_margin':
    case 'training_cost_with_cofi_amount':
    case 'training_cost_without_cofi_amount':
    case 'total_mission_revenue':
    case 'total_training_relief_cost':
    case 'total_mission_relief_cost':
    case 'total_mission_duration_in_months':
    case 'etti_agreement_amount':
    case 'etti_agreement_post_training_amount':
    case 'ifm_gains_amount':
    case 'ifm_gains_post_training_amount':
    case 'total_post_training_revenue':
    case 'total_post_training_relief_cost':
    case 'mission_wage_expenses':
    case 'mission_rfa_expenses':
    case 'mission_total_expenses':
    case 'total_wages_amount':
    case 'post_training_wage_expenses':
    case 'post_training_rfa_expenses':
    case 'post_training_total_expenses':
    case 'invoice_rate_against_extra_costs':
    case 'break_even_with_end_amount':
    case 'break_even_without_end_amount':
      return {
        label,
        type: 'string',
        newValue: formatFloat(newValue[key], 8),
        oldValue: (oldValue?.[key] && formatFloat(oldValue?.[key], 8)) || '',
      };
    case 'approver.last_name':
      return {
        label: 'activity.props.manager.full_name',
        type: 'string',
        newValue:
          `${newValue['approver.first_name']} ${newValue['approver.last_name']}`.trim(),
        oldValue: `${oldValue?.['approver.first_name'] || ''} ${
          oldValue?.['approver.last_name'] || ''
        }`.trim(),
      };
    case 'status':
      return {
        label,
        type: 'string',
        newValue: translate('training-course.estimator.status', newValue[key]),
        oldValue: translate(
          'training-course.estimator.status',
          oldValue?.[key],
        ),
      };
    case 'has_financial_aid':
    case 'has_external_co_funding':
    case 'ddc_is_valid_mission_duration':
    case 'is_national_need':
    case 'has_additional_costs':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    case 'cdii_trainee_number':
    case 'a2i_trainee_number':
    case 'senior_trainee_number':
    case 'afpr_trainee_number':
    case 'poec_trainee_number':
    case 'refugee_trainee_number':
    case 'skilled_trainee_number':
    case 'handicapped_trainee_number':
    case 'local_mission_trainee_number':
    case 'classic_trainee_number':
    case 'candidates_number':
      return {
        label,
        type: 'number',
        newValue: newValue[key],
        oldValue: oldValue?.[key] || null,
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromDocumentActivity = (
  trainingCourse: TrainingCourse,
  key: keyof DocumentActivityProperties,
  newValue: DocumentActivityProperties,
  oldValue?: DocumentActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'is_agency_charge':
    case 'is_locked':
      return {
        label,
        type: 'boolean',
        newValue: toBoolean(newValue[key]),
        oldValue: (oldValue?.[key] && toBoolean(oldValue?.[key])) || null,
      };
    case 'training_course_id':
      return null;
    case 'transmitter':
    case 'recipient':
    case 'subject':
      const newValueAttribute = getTrainingCourseAttributeByValue(
        newValue[key],
        trainingCourse,
      );
      const oldValueAttribute =
        (oldValue?.[key] &&
          getTrainingCourseAttributeByValue(oldValue?.[key], trainingCourse)) ||
        null;
      return {
        label,
        type: 'string',
        newValue: (newValueAttribute && i18n.t(newValueAttribute.label)) || '',
        oldValue: (oldValueAttribute && i18n.t(oldValueAttribute.label)) || '',
      };
    case 'version_count':
      return {
        label,
        type: 'number',
        newValue: newValue[key] || 0,
        oldValue: oldValue?.[key] || null,
      };
    case 'status':
      return {
        label,
        type: 'string',
        newValue:
          (newValue[key] === 2 &&
            i18n.t('training-course.ged.status.in_progress')) ||
          i18n.t('training-course.ged.status.todo'),
        oldValue:
          (oldValue?.[key] === 2 &&
            i18n.t('training-course.ged.status.in_progress')) ||
          i18n.t('training-course.ged.status.todo'),
      };
    case 'document_type':
      const newValueType = getDocumentTypeByValue(newValue[key]);
      const oldValueType =
        (oldValue?.[key] && getDocumentTypeByValue(oldValue?.[key])) || null;
      return {
        label,
        type: 'string',
        newValue: (newValueType && i18n.t(newValueType.label)) || '',
        oldValue: (oldValueType && i18n.t(oldValueType.label)) || '',
      };
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromDocumentVersionActivity = (
  key: keyof DocumentVersionActivityProperties,
  newValue: DocumentVersionActivityProperties,
  oldValue?: DocumentVersionActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'document_id':
      return null;
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const fromCommunicationActivity = (
  key: keyof CommunicationActivityProperties,
  newValue: CommunicationActivityProperties,
  oldValue?: CommunicationActivityProperties,
): ActivityTypeChange | null => {
  const label = `activity.props.${key}`;
  switch (key) {
    case 'training_course_id':
    case 'communication_type':
    case 'user_id':
      return null;
    default:
      return {
        label,
        type: 'string',
        newValue: newValue[key] || '',
        oldValue: oldValue?.[key] || null,
      };
  }
};

const isAllowedKey = (key: string): boolean =>
  !['id', 'uuid', 'created_at', 'updated_at', 'deleted_at'].includes(key);

export const toActivity = (
  { id, attributes }: Partial<ActivityHttp>,
  trainingCourse: TrainingCourse,
): Activity => {
  const changes = Object.keys(attributes?.properties.attributes || {})
    .filter((key) => isAllowedKey(key))
    .map((key): ActivityTypeChange | null => {
      switch (attributes?.log_name) {
        case 'training_course':
          return fromTrainingCourseActivity(
            key as keyof TrainingCourseActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_course_customer':
          return fromTrainingCourseCustomerActivity(
            key as keyof TrainingCourseCustomerActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_course_group':
          return fromTrainingCourseGroupActivity(
            key as keyof TrainingCourseGroupActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_action_candidate':
          return fromTrainingActionCandidateActivity(
            key as keyof TrainingActionCandidateActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_course_potential_candidate':
          return fromTrainingCourseCandidateActivity(
            key as keyof TrainingCourseCandidateActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_action_device_map':
          return fromTrainingActionDeviceMapActivity(
            key as keyof TrainingActionDeviceMapProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_course_training':
          return fromTrainingCourseTrainingActivity(
            key as keyof TrainingCourseTrainingActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_course_estimator':
          return fromTrainingCourseEstimatorActivity(
            key as keyof TrainingCourseEstimatorActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'document':
          return fromDocumentActivity(
            trainingCourse,
            key as keyof DocumentActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'document_version':
          return fromDocumentVersionActivity(
            key as keyof DocumentVersionActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_action_candidate_invoice':
          return fromTrainingActionCandidateInvoiceActivity(
            key as keyof TrainingActionCandidateInvoiceActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_action_candidate_period':
          return fromTrainingActionCandidatePeriodActivity(
            key as keyof TrainingActionCandidatePeriodActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'training_action_training_device_type_candidate':
          return fromTrainingActionEstimatorCandidateActivity(
            key as keyof TrainingActionEstimatorCandidateActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        case 'communication':
          return fromCommunicationActivity(
            key as keyof CommunicationActivityProperties,
            attributes?.properties.attributes,
            (attributes.event === 'updated' && attributes?.properties.old) ||
              undefined,
          );
        default:
          return {
            label: key,
            newValue:
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (attributes as any)?.properties?.attributes?.[key] || '',
            oldValue: '',
            type: 'string',
          };
      }
    });

  return {
    id: id || '',
    description:
      (!['created', 'updated', 'deleted'].includes(
        attributes?.description || '',
      ) &&
        attributes?.description) ||
      '',
    changes: compact(changes),
    entity: attributes?.log_name || '',
    userEmail: attributes?.email || '',
    userFirstName: attributes?.first_name || '',
    userLastName: attributes?.last_name || '',
    userFullName: attributes?.full_name || '',
    event: attributes?.event || 'updated',
    createdAt: dayjs(attributes?.createdAt || ''),
  };
};
