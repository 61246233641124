import day from '@_plugins/dayjs';
import {
  DocumentVersion,
  DocumentVersionHttp,
  DocumentVersionRaw,
  DocumentVersionWizard,
} from '@data/models/document-version.interface';
import { toBase64 } from '@helpers/base64.helper';
import DocumentSchema from '@pages/Document/schema/document.schema';
import { ValidationError } from 'yup';

export const toDocumentVersion = (
  documentVersion: Partial<DocumentVersionHttp>,
): DocumentVersion => {
  return {
    id: documentVersion.id || '',
    path: documentVersion.attributes?.path || '',
    createdBy: documentVersion.attributes?.created_by || '',
    createdAt: day(documentVersion.attributes?.createdAt),
    updatedAt: day(documentVersion.attributes?.updatedAt),
  };
};

export const toDocumentVersionWizard = (
  rawVersion: DocumentVersionRaw,
  documentId: string,
): DocumentVersionWizard => {
  return {
    id: '',
    type: 'document_versions',
    attributes: {
      encoded: rawVersion.encoded,
    },
    relationships: {
      document: {
        data: {
          type: 'documents',
          id: documentId,
        },
      },
    },
  };
};

export const toVersionRaw = async (file: File): Promise<DocumentVersionRaw> => {
  const encoded = await toBase64(file);

  const getImageSize = (): Promise<{ width?: number; height?: number }> => {
    return new Promise(function (resolved) {
      if (!file.type.startsWith('image')) {
        resolved({});
      }
      const image = new Image();
      image.onload = function () {
        resolved({ width: image.width, height: image.height });
      };
      image.src = encoded;
    });
  };
  const versionRaw: Omit<DocumentVersionRaw, 'errors'> = {
    encoded,
    name: file.name,
    size: file.size,
    type: file.type,
    ...(await getImageSize()),
  };

  try {
    DocumentSchema.validateSync(versionRaw, { abortEarly: false });
    return {
      ...versionRaw,
      errors: [],
    };
  } catch (error) {
    if (error instanceof ValidationError) {
      return {
        ...versionRaw,
        errors: error.errors,
      };
    }
    return {
      ...versionRaw,
      errors: [],
    };
  }
};
