import { Delegation } from '@data/models/delegation.interface';
import { StateCreator } from 'zustand';

export interface DelegationState {
  delegations: Delegation[];
  setDelegations: (delegations: Delegation[]) => void;
}

export const createDelegationSlice: StateCreator<
  DelegationState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  DelegationState
> = (set) => ({
  delegations: [],
  setDelegations: (delegations: Delegation[]) => set(() => ({ delegations })),
});
