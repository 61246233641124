import { useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { flatRoutes } from '@_plugins/router/router';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const BreadcrumbComponent = ({ t: trans }: WithTranslation): JSX.Element => {
  const { pathname } = useLocation();

  const activeRoute = useMemo(
    () => flatRoutes.find(({ path }) => pathname === path),
    [pathname],
  );

  return (
    <Breadcrumbs>
      {activeRoute?.ancestors.map((route) => (
        <Link key={route.name} style={{ cursor: 'pointer' }} underline="hover">
          {trans(route.name)}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default withTranslation()(BreadcrumbComponent);
