import day from '@_plugins/dayjs';
import {
  CustomerType,
  CustomerTypeHttp,
  CustomerTypeWizard,
} from '@data/models/customer-type.interface';

export const toCustomerType = (
  customerType: Partial<CustomerTypeHttp>,
): CustomerType => {
  return {
    id: customerType.id || '',
    label: customerType.attributes?.label || '',
    isImportantCustomer:
      (customerType.attributes?.is_important_customer && true) || false,
    createdAt: day(customerType.attributes?.createdAt),
    updatedAt: day(customerType.attributes?.updatedAt),
  };
};

export const toCustomerTypeWizard = (
  customerType: Partial<CustomerType>,
): CustomerTypeWizard => {
  return {
    type: 'customer_type_repositories',
    id: customerType.id || '',
    attributes: {
      label: customerType.label || '',
      is_important_customer: customerType?.isImportantCustomer || false,
    },
  };
};
