import { StateCreator } from 'zustand';

export interface UnreadCommunicationState {
  unreadCommunications: number;
  setCommunicationNotification: (unreadCommunications: number) => void;
  clearCommunicationNotification: () => void;
}

export const createUnreadCommunicationSlice: StateCreator<
  UnreadCommunicationState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  UnreadCommunicationState
> = (set) => ({
  unreadCommunications: 0,
  setCommunicationNotification: (unreadCommunications: number) =>
    set(() => ({ unreadCommunications })),
  clearCommunicationNotification: () =>
    set(() => ({ unreadCommunications: 0 })),
});
