/* eslint-disable no-unsafe-optional-chaining */
import i18n from '@_plugins/i18n';
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import ErrorService from 'core/error.service';
import {
  TrainingActionCandidate,
  TrainingActionCandidateHttp,
  TrainingActionCandidateWizard,
} from '@data/models/training-action-candidate.interface';
import { TrainingActionRawIncluded } from '@data/models/training-action.interface';
import { toTrainingActionCandidate } from '@data/dto/training-action-candidate.dto';
import { toTrainingActionIncluded } from '@data/dto/training-action.dto';
import { FilterParams } from '@data/models/filter.interface';
import { PaginateResult, Meta } from '@data/models/api.interface';
import { toTrainingActionDeviceMapIncluded } from '@data/dto/training-action-device-map.dto';
import { TrainingActionDeviceMapRawIncluded } from '@data/models/training-action-device-map.interface';

@injectable()
export default class TrainingActionCandidateService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingActionCandidateEndpoint =
    '/training-action-candidates';
  private errorService = container.get<ErrorService>(ErrorService);
  private includePath = ['profession', 'candidate_type'];

  public async listTrainingActionCandidates(
    filters: FilterParams,
  ): Promise<PaginateResult<TrainingActionCandidate[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingActionCandidateHttp[];
      meta?: Meta;
      included: TrainingActionDeviceMapRawIncluded;
    }>(this.trainingActionCandidateEndpoint, {
      ...filters,
      include: this.includePath,
    });

    const included = toTrainingActionDeviceMapIncluded(response?.included);

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const items =
      response?.data?.map((trainingActionDeviceMap) =>
        toTrainingActionCandidate(trainingActionDeviceMap, included),
      ) || [];

    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertTrainingActionCandidate({
    id,
    ...trainingActionCandidateWizard
  }: TrainingActionCandidateWizard): Promise<TrainingActionCandidate | null> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<TrainingActionCandidateWizard, 'id'> },
        {
          data: TrainingActionCandidateHttp;
          included: TrainingActionRawIncluded;
        }
      >(
        `${
          this.trainingActionCandidateEndpoint
        }?include=${this.includePath.join(',')}`,
        {
          data: trainingActionCandidateWizard,
        },
      );

      if (error) {
        this.errorService.handleHttpError(error);
        return null;
      }

      const included = toTrainingActionIncluded(data?.included || []);

      return toTrainingActionCandidate(data?.data || {}, included);
    }
    const { data, error } = await this.API?.patch<
      { data: TrainingActionCandidateWizard },
      {
        data: TrainingActionCandidateHttp;
        included: TrainingActionRawIncluded;
      }
    >(
      `${
        this.trainingActionCandidateEndpoint
      }/${id}?include=${this.includePath.join(',')}`,
      {
        data: { ...trainingActionCandidateWizard, id },
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    const included = toTrainingActionIncluded(data?.included || []);

    return toTrainingActionCandidate(data?.data || {}, included);
  }

  public async deleteTrainingActionCandidate(
    id: string,
  ): Promise<TrainingActionCandidate | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }
    const { data, error } = await this.API?.delete<{
      data: TrainingActionCandidateHttp;
      included: TrainingActionRawIncluded;
    }>(`${this.trainingActionCandidateEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toTrainingActionIncluded(data?.included || []);

    return toTrainingActionCandidate(data?.data || {}, included);
  }
}
