/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  toTrainingActionDeviceMap,
  toTrainingActionDeviceMapIncluded,
} from '@data/dto/training-action-device-map.dto';
import ErrorService from 'core/error.service';
import i18n from '@_plugins/i18n';
import { TrainingActionRawIncluded } from '@data/models/training-action.interface';
import { toTrainingActionIncluded } from '@data/dto/training-action.dto';
import {
  TrainingActionDeviceMap,
  TrainingActionDeviceMapHttp,
  TrainingActionDeviceMapRawIncluded,
  TrainingActionDeviceMapWizard,
} from '@data/models/training-action-device-map.interface';
import { FilterParams } from '@data/models/filter.interface';
import { Meta, PaginateResult } from '@data/models/api.interface';

@injectable()
export default class TrainingActionDeviceMapService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingActionDeviceMapEndpoint =
    '/training-action-device-maps';
  private errorService = container.get<ErrorService>(ErrorService);

  public async listTrainingActionDeviceMap(
    filters: FilterParams,
  ): Promise<PaginateResult<TrainingActionDeviceMap[]>> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingActionDeviceMapHttp[];
      meta?: Meta;
      included: TrainingActionDeviceMapRawIncluded;
    }>(this.trainingActionDeviceMapEndpoint, {
      ...filters,
      include: ['children', 'children.children', 'device_type'],
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return {
        items: [],
        totalSize: 0,
      };
    }

    const included = toTrainingActionDeviceMapIncluded(response?.included);

    const items =
      response?.data?.map((trainingActionDeviceMap) =>
        toTrainingActionDeviceMap(trainingActionDeviceMap, included),
      ) || [];

    return {
      items,
      totalSize: response?.meta?.page.total || 0,
    };
  }

  public async upsertTrainingActionDeviceMap({
    id,
    ...trainingActionDeviceMapWizard
  }: TrainingActionDeviceMapWizard): Promise<TrainingActionDeviceMap | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<TrainingActionDeviceMapWizard, 'id'> },
        {
          data: TrainingActionDeviceMapHttp;
          included: TrainingActionRawIncluded;
        }
      >(`${this.trainingActionDeviceMapEndpoint}/${id}`, {
        data: trainingActionDeviceMapWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      const included = toTrainingActionIncluded(data?.included || []);

      return toTrainingActionDeviceMap(data?.data || {}, included);
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingActionDeviceMapWizard },
      {
        data: TrainingActionDeviceMapHttp;
        included: TrainingActionRawIncluded;
      }
    >(`${this.trainingActionDeviceMapEndpoint}/${id}`, {
      data: { ...trainingActionDeviceMapWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toTrainingActionIncluded(data?.included || []);

    return toTrainingActionDeviceMap(data?.data || {}, included);
  }

  public async setIsChecked({
    id,
    isChecked,
  }: TrainingActionDeviceMap): Promise<TrainingActionDeviceMap | void> {
    const { data, error } = await this.API?.patch<
      { data: {} },
      {
        data: TrainingActionDeviceMapHttp;
        included: TrainingActionRawIncluded;
      }
    >(
      `${this.trainingActionDeviceMapEndpoint}/${id}/${
        (isChecked && 'check') || 'uncheck'
      }?include=training_course_training,device_type_repository,training_course_candidate`,
      {
        data: {},
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toTrainingActionIncluded(data?.included || []);

    return toTrainingActionDeviceMap(data?.data || {}, included);
  }

  public async deleteTrainingActionDeviceMap(
    id: string,
  ): Promise<TrainingActionDeviceMap | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: TrainingActionDeviceMapHttp;
      included: TrainingActionRawIncluded;
    }>(
      `${this.trainingActionDeviceMapEndpoint}/${id}?include=training_course_training,device_type_repository,training_course_candidate`,
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    const included = toTrainingActionIncluded(data?.included || []);

    return toTrainingActionDeviceMap(data?.data || {}, included);
  }
}
