import day from '@_plugins/dayjs';
import {
  TrainingCourseSession,
  TrainingCourseSessionHttp,
  TrainingCourseSessionPeriod,
  TrainingCourseSessionType,
} from '@data/models/training-course-session.interface';
import { TrainingCourseTraining } from '@data/models/training-course-training.interface';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';

import { toApiDate, toApiFloat } from './api.dto';

export const toTrainingCourseSession = (
  http: Partial<TrainingCourseSessionHttp>,
): TrainingCourseSession => {
  const trainingCourseTrainingId =
    http.relationships?.training_course_training?.data?.id || '';
  return {
    trainingCourseTrainingId,
    id: http?.id || '',
    fromDate: day(http.attributes?.from_date || ''),
    toDate: day(http.attributes?.to_date || ''),
    type: http.attributes?.session_type || TrainingCourseSessionType.TRAINING,
    period:
      http.attributes?.period || TrainingCourseSessionPeriod.ALL_DAY_PERIOD,
    duration: http.attributes?.session_duration || '',
  };
};

export const toTrainingCourseSessionHttp = (
  session: Partial<TrainingCourseSession>,
  trainingCourseId: string,
): TrainingCourseSessionHttp => {
  const sessionHttp: TrainingCourseSessionHttp = {
    type: 'training_course_sessions',
    id: session?.id || '',
    attributes: {
      from_date: toApiDate(session.fromDate),
      to_date: toApiDate(session.toDate),
      session_type: session.type || TrainingCourseSessionType.TRAINING,
      period: session.period || TrainingCourseSessionPeriod.ALL_DAY_PERIOD,
      session_duration: toApiFloat(session.duration, 2),
    },
    relationships: {
      training_course: {
        data: {
          type: 'training_courses',
          id: trainingCourseId,
        },
      },
    },
  };

  if (session.trainingCourseTrainingId) {
    sessionHttp.relationships.training_course_training = {
      data: {
        type: 'training_course_trainings',
        id: session.trainingCourseTrainingId,
      },
    };
  }

  return sessionHttp;
};

export const fromTrainingCourseTrainings = (
  trainings: TrainingCourseTraining[],
): TrainingCourseSession[] => {
  return trainings.reduce(
    (acc, { sessions, id }) => {
      const trainingSessions = sessions.map((session) => ({
        ...session,
        trainingId: id,
      }));

      return [...acc, ...trainingSessions];
    },
    [] as Array<TrainingCourseSession & { trainingId: string }>,
  );
};

export const toDateRange = ({
  fromDate,
  toDate,
}: TrainingCourseSession): DateRange<Dayjs> => {
  return [
    (fromDate.isValid() && fromDate) || null,
    (toDate.isValid() && toDate) || null,
  ];
};
