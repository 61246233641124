import { useMaterialThemeTokens } from '@groupeactual/design-tokens';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DesignSystemProvider } from '@groupeactual/ui-kit';
import { PropsWithChildren } from 'react';

const DesignSystemWrapper = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const { muiTokens } = useMaterialThemeTokens('Default');
  const theme = createTheme(muiTokens);

  return (
    <DesignSystemProvider>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </DesignSystemProvider>
  );
};

export default DesignSystemWrapper;
