import { Dayjs } from 'dayjs';

export enum CONTACT_TYPES {
  OF = 'training_organization',
  CANDIDATE = 'candidate',
  AGENCY = 'agency',
}

export interface ContactHttp {
  type: 'contacts';
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    email: string;
    company: string;
    job: string;
    phone_number: string;
    contact_type: CONTACT_TYPES;
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    training_course: {
      data?: {
        type: 'training_courses';
        id: string;
      };
    };
  };
}

export interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  company: string;
  job: string;
  phoneNumber: string;
  type: CONTACT_TYPES | null;
  createdAt: Dayjs;
  updatedAt: Dayjs;
  readAt: Dayjs | null;
  avatarColor: string;
}

export interface ContactWizard {
  type: 'contacts';
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    email: string;
    company: string;
    job: string;
    phone_number: string;
    contact_type: CONTACT_TYPES;
  };
  relationships: {
    training_course: {
      data?: {
        type: 'training_courses';
        id: string;
      };
    };
  };
}
