import day from '@_plugins/dayjs';
import {
  Chat,
  ChatHttp,
  ChatIncluded,
  ChatRawIncluded,
  ChatWizard,
  MESSAGE_ORIGIN,
} from '@data/models/chat.interface';
import { DocumentVersionHttp } from '@data/models/document-version.interface';
import { Document, DocumentHttp } from '@data/models/document.interface';
import { TrainingCourse } from '@data/models/training-course.interface';

import { toDocument } from './document.dto';

export const toChatIncluded = (
  included: ChatRawIncluded = [],
): ChatIncluded => {
  return {
    documents: included.filter(
      ({ type }) => type === 'documents',
    ) as DocumentHttp[],
    document_versions: included.filter(
      ({ type }) => type === 'document_versions',
    ) as DocumentVersionHttp[],
  };
};

export const toChat = (
  chat: Partial<ChatHttp>,
  included: Partial<ChatIncluded>,
): Chat => {
  const documentHttp =
    included.documents?.find(
      (document) => document?.id === chat.relationships?.document?.data?.id,
    ) || {};

  return {
    id: chat.id || '',
    firstName: chat.attributes?.first_name || '',
    lastName: chat.attributes?.last_name || '',
    fullName: chat.attributes?.full_name || '',
    email: chat.attributes?.email || '',
    document: toDocument(documentHttp, included),
    content: chat.attributes?.content || '',
    origin:
      (chat.attributes?.origin === 'erpf' && MESSAGE_ORIGIN.ERPF) ||
      MESSAGE_ORIGIN.LUCIE,
    createdAt: day(chat.attributes?.createdAt),
    updatedAt: day(chat.attributes?.updatedAt),
  };
};

export const toChatWizard = (
  chat: Partial<Chat>,
  trainingCourse: Pick<TrainingCourse, 'id'>,
  document: Partial<Document>,
): ChatWizard => {
  const chatWizard: ChatWizard = {
    type: 'chats',
    id: chat.id || '',
    attributes: {
      content: chat.content || '',
      origin: MESSAGE_ORIGIN.ERPF,
    },
    relationships: {
      training_course: {
        data: {
          type: 'training_courses',
          id: trainingCourse.id || '',
        },
      },
    },
  };

  if (document?.id) {
    chatWizard.relationships.document = {
      data: {
        type: 'documents',
        id: document.id || '',
      },
    };
  }

  return chatWizard;
};
