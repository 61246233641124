/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  TrainingActionTrainingCandidateInvoice,
  TrainingActionTrainingCandidateInvoiceHttp,
  TrainingActionTrainingCandidateInvoiceWizard,
} from '@data/models/training-action-training-candidate-invoice.interface';
import { toTrainingActionTrainingCandidateInvoice } from '@data/dto/training-action-training-candidate-invoice.dto';
import ErrorService from 'core/error.service';
import i18n from '@_plugins/i18n';
import { TrainingActionRawIncluded } from '@data/models/training-action.interface';

@injectable()
export default class TrainingActionTrainingCandidateInvoiceService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingActionTrainingCandidateInvoiceEndpoint =
    '/training-action-invoices';

  private errorService = container.get<ErrorService>(ErrorService);

  private include = ['estimator_candidate', 'training_course_training'];

  public async upsertTrainingActionTrainingCandidateInvoice({
    id,
    ...trainingActionTrainingCandidateInvoiceWizard
  }: TrainingActionTrainingCandidateInvoiceWizard): Promise<TrainingActionTrainingCandidateInvoice | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        {
          data: Omit<TrainingActionTrainingCandidateInvoiceWizard, 'id'>;
        },
        {
          data: TrainingActionTrainingCandidateInvoiceHttp;
          included: TrainingActionRawIncluded;
        }
      >(
        `${
          this.trainingActionTrainingCandidateInvoiceEndpoint
        }?include=${this.include.join(',')}`,
        {
          data: trainingActionTrainingCandidateInvoiceWizard,
        },
      );

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }
      return toTrainingActionTrainingCandidateInvoice(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingActionTrainingCandidateInvoiceWizard },
      {
        data: TrainingActionTrainingCandidateInvoiceHttp;
      }
    >(`${this.trainingActionTrainingCandidateInvoiceEndpoint}/${id}`, {
      data: { ...trainingActionTrainingCandidateInvoiceWizard, id },
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }
    return toTrainingActionTrainingCandidateInvoice(data?.data || {});
  }

  public async deleteTrainingActionTrainingCandidateInvoice(
    id: string,
  ): Promise<TrainingActionTrainingCandidateInvoice | void> {
    if (!id) {
      this.errorService.handleError({
        title: i18n.t('error.deleting'),
        message: i18n.t('error.id.missing'),
      });
      return;
    }

    const { data, error } = await this.API?.delete<{
      data: TrainingActionTrainingCandidateInvoiceHttp;
    }>(`${this.trainingActionTrainingCandidateInvoiceEndpoint}/${id}`);

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingActionTrainingCandidateInvoice(data?.data || {});
  }
}
