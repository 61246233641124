import day from '@_plugins/dayjs';
import {
  OpcoCharge,
  OpcoChargeHttp,
  OpcoChargeWizard,
} from '@data/models/opco-charge.interface';

export const toOpcoCharge = (
  opcoCharge: Partial<OpcoChargeHttp>,
): OpcoCharge => {
  return {
    id: opcoCharge.id || '',
    label: opcoCharge.attributes?.label || '',
    year: opcoCharge.attributes?.year || '',
    rate: opcoCharge.attributes?.rate || '',
    createdAt: day(opcoCharge.attributes?.createdAt),
    updatedAt: day(opcoCharge.attributes?.updatedAt),
  };
};

export const toOpcoChargeWizard = (
  opcoCharge: Partial<OpcoCharge>,
): OpcoChargeWizard => {
  return {
    type: 'opco_charge_repositories',
    id: opcoCharge.id || '',
    attributes: {
      label: opcoCharge.label || '',
      year: opcoCharge.year || '',
      rate: opcoCharge.rate || '',
    },
  };
};
