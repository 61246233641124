import {
  TrainingAction,
  TrainingActionIncluded,
} from '@data/models/training-action.interface';

import dayjs from 'dayjs';
import { toTrainingActionCandidatePeriod } from './training-action-candidate-period.dto';
import {
  FinancingBlock,
  TrainingActionCandidate,
} from '@data/models/training-action-candidate.interface';
import { toApiFloat } from './api.dto';
import { toTrainingActionTrainingCandidateInvoice } from './training-action-training-candidate-invoice.dto';
import { TrainingCourseEstimator } from '@data/models/training-course-estimator.interface';
import { fromTrainingCourseEstimatorHttp } from './training-course-estimator.dto';
import {
  TrainingActionEstimatorCandidate,
  TrainingActionEstimatorCandidateHttp,
  TrainingActionEstimatorCandidateStatuses,
  TrainingActionEstimatorCandidateWizard,
} from '@data/models/training-action-estimator-candidate.interface';
import { EstimatorCandidateConversion } from '@data/models/training-course-conversion.interface';
import { toApiDate } from '@data/dto/api.dto';

// eslint-disable-next-line complexity
export const toTrainingActionEstimatorCandidate = (
  {
    id,
    relationships,
    attributes,
  }: Partial<TrainingActionEstimatorCandidateHttp>,
  included: Partial<TrainingActionIncluded>,
): TrainingActionEstimatorCandidate => {
  const estimatorHttp =
    included.estimators?.find(
      ({ id }) => id === relationships?.training_course_estimator?.data?.id,
    ) || {};

  const candidatesHttp = included.candidates?.find(
    ({ id }) => id === relationships?.training_action_candidate?.data?.id,
  );

  const invoicesId =
    relationships?.training_action_invoices?.data?.map(({ id }) => id) || [];

  const invoicesHttp =
    included.invoices?.filter(({ id }) => invoicesId.includes(id)) || [];

  const candidateId = relationships?.training_action_candidate?.data?.id;

  const candidatePeriodsHttp =
    included.candidatePeriods?.filter(
      ({ relationships }) =>
        relationships.training_action_candidate.data?.id === candidateId,
    ) || [];

  return {
    id: id || '',
    status:
      attributes?.status || TrainingActionEstimatorCandidateStatuses.TO_DO,
    fromDate: dayjs(attributes?.from_date || undefined),
    toDate: dayjs(attributes?.to_date || undefined),
    positioningReportExpectedDuration:
      attributes?.positioning_report_expected_duration || '',
    positioningReportRealDuration:
      attributes?.positioning_report_real_duration || '',
    positioningReportPaidDuration:
      attributes?.positioning_report_paid_duration || '',
    externalTrainingExpectedDuration:
      attributes?.external_training_expected_duration || '',
    externalTrainingRealDuration:
      attributes?.external_training_real_duration || '',
    externalTrainingPaidDuration:
      attributes?.external_training_paid_duration || '',
    practicalTrainingExpectedDuration:
      attributes?.practical_training_expected_duration || '',
    practicalTrainingRealDuration:
      attributes?.practical_training_real_duration || '',
    practicalTrainingPaidDuration:
      attributes?.practical_training_paid_duration || '',
    absenceTrainingRealDuration:
      attributes?.absence_training_real_duration || '',
    totalExpectedDuration: attributes?.total_expected_duration || '',
    totalRealDuration: attributes?.total_real_duration || '',
    totalPaidDuration: attributes?.total_paid_duration || '',
    wageExpectedCost: attributes?.wage_expected_cost || '',
    wageRealCost: attributes?.wage_real_cost || '',
    additionalExpectedCost: attributes?.additional_expected_cost || '',
    additionalRealCost: attributes?.additional_real_cost || '',
    educationalExpectedCost: attributes?.educational_expected_cost || '',
    educationalRealCost: attributes?.educational_real_cost || '',
    totalExpectedCost: attributes?.total_expected_cost || '',
    totalRealCost: attributes?.total_real_cost || '',
    opcoForecastAmount: attributes?.opco_forecast_amount || '',
    opcoFinanceableAmount: attributes?.opco_financeable_amount || '',
    opcoRequestedAmount: attributes?.opco_requested_amount || '',
    opcoReceivedAmount: attributes?.opco_received_amount || '',
    miscellaneousServicesForecastAmount:
      attributes?.miscellaneous_services_forecast_amount || '',
    miscellaneousServicesFinanceableAmount:
      attributes?.miscellaneous_services_financeable_amount || '',
    miscellaneousServicesRequestedAmount:
      attributes?.miscellaneous_services_requested_amount || '',
    miscellaneousServicesReceivedAmount:
      attributes?.miscellaneous_services_received_amount || '',
    cofiForecastAmount: attributes?.cofi_forecast_amount || '',
    cofiFinanceableAmount: attributes?.cofi_financeable_amount || '',
    cofiRequestedAmount: attributes?.cofi_requested_amount || '',
    cofiReceivedAmount: attributes?.cofi_received_amount || '',
    variousAidsForecastAmount: attributes?.various_aids_forecast_amount || '',
    variousAidsFinanceableAmount:
      attributes?.various_aids_financeable_amount || '',
    variousAidsRequestedAmount: attributes?.various_aids_requested_amount || '',
    variousAidsReceivedAmount: attributes?.various_aids_received_amount || '',
    remainingForecastAmount: attributes?.remaining_forecast_amount || '',
    remainingFinanceableAmount: attributes?.remaining_financeable_amount || '',
    remainingRequestedAmount: attributes?.remaining_requested_amount || '',
    remainingReceivedAmount: attributes?.remaining_received_amount || '',
    estimator: fromTrainingCourseEstimatorHttp(estimatorHttp, {
      ...included,
      candidateTrainings: included.candidateTrainings?.filter(
        (training) =>
          training.relationships.training_action_candidate.data?.id ===
          candidateId,
      ),
    }),
    candidatePeriods: candidatePeriodsHttp?.map((candidatePeriod) =>
      toTrainingActionCandidatePeriod(candidatePeriod),
    ),
    transportExpectedFees: attributes?.transport_expected_fees || '',
    transportRealFees: attributes?.transport_real_fees || '',
    transportAnaelFees: attributes?.transport_anael_fees || '',
    accommodationExpectedFees: attributes?.accommodation_expected_fees || '',
    accommodationRealFees: attributes?.accommodation_real_fees || '',
    accommodationAnaelFees: attributes?.accommodation_anael_fees || '',
    mealExpectedFees: attributes?.meal_expected_fees || '',
    mealRealFees: attributes?.meal_real_fees || '',
    mealAnaelFees: attributes?.meal_anael_fees || '',
    totalExpectedFees: attributes?.total_expected_fees || '',
    totalRealFees: attributes?.total_real_fees || '',
    totalAnaelFees: attributes?.total_anael_fees || '',
    trainingCourseRate: candidatesHttp?.attributes?.training_course_rate || '0',
    trainingActionCandidateId: candidateId || '',
    invoices: invoicesHttp.map(toTrainingActionTrainingCandidateInvoice),
  };
};

// eslint-disable-next-line complexity
export const toTrainingActionEstimatorCandidateWizard = (
  { id, ...rest }: Partial<TrainingActionEstimatorCandidate>,
  action: Partial<TrainingAction>,
  actionCandidate?: Partial<TrainingActionCandidate>,
  estimator?: Partial<TrainingCourseEstimator>,
): TrainingActionEstimatorCandidateWizard => {
  const trainingDeviceTypeCandidateWizard: TrainingActionEstimatorCandidateWizard =
    {
      id: id || '',
      type: 'estimator_candidates',
      attributes: {
        status: rest.status || TrainingActionEstimatorCandidateStatuses.TO_DO,
        from_date: toApiDate(rest?.fromDate),
        to_date: toApiDate(rest?.toDate),
        positioning_report_expected_duration: toApiFloat(
          rest?.positioningReportExpectedDuration,
        ),
        positioning_report_real_duration: toApiFloat(
          rest?.positioningReportRealDuration,
        ),
        positioning_report_paid_duration: toApiFloat(
          rest?.positioningReportPaidDuration,
        ),
        external_training_expected_duration: toApiFloat(
          rest?.externalTrainingExpectedDuration,
        ),
        external_training_real_duration: toApiFloat(
          toApiFloat(rest?.externalTrainingRealDuration),
        ),
        external_training_paid_duration: toApiFloat(
          rest?.externalTrainingPaidDuration,
        ),
        practical_training_expected_duration: toApiFloat(
          rest?.practicalTrainingExpectedDuration,
        ),
        practical_training_real_duration: toApiFloat(
          rest?.practicalTrainingRealDuration,
        ),
        practical_training_paid_duration: toApiFloat(
          rest?.practicalTrainingPaidDuration,
        ),
        absence_training_real_duration: rest?.absenceTrainingRealDuration || '',
        total_expected_duration: toApiFloat(rest?.totalExpectedDuration),
        total_real_duration: toApiFloat(rest?.totalRealDuration),
        total_paid_duration: toApiFloat(rest?.totalPaidDuration),
        wage_expected_cost: toApiFloat(rest?.wageExpectedCost),
        wage_real_cost: toApiFloat(rest?.wageRealCost),
        additional_expected_cost: toApiFloat(rest?.additionalExpectedCost),
        additional_real_cost: toApiFloat(rest?.additionalRealCost),
        educational_expected_cost: toApiFloat(
          estimator?.educationalCostAmountPerCandidate ||
            rest?.educationalExpectedCost,
        ),
        educational_real_cost: toApiFloat(rest?.educationalRealCost),
        total_expected_cost: toApiFloat(rest?.totalExpectedCost),
        total_real_cost: toApiFloat(rest?.totalRealCost),
        transport_expected_fees: toApiFloat(rest?.transportExpectedFees),
        transport_real_fees: toApiFloat(rest?.transportRealFees),
        transport_anael_fees: toApiFloat(rest?.transportAnaelFees),
        accommodation_expected_fees: toApiFloat(
          rest?.accommodationExpectedFees,
        ),
        accommodation_real_fees: toApiFloat(rest?.accommodationRealFees),
        accommodation_anael_fees: toApiFloat(rest?.accommodationAnaelFees),
        meal_expected_fees: toApiFloat(rest?.mealExpectedFees),
        meal_real_fees: toApiFloat(rest?.mealRealFees),
        meal_anael_fees: toApiFloat(rest?.mealAnaelFees),
        total_expected_fees: toApiFloat(rest?.totalExpectedFees),
        total_real_fees: toApiFloat(rest?.totalRealFees),
        total_anael_fees: toApiFloat(rest?.totalAnaelFees),
        opco_forecast_amount: toApiFloat(rest?.opcoForecastAmount),
        opco_financeable_amount: toApiFloat(rest?.opcoFinanceableAmount),
        opco_requested_amount: toApiFloat(rest?.opcoRequestedAmount),
        opco_received_amount: toApiFloat(rest?.opcoReceivedAmount),
        miscellaneous_services_forecast_amount: toApiFloat(
          rest?.miscellaneousServicesForecastAmount,
        ),
        miscellaneous_services_financeable_amount: toApiFloat(
          rest?.miscellaneousServicesFinanceableAmount,
        ),
        miscellaneous_services_requested_amount: toApiFloat(
          rest?.miscellaneousServicesRequestedAmount,
        ),
        miscellaneous_services_received_amount: toApiFloat(
          rest?.miscellaneousServicesReceivedAmount,
        ),
        cofi_forecast_amount: toApiFloat(rest?.cofiForecastAmount),
        cofi_financeable_amount: toApiFloat(rest?.cofiFinanceableAmount),
        cofi_requested_amount: toApiFloat(rest?.cofiRequestedAmount),
        cofi_received_amount: toApiFloat(rest?.cofiReceivedAmount),
        various_aids_forecast_amount: toApiFloat(
          rest?.variousAidsForecastAmount,
        ),
        various_aids_financeable_amount: toApiFloat(
          rest?.variousAidsFinanceableAmount,
        ),
        various_aids_requested_amount: toApiFloat(
          rest?.variousAidsRequestedAmount,
        ),
        various_aids_received_amount: toApiFloat(
          rest?.variousAidsReceivedAmount,
        ),
        remaining_forecast_amount: toApiFloat(rest?.remainingForecastAmount),
        remaining_financeable_amount: toApiFloat(
          rest?.remainingFinanceableAmount,
        ),
        remaining_requested_amount: toApiFloat(rest?.remainingRequestedAmount),
        remaining_received_amount: toApiFloat(rest?.remainingReceivedAmount),
      },
      relationships: {
        training_action: {
          data: { type: 'training_actions', id: action?.id || '' },
        },
      },
    };

  if (estimator) {
    trainingDeviceTypeCandidateWizard.relationships.training_course_estimator =
      {
        data: {
          type: 'training_course_estimators',
          id: estimator?.id || '',
        },
      };
  }

  if (actionCandidate) {
    trainingDeviceTypeCandidateWizard.relationships.training_action_candidate =
      {
        data: {
          type: 'training_action_candidates',
          id: actionCandidate?.id || '',
        },
      };
  }

  return trainingDeviceTypeCandidateWizard;
};

// eslint-disable-next-line complexity
export const fromEstimatorCandidateConversion = (
  { candidate, estimator }: Partial<EstimatorCandidateConversion>,
  { id: trainingActionId }: Partial<TrainingAction>,
): TrainingActionEstimatorCandidateWizard => {
  return {
    id: '',
    type: 'estimator_candidates',
    attributes: {
      status: TrainingActionEstimatorCandidateStatuses.TO_DO,
      from_date: '',
      to_date: '',
      positioning_report_expected_duration: '',
      positioning_report_real_duration: '',
      positioning_report_paid_duration: '',
      external_training_expected_duration: '',
      external_training_real_duration: '',
      external_training_paid_duration: '',
      practical_training_expected_duration: '',
      practical_training_real_duration: '',
      practical_training_paid_duration: '',
      absence_training_real_duration: '',
      total_expected_duration: '',
      total_real_duration: '',
      total_paid_duration: '',
      wage_expected_cost: '',
      wage_real_cost: '',
      additional_expected_cost: '',
      additional_real_cost: '',
      educational_expected_cost: '',
      educational_real_cost: '',
      total_expected_cost: '',
      total_real_cost: '',
      transport_expected_fees: '',
      transport_real_fees: '',
      transport_anael_fees: '',
      accommodation_expected_fees: '',
      accommodation_real_fees: '',
      accommodation_anael_fees: '',
      meal_expected_fees: '',
      meal_real_fees: '',
      meal_anael_fees: '',
      total_expected_fees: '',
      total_real_fees: '',
      total_anael_fees: '',
      opco_forecast_amount: '',
      opco_financeable_amount: '',
      opco_requested_amount: '',
      opco_received_amount: '',
      miscellaneous_services_forecast_amount: '',
      miscellaneous_services_financeable_amount: '',
      miscellaneous_services_requested_amount: '',
      miscellaneous_services_received_amount: '',
      cofi_forecast_amount: '',
      cofi_financeable_amount: '',
      cofi_requested_amount: '',
      cofi_received_amount: '',
      various_aids_forecast_amount: '',
      various_aids_financeable_amount: '',
      various_aids_requested_amount: '',
      various_aids_received_amount: '',
      remaining_forecast_amount: '',
      remaining_financeable_amount: '',
      remaining_requested_amount: '',
      remaining_received_amount: '',
    },
    relationships: {
      training_course_candidate: {
        data: {
          type: 'training_course_candidates',
          id: candidate?.id || '',
        },
      },
      training_course_estimator: {
        data: {
          type: 'training_course_estimators',
          id: estimator?.id || '',
        },
      },
      training_action: {
        data: {
          type: 'training_actions',
          id: trainingActionId || '',
        },
      },
      training_action_candidate: {
        data: null,
      },
    },
  };
};

export const fromFinancingBlockToEstimatorCandidate = (
  estimatorCandidate: TrainingActionEstimatorCandidate,
  financingBlocks: FinancingBlock[],
): TrainingActionEstimatorCandidate => {
  const opco = financingBlocks.find(({ title }) => title === 'opco');
  const miscellaneousService = financingBlocks.find(
    ({ title }) => title === 'miscellaneous_service',
  );
  const cofi = financingBlocks.find(({ title }) => title === 'cofi');
  const variousAids = financingBlocks.find(
    ({ title }) => title === 'various_aids',
  );
  const remainings = financingBlocks.find(
    ({ title }) => title === 'remains_dependent',
  );
  return {
    ...estimatorCandidate,
    opcoForecastAmount: opco?.forecastValue.toString() || '',
    opcoFinanceableAmount: opco?.financableValue.toString() || '',
    opcoRequestedAmount: opco?.requestedValue.toString() || '',
    opcoReceivedAmount: opco?.receivedValue.toString() || '',
    miscellaneousServicesForecastAmount:
      miscellaneousService?.forecastValue.toString() || '',
    miscellaneousServicesFinanceableAmount:
      miscellaneousService?.financableValue.toString() || '',
    miscellaneousServicesRequestedAmount:
      miscellaneousService?.requestedValue.toString() || '',
    miscellaneousServicesReceivedAmount:
      miscellaneousService?.receivedValue.toString() || '',
    cofiForecastAmount: cofi?.forecastValue.toString() || '',
    cofiFinanceableAmount: cofi?.financableValue.toString() || '',
    cofiRequestedAmount: cofi?.requestedValue.toString() || '',
    cofiReceivedAmount: cofi?.receivedValue.toString() || '',
    variousAidsForecastAmount: variousAids?.forecastValue.toString() || '',
    variousAidsFinanceableAmount: variousAids?.financableValue.toString() || '',
    variousAidsRequestedAmount: variousAids?.requestedValue.toString() || '',
    variousAidsReceivedAmount: variousAids?.receivedValue.toString() || '',
    remainingForecastAmount: remainings?.forecastValue.toString() || '',
    remainingFinanceableAmount: remainings?.financableValue.toString() || '',
    remainingRequestedAmount: remainings?.requestedValue.toString() || '',
    remainingReceivedAmount: remainings?.receivedValue.toString() || '',
  };
};
