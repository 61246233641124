/* eslint-disable no-unsafe-optional-chaining */
import { injectable } from 'inversify';
import { container } from '@_plugins/ioc';
import LaravelAPI from '@data/api/laravel.api';
import {
  TrainingAction,
  TrainingActionHttp,
  TrainingActionWizard,
  TrainingActionRawIncluded,
} from '@data/models/training-action.interface';
import {
  toTrainingActionIncluded,
  toTrainingAction,
} from '@data/dto/training-action.dto';
import ErrorService from 'core/error.service';

@injectable()
export default class TrainingActionService {
  private API = container.get<LaravelAPI>(LaravelAPI);
  private readonly trainingActionEndpoint = '/training-actions';
  private errorService = container.get<ErrorService>(ErrorService);

  public async getTrainingActionByTrainingCourseReference(
    trainingCourseReference: string,
  ): Promise<TrainingAction | null> {
    const { data: response, error } = await this.API?.get<{
      data: TrainingActionHttp[];
      included: TrainingActionRawIncluded;
    }>(`${this.trainingActionEndpoint}`, {
      filter: {
        training_course_reference: trainingCourseReference,
      },
      itemsPerPage: 1,
      page: 1,
      include: [
        'training_action_candidates',
        'training_action_candidates.profession',
        'training_action_candidates.candidate_type',
        'educational_path',
      ],
    });

    if (error) {
      this.errorService.handleHttpError(error);
      return null;
    }

    if (!response?.data?.[0]) {
      return null;
    }

    const trainingActionHttp = response.data[0];

    const included = toTrainingActionIncluded(response?.included || []);

    return toTrainingAction(
      trainingActionHttp,
      included,
      trainingCourseReference,
    );
  }

  public async upsertTrainingAction(
    { id, ...trainingActionWizard }: TrainingActionWizard,
    isDraft = false,
  ): Promise<TrainingAction | void> {
    if (!id) {
      const { data, error } = await this.API?.post<
        { data: Omit<TrainingActionWizard, 'id'> },
        { data: TrainingActionHttp }
      >(`${this.trainingActionEndpoint}${(isDraft && '/draft') || ''}`.trim(), {
        data: trainingActionWizard,
      });

      if (error) {
        this.errorService.handleHttpError(error);
        return;
      }

      return toTrainingAction(data?.data || {});
    }

    const { data, error } = await this.API?.patch<
      { data: TrainingActionWizard },
      { data: TrainingActionHttp }
    >(
      `${this.trainingActionEndpoint}/${id}${
        (isDraft && '/draft') || ''
      }`.trim(),
      {
        data: { ...trainingActionWizard, id },
      },
    );

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingAction(data?.data || {});
  }

  public async revertTrainingAction({
    id,
  }: TrainingAction): Promise<TrainingAction | undefined> {
    const { data, error } = await this.API?.patch<
      {},
      { data: TrainingActionHttp }
    >(`${this.trainingActionEndpoint}/${id}/revert`.trim(), {});

    if (error) {
      this.errorService.handleHttpError(error);
      return;
    }

    return toTrainingAction(data?.data || {});
  }

  public async deleteTrainingAction({ id }: TrainingAction): Promise<void> {
    this.API?.delete(`${this.trainingActionEndpoint}/${id}`);
    return;
  }
}
