import dayjs from '@_plugins/dayjs';
import { SortParams } from '@data/models/api.interface';
import { DatatableReorderParams } from '@data/models/datatable.interface';
import { Dayjs } from 'dayjs';
import isNumber from 'lodash/isNumber';

export const toSortParams = (
  type: string,
  sortParams: DatatableReorderParams<{ id: string }>,
): SortParams => {
  return {
    type,
    id: sortParams.current.id,
    attributes: {
      resource_id: sortParams.target.id,
      direction: sortParams.position,
    },
  };
};

export const toApiFloat = (
  initialValue: string | number = '00',
  precision = 2,
): string => {
  if (!initialValue && initialValue !== 0) {
    return '';
  }
  if (isNumber(initialValue)) {
    return isNaN(initialValue) || initialValue === Infinity
      ? (0).toFixed(precision)
      : initialValue.toFixed(precision);
  }
  return parseFloat(initialValue.replace(' ', '').replace(',', '.')).toFixed(
    precision,
  );
};

export const toApiDate = (value?: Dayjs | string | null): string => {
  if (!value) {
    return '';
  }
  if (typeof value === 'string') {
    return dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }
  return value.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

// ?
export const toBoolean = (
  value: boolean | number | undefined | null,
): boolean => {
  return value === 1 || value === true || false;
};
