import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { ToastStore } from '@stores/slices/toast.slice';

interface Props {
  toast: ToastStore;
}
const Toast = ({ toast }: Props): JSX.Element => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar(toast.message, {
      key: toast.id,
      onClick: () => closeSnackbar(toast.id),
      variant: toast.level,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    });
  }, []);
  return <></>;
};

export default Toast;
