import { StateCreator } from 'zustand';
import { RefundRequest } from '@data/models/refund-request.interface';
import { toRefundRequest } from '@data/dto/refund-request.dto';
import compact from 'lodash/compact';

export interface RefundRequestState {
  refundRequests: RefundRequest[];
  setNewRefundRequest: (refundRequest: RefundRequest) => void;
  updateRefundRequest: (refundRequest: RefundRequest) => void;
  removeRefundRequest: (refundRequest: Pick<RefundRequest, 'id'>) => void;
  updateRefundRequests: (refundRequests: RefundRequest[]) => void;
  findRefundRequestByCandidate: (candidateId: string) => RefundRequest[];
}

export const createRefundRequestSlice: StateCreator<
  RefundRequestState,
  [['zustand/devtools', never]],
  [['zustand/persist', never]],
  RefundRequestState
> = (set, get) => ({
  refundRequests: [toRefundRequest({}, {})],
  setNewRefundRequest: (refundRequest: RefundRequest) =>
    set((state) => ({
      refundRequests: [...state.refundRequests, refundRequest],
    })),
  updateRefundRequest: (refundRequest: RefundRequest) =>
    set((state) => ({
      refundRequests: state.refundRequests.map((rr) =>
        rr.id === refundRequest.id ? refundRequest : rr,
      ),
    })),
  updateRefundRequests: (refundRequests: RefundRequest[]) =>
    set(() => ({
      refundRequests,
    })),
  removeRefundRequest: ({ id: refundReqId }: Pick<RefundRequest, 'id'>) =>
    set((state) => ({
      refundRequests: state.refundRequests.filter(
        ({ id }) => id !== refundReqId,
      ),
    })),
  findRefundRequestByCandidate: (candidateId: string) =>
    compact(
      get().refundRequests.filter(
        ({ trainingActionCandidateId }) =>
          candidateId === trainingActionCandidateId,
      ),
    ),
});
