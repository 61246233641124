import AuthService from '@core/auth.service';
import AtlasApi from '@data/api/atlas.api';
import FileAPI from '@data/api/file.api';
import GeoApi from '@data/api/geo.api';
import HolidayApi from '@data/api/holiday.api';
import LaravelAPI from '@data/api/laravel.api';
import ScudApi from '@data/api/scud.api';
import ActivityFieldService from '@data/services/activity-field.service';
import ActivityService from '@data/services/activity.service';
import BlocklistService from '@data/services/blocklist.service';
import CandidateTypeService from '@data/services/candidate-type.service';
import CandidateService from '@data/services/candidate.service';
import ChatService from '@data/services/chat.service';
import CityService from '@data/services/city.service';
import CoFunderService from '@data/services/co-funder.service';
import CommunicationService from '@data/services/communication.service';
import ContactService from '@data/services/contact.service';
import ControlOrganizationService from '@data/services/control-organization.service';
import CourseTypeService from '@data/services/course-type.service';
import CustomerTypeService from '@data/services/customer-type.service';
import CustomerService from '@data/services/customer.service';
import DelegationService from '@data/services/delegation.service';
import DeviceTypeService from '@data/services/device-type.service';
import DocumentVersionService from '@data/services/document-version.service';
import DocumentService from '@data/services/document.service';
import TrainingActionEstimatorCandidateService from '@data/services/estimator-candidate.service';
import EstimatorConfigurationService from '@data/services/estimator-configuration.service';
import FinancingTypeService from '@data/services/financing-type.service';
import GCloudService from '@data/services/gcloud.service';
import GroupMigrationService from '@data/services/group-migration.service';
import GroupService from '@data/services/group.service';
import HabilitationService from '@data/services/habilitation.service';
import HolidayService from '@data/services/holiday.service';
import ManagerService from '@data/services/manager.service';
import MinWageService from '@data/services/min-wage.service';
import NotionPageService from '@data/services/notion-page.service';
import OpcoChargeService from '@data/services/opco-charge.service';
import PecRateService from '@data/services/pec-rate.service';
import ProfessionService from '@data/services/profession.service';
import ProjectOriginService from '@data/services/project-origin.service';
import RefundRequestService from '@data/services/refund-request.service';
import RegionService from '@data/services/region.service';
import StatisticService from '@data/services/statistic.service';
import StoredSearchService from '@data/services/stored-search.service';
import StrategicAxisService from '@data/services/strategic-axis.service';
import TrainingActioCandidatePeriodService from '@data/services/training-action-candidate-period.service';
import TrainingActionCandidateTrainingService from '@data/services/training-action-candidate-training.service';
import TrainingActionCandidateService from '@data/services/training-action-candidate.service';
import TrainingActionDeviceMapService from '@data/services/training-action-device-map.service';
import TrainingActionTrainingCandidateInvoiceService from '@data/services/training-action-training-candidate-invoice.service';
import TrainingActionService from '@data/services/training-action.service';
import TrainingCourseCommentService from '@data/services/training-course-comment.service';
import TrainingCourseEducationalPathService from '@data/services/training-course-educational-path.service';
import TrainingCourseStateService from '@data/services/training-course-state.service';
import TrainingCourseService from '@data/services/training-course.service';
import TrainingService from '@data/services/training.service';
import UserNotificationService from '@data/services/user-notification.service';
import ValidationTypeService from '@data/services/validation-type.service';
import ErrorService from 'core/error.service';
import { Container } from 'inversify';

export const container = new Container();
container.bind(LaravelAPI).to(LaravelAPI);
container.bind(FileAPI).to(FileAPI);
container.bind(ScudApi).to(ScudApi);
container.bind(AtlasApi).to(AtlasApi);
container.bind(GeoApi).to(GeoApi);
container.bind(HolidayApi).to(HolidayApi);
container.bind(GCloudService).to(GCloudService);
container.bind(HolidayService).to(HolidayService);
container.bind(CityService).to(CityService);
container.bind(ErrorService).to(ErrorService);
container.bind(AuthService).to(AuthService);
container.bind(CoFunderService).to(CoFunderService);
container.bind(GroupMigrationService).to(GroupMigrationService);
container.bind(ProjectOriginService).to(ProjectOriginService);
container.bind(ActivityFieldService).to(ActivityFieldService);
container.bind(MinWageService).to(MinWageService);
container.bind(PecRateService).to(PecRateService);
container.bind(DeviceTypeService).to(DeviceTypeService);
container.bind(CandidateTypeService).to(CandidateTypeService);
container.bind(ProfessionService).to(ProfessionService);
container.bind(OpcoChargeService).to(OpcoChargeService);
container.bind(BlocklistService).to(BlocklistService);
container.bind(ControlOrganizationService).to(ControlOrganizationService);
container.bind(CourseTypeService).to(CourseTypeService);
container.bind(ValidationTypeService).to(ValidationTypeService);
container.bind(CustomerTypeService).to(CustomerTypeService);
container.bind(StrategicAxisService).to(StrategicAxisService);
container.bind(RegionService).to(RegionService);
container.bind(ManagerService).to(ManagerService);
container.bind(GroupService).to(GroupService);
container.bind(TrainingService).to(TrainingService);
container.bind(CandidateService).to(CandidateService);
container.bind(TrainingCourseService).to(TrainingCourseService);
container.bind(CustomerService).to(CustomerService);
container.bind(StatisticService).to(StatisticService);
container.bind(UserNotificationService).to(UserNotificationService);
container.bind(TrainingCourseStateService).to(TrainingCourseStateService);
container.bind(TrainingCourseCommentService).to(TrainingCourseCommentService);
container.bind(NotionPageService).to(NotionPageService);
container.bind(StoredSearchService).to(StoredSearchService);
container.bind(EstimatorConfigurationService).to(EstimatorConfigurationService);
container.bind(DocumentService).to(DocumentService);
container.bind(DocumentVersionService).to(DocumentVersionService);
container.bind(ActivityService).to(ActivityService);
container.bind(TrainingActionService).to(TrainingActionService);
container
  .bind(TrainingActionEstimatorCandidateService)
  .to(TrainingActionEstimatorCandidateService);
container
  .bind(TrainingActionDeviceMapService)
  .to(TrainingActionDeviceMapService);
container
  .bind(TrainingActionCandidateService)
  .to(TrainingActionCandidateService);
container
  .bind(TrainingActioCandidatePeriodService)
  .to(TrainingActioCandidatePeriodService);
container
  .bind(TrainingActionTrainingCandidateInvoiceService)
  .to(TrainingActionTrainingCandidateInvoiceService);
container.bind(CommunicationService).to(CommunicationService);
container.bind(ContactService).to(ContactService);
container.bind(ChatService).to(ChatService);
container.bind(DelegationService).to(DelegationService);
container.bind(FinancingTypeService).to(FinancingTypeService);
container.bind(RefundRequestService).to(RefundRequestService);
container
  .bind(TrainingCourseEducationalPathService)
  .to(TrainingCourseEducationalPathService);
container
  .bind(TrainingActionCandidateTrainingService)
  .to(TrainingActionCandidateTrainingService);
container.bind(HabilitationService).to(HabilitationService);

container.bind('IFactory').to(class {}); // utils for tu testing
