import i18n from '@_plugins/i18n';
import * as Yup from 'yup';

export const validMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/png',
  'image/jpeg',
  'application/x-bzip',
  'application/x-bzip2',
  'application/zip',
  'application/x-7z-compressed',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
];
const DocumentSchema = Yup.object().shape({
  size: Yup.number().max(20971520, i18n.t('form.validation.file_size')),
  type: Yup.string().oneOf(
    validMimeTypes,
    i18n.t('form.validation.file_extension'),
  ),
});

export default DocumentSchema;
