import { Dayjs } from 'dayjs';

export enum ResultTypes {
  RESULT_TYPE_TOTAL_VALIDATION = 'total_validation',
  RESULT_TYPE_PARTIAL_VALIDATION = 'partial_validation',
  RESULT_TYPE_FAILURE = 'failure',
  RESULT_TYPE_RESIGNATION = 'resignation',
  RESULT_TYPE_PE_AE_BREAK = 'pe_ae_break',
  RESULT_TYPE_PE_EU_BREAK = 'pe_eu_break',
  RESULT_TYPE_AE_BREAK = 'ae_break',
  RESULT_TYPE_EU_BREAK = 'eu_break',
  RESULT_TYPE_UNDEFINED = 'undefined',
}

export interface TrainingActionCandidateTraining {
  id: string;
  label: string;
  resultType: ResultTypes | undefined;
  trainingCourseTrainingId: string;
  trainingActionCandidateId: string;
  validatedAt: Dayjs | null;
  createdAt: Dayjs | null;
  updatedAt: Dayjs | null;
}

export interface TrainingActionCandidateTrainingHttp {
  type: 'candidate_trainings';
  id: string;
  attributes: {
    label: string;
    result_type: string;
    validated_at: string;
    createdAt: string;
    updatedAt: string;
  };
  relationships: {
    training_course_training: {
      data?: {
        type: 'training_course_trainings';
        id: string;
      };
    };
    training_action_candidate: {
      data?: {
        type: 'training_action_candidates';
        id: string;
      };
    };
  };
}

export interface TrainingActionCandidateTrainingWizard {
  type: 'candidate_trainings';
  id: string;
  attributes: {
    label: string;
    result_type: string;
    validated_at: string;
  };
  relationships: {
    training_course_training: {
      data: {
        type: 'training_course_trainings';
        id: string;
      };
    };
    training_action_candidate: {
      data: {
        type: 'training_action_candidates';
        id: string;
      };
    };
  };
}
