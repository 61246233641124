import { Dayjs } from 'dayjs';

export enum MANAGER_INTERNAL_ROLES {
  ADMIN = 'admin',
  MANAGER = 'manager',
  CPEF = 'cpef',
}

export interface ManagerHttp {
  type: 'manager_repositories';
  id: string;
  relationships: {
    supervisor: {
      data: {
        id: string;
      } | null;
    };
  };
  attributes: {
    avatar: string;
    email: string;
    first_name: string;
    initials: string;
    last_name: string;
    service: string;
    last_connection: string | null;
    internal_role: MANAGER_INTERNAL_ROLES;
    title: string;
    has_signature: boolean;
    createdAt: string;
    updatedAt: string;
  };
}

export type CpefManagerHttp = ManagerHttp;

export interface Manager {
  id: string;
  avatar: string;
  email: string;
  fullName: string;
  firstName: string;
  initials: string;
  lastName: string;
  service: string;
  title: string;
  internalRole: MANAGER_INTERNAL_ROLES;
  lastConnection: Dayjs | null;
  hasSignature: boolean;
  createdAt: Dayjs;
  updatedAt: Dayjs;
  supervisor: Manager | null;
}

export interface ManagerWizard {
  type: 'manager_repositories';
  id: string;
  attributes: {
    avatar: string;
    email: string;
    first_name: string;
    initials: string;
    last_name: string;
    service: string;
    title: string;
    internal_role: string;
    signature_encoded?: string;
  };
  relationships: {
    supervisor: {
      data: {
        type: 'manager_repositories';
        id: string;
      } | null;
    };
  };
}
